<tour-step-template>
    <ng-template let-step="step">
        <div class="row">
        <div>
        <button mat-icon-button [disabled]="!iTourService.hasPrev(step)"
        [ngClass]="{'inactive-btn' : !iTourService.hasPrev(step)}" class="ml-2"
        (click)="iTourService.prev()"> <mat-icon>arrow_back</mat-icon></button>
    </div>
    <div class="ngxp-end">
        <button mat-flat-button (click)="iTourService.end()">{{step?.endBtnTitle}}</button>
    </div>
    </div>
        <div class="ngxp-title" [ngClass]="{'ngxp-first-step' : !iTourService.hasPrev(step)}">
            <div class="ngxp-title-text">
                {{step?.title}}
        </div> 
           
        </div>
        <img src="../../assets/icon/guided_tour.png" width="60px">
        <div class="ngxp-content mb-3">{{step?.content}}</div>
        <div class="tour-step-navigation" style="display: flex;
        justify-content: center;
        align-items: center;">
            
            <button mat-raised-button color="primary" [disabled]="!iTourService.hasNext(step)"
                [ngClass]="{'inactive-btn' : !iTourService.hasNext(step)}" class="nextBtn"
                (click)="iTourService.next()">{{step?.nextBtnTitle}} »</button>
        </div>
    </ng-template>
</tour-step-template>

<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12 white-container">  
            <!-- <mat-toolbar>
                <h3> Image Upload Settings</h3>
            </mat-toolbar> -->
            <div class="card">
               
                <div class="card-body pt-4">
           
                    <form [formGroup]="imageSettingsForm" >
                    <div class="form-horizontal pl-2 card-body ">
                        <div style="float: right;" class="col-md-1 text-center">
                            <mat-icon matTooltip="Click for a guided Tour" class="pointer"  (click)="imageTour()">play_circle_outline</mat-icon> 
                            <h6 style="margin-top: 0mm;">Guided Tour</h6>
                        </div>
                        <div class="row pl-2">
                            <label for="logo"><strong>Logo Upload</strong></label>
                        </div>
                        <div class="row">
                        <div class="form-group col-md-6"  tourAnchor="cptour.istep1.id">
    
    
                                <input #myInput
                                [formControl]="imageSettingsForm.controls['logo']"  
                                type="file" 
                                accept=".jpg, .jpeg, .png"
                                class="upload-box"
                                (change)="onFileChangedLogo($event)">
                                <p style="color: red;">{{imageLogoError}}</p>
    
                         </div>
                         <div>
                            <button class=" ml-4 mr-2 green"  mat-raised-button [class.spinner]="uploadLogoVar" [disabled]="uploadLogoVar" [disabled]="continue" (click)="uploadLogo()">
                               Upload Logo</button>
                           </div>
                        </div>
                        
                        <span class="ml-2 pb-2" tourAnchor="cptour.istep3.id"> <strong>Upload New Slides</strong></span>
                        
                        
                        <div class="row">
                            
                            <div class="ml-2 col-md-3 pb-2">
                                <div class="row pl-2">
                                    <label for="title">Title for Slide</label>
                                </div>
                                <div class="row pl-2">
                                    <input type="text"  id="title" formControlName="bannerText" class="form-control">
                                </div>
                            </div>
                            <!--
                                <div class="col-md-3 pb-2">
                                    <label for="description">Description for Slide</label>
                                    <input type="text" id="description" formControlName='bannerDescription'  class="form-control">
                                </div> -->
                                
                                <div class="col-md-6 ml-2 pb-2 ">
                                    <div class="row">
                                        <label for="file">Slide Image</label>
                                    </div>
                                    <div class="row">
                                        <input 
                                        formControlName='bannerImage'
                                        id="file" 
                                        type="file" 
                                        class="upload-box"
                                        multiple="" 
                                        (change)="onFileChanged($event)">
                                        <span style="color: red;">{{imageError}}</span>
                                    </div>
                                </div>
                                <div>
                                    <button class="mt-3  ml-2 mr-2 green"  mat-raised-button [class.spinner]="isUploading" [disabled]="isUploading" [disabled]="continue" [disabled]="imageSizeError" (click)="uploadBanner()">
                                        Upload Image</button>
                                    </div>
                                </div>
                                
                                <div class="form-group pl-2" tourAnchor="cptour.istep2.id"><strong>Slides for Banner</strong></div>
                                
                                <div class="pl-2 " *ngFor="let slides of bannerImages; let i=index" >
                                  <mat-grid-list cols="2" rowHeight="60px" class="bg">
                                    <mat-grid-tile>
                                        
                                        <mat-icon>image</mat-icon>
                                  {{i+1}} .   {{slides.name}} &nbsp;&nbsp;&nbsp;&nbsp; {{slides.title}}
                              </mat-grid-tile>
                            <mat-grid-tile>
                                  <button mat-icon-button class="ml-2"  (click)="deleteBanner(slides.id)"><mat-icon>delete</mat-icon></button>
                              </mat-grid-tile>
                               
                              </mat-grid-list>
                            </div>
                                <!-- <div fxFlex="100" class="form-group col-md-6 mt-3"  tourAnchor="cptour.istep4.id" >
                                    <button mat-raised-button class="green" (click)="submitImages()">Save</button> -->
                                    <button mat-button color="accent" mat-raised-button type="button" class="mt-3 ml-3" (click)="continueToFooter()">Continue</button>  
                                    
                                    <!-- <button mat-button color="warn" type="button" class="popup_cancel_btn" (click)="Reset()">Close</button> -->
                                    <!-- </div>  -->
                                    
                                </div>
                 </form>
            
            </div>
        </div>
    </div>
</div>
</div>

