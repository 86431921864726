<div class="row">
  <div class="col-lg-3 pr-0 white-container">
    <mat-nav-list>
      <mat-list-item >
       
        <button  mat-flat-button class="btn-block test"  [ngClass]="this.selected == 1 ? 'tab_selected' : 'tab_unselected'" (click)="toggleColor(1)" 
          (click)="openWarehouseSettings()">
          <img src={{warehouseImgUrl}} alt="" style="height:20px;" class="pr-1">
          WareHouse</button>
      </mat-list-item>

      <mat-divider></mat-divider>

      <mat-list-item>
       
        <button class="btn-block test" [ngClass]="this.selected == 2 ? 'tab_selected' : 'tab_unselected'" (click)="toggleColor(2)"
        (click)="openGeneralSettings()" mat-flat-button>
        <img src={{generalImgUrl}} alt="" style="height:20px" class="pr-1">General</button>
      </mat-list-item>
      <mat-divider></mat-divider>

      <mat-list-item>
        
        <button (click)="openNotificationSettings()" mat-flat-button class="btn-block test"
        [ngClass]="this.selected == 3 ? 'tab_selected' : 'tab_unselected'" (click)="toggleColor(3)">
          <img src={{notificationImgUrl}} alt="" style="height:20px" class="pr-1">
          Notification </button>
      </mat-list-item>
      <mat-divider></mat-divider>

      <mat-list-item>
       
        <button (click)="openOfsSettings()" mat-flat-button class="btn-block test"
        [ngClass]="this.selected == 4 ? 'tab_selected' : 'tab_unselected'" (click)="toggleColor(4)">
          <img src={{ofsImgUrl}} alt="" style="height:20px" class="pr-1">
          OFS</button>
      </mat-list-item>
      <mat-divider></mat-divider>


      <mat-list-item>
       
        <button (click)="openProfileSettings()" mat-flat-button class="btn-block test"
        [ngClass]="this.selected == 5? 'tab_selected' : 'tab_unselected'" (click)="toggleColor(5)" >
          <img src={{profileImgUrl}} alt="" style="height:20px" class="pr-1">
          Profile</button>
      </mat-list-item>
      <mat-divider></mat-divider>

      <mat-list-item>
       
        <button (click)="openImageUploadSettings()" mat-flat-button class="btn-block test"
        [ngClass]="this.selected == 6 ? 'tab_selected' : 'tab_unselected'" (click)="toggleColor(6)">
          <img src={{imagesImgUrl}} alt="" style="height:20px" class="pr-1">Upload Images</button>
      </mat-list-item>
      <mat-divider></mat-divider>

      <mat-list-item>
       
        <button (click)="openFooterSettings()" mat-flat-button class="btn-block test"
        [ngClass]="this.selected == 7 ? 'tab_selected' : 'tab_unselected'" (click)="toggleColor(7)">
          <img src={{footerImgUrl}} alt="" style="height:18px" class="pr-1">
          Custom Footer</button>
      </mat-list-item>
      <mat-divider></mat-divider>

      <mat-list-item *ngIf="enableErp">
       
        <button (click)="openErpSettings()" mat-flat-button class="btn-block test"
        [ngClass]="this.selected == 8 ? 'tab_selected' : 'tab_unselected'" (click)="toggleColor(8)">
          <img src={{erpImgUrl}} alt="" style="height:18px" class="pr-1">
          ERP Settings</button>
      </mat-list-item>
      <mat-divider></mat-divider>

      <mat-list-item *ngIf="enableWebhook">
       
        <button (click)="openWebhookSettings()" mat-flat-button class="btn-block test"
        [ngClass]="this.selected == 9 ? 'tab_selected' : 'tab_unselected'" (click)="toggleColor(9)">
          <img src={{webhookImgUrl}} alt=""  class="pr-1">
          Webhook Settings</button>
      </mat-list-item>
      <mat-divider></mat-divider>

    </mat-nav-list>
  </div>
  <div *ngIf="warehouseSettings" class="col-lg-9 pl-0">

    <app-warehouse></app-warehouse>
  </div>
  <div *ngIf="generalSettings" class="col-lg-9 pl-0">

    <app-general></app-general>
  </div>
  <div *ngIf="showNotification" class="col-lg-9 pl-0">

    <app-notifications></app-notifications>
  </div>
  <div *ngIf="showOfs" class="col-lg-9 pl-0">

    <app-ofs></app-ofs>
  </div>
  <div *ngIf="showProfile" class="col-lg-9 pl-0">

    <app-profile></app-profile>
  </div>
  <div *ngIf="showImages" class="col-lg-9 pl-0">

    <app-images></app-images>
  </div>
  <div *ngIf="showFooter" class="col-lg-9 pl-0">

    <app-footersettings></app-footersettings>
  </div>
  <div *ngIf="showErpSettings" class="col-lg-9 pl-0">

    <app-erpsettings></app-erpsettings>
  </div>
  <div *ngIf="showWebhook" class="col-lg-9 pl-0">

    <app-webhooksettings></app-webhooksettings>
  </div>
  <router-outlet></router-outlet>
</div>