<tour-step-template>
    <ng-template let-step="step">
        <div class="row">
        <div>
        <button mat-icon-button [disabled]="!oTourService.hasPrev(step)"
        [ngClass]="{'inactive-btn' : !oTourService.hasPrev(step)}" class="ml-2"
        (click)="oTourService.prev()"> <mat-icon>arrow_back</mat-icon></button>
    </div>
    <div class="ngxp-end">
        <button mat-flat-button (click)="oTourService.end()">{{step?.endBtnTitle}}</button>
    </div>
    </div>
        <div class="ngxp-title" [ngClass]="{'ngxp-first-step' : !oTourService.hasPrev(step)}">
            <div class="ngxp-title-text">
                {{step?.title}}
        </div> 
           
        </div>
        <img src="../../assets/icon/guided_tour.png" width="60px">
        <div class="ngxp-content mb-3">{{step?.content}}</div>
        <div class="tour-step-navigation" style="display: flex;
        justify-content: center;
        align-items: center;">
            
            <button mat-raised-button color="primary" [disabled]="!oTourService.hasNext(step)"
                [ngClass]="{'inactive-btn' : !oTourService.hasNext(step)}" class="nextBtn"
                (click)="oTourService.next()">{{step?.nextBtnTitle}} »</button>
        </div>
    </ng-template>
</tour-step-template>
<div class="container">
    <div class="row">
        <div class="col-lg-12 white-container">


           <!--  <mat-toolbar>
                OFS Settings
            </mat-toolbar> -->
            <div class="card">
                <div class="card-body pt-4">
 <div class="example-small-box mat-elevation-z4">

    <form [formGroup]="ofsSettingsForm" >
        <div class="form-horizontal card-body">
            <div style="float: right;" class="col-md-1 text-center">
                <mat-icon matTooltip="Click for a guided Tour" class="pointer"  (click)="ofsTour()">play_circle_outline</mat-icon> 
                <h6 style="margin-top: 0mm;">Guided Tour</h6>
            </div>
            <div class="form-group col-md-6 ws-guided-tour ostep1" tourAnchor="cptour.ostep1.id">
                <!-- <label for="OFname" class="bold">Name *</label> -->
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Name </mat-label>
                    <input matInput type="text" id="OFname" [formControl]="ofsSettingsForm.controls['name']"   required>
                    <mat-error *ngIf="ofsSettingsForm.controls['name']?.errors?.required && ofsSettingsForm.controls['name'].touched">
                        this field required</mat-error>
                    </mat-form-field>
            </div> 
            <div class="row">
            <div class="form-group ml-2   ws-guided-tour ostep1"  >
                <!-- <label for="OFname" class="bold">Name *</label> -->
                
                <mat-form-field style="margin-bottom: -1.25em;
                font-size: 12px " appearance="outline">
                    <mat-label>Country</mat-label>
                <mat-select [formControl]="ofsSettingsForm.controls['countryCode']" [disabled]="codeSelected" placeholder="Country Code">
                    <mat-option *ngFor="let item of countryList" [value]="item.countryCode" required>
                        {{item.countryCode}}
                    </mat-option>
                </mat-select>
                
              </mat-form-field> 

            </div> 
            <div class="form-group ws-guided-tour ostep1" tourAnchor="cptour.ostep2.id">
                <!-- <label for="OFname" class="bold">Name *</label> -->
                <mat-form-field  style="padding-left: 18px; margin-bottom: -1.25em;
                font-size: 12px " appearance="outline" >
                    <mat-label>Phone </mat-label>
                    <input matInput type="tel" id="OFPhone" [formControl]="ofsSettingsForm.controls['phone']" >
                </mat-form-field>
             </div> 
            </div>
            <div class="form-group col-md-6 ws-guided-tour ostep2" tourAnchor="cptour.ostep3.id">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Login ID</mat-label>
                    <!-- <label for="OFlogin" class="bold">Login ID *</label> -->
                    <input matInput type="text" id="OFlogin" [readOnly]="loginSelected" [formControl]="ofsSettingsForm.controls['loginId']"   required>
                    <mat-error *ngIf="ofsSettingsForm.controls['loginId']?.errors?.required && ofsSettingsForm.controls['loginId'].touched">
                        this field required</mat-error>
                    </mat-form-field>
            </div> 
             <div *ngIf='ofsId !== "NEW"' class="form-group col-md-6 ws-guided-tour ostep3 " tourAnchor="cptour.ostep4.id">
                <div class="example-margin">
                    <mat-slide-toggle id="customCheck1" [formControl]="ofsSettingsForm.controls['changePassword']"
                    [(ngModel)]="checked"  (change)="passwordChange()" >Change Password</mat-slide-toggle>
                    <!-- <input  type="checkbox" class="custom-control-input" [formControl]="ofsSettingsForm.controls['changePassword']"  id="customCheck1" (change)="passwordChange=!passwordChange;">
                    <label class="custom-control-label bold" for="customCheck1"> Change Password</label> -->
                </div>
             </div> 
           <div> 
         <div class="form-group col-md-6 " >
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>Password</mat-label>
                <!-- <label for="OFpwd" class="bold">Password *</label> -->
                <input matInput type="password" id="OFpwd" [readOnly]="passwordSelected" [formControl]="ofsSettingsForm.controls['password']" required>
                <mat-error *ngIf="ofsSettingsForm.controls['password']?.errors?.required && ofsSettingsForm.controls['password'].touched">
                    this field required</mat-error>
                </mat-form-field>
            </div> 
            <div class="form-group col-md-6" >
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Confirm Password</mat-label>
                    <!-- <label for="OFcpwd" class="bold">Confirm Password *</label> -->
                    <input matInput type="password" id="OFcpwd" [readOnly]="passwordSelected" [formControl]="ofsSettingsForm.controls['confirmPassword']" required>
                    <mat-error *ngIf="ofsSettingsForm.controls['confirmPassword']?.errors?.required && ofsSettingsForm.controls['confirmPassword'].touched">
                        this field required</mat-error>
                        <mat-error *ngIf="ofsSettingsForm.controls['confirmPassword']?.errors?.mustMatch  && ofsSettingsForm.controls['confirmPassword'].touched"> Passwords must match </mat-error>   
                    </mat-form-field> 
            </div>
        </div> 
          <div fxFlex="100" class="form-group col-md-6 mt-2" tourAnchor="cptour.ostep5.id">
                <button mat-raised-button class="green" [disabled]="continue" (click)="submitOfs()">Save</button>
                <button *ngIf="showContinueButton" mat-button color="accent" mat-raised-button type="button" class="ml-3" (click)="continueToProfile()">Continue</button>
            </div> 
        </div>
    </form> 
</div>
                </div>
</div>
</div>
</div>
</div>