import {Pipe, PipeTransform} from '@angular/core';



@Pipe({name: 'livesummarypipe'})
export class Summarypipe implements PipeTransform {

    iconClasses={
        
        TO:"mdi mdi-cart-plus",
        I:"mdi mdi-flag",
        A:"mdi mdi-dump-truck",
        S:"mdi mdi-shield-check",
        DD:"mdi mdi-basket"

    }
  transform(livesummary:string) {
    //   console.log(livesummery.replace(" ",""))
      // switch (livesummery) {
      //     case "Today's Order":
      //       return this.iconClasses["TodaysOrder"]
      //         break;
      //     case "To be processed":
      //           return this.iconClasses["Tobeprocessed"]
      //         break;                  
      //     default:
      //       return this.iconClasses[livesummery]
      //         break;
      // }
      return this.iconClasses[livesummary]

  }
}


@Pipe({name: 'currencypipe'})
export class Currencypipe implements PipeTransform {

    iconClasses={
        
        INR:"mdi mdi-currency-inr",
        USD:"mdi mdi-currency-usd"
        

    }
  transform(currencyicon:string) {
  
            return this.iconClasses[currencyicon];
              
      }
   

  }



  @Pipe({
    name: 'thousandSuff'
  })
  export class ThousandSuffixesPipe implements PipeTransform {
  
    transform(input: any, args?: any): any {
      var exp, rounded,
        suffixes = ['K', 'M', 'G', 'T', 'P', 'E'];
  
      if (Number.isNaN(input)) {
        return null;
      }
  
      if (input < 1000) {
        return input;
      }
  
      exp = Math.floor(Math.log(input) / Math.log(1000));
  
      return (input / Math.pow(1000, exp)).toFixed(args) + suffixes[exp - 1];
  
  
    }
  
  }
   
