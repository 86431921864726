import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ControlPanelService } from '../../control-panel/control-panel.service';
import { WarehouseSettings } from '../../control-panel/models/WarehouseSettings.model'
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HostListener } from "@angular/core";
import { TourService } from 'ngx-tour-ngx-popper';
import { HttpErrorResponse } from '@angular/common/http';
import { GeneralSettings} from '../../control-panel/models/GeneralSettings.model';
import {SettingsComponent} from '../settings.component'

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css']
})


export class GeneralComponent implements OnInit {

  public generalSettingsForm: FormGroup;
  tempPrevTourStepCount = 0;
  tempPrevTourStep;
  successMessage: any;
  id = 0;
  saveGeneralSettings: GeneralSettings;
  warehouseSettingsDetails: any;
  generalSettingsDetails: any;
  timeZoneList: any;
  errorMessage:any;
  checked = false;
  checkedErp=false;
  checkedWebhook=false;
  checkedProductImages:boolean;
  checkedTaxableProducts:boolean;
  checkedInventoryCountForSalesman:boolean;
  checkedOfflineSalesmanApp: boolean;
  countryList:any;
  hideSubmitStep = false;
  hidePrevStep = false;
  continue=false;
  timezoneSelected=false;
  currencySelected=false;
  showContinueButton=false;
  

  constructor(
    private fb: FormBuilder,
    private controlPanelService: ControlPanelService,
    public activateRoute: ActivatedRoute,
    private router: Router,
    public gTourService: TourService,
    private toastr: ToastrService,
    private settings:SettingsComponent
  ) 
  {}

  public genSettingsTour(): void {
    this.gTourService.disableHotkeys();
    this.gTourService.start();
  }

  ngOnInit(): void {

    this.controlPanelService.getwarehouseSettings().subscribe(
      res => {
        this.warehouseSettingsDetails = res.warehouseSettings;
        if(this.warehouseSettingsDetails === null)
        {
        this.toastr.error("Please Update the WareHouse Settings first to Continue","ERROR")
          this.continue=true;
        }
        else
        this.id = this.warehouseSettingsDetails.shopId;
      });

     

    this.generalSettingsForm = this.fb.group({
      displayName: ['' || ''],
      timeZone: ['' || ''],
      mov: ['' || '',],
      moq: [1],
      shippingCost: [''|| ''],
      currency: ['' || ''],
      taxNumber:[''||''],
      orderPrefix:[''||''],
      displayProdImages:[false],
      taxableProducts:[false],
      enableInventory:[''||''],
      enableInventoryCountForSalesman:[false],
      enableOfflineSalesmanApp:[false],
      enableERP:[''||''],
      enableWebhook:[''||''],

     });

     this.controlPanelService.tenantCountryList().subscribe(
      res => {
        this.countryList = res.country;
      }
    );

    this.controlPanelService.timeZoneList().subscribe(
      res => {
        this.timeZoneList = res.timeZone;
      }
    );

     

     this.controlPanelService.getgeneralSettings().subscribe(
      res => {
        this.generalSettingsDetails = res.generalSettings;
        if(this.generalSettingsDetails)
        { 
        this.generalSettingsForm.controls['displayName'].setValue(this.generalSettingsDetails.displayName);
        this.generalSettingsForm.controls['timeZone'].setValue(this.generalSettingsDetails.timeZone);
        if(this.generalSettingsDetails.timeZone){
          this.timezoneSelected=true;
        }
        this.generalSettingsForm.controls['mov'].setValue(this.generalSettingsDetails.mov);
        
        if(this.generalSettingsDetails.moq === "0")
        this.generalSettingsForm.controls['moq'].setValue("1")
        else
        this.generalSettingsForm.controls['moq'].setValue(this.generalSettingsDetails.moq);
        this.generalSettingsForm.controls['currency'].setValue(this.generalSettingsDetails.currency);
        if(this.generalSettingsDetails.currency){
          this.currencySelected=true;
        }
        this.generalSettingsForm.controls['shippingCost'].setValue(this.generalSettingsDetails.shippingCost);

        if(this.generalSettingsDetails.taxNumber !== "null")
        this.generalSettingsForm.controls['taxNumber'].setValue(this.generalSettingsDetails.taxNumber);
        else
        this.generalSettingsForm.controls['taxNumber'].setValue("");
        if(this.generalSettingsDetails.orderPrefix!== "null")
        this.generalSettingsForm.controls['orderPrefix'].setValue(this.generalSettingsDetails.orderPrefix);
        else
        this.generalSettingsForm.controls['orderPrefix'].setValue("");
        this.generalSettingsForm.controls['displayProdImages'].setValue(this.generalSettingsDetails.displayProductImages);
        if(!this.generalSettingsDetails.displayProdImages)
        {
          this.generalSettingsForm.controls['displayProdImages'].setValue(false);
        }
        this.generalSettingsForm.controls['taxableProducts'].setValue(this.generalSettingsDetails.taxableProducts);
        if(!this.generalSettingsDetails.taxableProducts)
        {
          this.generalSettingsForm.controls['taxableProducts'].setValue(false);
        }
        if(this.generalSettingsDetails.displayProductImages)
        this.checkedProductImages=true;
        if(this.generalSettingsDetails.taxableProducts === true)
        this.checkedTaxableProducts=true;
        if(this.generalSettingsDetails.enableInventory === true)
        this.checked=true;
        if(this.generalSettingsDetails.enableInventoryCountForSalesman === true)
        this.checkedInventoryCountForSalesman=true;
        if(this.generalSettingsDetails.enableOfflineSalesmanApp === true)
        this.checkedOfflineSalesmanApp=true;
        if(this.generalSettingsDetails.enableErp === true)
        this.checkedErp=true;
        if(this.generalSettingsDetails.enableWebhook === true)
        this.checkedWebhook=true;
        }
      }
    );

    this.gTourService.initialize([
      {
        stepId : 'cptour.gstep1.id',
        anchorId: 'cptour.gstep1.id',
        content: 'Warehouse name as seen by Buyers' ,
        title: 'Display name',
        endBtnTitle:'Skip',
        placement:"right",
        
        
       
      },
      {  
        stepId : 'cptour.gstep2.id',
        anchorId: 'cptour.gstep2.id',
        content: 'Currency used for the transaction' ,
        title: 'Currency',
        placement:'right',
        endBtnTitle:'Skip',
        
      },
  
  {  
        stepId:'cptour.gstep3.id',
        anchorId: 'cptour.gstep3.id',
        content: 'Shipping cost for a transaction' ,
        title: 'Shipping cost',
        placement:'right',
        endBtnTitle:'Skip',
        },
      {
        
        stepId:'cptour.gstep4.id',
        anchorId: 'cptour.gstep4.id',
        content: 'Please select the timezone you are in' ,
        title: 'Time Zone',
        placement:'right',
        endBtnTitle:'Skip',
       
      },
      {
        stepId:'cptour.gstep5.id',
        anchorId: 'cptour.gstep5.id',
        title: 'Minimum Order Quantity',
        content: 'The global setting for Minimum Order quantity' ,
        placement:'right',
        endBtnTitle:'Skip',
        
        
      },
  
      {
        stepId:'cptour.gstep6.id',
        anchorId: 'cptour.gstep6.id',
        title: 'Minimum Order Value' ,
        content: 'The global setting for Minimum Order Value' ,
        placement:'right',
        endBtnTitle:'Skip',
        
        
      },
      {
        stepId:'cptour.gstep7.id',
        anchorId: 'cptour.gstep7.id',
        title: 'Tax information' ,
        content: 'Please enter the Tax or GST number here' ,
        placement:'right',
        endBtnTitle:'Skip',
        
        
      },
      {
        stepId:'cptour.gstep8.id',
        anchorId: 'cptour.gstep8.id',
        title: 'Order Prefix' ,
        content: 'Please enter 4 digit Order Prefix' ,
        placement:'right',
        endBtnTitle:'Skip',
        
        
      },
      {
        stepId:'cptour.gstep9.id',
        anchorId: 'cptour.gstep9.id',
        title: 'Enable Inventory',
        content: 'Please check the box if you want the buyer to see the inventory/stock information' ,
        placement:'right',
        endBtnTitle:'Skip',
        
        
      },
      {
        stepId:'cptour.gstep10.id',
        anchorId: 'cptour.gstep10.id',
        title: 'Enable ERP',
        content: 'Please check the box if you want to enable ERP' ,
        placement:'right',
        endBtnTitle:'Skip',
        
        
      },
      {
        stepId:'cptour.gstep11.id',
        anchorId: 'cptour.gstep11.id',
        title: 'Enable Wehook',
        content: 'Please check the box if you want to enable Webhook' ,
        placement:'right',
        endBtnTitle:'Skip',
        
        
      },
      {
        anchorId: 'cptour.gstep12.id',
        content: 'Please press this button to save all the above information',
        title: 'Save and Submit',
        placement:'right',
        
       } ],
      {route: 'settings',
      });
  }

      @HostListener('click',['$event'])mouseover(event) {  
        if (this.gTourService.currentStep) {
          const nextStep = $(event.target).closest(".ws-guided-tour").attr("tourAnchor")
          if (nextStep && this.tempPrevTourStep !== nextStep) {
  
            this.gTourService.startAt(nextStep);
            this.tempPrevTourStep = nextStep;
          }      
        }
      };
      @HostListener('document:keydown.tab', ['$event'])
      onKeydownHandler(event: KeyboardEvent) {
       if (this.gTourService.currentStep) {
             this.gTourService.next();
       
         }    
       }
    
       
       
       onChange() {
         
        this.controlPanelService.checkBoxSubject$.next(this.checkedErp);
      }

      onSelect(){
        this.controlPanelService.webHookSubject$.next(this.checkedWebhook);
      }

      onChangeInventory() {
         
        this.controlPanelService.inventorySubject$.next(this.checked);
      }


  
  continueToNotification(){
    this.settings.openNotificationSettings();
    this.settings.toggleColor(3);
  }

  Submit() {
    
    if (this.generalSettingsForm.valid) {
       
      var obj: GeneralSettings = this.generalSettingsForm.value
      obj['shopId'] = this.warehouseSettingsDetails.shopId;
      if(obj['shippingCost'] === null || obj['shippingCost'] === 'null' )
      obj['shippingCost']= '0';
      if(obj['orderPrefix'] === null || obj['orderPrefix'] === 'null' )
      obj['orderPrefix']= '';
      this.controlPanelService.savegeneralSettings(obj).subscribe(
        (res: any) => {
          if (res.acknowledgement.status === "SUCCESS") {
            this.showContinueButton=true;
            this.successMessage = res.acknowledgement.message
            this.toastr.success( this.successMessage,"SUCCESS");
            this.controlPanelService.getprofileSettings().subscribe(
              res => {
                if(res.profileSettings === null)
                this.toastr.info("Please update Profile settings before publishing the Warehouse", "INFO")
              });
          }
          else{
            this.errorMessage = res.acknowledgement.message
            this.toastr.error( this.errorMessage,"ERROR")
          }
          
        },

       
        (err:HttpErrorResponse) => {
          
          if(err)
          {
             this.errorMessage=err.error.acknowledgement.message
            this.toastr.error(this.errorMessage,"ERROR")
          }
          else{
            this.toastr.error("Sorry,the information was not updated,Please try again later","ERROR")
          }
       }
      );
    }


  }

}
