import { SubscriptionComponent } from './subscription/subscription.component';
import { SetpasswordComponent } from './setpassword/setpassword.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { NewuserComponent } from './newuser/newuser.component';
import { AppComponent } from './app.component';
import { MenusComponent } from './menus/menus.component';
import {MyNavbarComponent} from './my-navbar/my-navbar.component';
import { ReportComponent } from './report/report.component';
import { NgModule } from '@angular/core';
import { DbComponent } from './db/db.component';
import { DashComponent } from './dash/dash.component';
import { AuthGuard } from './Service/AuthGuard';
import { LoginComponent } from './login/login.component';
import { Routes, RouterModule } from '@angular/router';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { ControlPanelComponent } from './control-panel/control-panel.component';
import {BuyersComponent} from './buyers/buyers.component';
import {SettingsComponent} from './settings/settings.component';
import {GeneralComponent}  from './settings/general/general.component';
import {WarehouseComponent} from './settings/warehouse/warehouse.component';
import {NotificationsComponent} from './settings/notifications/notifications.component';
import {OfsComponent} from './settings/ofs/ofs.component';
import {SSOComponent} from './sso/sso.component';
import {ProfileComponent} from './settings/profile/profile.component';
import {ImagesComponent} from './settings/images/images.component';
import {FootersettingsComponent} from './settings/footersettings/footersettings.component';
import {WebhooksettingsComponent} from './settings/webhooksettings/webhooksettings.component';
import {ERPsettingsComponent} from './settings/erpsettings/erpsettings.component';
import { BuyerFormPageComponent } from './buyers/buyer-form-page/buyer-form-page.component';
import { SalesMenComponent } from './sales-men/sales-men.component';
import { StockComponent } from './stock/stock.component';
import { CatalogManagerComponent } from './catalog-manager/catalog-manager.component';
import { ProductUploadComponent } from './product-upload/product-upload.component';

const routes: Routes = [
  // {path:'./root',component:DbComponent},
  
  { path: '', pathMatch: 'full', redirectTo: '/login' },
  {
    path: '',
    component: AppComponent,
    children: [
      {
        path:'login/sso/:token',
        component:SSOComponent
      }, 
      {
        path:'catalogManager',
        component:CatalogManagerComponent,
       
      },
      {
        path: 'login',
         component:LoginComponent,
        // loadChildren: () => import('./login/login.component').then(m => m.AdminModule),
      },     
      {
        path:'signup',
        component:NewuserComponent
      },
      {
        path:'resetpassword',
        component:ResetpasswordComponent
      }, 
      {
        path:'setpassword',
        component:SetpasswordComponent
      },
      {
        path:'authenticateuser/:authtoken',
        component:SetpasswordComponent
      },
      
    ]
  },

{
  path: '',
    component: MyNavbarComponent,
    canActivate: [AuthGuard],
    children: [
    /*   {
        path: 'dashboard',
        component:DbComponent,
        data: { title: 'Dashboard',  }
      },  */
       {
        path: 'dashboard',
        component:DashComponent,
        data: { title: 'Dashboard',  }
      },  
      {
        path: 'report',
        component:ReportComponent,
        data: { title: 'report',  }
      },
      {
        path: 'subscription',
        component:SubscriptionComponent,
        data: { title: 'subscription',  }
      },
      {
        path: 'settings',
        component:SettingsComponent,
        data: { title: 'settings',  }
      },
      {
        path: 'buyers',
        component:BuyersComponent,
        data: { title: 'buyers',  }
      },
      {
        path: 'productupload',
        component:ProductUploadComponent,
        data: { title: 'Product Upload',  }
      },
      {
        path: 'salesexecutives',
        component:SalesMenComponent,
        data: { title: 'salesmen',  }
      },
      {
        path: 'stock',
        component:StockComponent,
        data: { title: 'stock',  }
      },

      {
        path:  'buyer-form',
        component:BuyerFormPageComponent,
        data:{  title:  'addbuyer'}
      },
      {
        path: 'warehousesettings',
        component:WarehouseComponent,
        data: { title:'warehousesettings',}
      },
      {
        path: 'generalsettings',
        component:GeneralComponent,
        data: { title: 'generalsettings',  }
      },
      {
        path: 'notificationsettings',
        component:NotificationsComponent,
        data: { title: 'notificationsettings',  }
      },
      {
        path: 'ofssettings',
        component:OfsComponent,
        data: { title: 'ofssettings',  }
      },

      {
        path: 'profilesettings',
        component:ProfileComponent,
        data: { title: 'profilesettings',  }
      },
      {
        path: 'imagesettings',
        component:ImagesComponent,
        data: { title: 'imagesettings',  }
      },
      {
        path: 'footersettings',
        component:FootersettingsComponent,
        data: { title: 'footersettings',  }
      },
      {
        path: 'webhooksettings',
        component:WebhooksettingsComponent,
        data: { title: 'webhooksettings',  }
      },
      {
        path: 'erpsettings',
        component:ERPsettingsComponent,
        data: { title: 'erpsettings',  }
      },
     



    ]
  },
  {
        path: '**',
         component: PagenotfoundComponent
      }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
