<tour-step-template>
  <ng-template let-step="step">
      <div class="row">
      <div>
      <button mat-icon-button [disabled]="!bTourService.hasPrev(step)"
      [ngClass]="{'inactive-btn' : !bTourService.hasPrev(step)}" class="ml-2"
      (click)="bTourService.prev()"> <mat-icon>arrow_back</mat-icon></button>
  </div>
  <div class="ngxp-end">
      <button mat-flat-button (click)="bTourService.end()">{{step?.endBtnTitle}}</button>
  </div>
  </div>
      <div class="ngxp-title" [ngClass]="{'ngxp-first-step' : !bTourService.hasPrev(step)}">
          <div class="ngxp-title-text">
              {{step?.title}}
      </div> 
         
      </div>
      <img src="../../assets/icon/guided_tour.png" width="60px">
      <div class="ngxp-content mb-3">{{step?.content}}</div>
      <div class="tour-step-navigation" style="display: flex;
      justify-content: center;
      align-items: center;">
          
          <button mat-raised-button color="primary" [disabled]="!bTourService.hasNext(step)"
              [ngClass]="{'inactive-btn' : !bTourService.hasNext(step)}" class="nextBtn"
              (click)="bTourService.next()">{{step?.nextBtnTitle}} »</button>
      </div>
  </ng-template>
</tour-step-template>

<div class="mat-elevation-z8">
  <div class="row">
    <div class="col-lg-2 pr-0 white-container">
      <mat-nav-list>
        <mat-list-item >
          <button  mat-flat-button class="btn-block test"  [ngClass]="this.selected == 1 ? 'tab_selected' : 'tab_unselected'" (click)="toggleColor(1)" 
          (click)="openBuyer()">
          <img src={{buyerImgUrl}} alt="" style="height:20px;" class="pr-1">
          Buyers</button>
      </mat-list-item>

      <mat-divider></mat-divider>

      <mat-list-item>
       
        <button tourAnchor="btour.step14.id" class="btn-block test" [ngClass]="this.selected == 2 ? 'tab_selected' : 'tab_unselected'" (click)="toggleColor(2)"
        (click)="openGroups()" mat-flat-button>
        <img src={{groupImgUrl}} alt="" style="height:20px" class="pr-1">Buyer Groups</button>
      </mat-list-item>
      <mat-divider></mat-divider>
        
       </mat-nav-list>
    </div>
    
    <div class="col-lg-10 pl-0">
 
  <!-- <div class="alert alert-info green" role="alert">
    <strong>Info!</strong> You have {{licenseCount}} licences left.
    </div> -->
    
       
     

  <div class="example-container" *ngIf="showBuyer">
    <div>
      <mat-card>
      <mat-card-content>
          <button mat-flat-button style="margin-right:30px" (click)="openBuyerForm()" color="primary" tourAnchor="btour.step1.id">
            <mat-icon style="font-size:18px; vertical-align: middle;">add</mat-icon>Add Buyer
          </button>
      
          <input #fileInput type="file" id="file" accept=".xlsx, .xls, .csv" (click)="fileInput.value = null" value="" (change)="onExcel($event)">
      
      
          <label for="file" tourAnchor="btour.step2.id">
            <mat-icon style="font-size:18px; vertical-align: middle; color:blue">upload</mat-icon><span style="color:blue">Upload as excel
              file</span>
          </label>
          <a mat-button href="assets/files/BuyersList.xlsx" style="margin:5px; color:blue" tourAnchor="btour.step3.id">
            <mat-icon style="font-size:18px; vertical-align: middle;">download</mat-icon>Download Sample File
          </a>
          <span style="color:blue" tourAnchor="btour.step4.id"><strong>Info!</strong> You have {{licenseCount}} licences left.</span>

          <div style="float: right;" class="col-md-1 text-center">
            <mat-icon matTooltip="Click for a guided Tour" class="pointer" (click)="buyerTour()">play_circle_outline
            </mat-icon>
            <h6 style="margin-top: 0mm;">Guided Tour</h6>
        </div>
        </mat-card-content>
       </mat-card>
  </div>
  <mat-card>
   
          <div  *ngIf="ifUploading" >
            <mat-spinner></mat-spinner>
          </div>

           
          
        
    
   
    <div class="col-lg-3 pl-0">
     <!--  <mat-form-field appearance="outline">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search"  autocomplete="new-search" tourAnchor="btour.step6.id">
        <mat-icon matSuffix>search</mat-icon>
        
      </mat-form-field> -->
     
    </div> 

 <!--    <button class="ml-2" style="margin-bottom: 20px;"  mat-raised-button class="green"  (click)="exporter.exportTable('xlsx',{fileName:getName()})">Buyer List <mat-icon style="font-size:19px; vertical-align: middle;">download</mat-icon></button>
  
      <button class="col-lg-3"  mat-raised-button class="green"  (click)="downloadApi()">Download Api Resonse</button>   -->    

     <div *ngIf="ifDownloading" fxLayout="row" fxLayoutAlign="center" style="height:50%">
      <mat-spinner diameter="50" strokeWidth="5"></mat-spinner>
    </div>
    <table mat-table [dataSource]="dataSource | paginate: { id: 'server', itemsPerPage: 20, currentPage: page, totalItems:dataLength }" matSort class="mat-elevation-z8" matTableExporter hiddenColumns=[3,6,7] #exporter="matTableExporter" (exportStarted)="startDownload()"  (exportCompleted)="endDownload()" tourAnchor="btour.step5.id">


      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> SN </th>
        <td mat-cell *matCellDef="let i =index;"> {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }}</td>
      </ng-container>

      
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef width ="13%"> NAME </th>
        <td mat-cell *matCellDef="let element" style="word-break: break-all;">&nbsp; <p>{{element.name}} </p></td>
      </ng-container>

     
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef> PHONE </th>
        <td mat-cell *matCellDef="let element"> &nbsp;{{element.countryId}} &nbsp; {{element.phone}} </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>EMAIL </th>
        <td mat-cell *matCellDef="let element">
          <a *ngIf="element.email" mat-icon-button href="mailto:{{element.email}}">
            <mat-icon matTooltip="{{element.email}}">email</mat-icon>
          </a>
          
        </td>
      </ng-container>   
     
      <ng-container matColumnDef="groupname" >
        <th mat-header-cell *matHeaderCellDef> BUYER GROUP </th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.group && element.group.groupName"> &nbsp; {{element.group.groupName}} </div></td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> STATUS </th>
        <td  mat-cell *matCellDef="let element">
        <div  *ngIf="element.status === 'A' " style="color:#435cc8;"> &nbsp;Active</div>
        <div  *ngIf="element.status != 'A'" style="color:#FE4831;"> &nbsp;In-Active</div>
      </td>

      </ng-container>

      <ng-container matColumnDef="pin">
        <th mat-header-cell *matHeaderCellDef style="text-align: center">PIN </th>
        <td mat-cell *matCellDef="let element" width ="13%"> 
        <div *ngIf="element.mainappuser">
            <show-hide-password size="sm" btnStyle="light">
              <input style="padding:0" type="password" value={{element.mainappuser.pin}} autocomplete="new-password" >
            </show-hide-password>
          </div>
          
      </ng-container>

      <!-- <ng-container matColumnDef="users" tourAnchor="btour.step8.id">
        <th mat-header-cell *matHeaderCellDef style="text-align: center"> USERS </th>
       
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button  style="margin-right:10px" (click)="addAppUser(element.id)"><mat-icon matTooltip="Add App User"> &nbsp;person_add</mat-icon></button>
          <button *ngIf="element.hasAppUser === 'T'" mat-icon-button  (click)="getUsersList(element.id)"><mat-icon matTooltip="View App Users"> &nbsp;people_alt</mat-icon></button>
          
      </ng-container> -->

      <ng-container matColumnDef="actions"  tourAnchor="btour.step9.id">
        <th mat-header-cell *matHeaderCellDef style="text-align:center"> ACTIONS </th>
        <td mat-cell *matCellDef="let element" width ="18%">
          
         <!--  <button class="editButton" *ngIf="element.status != 'A'" mat-stroked-button color="green" style="margin-right:25px" (click)="activateBuyer('A',element.id)"> <span style="color:#0AC80A;">Activate</span></button> 
          <button class="editButton" *ngIf="element.status === 'A'" mat-stroked-button color="pink" style="margin-right:10px" (click)="activateBuyer('I',element.id)"> <span style="color:#FE4831;">Deactivate</span></button>
          <button class="editButton" mat-stroked-button color="primary" (click)="openBuyerSettings(element)"><mat-icon style="font-size:20px; vertical-align: middle;">edit</mat-icon><span class="with-icon"style="color:blue;">Edit</span></button></td>
       -->
       <button class="editButton" mat-icon-button   (click)="addAppUser(element.id)"><mat-icon matTooltip="Add App User"> &nbsp;person_add</mat-icon></button>
       <button class="editButton" *ngIf="element.hasAppUser === 'T'" mat-icon-button  (click)="getUsersList(element.id)"><mat-icon matTooltip="View App Users">people_alt</mat-icon></button>
       <button class="editButton" mat-icon-button *ngIf="element.status != 'A'" (click)="activateBuyer('A',element.id)"><mat-icon matTooltip="Activate" style="color:#435cc8;">how_to_reg</mat-icon></button> 
       <button class="editButton" mat-icon-button  *ngIf="element.status === 'A'" (click)="activateBuyer('I',element.id)"> <mat-icon matTooltip="Deactivate" style="color:#FE4831;">person_off</mat-icon></button>
       <button class="editButton" mat-icon-button (click)="openBuyerSettings(element)"><mat-icon matTooltip="Edit">edit</mat-icon></button></td>
   
      
      </ng-container>

      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef [hidden]=true> ADDRESS </th>
        <td mat-cell *matCellDef="let element" [hidden]=true> &nbsp;<p>{{element.address.adline1}}</p> </td>
      </ng-container>

      <ng-container matColumnDef="street">
        <th mat-header-cell *matHeaderCellDef [hidden]=true> STREET </th>
        <td mat-cell *matCellDef="let element" [hidden]=true> &nbsp;<p>{{element.address.street}}</p> </td>
      </ng-container>

      <ng-container matColumnDef="city">
        <th mat-header-cell *matHeaderCellDef [hidden]=true> CITY </th>
        <td mat-cell *matCellDef="let element" [hidden]=true> &nbsp;<p>{{element.address.city}}</p> </td>
      
      </ng-container><ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef [hidden]=true> STATE </th>
        <td mat-cell *matCellDef="let element" [hidden]=true> &nbsp;<p>{{element.address.state}}</p> </td>
      </ng-container>

      <ng-container matColumnDef="country">
        <th mat-header-cell *matHeaderCellDef [hidden]=true> COUNTRY </th>
        <td mat-cell *matCellDef="let element" [hidden]=true> &nbsp;<p>{{element.address.country}}</p> </td>

      </ng-container>

      <ng-container matColumnDef="zip">
        <th mat-header-cell *matHeaderCellDef [hidden]=true> ZIP/PIN </th>
        <td mat-cell *matCellDef="let element" [hidden]=true> &nbsp;<p>{{element.address.zip}}</p> </td>
      </ng-container>
      
      <ng-container matColumnDef="appusername">
        <th mat-header-cell *matHeaderCellDef [hidden]=true> APP USER NAME </th>
        <td mat-cell  *matCellDef="let element" [hidden]=true>
          <div *ngIf="element.mainappuser" > &nbsp;<p>{{element.mainappuser.name}}</p> </div></td>
      </ng-container>

      <ng-container matColumnDef="appuserphone">
        <th mat-header-cell *matHeaderCellDef [hidden]=true> APP USER PHONE </th>
        <td mat-cell  *matCellDef="let element" [hidden]=true>
          <div *ngIf="element.mainappuser" > &nbsp;<p>{{element.mainappuser.phone}}</p> </div></td>
      </ng-container>

      <ng-container matColumnDef="appuseremail">
        <th mat-header-cell *matHeaderCellDef [hidden]=true> APP USER EMAIL </th>
        <td mat-cell  *matCellDef="let element" [hidden]=true>
          <div *ngIf="element.mainappuser" > &nbsp;<p>{{element.mainappuser.email}}</p> </div></td>
      </ng-container>

      <ng-container matColumnDef="appuserpin">
        <th mat-header-cell *matHeaderCellDef [hidden]=true> APP USER PIN </th>
        <td mat-cell  *matCellDef="let element" [hidden]=true>
          <div *ngIf="element.mainappuser" > &nbsp;<p>{{element.mainappuser.pin}}</p> </div></td>
      </ng-container>

      <ng-container matColumnDef="taxnumber">
        <th mat-header-cell *matHeaderCellDef [hidden]=true> TAX NUMBER </th>
        <td mat-cell *matCellDef="let element" [hidden]=true> &nbsp;<p>{{element.taxNumber}}</p> </td>
      </ng-container>


      
     



      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns ;"></tr>
    </table>

    <mat-paginator showFirstLastButtons  [length]="dataLength"
    [pageSize]="20" 
    showFirstLastButtons  
    (page)="pageChanged($event)"></mat-paginator>
  </mat-card>
    
  </div>
  <div *ngIf="showGroups">
    <mat-card>
      <mat-card-actions class="pl-2" >
      
      <button mat-flat-button (click)="openBuyerGroupForm()" color="primary" tourAnchor="btour.step10.id">Add Buyer group<mat-icon>add</mat-icon></button>

      <div style="float: right;" class="col-md-2 text-center">
        <mat-icon matTooltip="Click for a guided Tour" class="pointer" (click)="buyerGroupTour()">play_circle_outline
        </mat-icon>
        <h6 style="margin-top: 0mm;">Guided Tour</h6>
    </div>
   </mat-card-actions>
   </mat-card>  
   
   
   <mat-card>
   
    <div class="col-lg-3 pl-0">
      <mat-form-field appearance="outline">
        <input matInput (keyup)="applyFilterBuyerGroup($event.target.value)" placeholder="Search" tourAnchor="btour.step12.id">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>

    <table mat-table [dataSource]="dataBuyerGroup" matSort class="mat-elevation-z8" tourAnchor="btour.step11.id">


      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell *matCellDef="let post"> {{post.id}} </td>
      </ng-container>

      
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> NAME </th>
        <td mat-cell *matCellDef="let post" > {{post.name}} </td>
      </ng-container>


      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> STATUS </th>
        <td  mat-cell *matCellDef="let post">
        <div  *ngIf="post.status === 'A' " style="color:#435cc8;">Active</div>
        <div  *ngIf="post.status != 'A'" style="color:#FE4831;">In-Active</div>
      </td>

      </ng-container>

      

      <ng-container matColumnDef="actions" class="text-center" tourAnchor="btour.step13.id">
        <th mat-header-cell *matHeaderCellDef> ACTIONS </th>
        <td mat-cell *matCellDef="let post">
          
          <button class="editButton" *ngIf="(post.status != 'A') && (post.name !='Default Group')" mat-stroked-button color="green" style="margin-right:25px" (click)="activateBuyerGroup('activate',post.id)" ><span style="color:#435cc8;">Activate</span></button> 
          <button class="editButton" *ngIf="(post.status === 'A')&& (post.name !='Default Group')" mat-stroked-button color="pink" style="margin-right:10px" (click)="activateBuyerGroup('deactivate',post.id)"><span style="color:#FE4831;">Deactivate</span></button>
          <!-- <button class="editButton" mat-stroked-button color="secondary" style="margin-right:10px" (click)="viewGroupInfo(post.id)"><mat-icon style="font-size:25px; vertical-align: middle;">remove_red_eye</mat-icon><span class="with-icon" style="color:#6D737C;">View</span></button> -->
          <button class="editButton" *ngIf="post.name !='Default Group'" mat-stroked-button color="primary" (click)="editGroupInfo(post.id,post.name)"><mat-icon style="font-size:20px; vertical-align: middle;">edit</mat-icon><span class="with-icon" style="color:#6D737C;">Edit</span></button></td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumnsForGroup"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsForGroup ;"></tr>
    </table>

    <mat-paginator  #paginator1 [length]="dataLengthBuyer"
    [pageSize]="15"  [pageSizeOptions]="[5, 10, 25]" showFirstLastButtons></mat-paginator>

    
  </mat-card>

  
    
  </div>
</div>
</div>
  
</div>

