import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ControlPanelService } from '../../control-panel/control-panel.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HostListener} from "@angular/core";
import { TourService } from 'ngx-tour-ngx-popper';
import { HttpErrorResponse } from '@angular/common/http';
import {ErpSettingsConfig} from '../Models/erpSettings';




@Component({
  selector: 'app-erpsettings',
  templateUrl: './erpsettings.component.html',
  styleUrls: ['./erpsettings.component.css']
})


export class ERPsettingsComponent implements OnInit {

  erps = [
    {value: 'posibolt', viewValue: 'Posibolt'},
    {value: 'odoo', viewValue: 'Odoo'},
    {value: 'Other', viewValue: 'Other'}
  ];

  selectedValue: string;
  erpconfigurations=[];
  public erpSettingsForm : FormGroup;
  erpGeneralSettingsForm:FormGroup;
  hideSubmitStep = false;
  hidePrevStep = false;
  checked = false;
  checkedStock= false;
  checkedShipping= false;
  checkedImage= false;
  checkedInvoice= false;
  successMessage:string="";
  errorMessage:string="";
  tempPrevTourStepCount = 0;
  tempPrevTourStep;
  selected = '';
  warehouseSettingsDetails: any;
  erpSettingsDetails:any;
  id:string="";
  sellerId:string="";
  erpSettings ={} as ErpSettingsConfig;
  onSelected:boolean=false;

  constructor(private fb: FormBuilder,
    private controlPanelService: ControlPanelService,
    public activateRoute: ActivatedRoute,
    public erpTourService: TourService,
    public erpgTourService:TourService,
    private toastr: ToastrService) { }

    public erpTour(): void {
        
     this.erpTourService.disableHotkeys();
     this.erpTourService.start(); 
   }

   public erpgTour(): void {
        
    this.erpgTourService.disableHotkeys();
    this.erpgTourService.start(); 
  }


  ngOnInit(): void {

    this.erpSettingsForm =  this.fb.group({
      erpType: [""|| ""],
      domainUrl: [""|| ""],
      authUserName: [""|| ""],
      authPassword : [""|| ""],
      posiboltUserName: [""|| ""],
      posiboltPassword : [""|| ""],
      terminal: [""|| ""],
    });

      this.erpGeneralSettingsForm =  this.fb.group({
      saveProduct: [""|| ""],
      changeStock: [""|| ""],
      sendShipping : [""|| ""],
      backgroundImage: [""|| ""],
      allocateInvoice: [""|| ""],
      syncEmails: [""|| ""]
    });

  this.erpTourService.initialize([
    {   
     
      stepId : 'cptour.pbstep1.id',
      anchorId: 'cptour.pbstep1.id',
      content: 'Please enter the posibolt Domain URL here' ,
      title: 'Posibolt Domain Url',
      endBtnTitle:'Skip',
      placement:"right", 
     
    },
      
     {    
    stepId : 'cptour.pbstep2.id',
    anchorId: 'cptour.pbstep2.id',
    content: 'Please enter your Authentication UserName' ,
    title: 'UserName',
    endBtnTitle:'Skip',
    placement:"right", 
    
    },
    {    
      stepId : 'cptour.pbstep3.id',
      anchorId: 'cptour.pbstep3.id',
      content: 'Please enter your Authentication Password' ,
      title: 'Password',
      endBtnTitle:'Skip',
      placement:"right", 
      
      },
      {    
        stepId : 'cptour.pbstep4.id',
        anchorId: 'cptour.pbstep4.id',
        content: 'Please enter your Posibolt UserName' ,
        title: 'UserName',
        endBtnTitle:'Skip',
        placement:"right", 
        
        },
        {    
          stepId : 'cptour.pbstep5.id',
          anchorId: 'cptour.pbstep5.id',
          content: 'Please enter your Posibolt Password' ,
          title: 'Password',
          endBtnTitle:'Skip',
          placement:"right", 
          
          },
          {    
            stepId : 'cptour.pbstep6.id',
            anchorId: 'cptour.pbstep6.id',
            content: 'Please enter the Terminal' ,
            title: 'Terminal',
            endBtnTitle:'Skip',
            placement:"right", 
            
            },
    
          {
            anchorId: 'cptour.pbstep7.id',
            content: 'Please press this button to Save the above information.',
            title: 'Save and Submit',
            placement:'right',
            
          },
],
{route: 'erpsettings',
});

    

    this.erpgTourService.initialize([
      {   
       
        stepId : 'cptour.pbgstep1.id',
        anchorId: 'cptour.pbstep1.id',
        content: 'Please enter the posibolt Domain URL here' ,
        title: 'Posibolt Domain Url',
        endBtnTitle:'Skip',
        placement:"right", 
       
      },
        
       {    
      stepId : 'cptour.pbgstep2.id',
      anchorId: 'cptour.pbstep2.id',
      content: 'Please enter your Authentication UserName' ,
      title: 'UserName',
      endBtnTitle:'Skip',
      placement:"right", 
      
      },
      {    
        stepId : 'cptour.pbgstep3.id',
        anchorId: 'cptour.pbstep3.id',
        content: 'Please enter your Authentication Password' ,
        title: 'Password',
        endBtnTitle:'Skip',
        placement:"right", 
        
        },
        {    
          stepId : 'cptour.pbgstep4.id',
          anchorId: 'cptour.pbstep4.id',
          content: 'Please enter your Posibolt UserName' ,
          title: 'UserName',
          endBtnTitle:'Skip',
          placement:"right", 
          
          },
          {    
            stepId : 'cptour.pbgstep5.id',
            anchorId: 'cptour.pbstep5.id',
            content: 'Please enter your Posibolt Password' ,
            title: 'Password',
            endBtnTitle:'Skip',
            placement:"right", 
            
            },
            {    
              stepId : 'cptour.pbgstep6.id',
              anchorId: 'cptour.pbstep6.id',
              content: 'Please enter the Terminal' ,
              title: 'Terminal',
              endBtnTitle:'Skip',
              placement:"right", 
              
              },
      
            {
              anchorId: 'cptour.pbgstep7.id',
              content: 'Please press this button to Save the above information.',
              title: 'Save and Submit',
              placement:'right',
              
            },
  ],
  {route: 'erpsettings',
  });

  this.controlPanelService.getwarehouseSettings().subscribe(
    res => {
      this.warehouseSettingsDetails = res.warehouseSettings;
  
      this.id = this.warehouseSettingsDetails.shopId;
    }
  );
  
  this.controlPanelService.getErpSettings().subscribe(
    res => {
      this.erpSettingsDetails = res;
      this.sellerId = this.erpSettingsDetails.sellerId;
      console.log(this.erpSettingsDetails);
    
      this.erpSettingsForm.controls['erpType'].setValue(this.erpSettingsDetails.erpType);
      
      this.erpSettingsForm.controls['domainUrl'].setValue(this.erpSettingsDetails.configurations[0].configValue);
      this.erpSettingsForm.controls['authUserName'].setValue(this.erpSettingsDetails.configurations[1].configValue);
      this.erpSettingsForm.controls['authPassword'].setValue(this.erpSettingsDetails.configurations[2].configValue);
      this.erpSettingsForm.controls['posiboltUserName'].setValue(this.erpSettingsDetails.configurations[3].configValue);
      this.erpSettingsForm.controls['posiboltPassword'].setValue(this.erpSettingsDetails.configurations[4].configValue);
      this.erpSettingsForm.controls['terminal'].setValue(this.erpSettingsDetails.configurations[5].configValue);
      
    }
    );
  
      }

    @HostListener('click',['$event'])mouseover(event) {  
      if (this.erpTourService.currentStep) {
        const nextStep = $(event.target).closest(".ws-guided-tour").attr("tourAnchor")
        if (nextStep && this.tempPrevTourStep !== nextStep) {

          this.erpTourService.startAt(nextStep);
          this.tempPrevTourStep = nextStep;
        }      
      }
    };
    @HostListener('document:keydown.tab', ['$event'])
    onKeydownHandler(event: KeyboardEvent) {
     if (this.erpTourService.currentStep) {
           this.erpTourService.next();
     
       }    
     }

    submitErpSettings()
    {
      console.log(this.erpSettingsForm.value);

      this.erpconfigurations = [
      {"configKey": "ERP_DOMAIN_URL","configValue": this.erpSettingsForm.controls['domainUrl'].value},
      {"configKey": "CLIENT_ID", "configValue":this.erpSettingsForm.controls['authUserName'].value},
      {"configKey": "CLIENT_SECRET", configValue: this.erpSettingsForm.controls['authPassword'].value},
      {"configKey": "USER_NAME", "configValue": this.erpSettingsForm.controls['posiboltUserName'].value},
      {"configKey": "PASSWORD", "configValue": this.erpSettingsForm.controls['posiboltPassword'].value},
      {"configKey": "TERMINAL", "configValue":this.erpSettingsForm.controls['terminal'].value },
      {"configKey": "SAVE_PROD_TO_DRAFT", "configValue": ""},
      {"configKey": "CHANGES_IN_STOCK", "configValue": ""},
      {"configKey": "SEND_SHIPPING_CHARGES", "configValue": ""},
      {"configKey": "ENABLE_BG_IMAGE", "configValue": ""},
      {"configKey": "AUTO_ALLOCATE_PAYMENT", "configValue": ""}
    ]

    if(this.erpconfigurations.length === 11 ){
       this.erpSettings ={
         "sellerId": this.sellerId,
         "erpType" : this.erpSettingsForm.controls['erpType'].value,
         configurations:this.erpconfigurations
        }
        console.log(this.erpSettings);

        if( this.erpSettings){

          this.controlPanelService.saveErpSettings(this.erpSettings).subscribe(
            (res: any) => {
              if (res.acknowledgement.status === "SUCCESS") {
                this.successMessage = res.acknowledgement.message
                this.toastr.success(this.successMessage, "SUCCESS")
              }
              else {
                this.errorMessage = res.acknowledgement.message
                this.toastr.error(this.errorMessage, "ERROR")
              }
    
            },
            (err: HttpErrorResponse) => {
              if (err) {
                this.errorMessage = err.error.acknowledgement.message
                this.toastr.error(this.errorMessage, "ERROR")
              }
              else {
                this.toastr.error("Sorry, the information was not updated.", "ERROR")
              }
    
            }
          );
        }


    }


  }


      

    submitErpGeneralSettings(){
      console.log(this.erpGeneralSettingsForm.value);
    }

    onSelectType(value){
      if(value === "posibolt" || value === "odoo")
      this.onSelected=true;
      else
      this.onSelected=false;
    }

}
