
import { authService } from './../Service/authService';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import {NgxCaptchaModule,ReCaptcha2Component} from 'ngx-captcha';
import {ToastrService} from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import {MyVersion} from 'src/my-version';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {
  resetform: FormGroup;
  siteKey: string;
  isRobort: boolean;
  isSubmittedSuccess: boolean;
  loading:boolean = false;
  curDate;
  ver:number;
  errorMessage: any;
  successInvalid: boolean;
  successMessage: any;
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastr:ToastrService,
    private authservice:authService) { }

  ngOnInit(){
   this.siteKey = environment.siteKey;
   this.curDate = new Date().getFullYear().toString().substr(-2) + '.' +(new Date().getMonth() + 1).toString();
  this.ver=MyVersion.number;

    this.resetform = this.fb.group({
      username: ['', Validators.email],
      recaptcha: ['', Validators.required]
    });
  }
  onSubmit()
  {
    this.loading=true;
    var response =this.resetform.value.recaptcha
    if(response.length == 0) 
    { 
      //reCaptcha not verified
     this.isRobort =true
      // form.preventDefault();
      return false;
    }

    if(this.resetform.valid)
    {
     
    var obj={
      "email":this.resetform.value.username,
      "g-recaptcha-response":this.resetform.value.recaptcha
      }
      this.authservice.resetPasswordMail(obj)
      .subscribe(resetPasswordMailResponse=>
      {
        if(resetPasswordMailResponse.acknowledgement.status==="SUCCESS")
        {
          this.isSubmittedSuccess=true
        this.successMessage=resetPasswordMailResponse.acknowledgement.message
          this.successInvalid = false
          this.loading=false;
          this.toastr.success("",this.successMessage)
        } else if(resetPasswordMailResponse.acknowledgement.status==="ERROR")
        {
          this.errorMessage=resetPasswordMailResponse.acknowledgement.message
          this.successInvalid = true
          this.isSubmittedSuccess=false
          // grecaptcha.reset();
          this.captchaElem.resetCaptcha();
          this.loading=false;
          this.toastr.error("",this.errorMessage);
        }
      }, (err:HttpErrorResponse) => {
        if(err.error.acknowledgement.status==="ERROR")
        {
          this.errorMessage=err.error.acknowledgement.message
          this.successInvalid = true
          this.isSubmittedSuccess=false
          // grecaptcha.reset();
          this.captchaElem.resetCaptcha();
          this.loading=false;
          this.toastr.error("",this.errorMessage);
        }
        
     });
      
    }
  }

  handleSuccess(captchaResponse:String)
  {
    this.isRobort=false
    
  }
}
