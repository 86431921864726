import { Component, OnInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { StockService } from '../stock/stock.service'
import { category } from './models/categoryResponse.model';
import { ToastrService } from 'ngx-toastr';
import { TourService } from 'ngx-tour-ngx-popper';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment';
import { CdkAccordion } from '@angular/cdk/accordion';
import {ControlPanelService} from '../../app/control-panel/control-panel.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {ConfirmdeleteComponent} from '../../app/settings/images/confirmdelete/confirmdelete.component'
import { ShowReportComponent } from './show-report/show-report.component';
import { MatSnackBar } from "@angular/material/snack-bar";
import { userDetails } from '../Models/Response/loginResponse';

interface Catalog {
  value: string;
  viewValue: string;
}

interface FoodNode {
  name: string;
  children?: FoodNode[];
}

export interface Category {
  categories?: (CategoriesEntity)[] | null;
  message: string;
  status: string;
}
export interface CategoriesEntity {
  categories?: (CategoriesEntity | null)[] | null;
  categoryName: string;
  categoryId: string;
}

export interface StockObj {
  skuId: string;
  stock: string;
}


export interface PeriodicElement {
  name: string;
  id: number;
  stock: number;
  catalog: string;
}


const CATE = [
  {    categories: [
    {
        categories: [],
        categoryName: "Walkaroo",
        categoryId: 209
    },
    {
        categories: [],
        categoryName: "Stylo",
        categoryId: 419
    }
],
categoryName: "VKC",
categoryId: 226
},
{
categories: [
    {
        categories: [{
          categories: [],
          categoryName: "Walkaroo",
          categoryId: 228
      }],
        categoryName: "Walkaroo",
        categoryId: 225
    },
    {
        categories: [],
        categoryName: "Stylo",
        categoryId: 309
    }
],
categoryName: "VKC",
categoryId: 227
}]

export interface StockUploadResponse{
  details?:Details;
  status:string;
  message:string;
  type:string;
}

export interface Details{
failedCount:number;
mandatoryIdMissingCount : number;
failureDetails: string;
updatedCount :number;
requestedUpdateCount :number;
negativeStockRequestCount :number;
noDbSkuCount :number;
invalidStockCount:number;
invalidStockMessage:string;
}


@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.css']
})

export class StockComponent implements OnInit {

  selectedItem:number = null;
  uploadForm: FormGroup; 
   tempTree:CategoriesEntity[]=[]
  filteredTree:CategoriesEntity[]=[] 
  tempTrees: CategoriesEntity[];
  
  categoryList: Catalog[] = [];
  treeControl = new NestedTreeControl<CategoriesEntity>(node => node.categories);
  dataSource = new MatTreeNestedDataSource();
  displayedColumns: string[] = ['select', 'id', 'name', 'catalog', 'cstock', 'update', 'actions']
  @ViewChild (MatPaginator,{ static:false}) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;
  tableSource:any;
  selection = new SelectionModel(true, []);
  selected = "";
  selectedTab;
  skuId = "";
  productId = "";
  skuName = "";
  checked=false;
  newStock: number = 0
  showTree = false;
  showTable = false;
  catalogList: any;
  categoryDetails: any;
  stockObj: StockObj = {} as StockObj;
  stockArray: StockObj[] = [];
  showViewStock: boolean = true;
  showEditStock: boolean;
  viewImgUrl = '../assets/icon/viewStock.png';
  editImgUrl = '../assets/icon/editStock.png'
  checkImgUrl = '../assets/icon/checkAll.png'
  errorMsg = "Please enter a valid SKU Name"
  isError = false;
  searchSelectVal:number=0;
  DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm';
  @ViewChild(MatPaginator) paginator1: MatPaginator;
  @ViewChild(MatSort)sort1: MatSort;
  file: any;
  message:string=""
  fileName: any;
  tableLength: any;
  activeNode;
  ifDownloading=false;
  now:any;
  isSelectErr: boolean = false;
  enableEdit: boolean=false;
  totalProducts: any;
  categoryId: any;
  p: number = 1;
  clickedItem: any;
  ifRefresh: boolean = false;
  ifUpdate: boolean;
  data: any;
  ifSave: boolean;
  ifRefreshId: any;
  blob: Blob;
  pdfURL: string;
  warehouseSettingsDetails: any;
  shopid: any;
  isFilter: boolean;
  filterString:string;
  uploadType: string;
  ifExport: boolean;
  ifImport: boolean;
  ifCategoryLoading: boolean;
  constructor(private stockService: StockService,
    private toastr: ToastrService,
    public sTourService: TourService, private cdr:ChangeDetectorRef,private controlPanelService:ControlPanelService,
    private formBuilder: FormBuilder,private dialog:MatDialog,private snackBar: MatSnackBar)
     {

  }

  hasChild = (_: number, node: CategoriesEntity) => node.categories && node.categories.length > 0;

  ngOnInit(): void {
    this.now=moment(new Date(), this.DATE_TIME_FORMAT);
    this.getCategories();
    this.initialiseTour();
    this.uploadForm = this.formBuilder.group({
      stockDataFile: ['']
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.isFilter = true;
    this.tableSource.filter = filterValue;
   
  }

  selectionChanged(i) {
    this.selectedItem = i;
}

  editEnabled(){
    if(this.checked === true)
    this.enableEdit=true;
    else
    this.enableEdit=false;
  }

  initialiseTour(){
    this.sTourService.initialize([
      {
        anchorId: 'stour.step1.id',
        content: 'Click this option to view stock',
        title: 'View Stock',
        nextBtnTitle: 'Next',
        endBtnTitle: 'Skip',
      },
      {
        anchorId: 'stour.step2.id',
        content: 'Get Stock using category, skuId or product Id',
        title: 'Choose/Enter any one value',
        nextBtnTitle: 'Next',
        endBtnTitle: 'Skip',
      },
      {
        anchorId: 'stour.step3.id',
        content: 'Click this button to get the stock info in a table based on your filter ',
        title: 'Get Stock Info',
        nextBtnTitle: 'Next',
        endBtnTitle: 'Skip',
      },
      {
        anchorId: 'stour.step4.id',
        content: 'This is the list of the categories/Sub categories.',
        title: 'Category tree',
        nextBtnTitle: 'Next',
        endBtnTitle: 'Skip',
      }, 
      {
        anchorId: 'stour.step5.id',
        content:'Click this button to edit stock',
        title: 'Edit Stock',
        nextBtnTitle: 'Next',
        endBtnTitle: 'Skip',
      },
      {
        anchorId: 'stour.step6.id',
        content: 'Click this button to get the stock info in a table where you can update your stock',
        title: 'Get Stock Info',
        nextBtnTitle: 'Next',
        endBtnTitle: 'Skip',
      },
    ])
  }

  refreshRows(rowId){
     var obj = this.refreshRow(rowId);
     console.log(obj);
    return obj;
  }
 
 refreshRow(post){
    this.ifRefresh=true;
    this.ifRefreshId=post.skuId
  return this.stockService.getStock("",post.skuId,"","","20","0").subscribe((res:any) => {
    if(res.status === "OK"){
      post.stock =  (res.skuStocks[0].stock);
      post.update=''
      this.selection.deselect(post)
      this.ifRefresh=false;
      this.ifSave=false;
      this.ifRefreshId=''
    }
    this.ifRefresh=false;
    this.ifSave=false;
    this.ifRefreshId=''
    });
    
  }   
     

 

  getCategories() {
    this.ifCategoryLoading=true;
      this.stockService.getCategories().subscribe((response: Category) => {
      if (response.status === "OK") {
        this.ifCategoryLoading=false
        this.showTree = true;
        this.tempTree = response.categories;
        console.log(this.tempTree)
        this.tempTrees = response.categories;
        this.filteredTree = response.categories;
        this.dataSource.data = response.categories;
       /*  response.categories.forEach(category => {
          if (category.categories) {
            for (var i = 0; i < category.categories.length; i++) {
              this.categoryList.push({

                value: category.categories[i].categoryId,
                viewValue: category.categories[i].categoryName

              })
            }
          }


          this.categoryList.push({

            value: category.categoryId,
            viewValue: category.categoryName

          })

        }) */
      }
      else {

        this.toastr.error(response.message, "ERROR")
        this.ifCategoryLoading=false
      }

    }, (err: HttpErrorResponse) => {
      if (err) {
        this.ifCategoryLoading=false
        this.toastr.error(err.error.message, "ERROR")
      }


    });
 
  }

  toggleColor(n) {
    this.selectedTab = n;
  }
/* 
  openViewStock() {
    this.showViewStock = true;
    this.showEditStock = false;
    this.showTable = false;
    this.selected = "";
    this.skuId = "";
    this.productId = "";
    this.skuName=""
    this.getCategories();
    this.isError = false;
  }

  openEditStock() {
    this.showViewStock = false;
    this.showEditStock = true;
    this.showTable = false;
    this.selected = "";
    this.skuId = "";
    this.productId = "";
    this.skuName=""
    this.getCategories();
    this.isError = false;
  } */


  onSelectCatalog() {
    this.showTree = true;

  }

  stockTour() {
    this.sTourService.start();
  }

  editStockTour() {
    this.sTourService.startAt('stour.step6.id');
  }

  onExcel(event) {
    this.uploadType=""
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('stockDataFile').setValue(file);

    const formData = new FormData();
    formData.set('stockDataFile', file);
    const dialogRef = this.dialog.open(ConfirmdeleteComponent, {
      maxWidth: "400px",
      disableClose: true,
      data: {
          title: "Do you wish to replace or update the stock?",
          btn1: "Replace",
          btn2: "Update"
         }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      if(result === true)
      this.uploadType ="VARIANCE"
      else if(result === false)
      this.uploadType ="REPLACE"
      else if(result === "") 
      return;
   

    if(formData && this.uploadType){
    this.ifImport=true
    this.stockService.uploadStockDataFile(this.uploadType,formData).subscribe((res:StockUploadResponse) => 
    {
    this.ifImport=false
        if(res.status === "OK"){
         
         
          const dialogRef = this.dialog.open(ShowReportComponent, {
            
            data: res.details
          });

        }
        else
        {
        this.toastr.error(res.message)
       
        }
      }, (err: HttpErrorResponse) => {
        if (err) {
          this.ifImport=false
          this.toastr.error(err.error.message, "ERROR")
        }
      }
    );
  }

    });
  }
  }  


  catClicked(categoryId){
 
    this.categoryId=categoryId
  }
  
  treeClicked(categoryId) {
    this.skuName ="";
    this.clickedItem = categoryId;
    this.showTable = false;
    this.categoryId=categoryId;
    this.ifDownloading=true;
    this.isError=false;
    this.stockService.getStock(categoryId, "", "","","20","0").subscribe(
      res => {
        this.ifDownloading=false;
        this.showTable = true;
        if(res.status === "OK")
       { 
        this.showTable = true;        
        this.tableSource = new MatTableDataSource(res.skuStocks);
        this.tableSource.paginator=this.paginator
        this.tableSource.sort=this.sort
       // this.tableLength=res.skuStocks.length
        this.totalProducts=res.totalCount
        console.log(this.totalProducts)
       }
       else{
        this.showTable = false;
        this.ifDownloading=false;
        this.toastr.error(res.message, "ERROR") 
       }
      }, (err: HttpErrorResponse) => {
        if (err) {
          this.showTable = false;
          this.ifDownloading=false;
          this.toastr.error(err.error.message, "ERROR")
        }


      }
    );
  }
  
  isSomeSelected() {
    
    return this.selection.selected.length > 0;
  }
  

  isAllSelected() {
    if(this.filterString === ""){
    const numSelected = this.selection.selected.length;
    const numRows = this.tableSource.data.length;
    return numSelected === numRows;
    }
    else if(this.filterString !== ""){
      const numSelected = this.selection.selected.length;
      const numRows = this.tableSource.filteredData.length;
      return numSelected === numRows;
      }
  }

  /* checkboxLabel(row?:any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.orderId}`;
  } */

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(ref) {
    if (this.isSomeSelected()) {
      
      this.selection.clear();
    ref.checked=false;
      
    } else if(this.filterString === ""){
      this.isAllSelected() ?
        this.selection.clear() :
        this.tableSource.data.forEach(row => this.selection.select(row));
    }
    else
    this.isAllSelected() ?
    this.selection.clear() :
    this.tableSource.filteredData.forEach(row => this.selection.select(row));
  }

  getStock() {
    this.showTable = false;
    if (this.selected || this.skuId || this.productId || this.skuName) {
      this.isError = false;
      this.isSelectErr=false;
      this.searchSelectVal=0;
      if(this.selected){this.searchSelectVal++}
      if( this.skuId){this.searchSelectVal++}
      if(this.productId){this.searchSelectVal++}
      if(this.skuName){this.searchSelectVal++}
      if(this.searchSelectVal > 1)
      {this.isSelectErr=true;
        this.searchSelectVal=0;}
      else{
      this.ifDownloading=true;
      this.isSelectErr=false;
      this.searchSelectVal=0;
      this.categoryId="";
      this.stockService.getStock(this.selected, this.skuId, this.productId,this.skuName,"20","0").subscribe(
        res => {
          this.ifDownloading=false;
          if(res.status === "OK")
         { 

          this.showTable = true;
          this.tableSource = new MatTableDataSource(res.skuStocks);
          this.cdr.detectChanges();
          this.tableSource.paginator=this.paginator
          this.tableSource.sort=this.sort
         // this.tableLength=res.skuStocks.length
          this.totalProducts=res.totalCount
          console.log(this.totalProducts);
         }
         else{
          this.showTable = false;
          this.ifDownloading=false;
          this.toastr.error(res.message, "ERROR") 
         }
        }, (err: HttpErrorResponse) => {
          if (err) {
            this.ifDownloading=false;
            this.showTable = false;
            this.toastr.error(err.error.message, "ERROR")
          }


        }
      );
      }
    }
    else {
      this.isError = true;
    }
  }

  

  downloadStock(){
   
    this.isError=false;
    this.ifExport=true
    this.controlPanelService.getwarehouseSettings().subscribe(
      res => {
        this.warehouseSettingsDetails = res.warehouseSettings;
        this.shopid = this.warehouseSettingsDetails.shopId;
        this.stockService.downloadStockReport(this.categoryId,this.shopid).subscribe(
          res => {
            this.ifExport=false
            if(res.fileName)
           { 
             //window.open(res.fileUrl)
            // window.location.href =res.fileUrl;
            //var downloadURL = window.URL.createObjectURL(res.fileName);
            
            const link = document.createElement('a');
            link.setAttribute('target', '_self');
            link.setAttribute('href', res.fileUrl);
            link.setAttribute('download',res.fileName);
            document.body.appendChild(link);
            link.click();
            link.remove(); 
            
           }
            else
            this.toastr.error("Error downloading file")
            
          },
          (err: HttpErrorResponse) => {
            if (err) {
              this.ifExport=false
              this.toastr.error(err.error.message, "ERROR")
            }
  
  
          }
        );
      });

    
  }

  pageChanged(page){
    this.ifDownloading=true;
    this.stockService.getStock(this.categoryId, this.skuId, this.productId,this.skuName,page.pageSize, page.pageIndex * page.pageSize).subscribe(
      res => {
        this.ifDownloading=false;
        if(res.status === "OK")
       { 

        this.showTable = true;
        this.tableSource = new MatTableDataSource(res.skuStocks);
        this.cdr.detectChanges();
        // this.tableSource.paginator=this.paginator
        // this.tableSource.sort=this.sort
        // this.tableLength=res.skuStocks.length
         //this.totalProducts=res.totalCount
       }
       else{
        this.showTable = false;
        this.ifDownloading=false;
        this.toastr.error(res.message, "ERROR") 
       }
      }, (err: HttpErrorResponse) => {
        if (err) {
          this.ifDownloading=false;
          this.showTable = false;
          this.toastr.error(err.error.message, "ERROR")
        }


      }
    );

  }
  getNewStock(current, add){
   
    if (Number(current) + Number(add) < 0)
    return true
    else return false
  }

  removeFinalComma(str){
  return(str.replace(/,\s*$/, ""));
  }

  filter(value){
    value = value.trim(); // Remove whitespace
    
    let filteredTreeData:CategoriesEntity[] =[]
    if (value !="") {
      filteredTreeData = this.tempTrees.filter(d => d.categoryName.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) > -1);
      Object.assign([], filteredTreeData).forEach(ftd => {
        let str = (<string>ftd.categoryId);
        while (str.toString().lastIndexOf('.') > -1) {
          const index = str.toString().lastIndexOf('.');
          str = str.substring(0, index);
          if (filteredTreeData.findIndex(t => t.categoryId === str) === -1) {
            const obj = this.tempTrees.find(d => d.categoryId === str);
            if (obj) {
              filteredTreeData.push(obj);
            }
          }
        }
      });
      this.tempTree = filteredTreeData;
    }
    else{
      this.tempTree=this.filteredTree;
    }

   
    
  }

  onTabChanged($event) {
    this.selected = "";
    this.skuId = "";
    this.productId = "";
    this.skuName=""
    this.isError=false;
    this.isSelectErr=false;
  }


  saveStock(post) {
    if(post.update){
      
    this.ifSave=true;
    this.stockObj.skuId = post.skuId
    this.stockObj.stock = post.update
    var obj = {
      skus:
        [this.stockObj]
    }
    if (obj && (Object.keys(obj).length != 0)) {
      this.stockService.updateStock(obj).subscribe(
        res => {
          
          if (res.status === "OK") {

            this.toastr.success(res.message)
            this.refreshRow(post);
           
          }
          else {
            this.toastr.error(res.message)
            this.ifSave=false;
          }

        }, (err: HttpErrorResponse) => {
          if (err) {
            this.ifSave=false;
            this.toastr.error(err.error.message, "ERROR")
          }


        }
      );
    }

  }
  else{
    this.toastr.error("Please update the stock to continue", "ERROR")
  }
}

  updateAll() {
    this.stockArray=[];
    if (this.selection.selected.length) {
      this.ifUpdate=true;
    this.showTable = true;
      this.selection.selected.forEach(post => {
        if(this.selection.select){
         
        this.stockArray.push({
          skuId: post.skuId,
          stock: post.update
        })
      }
      })
      var obj = {
        skus:
          this.stockArray
      }

      if (obj && (Object.keys(obj).length != 0)) {
        this.stockService.updateStock(obj).subscribe(
          res => {
            
            if (res.status === "OK") {
              // this.refreshTable();
             
              this.toastr.success(res.message)
              this.selection.selected.forEach(post => {
                if(this.selection.select){
                this.refreshRow(post);
              }
              })
             this.ifUpdate = false;
            }
            else {
              
              this.toastr.error(res.message)
              this.ifUpdate = false;
            }

          }, (err: HttpErrorResponse) => {
            if (err) {
              
              this.ifUpdate = false;
              this.toastr.error(err.error.message, "ERROR")
            }
  
  
          }
        );
      }
    }
    else {
      this.toastr.error("Please update the stocks first", "ERROR")
    }

  }

  refreshTable() {
    this.stockService.getStock(this.selected,this.skuId, this.productId,this.skuName,"20","0").subscribe(
      res => {
        
        this.tableSource = new MatTableDataSource(res.responseSkuArray);
        this.totalProducts=res.totalCount
        console.log( this.totalProducts)
      }
    );
  }



}



