import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from '../../environments/environment'

@Injectable({
    providedIn: 'root'
  })
  export class ReportService {
  
     baseUrl=environment.serviceURL+"storimartreport"
  Authtoken: string;
    constructor(private httpclient: HttpClient, private _zone: NgZone) { 
  this.Authtoken="Basic ZW1hZC5hc2hlckBzdG9yaWxhYnMuY29tOm9rYXkxMjM0"
      
    }
  
   

    getDropDown():Observable<any>
    {
      //  return this.httpclient.get("https://api.myjson.com/bins/yker4");
       return this.httpclient.get(this.baseUrl+"/customers");
       
    }


    getCountryCode():Observable<any>
    {

      return this.httpclient.get(this.baseUrl+"/country");
    }
    
    getByOrderId():Observable<any>
    {
      //  return this.httpclient.get("https://api.myjson.com/bins/yker4");
      //  return this.httpclient.get("https://api.myjson.com/bins/rrem4");
       return this.httpclient.get(" https://api.myjson.com/bins/lf50s");
      
    }

        
    getInvoice(startDate,endDate,shopId,retailerId):Observable<any>
    {
    let httpParamsInvoice=new HttpParams()
    .set('startDate',startDate)
    .set('endDate',endDate)
    .set('shopId',shopId)
    .set('retailerId',retailerId)
     return this.httpclient.get(this.baseUrl+"/invoice",{params:httpParamsInvoice});
       
    }
  

    getDataTable(startDate,endDate,pageOffset,pageLimit,searchType,buyer,status,orderId,countryCode,phone):Observable<any>
    {
      
      
      

  switch (searchType) {
  case 'AL':
  {
    let httpParamsWarehouse=new HttpParams()
    .set('startDate',startDate)
    .set('endDate',endDate)
    .set('offset',pageOffset)
    .set('limit',pageLimit)
    return this.httpclient.get(this.baseUrl+"/reportbywarehouse",{params:httpParamsWarehouse});
    break;
  }
  case 'SM':
    { 
    let httpParamsSalesman = new HttpParams()
      .set('startDate', startDate)
      .set('endDate', endDate)
      .set('limit', pageLimit)
      .set('offset', pageOffset)
      .set('searchType', "salesman")
      .set('salesmanId', buyer)
      return this.httpclient.get(this.baseUrl+"/reports",{params:httpParamsSalesman});
     break;
    }
    case 'BR':
      {
      let httpParamsBuyer = new HttpParams()
        .set('startDate', startDate)
        .set('endDate', endDate)
        .set('limit', pageLimit)
        .set('offset', pageOffset)
        .set('searchType', "buyer")
        .set('retailerId',buyer)
        return this.httpclient.get(this.baseUrl+"/reports",{params:httpParamsBuyer});
      break;
  }
      case 'ST':
        {
        let httpParamsStatus = new HttpParams()
          .set('startDate', startDate)
          .set('endDate', endDate)
          .set('limit', pageLimit)
          .set('offset', pageOffset)
          .set('searchType',"status")
          .set('status', status)
          return this.httpclient.get(this.baseUrl+"/reports",{params:httpParamsStatus});
        break;
        }
        case 'OI':
          {
          let httpParamsOrder= new HttpParams()
            .set('startDate', startDate)
            .set('endDate', endDate)
            .set('limit', pageLimit)
            .set('offset', pageOffset)
            .set('searchType',"order")
            .set('orderId', orderId)
            return this.httpclient.get(this.baseUrl+"/reports",{params:httpParamsOrder});
          break;
          }
          case 'PH':
            {
          let httpParamsPhone= new HttpParams()
            .set('startDate', startDate)
            .set('endDate', endDate)
            .set('limit', pageLimit)
            .set('offset', pageOffset)
            .set('searchType',"phone")
            .set('countryCode',countryCode)
            .set('phone', phone)
            return this.httpclient.get(this.baseUrl+"/reports",{params:httpParamsPhone});
          break;
            }
  default:
    break; 
          }
 }
     
   

    getAlertSummery()
    {
      return this.httpclient.get(this.baseUrl+"/alertsummary")
    }

    getAlertDetails(accountId, alertType) 
    {
     let httpParamsAlert=new HttpParams()
     .set('accountId',accountId)
     .set('alertType',alertType)
      return this.httpclient.get(this.baseUrl+"/viewalertdetails",{params:httpParamsAlert});
    }
  
    getItemWiseReport(){
      
      return this.httpclient.get(this.baseUrl+"/itemWiseReport");
    }

    getItemWiseReportWithDates(startDate,endDate){
      let httpParamsItems=new HttpParams()
      .set('startDate',startDate)
      .set('endDate',endDate)
       return this.httpclient.get(this.baseUrl+"/itemWiseReport",{params:httpParamsItems}); 
    }

  createExcel(startDate,endDate,shopId,retailerId):Observable<any>
    {
    let httpParamsExcel=new HttpParams()
    .set('startDate',startDate)
    .set('endDate',endDate)
    .set('shopId',shopId)
    .set('retailerId',retailerId)
     return this.httpclient.get(this.baseUrl+"/createexcel",{params:httpParamsExcel});
       
    }

    // getServerSentEvent(url: string): Observable<any> {
    //   return Observable.create(observer => {
        
    //   // let httpparamSse=new HttpParams()
    //   this.Authtoken="Basic ZW1hZC5hc2hlckBzdG9yaWxhYnMuY29tOm9rYXkxMjM0"
    //   // .set('token',"Basic ZW1hZC5hc2hlckBzdG9yaWxhYnMuY29tOm9rYXkxMjM0")
    //     const eventSource = this.getEventSource(this.baseUrl+url);
    //     eventSource.onmessage = event => {
    //       this._zone.run(() => {
    //         observer.next(event);
    //       });
    //     };
    //     eventSource.onerror = error => {
    //       this._zone.run(() => {
    //         observer.error(error);
    //       });
    //     };
    //   });
    // }
    // private getEventSource(url: string): EventSource {
    //   return new EventSource(url+"?token="+this.Authtoken);
    // }
  
    
   
  }