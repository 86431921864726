import { Component, OnInit,Inject } from '@angular/core';
import { FormGroup,FormBuilder,Validators } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {BuyersService} from './../buyers.service'
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-buyer-form',
  templateUrl: './buyer-form.component.html',
  styleUrls: ['./buyer-form.component.css']
})
export class BuyerFormComponent implements OnInit {

  buyerForm: FormGroup;
  timeZoneList:any;
  countryList:any;
  buyerGroups:any;

  constructor(
    private toastr:ToastrService,
    private dialogRef: MatDialogRef<BuyerFormComponent>,public fb: FormBuilder,
    private BuyersService:BuyersService
    ) {}

  ngOnInit(): void {

    this.BuyersService.timeZoneList().subscribe(
      res => {
        console.log(res);
        this.timeZoneList = res.timeZone;
      }
    );
    this.BuyersService.tenantCountryList().subscribe(
      res => {
        this.countryList = res.country;
        console.log(res);
      }
    );
    this.BuyersService.getListOfBuyergroups().subscribe(
      res => {
        console.log(res)
        this.buyerGroups = res.groups;
      }
    );

    this.buyerForm = this.fb.group({
      name: ['' || '', Validators.required],
      phone: ['',Validators.compose([ Validators.required,
        Validators.pattern("[0-9]{10}")])],
        email: ['', {
          validators: [
        
              Validators.email
          ],
          updateOn: 'blur'
       }],
       addressLine1: ['' || '', Validators.required],
      city: ['' || '', Validators.required],
      state: ['' || ''],
      countryId: ['' || '', Validators.required],
      pin: ['' || ''],
      latitude: ['' || ''],
      longitude: ['' || ''],
      taxNumber: ['' || '',],
      timezoneId: ['' || '', Validators.required],
      groupId:[''||'',Validators.required],
      buyerAsAppUser:[false],
    });
  }

  save(){
    if (!this.buyerForm.valid) {
      console.log("Not valid Form")
      this.toastr.error("Please check if you have entered all the required  * fields","Error")
    } 
    else{
    console.log(this.buyerForm.value);
    if(this.buyerForm.value.groupId)
    {
      this.buyerForm.value.groupId=this.buyerForm.value.groupId.toString();
    }
    this.dialogRef.close(this.buyerForm.value); 
    } 
  }
  
  close() {
    this.dialogRef.close();  
}
}
