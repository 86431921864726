import { authService } from './../Service/authService';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ReCaptcha2Component } from 'ngx-captcha';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import {MyVersion} from 'src/my-version';

// declare gtag as a function to access the JS code in TS
declare let gtag: Function;

@Component({
  selector: 'app-newuser',
  templateUrl: './newuser.component.html',
  styleUrls: ['./newuser.component.css']
})
export class NewuserComponent implements OnInit {

  curDate;
  ver:number;
  hide = true;
  regform: FormGroup;
  public loginInvalid: boolean;
  private formSubmitAttempt: boolean;
  private returnUrl: string;
  isCheckedCaptch: boolean;
  siteKey: string;
  isRobort: boolean;
  isSubmittedSuccess: boolean;
  successMessage: any;
  successInvalid: boolean;
  errorMessage: any;
  loading = false;
  youtubeUrl:string = 'https://www.youtube.com/channel/UCqMifbRHpEGhRFyhk1kpRKg';
  linkedinUrl: string = ' https://www.linkedin.com/company/storimart-b2b';
  facebookUrl:string = 'https://www.facebook.com/storimart';
  countryList: any;
  _window: Window;

  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: authService,
    private toastr: ToastrService
  ) {

  }
  async ngOnInit() {
    gtag('js', new Date());
    gtag('config', 'UA-147464651-1');
    this.curDate = new Date().getFullYear().toString().substr(-2) + '.' +(new Date().getMonth() + 1).toString();
    this.ver=MyVersion.number;
    this.siteKey = environment.siteKey;
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/login';
    this.regform = this.fb.group({
      businessname: ['', Validators.required],
      username: ['', Validators.email],
      country: ['', Validators.required],
      phone: ['', [Validators.required, Validators.minLength(8),Validators.maxLength(10)]],
      recaptcha: ['', Validators.required]
    });

    this.authService.countryList().subscribe(
      res => {
        this.countryList = res.country;
      }
    );

  }

  Submit() {
    this.loading =true;
    var reCaptchaResponse = this.regform.value.recaptcha
    if (reCaptchaResponse.length == 0) {
      //reCaptcha not verified
      // alert("please verify you are humann!"); 
      this.isRobort = true
      // form.preventDefault();
      return false;
    }
      var obj = {
        "email": this.regform.value.username,
        "businessName": this.regform.value.businessname,
        "phone": this.regform.value.phone,
        "countryCode": this.regform.value.country,
        "g-recaptcha-response": reCaptchaResponse
      }
        this.authService.newUserRegister(obj)
        .subscribe(regformSubmitResponse=>
        {

          if(regformSubmitResponse.acknowledgement.status=="SUCCESS")
          {

         this.isSubmittedSuccess=true
         this.successInvalid = false
         this.successMessage=regformSubmitResponse.acknowledgement.message
         this.loading = false;
        this.toastr.success("",this.successMessage)
          }
          else{
          this.isSubmittedSuccess=false;
         this.successInvalid = true;
         this.successMessage=regformSubmitResponse.acknowledgement.message
         this.loading = false;
        this.toastr.error("",this.successMessage)
          }
        }, (err:HttpErrorResponse) => {
          // if(err.error.acknowledgement.status==="ERROR")
          if(err)
          {
             this.errorMessage=err.error.acknowledgement.message
            // this.successInvalid = true
            // this.isSubmittedSuccess=false
            // this.captchaElem.resetCaptcha();
            this.loading = false;
            this.toastr.error("",this.errorMessage)
          }

       });


  }
  handleSuccess(captchaResponse: String) {
    this.isRobort = false

  }

}
