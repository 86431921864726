import { Component, ElementRef, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ControlPanelService } from '../../control-panel/control-panel.service';
import { WarehouseSettings } from '../../control-panel/models/WarehouseSettings.model'
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HostListener } from "@angular/core";
import { TourService } from 'ngx-tour-ngx-popper';
import { HttpErrorResponse } from '@angular/common/http';
import { BannerImage, UpdateImageRequest } from '../../control-panel/models/BannerImage.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {ConfirmdeleteComponent} from'./confirmdelete/confirmdelete.component'
import { SettingsComponent } from '../settings.component';
import { ViewChild } from '@angular/core';

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.css']
})
export class ImagesComponent implements OnInit {
  @ViewChild('myInput')
  myInputVariable: ElementRef;

  public imageSettingsForm :  FormGroup;
  isUploading:boolean=false;
  hideSubmitStep = false;
  hidePrevStep = false;
  successMessage:string="";
  errorMessage:string="";
  warehouseSettingsDetails: any;
  file : any;
  logo:any;
  bannerdetails:any;
  imagesFile = [];
  imageError:string ="";
  logoSize;
  bannerSize;
  bannerImages:any;
  shopId;
  fileName:string="";
  logoName:string="";
  imageFileName:string[]=[];
  updateImageBody={} as UpdateImageRequest;
  uploadLogoVar=false;
  tempPrevTourStepCount = 0;
  tempPrevTourStep;
  continue=false;
  wrongSize=false;
  bannerHeight: any;
  bannerWidth: any;
  imageSizeError: boolean;
  imageLogoError: string;
  constructor(private fb: FormBuilder,
    private controlPanelService: ControlPanelService,
    public activateRoute: ActivatedRoute,
    private router: Router,
    public iTourService: TourService,
    private toastr: ToastrService,
    private dialog:MatDialog,private settings:SettingsComponent) { }

  ngOnInit(): void {

    this.controlPanelService.getwarehouseSettings().subscribe(
      res => {
        this.warehouseSettingsDetails = res.warehouseSettings;
        if(this.warehouseSettingsDetails === null)
        {
        this.toastr.error("Please Update the WareHouse Settings first to Continue","ERROR")
          this.continue=true;
        }
        else
        {
        this.shopId = this.warehouseSettingsDetails.shopId;
        
        this.controlPanelService.getBannerImages(this.shopId).subscribe(
          result => {
            console.log(result);
            this.bannerImages = result.images;
           
          }
        
        );
        }
  });


  this.imageSettingsForm = this.fb.group({
    logo : [""|| ""],
    bannerText: ['', ''],
    bannerDescription: ['', ''],
    bannerImage: ['', Validators.required]
    
  });

  this.iTourService.initialize([
    {
      stepId : 'cptour.istep1.id',
      anchorId: 'cptour.istep1.id',
      content: 'Please Upload the Image of your Logo' ,
      title: 'Logo',
      endBtnTitle:'Skip',
      placement:"right",
      
     
    },
    {  
         
      stepId : 'cptour.istep2.id',
      anchorId: 'cptour.istep2.id',
      content: 'Here is the current list of all your uploaded Banner slides, press the Delete button to remove them' ,
      title: 'Current Banners',
      endBtnTitle:'Skip',
      placement:"top",
      
     
    },
    {  
         
      stepId : 'cptour.istep3.id',
      anchorId: 'cptour.istep3.id',
      content: 'You can upload more Banners here one image at a time.Please select the image and press the Upload button',
      title: 'Add Banners',
      endBtnTitle:'Skip',
      placement:"top",
      
     
    },
    
    {
      anchorId: 'cptour.istep4.id',
      content: 'Please press this button to Submit and move to next section.',
      title: 'Save and Submit',
      placement:'right',
      
    },
  ],
  {route: 'settings',
  });    
}

@HostListener('click',['$event'])mouseover(event) {  
  if (this.iTourService.currentStep) {
    const nextStep = $(event.target).closest(".ws-guided-tour").attr("tourAnchor")
    if (nextStep && this.tempPrevTourStep !== nextStep) {

      this.iTourService.startAt(nextStep);
      this.tempPrevTourStep = nextStep;
    }      
  }
};
@HostListener('document:keydown.tab', ['$event'])
onKeydownHandler(event: KeyboardEvent) {
 if (this.iTourService.currentStep) {
       this.iTourService.next();
 
   }    
 }

 resetLogo() {
  console.log(this.myInputVariable.nativeElement.files);
  this.myInputVariable.nativeElement.value = "";
  console.log(this.myInputVariable.nativeElement.files);
}

 continueToFooter(){
   this.settings.openFooterSettings();
   this.settings.toggleColor(7);
 }
  public imageTour(): void {
  
    this.iTourService.disableHotkeys();
    this.iTourService.start(); 
  }

  onFileChangedLogo(event){
    console.log(event);

    if (event.target.files && event.target.files[0]) {
      this.logoName="";
      this.imageError = "";
      this.imageLogoError = "";
      
     this.logo="";
      this.logoName = event.target.files[0].name;
      this.logoSize = event.target.files[0].size;
      console.log(this.logoSize)
            // Size Filter Bytes
            const max_size = 20971520;
            const allowed_types = ['image/png', 'image/jpeg','image/jpg'];
            const max_height = 15200;
            const max_width = 25600;
            console.log(event.target.files[0].type);
if(allowed_types.includes(event.target.files[0].type))
{

  if (event.target.files[0].size > max_size) {
              

    this.imageError =
        'Maximum size allowed is ' + max_size / 1000 + 'Mb';
        this.resetLogo();
    return false;
}

this.logo = event.target.files[0];
  var reader = new FileReader();

  reader.onload = (event:any) => {
   
    

     this.imageSettingsForm.patchValue({
        fileSource: this.logo
     });
  }

  reader.readAsDataURL(event.target.files[0]);
  
}
else{
  this.resetLogo();
  this.imageLogoError = 'File type not supported'

                return false;
}
            
      
  }


  }

  deleteBanner(imageId){
    
    const dialogRef = this.dialog.open(ConfirmdeleteComponent, {
      maxWidth: "400px",
      data: {
          title: "Delete the Banner image?",
          btn1: "No",
          btn2: "Yes"
         }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result);//returns undefined
      if(result === true){
        this.updateImageBody.shopId=this.shopId;
        this.updateImageBody.imageId =imageId.toString();
        
        this.controlPanelService.updateBanner(this.updateImageBody).subscribe(
          (res: any) => {
            console.log(res);
            if (res.acknowledgement.status === "SUCCESS") {
              this.successMessage = res.acknowledgement.message
              this.toastr.success( this.successMessage,"SUCCESS")
              this.controlPanelService.getBannerImages(this.shopId).subscribe(
                result => {
                  this.bannerImages = result.images;
                 
                }
              
              );
            }
            else{
              this.errorMessage=res.acknowledgement.message
              this.toastr.error(this.errorMessage,"ERROR")
            }
            
          },
          (err:HttpErrorResponse) => {
            if(err)
            {
               this.errorMessage=err.error.acknowledgement.message
               this.toastr.error(this.errorMessage,"ERROR")
            }
            else{
              this.toastr.error("Sorry, Image was not deleted","ERROR")
            }
    
       

      }

    );
  
  
  }
})
  }



  onFileChanged(event) {

    if (event.target.files && event.target.files[0]) {
      this.imageSizeError = false;
      this.fileName="";
      this.imageError ="";
      this.file = event.target.files[0];
      this.imageSizeError =false;
      this.fileName = event.target.files[0].name;
      this.bannerSize = event.target.files[0].size;
      
    
   
            // Size Filter Bytes
            const max_size = 20971520;
            const allowed_types = ['image/png', 'image/jpeg'];
            const max_height = 15200;
            const max_width = 25600;

            if (event.target.files[0].size > max_size) {
                this.imageError =
                    'Maximum size allowed is ' + max_size / 1000 + 'Mb';

                return false;
            }
      
      
              var reader = new FileReader();
              reader.readAsDataURL(event.target.files[0]);
             
              reader.onload = (event:any) => {
               
                const img = new Image();
                img.src = reader.result as string;
                img.onload = () => {
                  const height = img.naturalHeight;
                  const width = img.naturalWidth;
                  console.log('Width and Height', width, height);
                  if(width >850 || height>400 )
                  {
                  this.imageError ='Maximum size allowed is 850px * 400px'
                  this.imageSizeError = true;
                  event.target.value = null;
                  return false;
                  }
                };
 
                 this.imageSettingsForm.patchValue({
                    fileSource: this.file
                 });
              }

              
      
  }
  }

 
  deleteBanner1(imageId)
  {
    this.updateImageBody.shopId=this.shopId;
    this.updateImageBody.imageId =imageId.toString();
    
    this.controlPanelService.updateBanner(this.updateImageBody).subscribe(
      (res: any) => {
        console.log(res);
        if (res.acknowledgement.status === "SUCCESS") {
          this.successMessage = res.acknowledgement.message
          this.toastr.success( this.successMessage,"SUCCESS")
          this.controlPanelService.getBannerImages(this.shopId).subscribe(
            result => {
              this.bannerImages = result.images;
             
            }
          
          );
        }
        else{
          this.errorMessage=res.acknowledgement.message
          this.toastr.error(this.errorMessage,"ERROR")
        }
        
      },
      (err:HttpErrorResponse) => {
        if(err)
        {
           this.errorMessage=err.error.acknowledgement.message
           this.toastr.error(this.errorMessage,"ERROR")
        }
        else{
          this.toastr.error("Sorry, Image was not deleted","ERROR")
        }

     }
  
    );
  }
  
  uploadLogo()
  {
    if(this.logo)
    {
    this.uploadLogoVar=true;
    const formData = new FormData();
    formData.set('logoFile',this.logo);
    var shop =this.warehouseSettingsDetails.shopId.toString();
    formData.set('shopId',shop);
    this.controlPanelService.saveLogo(formData).subscribe(
      (res: any) => {
        if (res.acknowledgement.status === "SUCCESS") {
          this.successMessage = res.acknowledgement.message
          this.toastr.success( this.successMessage,"SUCCESS")
          this.uploadLogoVar=false;
        }
        else {
          this.errorMessage=res.acknowledgement.message
          this.toastr.error(this.errorMessage,"ERROR")
          this.uploadLogoVar=false;
        }
        
      },
      (err:HttpErrorResponse) => {
        if(err)
        {
           this.errorMessage=err.error.acknowledgement.message
           this.toastr.error(this.errorMessage,"ERROR")
           this.uploadLogoVar=false;
        }
        else{
          this.toastr.error("Sorry, Image was not uploaded","ERROR")
          this.uploadLogoVar=false;
        }

     }
    ); 
    }
    else {
      this.toastr.error("Sorry, please check if you selected an image","ERROR")
     
    }

  }
  uploadBanner(){
    
    this.isUploading=true;
    if(this.imageSettingsForm.valid){
  
    const formData = new FormData();
    formData.set('imageFile',this.file);
    formData.set('name',this.fileName);
    formData.set('shopId',this.warehouseSettingsDetails.shopId);
    formData.set('title',this.imageSettingsForm.value.bannerText);
    formData.set('description',this.imageSettingsForm.value.bannerDescription);
    console.log(formData);
     this.controlPanelService.saveBanner(formData).subscribe(
      (res: any) => {
        if (res.acknowledgement.status === "SUCCESS") {
          this.successMessage = res.acknowledgement.message
          this.toastr.success( this.successMessage,"SUCCESS")
          this.isUploading=false;
          this.imageSettingsForm.patchValue({
            bannerText: "",
            bannerImage: ""
       });
          this.controlPanelService.getBannerImages(this.shopId).subscribe(
            result => {
              this.bannerImages = result.images;
             
            }
          
          );
        }
        else{
          this.errorMessage=res.acknowledgement.message
          this.toastr.error(this.errorMessage,"ERROR")
          this.isUploading=false;
        }
        
      },
      (err:HttpErrorResponse) => {
        if(err)
        {
           this.errorMessage=err.error.acknowledgement.message
           this.toastr.error(this.errorMessage,"ERROR")
           this.isUploading=false;
        }
        else{
          this.toastr.error("Sorry, Image was not uploaded","ERROR")
          this.isUploading=false;
        }

     }
    ); 

  
}
else {
  this.toastr.error("Sorry, please check if you selected an image","ERROR")
  this.isUploading=false;

}
  }
  
  submitImages(){
    console.log("here");
    this.toastr.success( "Saved","SUCCESS")
  }

}
