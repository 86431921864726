<tour-step-template>
    <ng-template let-step="step">
        <div class="row">
        <div>
        <button mat-icon-button [disabled]="!gTourService.hasPrev(step)"
        [ngClass]="{'inactive-btn' : !gTourService.hasPrev(step)}" class="ml-2"
        (click)="gTourService.prev()"> <mat-icon>arrow_back</mat-icon></button>
    </div>
    <div class="ngxp-end">
        <button mat-flat-button (click)="gTourService.end()">{{step?.endBtnTitle}}</button>
    </div>
    </div>
        <div class="ngxp-title" [ngClass]="{'ngxp-first-step' : !gTourService.hasPrev(step)}">
            <div class="ngxp-title-text">
                {{step?.title}}
        </div> 
           
        </div>
        <img src="../../assets/icon/guided_tour.png" width="60px">
        <div class="ngxp-content mb-3">{{step?.content}}</div>
        <div class="tour-step-navigation" style="display: flex;
        justify-content: center;
        align-items: center;">
            
            <button mat-raised-button color="primary" [disabled]="!gTourService.hasNext(step)"
                [ngClass]="{'inactive-btn' : !gTourService.hasNext(step)}" class="nextBtn"
                (click)="gTourService.next()">{{step?.nextBtnTitle}} »</button>
        </div>
    </ng-template>
</tour-step-template>

<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12 white-container">  
            <!-- <mat-toolbar>
                <h3> General Settings</h3>
            </mat-toolbar> -->
            <div class="card">
               
                <div class="card-body pt-4">
                    <div class="example-small-box mat-elevation-z4">
                        <form [formGroup]="generalSettingsForm">
                            <div class="form-horizontal card-body pl-2">
                                <div style="float: right;" class="col-md-1 text-center">
                                    <mat-icon matTooltip="Click for a guided Tour" class="pointer" (click)="genSettingsTour()">play_circle_outline
                                    </mat-icon>
                                    <h6 style="margin-top: 0mm;">Guided Tour</h6>
                                </div>
                                <div class="form-group col-md-6 ws-guided-tour step1" tourAnchor="cptour.gstep1.id"  >
                                    <mat-form-field class="full-width" appearance="outline">
                                        <mat-label>Display Name</mat-label>
                                        <!-- <label class="oo" for="displayName">Display Name *</label> -->
                                        <input matInput type="text" id="displayName"
                                        [formControl]="generalSettingsForm.controls['displayName']"
                                        (keydown.enter)="false"
                                        required>
                                        <mat-error *ngIf="generalSettingsForm.controls['displayName']?.errors?.required && generalSettingsForm.controls['displayName'].touched">
                                            This field is required</mat-error>
                                        </mat-form-field>
                                </div> 
                                 <div class="form-group col-md-6 ws-guided-tour step2" tourAnchor="cptour.gstep2.id">
                                     <mat-form-field class="full-width" appearance="outline">
                                         <mat-label>Currency</mat-label>
                                         <!-- <label class="oo" for="currency-multiselect">Currency *</label> -->
                                         <mat-select 
                                         [formControl]="generalSettingsForm.controls['currency']" [disabled]="currencySelected" (keydown.enter)="false">
                                         <mat-option   *ngFor="let item of countryList" [value]="item.currency" >{{item.currency}}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="generalSettingsForm.controls['currency']?.errors?.required && generalSettingsForm.controls['currency'].touched">
                                            This field is required</mat-error>
                                        </mat-form-field>
                                </div> 
                                 <div class="form-group col-md-6 ws-guided-tour step3" tourAnchor="cptour.gstep3.id">
                                     <mat-form-field class="full-width" appearance="outline">
                                         <mat-label>Shipping Cost</mat-label>
                                         <!-- <label class="oo" for="shippingcost">Shipping Cost</label> -->
                                         <input matInput type="number" min="0" oninput="validity.valid||(value='');" id="shippingcost"
                                         [formControl]="generalSettingsForm.controls['shippingCost']"
                                         (keydown.enter)="false" >
                                         <mat-error *ngIf="generalSettingsForm.controls['shippingCost']?.errors?.required && generalSettingsForm.controls['shippingCost'].touched">
                                             This field is required</mat-error>
                                            </mat-form-field>
                                </div> 
                                 <div class="form-group col-md-6 ws-guided-tour step4" tourAnchor="cptour.gstep4.id">
                                     <mat-form-field class="full-width" appearance="outline">
                                         <mat-label>Time Zone</mat-label>
                                         <!-- <label  class="oo" for="timezone-multiselect">Time Zone *</label> -->
                                         <mat-select 
                                         [formControl]="generalSettingsForm.controls['timeZone']" [disabled]="timezoneSelected" required>
                                         <mat-option *ngFor="let item of timeZoneList" [value]="item.timeZoneId" >
                                             {{item.zoneName}}</mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="generalSettingsForm.controls['timeZone']?.errors?.required && generalSettingsForm.controls['timeZone'].touched">
                                                This field is required</mat-error>
                                            </mat-form-field>
                                 </div> 

                               <!--  <div class="form-group col-md-6 ws-guided-tour step5" tourAnchor="cptour.gstep5.id">
                                     <mat-form-field class="full-width" appearance="outline">
                                         <mat-label>MOQ</mat-label>
                                          <label class="oo" for="moq">MOQ</label>
                                         <input matInput type="number"  min="0" id="moq" oninput="validity.valid||(value='');"
                                         [formControl]="generalSettingsForm.controls['moq']"
                                         >
                                        </mat-form-field>
                                </div>
                                <div class="form-group col-md-6 ws-guided-tour step6" tourAnchor="cptour.gstep6.id">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>MOV</mat-label>
                                  <label  class="oo" for="mov">MOV</label>
                                    <input matInput type="number" min="0" id="mov" oninput="validity.valid||(value='');"
                                    [formControl]="generalSettingsForm.controls['mov']"
                                    > 
                                </mat-form-field>
                                       
                                </div> -->
                               
                               
                                <div class="form-group col-md-6 ws-guided-tour step7" tourAnchor="cptour.gstep7.id">
                                    <mat-form-field class="full-width" appearance="outline">
                                        <mat-label>Tax-Number/GST</mat-label>
                                        <!-- <label class="oo" for="taxNumber">Tax-Number/GST</label> -->
                                        <input matInput [formControl]="generalSettingsForm.controls['taxNumber']"
                                        > 
                                    </mat-form-field>
                                       
                                </div>
                                <div class="form-group col-md-6" tourAnchor="cptour.gstep8.id" >
                                    <mat-form-field class="full-width" appearance="outline">
                                        <mat-label>Order Prefix</mat-label>
                                        <!-- <label class="oo" for="taxNumber">Tax-Number/GST</label> -->
                                        <input matInput [maxLength]="4" [formControl]="generalSettingsForm.controls['orderPrefix']"
                                        > 
                                    </mat-form-field>
                                       
                                </div>

                                <div  class="example-margin" tourAnchor="cptour.gstep9.id">
                                    
                                        <mat-slide-toggle [formControl]="generalSettingsForm.controls['enableInventory']"
                                           
                                           
                                            [(ngModel)]="checked">
                                            
                                            Enable Inventory (Available To Promise)
                                        </mat-slide-toggle>
                                      
                                    <!-- <mat-checkbox [formControl]="generalSettingsForm.controls['enableInventory']"
                                    [(ngModel)]="checked">Enable Inventory (change)="onChangeInventory()</mat-checkbox> -->
                                </div>
                                <div  class="example-margin" >
                                    <mat-slide-toggle [formControl]="generalSettingsForm.controls['enableInventoryCountForSalesman']"
                                    [(ngModel)]="checkedInventoryCountForSalesman">Enable Inventory Count for Sales Executive</mat-slide-toggle>
                                </div>
                                
                                <div  class="example-margin" >
                                    <mat-slide-toggle [formControl]="generalSettingsForm.controls['enableOfflineSalesmanApp']"
                                    [(ngModel)]="checkedOfflineSalesmanApp">Enable Offline Salesman App</mat-slide-toggle>
                                </div>


                                <div  class="example-margin" tourAnchor="cptour.gstep10.id">
                                    <mat-slide-toggle [formControl]="generalSettingsForm.controls['displayProdImages']"
                                    [(ngModel)]="checkedProductImages">Show Product Images</mat-slide-toggle>
                                </div>

                                <div  class="example-margin" tourAnchor="cptour.gstep11.id">
                                    <mat-slide-toggle [formControl]="generalSettingsForm.controls['taxableProducts']"
                                    [(ngModel)]="checkedTaxableProducts">Products are Taxable</mat-slide-toggle>
                                </div>

                                <div  class="example-margin" tourAnchor="cptour.gstep12.id">
                                    <mat-slide-toggle [formControl]="generalSettingsForm.controls['enableERP']"
                                    [(ngModel)]="checkedErp"  (change)="onChange()">Enable ERP</mat-slide-toggle>
                                </div>

                                <div  class="example-margin" tourAnchor="cptour.gstep13.id">
                                    <mat-slide-toggle [formControl]="generalSettingsForm.controls['enableWebhook']"
                                    [(ngModel)]="checkedWebhook" (change)="onSelect()">Enable Webhook</mat-slide-toggle>
                                </div>


                               <div fxFlex="100" class="mt-1 col-md-6" tourAnchor="cptour.gstep14.id">
                                    <button mat-raised-button class="green" [disabled]="continue" (click)="Submit()">Save</button> 
                                    <button *ngIf="showContinueButton" mat-button color="accent" mat-raised-button type="button" class="ml-3" (click)="continueToNotification()">Continue</button>
                                   
                                 </div> 
                               
                            </div>
                        </form>
                    </div>


                </div>
            </div>
        </div>
    </div>
</div>