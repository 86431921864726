import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ControlPanelService } from '../../control-panel/control-panel.service';
import { WarehouseSettings } from '../../control-panel/models/WarehouseSettings.model'
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HostListener } from "@angular/core";
import { TourService } from 'ngx-tour-ngx-popper';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationSettings } from '../../control-panel/models/NotificationSetting.model';
import {SettingsComponent} from '../settings.component'

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  public notificationForm: FormGroup;
  notificationSettingsDetail: any;
  warehouseSettingsDetails: any;
  hideSubmitStep = false;
  hidePrevStep = false;
  successMessage:string="";
  errorMessage:string="";
  tempPrevTourStep;
  shopId="";
  continue=false;
  countryList:any;
  codeSelected=false;
  showContinueButton=false;

  constructor(private fb: FormBuilder,
    private controlPanelService: ControlPanelService,
    public activateRoute: ActivatedRoute,
    private router: Router,
    public nTourService: TourService,
    private toastr: ToastrService,
    private settings:SettingsComponent) { }

  ngOnInit(): void {
    this.controlPanelService.getwarehouseSettings().subscribe(
      res => {
        this.warehouseSettingsDetails = res.warehouseSettings;
        if(this.warehouseSettingsDetails === null)
        {
        this.toastr.error("Please Update the WareHouse Settings first to Continue","ERROR")
          this.continue=true;
        }
        else
        this.shopId = this.warehouseSettingsDetails.shopId;
      });

    this.controlPanelService.getnotificationSettings().subscribe(
      res => {
        this.notificationSettingsDetail = res.notificationSettings;
       if(this.notificationSettingsDetail){
        this.notificationForm.controls['email'].setValue(this.notificationSettingsDetail.email);
        this.notificationForm.controls['phone'].setValue(this.notificationSettingsDetail.phone);
        this.notificationForm.controls['countryId'].setValue(this.notificationSettingsDetail.countryId);
        if(this.notificationSettingsDetail.countryId)
        this.codeSelected=true;
       }
      }
    );
    this.controlPanelService.tenantCountryList().subscribe(
      res => {
        this.countryList = res.country;
      });

    this.notificationForm = this.fb.group({
      email: ['', {
        validators: [
            Validators.required,
            Validators.email
        ],
        updateOn: 'blur'
     }],
      phone: ['',Validators.compose([ Validators.required])],
      countryId: ['' || '']
    });

    this.nTourService.initialize([
      {
        stepId : 'cptour.nstep1.id',
       anchorId: 'cptour.nstep1.id',
       content: 'Email address to sent the notification about Orders' ,
       title: 'Email',
       placement:'right',
       endBtnTitle:'Skip',
       
      
    
     },
     { 
      stepId:'cptour.nstep2.id', 
       anchorId: 'cptour.nstep2.id',
       content: 'Mobile number for the Order notification' ,
       title: 'Phone Number',
       placement:'right',
       endBtnTitle:'Skip',
       
     },
     {
      stepId:'cptour.nstep3.id',
      anchorId: 'cptour.nstep3.id',
      content: 'Please press this button to save all the above information',
      title: 'Save and Submit',
      placement:'right',
      
    } ],
      {route: 'settings',
      });
  }

      @HostListener('click',['$event'])mouseover(event) {  
        if (this.nTourService.currentStep) {
          const nextStep = $(event.target).closest(".ws-guided-tour").attr("tourAnchor")
          if (nextStep && this.tempPrevTourStep !== nextStep) {
  
            this.nTourService.startAt(nextStep);
            this.tempPrevTourStep = nextStep;
          }      
        }
      };
      @HostListener('document:keydown.tab', ['$event'])
      onKeydownHandler(event: KeyboardEvent) {
       if (this.nTourService.currentStep) {
             this.nTourService.next();
       
         }    
       }
    

       continueToOfs(){
         this.settings.openOfsSettings();
         this.settings.toggleColor(4);
       }

  public notificationTour(): void {
    
   this.nTourService.disableHotkeys();
   this.nTourService.start(); 

 }

 Submitnotification() {
  this.notificationForm.markAllAsTouched();
 
  if(this.notificationForm.valid){
  var obj: NotificationSettings = this.notificationForm.value
  obj['shopId'] = this.shopId;
  this.controlPanelService.savenotificationSettings(obj).subscribe(
    (res: any) => {
      if(res.acknowledgement.status==="SUCCESS"){
       this.showContinueButton=true;
       this.successMessage=res.acknowledgement.message
       this.toastr.success( this.successMessage,"SUCCESS")
      }
      else{
        this.errorMessage=res.acknowledgement.message
        this.toastr.error(this.errorMessage,"ERROR")
      }
      
    },
    
      (err:HttpErrorResponse) => {
       
        if(err)
        {
           this.errorMessage=err.error.acknowledgement.message
           this.toastr.error(this.errorMessage,"ERROR")
        }
        else{
          this.toastr.error("Sorry,The information was not updated,Please try again later","ERROR")
        }

     }
    
  );
}
}

}
