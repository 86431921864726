import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ControlPanelService {

  baseUrl = environment.serviceURL + 'storimartadmin';
  Authtoken: string;

  checkBoxSubject$ = new BehaviorSubject<boolean>(null);
  webHookSubject$ = new BehaviorSubject<boolean>(null);
  inventorySubject$ = new BehaviorSubject<boolean>(null);

  constructor(private httpclient: HttpClient, private _zone: NgZone) {
    this.Authtoken = "Basic ZW1hZC5hc2hlckBzdG9yaWxhYnMuY29tOm9rYXkxMjM0"
  }

  saveBanner(obj): Observable<any> {

    return this.httpclient.post(this.baseUrl + "/seller/banner", obj);
  }

  saveLogo(obj): Observable<any> {

    return this.httpclient.post(this.baseUrl + "/seller/logo", obj);
  }

  getgeneralSettings(): Observable<any> {
    return this.httpclient.get(this.baseUrl + "/settings/general");
  }
  getwarehouseSettings(): Observable<any> {
    return this.httpclient.get(this.baseUrl + "/settings/warehouse");
  }

  getErpSettings(): Observable<any> {
    return this.httpclient.get(this.baseUrl + "/seller/config/erp");
  }

  getWebhookSettings(): Observable<any> {
    return this.httpclient.get(this.baseUrl + "/seller/config/webhook");
  }

  getofsSettings(): Observable<any> {
    return this.httpclient.get(this.baseUrl + "/settings/ofs");
  }
  getnotificationSettings(): Observable<any> {
    return this.httpclient.get(this.baseUrl + "/settings/notification");
  }
  getprofileSettings(): Observable<any> {
    return this.httpclient.get(this.baseUrl + "/settings/profile");
  }

  getFooterDetails(shopId): Observable<any> {
    let params = new HttpParams().set('shopId', shopId);
    return this.httpclient.get(this.baseUrl + "/sellerdetails", { params: params });
  }

  timeZoneList(): Observable<any> {
    return this.httpclient.get(this.baseUrl + "/timezone");
  }
  countryList(): Observable<any> {
    return this.httpclient.get(this.baseUrl + "/country");
  }
  savegeneralSettings(obj) {
    return this.httpclient.post(this.baseUrl + "/settings/general", obj)
  }

  tenantCountryList(): Observable<any> {
    return this.httpclient.get(this.baseUrl +"/tenantCountryList");
  }

  savewarehouseSettings(obj) {
    return this.httpclient.post(this.baseUrl + "/settings/warehouse", obj)
  }
  saveofsSettings(obj) {
    return this.httpclient.post(this.baseUrl + "/settings/ofs", obj)
  }
  savenotificationSettings(obj) {
    return this.httpclient.post(this.baseUrl + "/settings/notification", obj)
  }
  saveprofileSettings(obj) {
    return this.httpclient.post(this.baseUrl + "/settings/profile", obj)
  }

  saveErpSettings(obj) {
    return this.httpclient.post(this.baseUrl + "/seller/config/save", obj)
  }

  saveWebhookSettings(obj) {
    return this.httpclient.post(this.baseUrl + "/seller/config/save", obj)
  }



  getBannerImages(shopId): Observable<any> {
    let params = new HttpParams().set('shopId', shopId);
    return this.httpclient.get(this.baseUrl + "/seller/banner", { params: params });
  }

  updateBanner(obj): Observable<any> {
    return this.httpclient.put(this.baseUrl + "/seller/banner/status", obj);
  }

  saveFooterSettings(obj) {
    return this.httpclient.post(this.baseUrl + "/sellerdetails", obj)
  }


}
