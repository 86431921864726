import { Component, OnInit,Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { environment } from '../../../environments/environment'

@Component({
  selector: 'app-email-dialog',
  templateUrl: './email-dialog.component.html',
  styleUrls: ['./email-dialog.component.css']
})
export class EmailDialogComponent implements OnInit {


  isGenerated:boolean;
  isGeneratedClick: boolean;
  excelDataUrl: any;
  excelDataProcessId: any;

  constructor(public dialogRef: MatDialogRef<EmailDialogComponent>, @Inject(MAT_DIALOG_DATA) data) 
  { 
    this.excelDataUrl=data.excelDataUrl,
    this.excelDataProcessId=data.excelDataProcessId
  }

  ngOnInit(): void {
  }

  
processExcel()
{
 this.isGeneratedClick=true
    let eventSource = new EventSource(environment.serviceURL + "storimartreport" + "/stream-sse-mvc/" + this.excelDataProcessId + "?token=" + localStorage.getItem("AuthorizationToken"))
     eventSource.onerror = (evt) => {
      eventSource.close()
    }
    eventSource.addEventListener("In Progress", (evt: any) => {
      this.progressBarAnimate(evt.data)
      eventSource.close()
    })
    eventSource.addEventListener("Completed", (evt: any) => {
      this.progressBarAnimate(evt.data)
        this.isGenerated=true
        this.isGeneratedClick=false
        eventSource.close()
      
    })
    
}

progressBarAnimate(limit)
{
  var i = 0;
     
  if (i == 0) {
    i = 1;
    var elem = document.getElementById("myBar");
    var width = 1;
    var id = setInterval(frame, 10);
    function frame() {
      if (width >= limit) {
        clearInterval(id);
        i = 0;
   
      
      } else {
        width++;
        elem.style.width = width + "%";
        
      }
    }
    
  }  
}

closeExcel()
{
  this.isGeneratedClick=false ;
  this.isGenerated=false;
  this.dialogRef.close();
}


}
