
import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class BuyersService {

  baseUrl = environment.serviceURL + 'storimartadmin';
  authToken = localStorage.getItem("AuthorizationToken");

  constructor(private httpclient: HttpClient, private _zone: NgZone) { }

  timeZoneList(): Observable<any> {
    return this.httpclient.get(this.baseUrl + "/timezone");
  }

  countryList(): Observable<any> {
    return this.httpclient.get(this.baseUrl + "/country");
  }
  
  tenantCountryList(): Observable<any> {
    return this.httpclient.get(this.baseUrl +"/tenantCountryList");
  }

  addBuyer(obj): Observable<any> {
    return this.httpclient.post(this.baseUrl + "/seller/retailer/add", obj)
  }

  addExcelBuyers(obj): Observable<any> {
    return this.httpclient.post(this.baseUrl + "/retailer/excel/add", obj)
  }

  getBuyersDetails(shopId,limit,offset): Observable<any> {
    let buyerparams=new HttpParams()
    .set('shopId',shopId)
    .set('limit',limit)
    .set('offset',offset)
    return this.httpclient.get(this.baseUrl + "/retailers", { params:buyerparams })
  }

  getBuyersDetailsForExcel(shopId): Observable<any> {
    const params = new HttpParams().append('shopId', shopId);
    return this.httpclient.get(this.baseUrl + "/retailers", { responseType: 'blob' as 'blob', params })
  }

 


  addBuyerAsAppUser(obj): Observable<any> {
    return this.httpclient.post(this.baseUrl + "/retailer/user", obj)
  }

  updateBuyer(obj): Observable<any> {
    return this.httpclient.put(this.baseUrl + "/seller/retailer/edit", obj)
  }

  getUserListOfBuyer(shopId, retailerId): Observable<any> {

    return this.httpclient.get(this.baseUrl + "/retailer/users", {
      params: {
        'shopId': shopId,
        'retailerShopId': retailerId
      }
    })
  }

  changeStatus(obj): Observable<any> {
    return this.httpclient.post(this.baseUrl + "/retailer/changestatus", obj)
  }

  updateAppUser(obj): Observable<any> {
    return this.httpclient.put(this.baseUrl + "/retailer/user", obj)
  }

  getListOfBuyergroups(): Observable<any> {
    return this.httpclient.get(this.baseUrl + "/seller/groups")
  }

  getParametersListArray(): Observable<any> {
    return this.httpclient.get(this.baseUrl + "/parameters")
  }

  getGroupInfo(groupId): Observable<any> {
    const params = new HttpParams().append('groupId', groupId);
    return this.httpclient.get(this.baseUrl+'/group/groupinfo', { params })
  }
  changeStatusOfGroup(action): Observable<any> {
    return this.httpclient.post(this.baseUrl +'/group/changestatus', action)
  }

  getParametersList(groupId): Observable<any> {
    const params = new HttpParams().append('groupid', groupId);
    return this.httpclient.get(this.baseUrl + "/parameters", { params })
  }

  addBuyerGroup(buyerGroup): Observable<any> {
    return this.httpclient.post(this.baseUrl +'/seller/groups', buyerGroup)
  }
  
  editBuyerGroup(buyerGroup): Observable<any> {
    return this.httpclient.put(this.baseUrl +'/seller/groups', buyerGroup)
  }

}
