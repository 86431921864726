import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BuyersService } from './../buyers.service';

@Component({
  selector: 'app-addappuser',
  templateUrl: './addappuser.component.html',
  styleUrls: ['./addappuser.component.css']
})
export class AddappuserComponent implements OnInit {

  appUserForm: FormGroup;
  countryList: any;

  constructor(private dialogRef: MatDialogRef<AddappuserComponent>, public fb: FormBuilder,
    private BuyersService: BuyersService
  ) { }

  ngOnInit(): void {

    this.BuyersService.tenantCountryList().subscribe(
      res => {
        this.countryList = res.country;
      }
    );

    this.appUserForm = this.fb.group({
      userId:[''||''],
      name: ['' || '', Validators.required],
      countryId: ['' || '', Validators.required],
      phone: ['', Validators.compose([Validators.required,
      Validators.pattern("[0-9]{10}")])],
      email: ['', {
        validators: [

          Validators.email
        ],
        updateOn: 'blur'
      }],
    });
  }

  save() {
    if (!this.appUserForm.valid) {
      return;
    }
    else {
      console.log(this.appUserForm.value);
    }
    this.dialogRef.close(this.appUserForm.value);

  }

  close() {
    this.dialogRef.close();
  }

}
