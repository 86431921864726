import { ServiceService } from './../Service/service.service';
import { ReportService } from './../Service/report.service';
import { Component, OnInit, QueryList, ViewChildren, ViewChild, ChangeDetectorRef} from '@angular/core';
import { ReportDropDownResponse } from '../Models/Response/reportDropDownResponse';
import { responseForReportTable } from '../Models/Response/responseForReportTable';
import { NgForm, FormGroup, FormControl, FormBuilder,Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, merge } from 'rxjs';
import { DatePipe, registerLocaleData } from '@angular/common';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from "@angular/material/paginator";
import { countryResponse } from '../Models/Response/countryResponse';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { environment } from '../../environments/environment'
import { InvoiceViewResponse } from '../Models/Response/InvoiceViewResponse';
import { TourService } from 'ngx-tour-ngx-popper';
import {MyVersion} from 'src/my-version';
import {MatDatepickerInputEvent} from '@angular/material/datepicker'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'; 
import {EmailDialogComponent} from './email-dialog/email-dialog.component'
import {InvoiceDialogComponent} from './invoice-dialog/invoice-dialog.component'
import * as moment from 'moment';
import { ControlPanelService } from '../control-panel/control-panel.service';




@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})


export class ReportComponent implements OnInit {

  itemForm: FormGroup;
  searchForm: FormGroup;
  reportForm:FormGroup;
  bsValue = new Date();
  bsRangeValue: Date[];
  maxDate = new Date();
  pdfURL:string;
  noItems=false;
  startDate 
  endDate
  phHide
  sbHide
  searchType: any;
  salesman: any;
  buyer:any;
  status: any;
  orderId: any;
  countryCode: any;
  phone: any;
  rowCount: Number;
  tabShown : boolean;
  hideSubmitStep=false;
  hidePrevStep =false;
  textBoxOIDisabled = true;
  textBoxCNDisabled = true;
  textBoxPNDisabled = true;
  salesmanList
  retailerList
  statusList
  ddListBuyer
  ddListSalesman
  isNameSelected:boolean
  isSalesmanSelected: boolean;
  isOrderSelected: boolean;
  isPhoneSelected:boolean;
  tbHeaders
  orders
  noPdtMsg=""
  isorderidtrue:false
  isTableSelected:boolean
  isWhTableSelected:boolean
  pageOffset=0
  pageLimit=10
  currentPage=1
  reportDataSource: any;  
  itemWiseReportDataSource: any;
  isGenerated:boolean;
  loading:boolean = true;
  showModal:boolean=false;
  itemWiseReport:boolean=false;
  buyerImgUrl = '../assets/icon/transsummary.png';
  groupImgUrl ='../assets/icon/orderhistory.png'
  topSellingImgUrl ='../assets/icon/bestSeller.png'
  displayedColumns = ['orderId', 'customerName','phone','submittedBy','totalAmount','paid','balance','submittedDate','preferredDeliveryTime','actualDeliveryTime','status','actions'];  
  columnDefinitions = [
    { def: 'orderId', hide: false},
    { def: 'customerName', hide: false},
    { def: 'phone', hide: false},
    { def: 'submittedBy', hide: false},
    { def: 'totalAmount', hide: false},
    { def: 'paid', hide: false},
    { def: 'balance', hide: false},
    { def: 'submittedDate', hide: false},
    { def: 'preferredDeliveryTime', hide: false},
    { def: 'actualDeliveryTime', hide: false},
    { def: 'status', hide: false},
    { def: 'actions', hide: false}
    
]

displayedColumnsWarehouse=['customerId','warecustomerName','noOfOrders','warephone','waretotalAmount','actions']
displayedColumnsItemWise=['sn','productName','quantity']

@ViewChild(MatSort) sort: MatSort;
@ViewChild (MatPaginator) paginator: MatPaginator;
@ViewChild (MatPaginator) paginatorItem: MatPaginator;
 minDate = new Date();
  dateRanges: Date;
  countryData
  pageNo
  suitedCountryArray
  phMinLenght=10
  phMaxLenght=10
  phErrMsg="Please choose one of the above filters"
  isErrorMsg: boolean;
  isData:boolean
  invoiceDataSet
  inCustomername: string;
  inCountry: string;
  inCity: string;
  inStreet: string;
  inAddressLine1: string;
  inZipPin: string;
  inState: string;
  inTotalAmount
  inPaidAmount
  inToPayAmount
  inOrderCount
  inCurrency: string;
  inPhone: string;
  disableSalesman:boolean;
  excelDataUrl: any;
  excelDataProcessId: any;
  excelAuthToken:any;
  allData: any
  searchBydata:any
  isGeneratedClick: boolean;
  progresswidth: void;
  curDate;
  ver:number;
  selected;
  selectedOption;
  transactionSummary:boolean=true;
  orderDetails:boolean=false;
  currency="";
  warehouseName="";

  filters:any[] =[
    {value: 'buyers', viewValue: 'Buyers'},
    {value: 'id', viewValue: 'Order ID'},
    {value: 'status', viewValue: 'Order Status'}
  ];

  

  isSelected: boolean = false;
  buyerSelected: Boolean = undefined;
  idSelected:Boolean = undefined;
  statusSelected:Boolean = undefined;
  startDateForItem: string;
  endDateForItem: string;
  paginatorLength: any;
  searchKey: any;

  get(data) {
    this.isSelected = true;
    if (data == 'buyers') {
      this.buyerSelected = true;
      this.statusSelected =false;
      this.idSelected=false;
    } 
    else if (data === 'id')
    {
      this.buyerSelected = false;
      this.idSelected=true;
      this.statusSelected =false;
    }
    else {
      this.statusSelected=true;
      this.buyerSelected = false;
      this.idSelected=false;
    }
  }

getDisplayedColumns():string[] {
  return this.columnDefinitions.filter(cd=>!cd.hide).map(cd=>cd.def);
}

toggleColor(n) {
  this.selected=n;
}

ngAfterViewInit() {
 
}

  toggleByOrderId()
  {
    this.textBoxOIDisabled = false;
    this.textBoxCNDisabled = true;
    this.textBoxPNDisabled = true;
    
  }
  toggleByCustomerName()
  {
    this.textBoxOIDisabled = true;
    this.textBoxCNDisabled = false;
    this.textBoxPNDisabled = true
  }

  toggleByPhoneNumber()
  {
    this.textBoxOIDisabled = true;
    this.textBoxCNDisabled = true;
    this.textBoxPNDisabled = false;
  }
  
  clearFilters(){
    this.reportDataSource.filter = '';
    this.searchKey="";
 }
 
  onOptionsSelected(event)
  {
    let selected = event.target.value;
  

    switch(selected)
    {
      case 'AL':
        {
          this.isNameSelected = false
          this.isOrderSelected=false
          this.isPhoneSelected=false
          this.isSalesmanSelected=true
          this.disableSalesman=true
          this.isErrorMsg= false;
          
          break;
        }
      case 'SM':
      {
        
        this.ddListSalesman=this.salesmanList
        this.isNameSelected = false
        this.isOrderSelected=false
        this.isPhoneSelected=false
        this.isSalesmanSelected=true
        this.disableSalesman=false
        this.isErrorMsg= false;
    
        break;
      }
      case 'BR':
      {
        // this.isNameSelected = false;
        this.ddListBuyer=this.retailerList
        this.isNameSelected = false
        this.isOrderSelected=false
        this.isPhoneSelected=false
        this.isSalesmanSelected=true
        this.disableSalesman=false
        this.isErrorMsg= false;
        break;
      }
      case 'ST':
        {
          this.isNameSelected = true
          this.isOrderSelected=false
          this.isPhoneSelected=false
          this.isSalesmanSelected=false
          this.isErrorMsg= false;
         break;
          
        }
        case 'OI':
        {
         this.isNameSelected = false
        this.isOrderSelected= true
        this.isPhoneSelected=false
        this.isSalesmanSelected=false
        this.isErrorMsg= false;
        break;

        }
        case 'PH':
        {
         this.isNameSelected = false
        this.isOrderSelected=false
        this.isPhoneSelected=true
        this.isSalesmanSelected=false
        this.isErrorMsg= false;
        break;

        }
    }

   
  }
  
  onChangeEvent(event){
    console.log(event);
    console.log(event.value);
    this.onReportFormSubmit();
  } 

  onChangeEventItem(event){
    this.getItemWiseReport();
  }
  

  constructor(private reportService: ReportService,
    public rpTourService: TourService,
    private cdr: ChangeDetectorRef,
    private controlPanelService:ControlPanelService,
    private datePipe:DatePipe,private formBuilder: FormBuilder,private dialog:MatDialog) {
      this.maxDate.setDate(this.maxDate.getDate()); //current date
      this.bsValue.setDate(this.bsValue.getDate() - 30); // 30 days back date
      this.bsRangeValue = [this.bsValue, this.maxDate]; 
    /* this.maxDate.setDate(this.maxDate.getDate() - 7);
    this.bsRangeValue = [this.maxDate,this.bsValue]; */
   
   
    this.rpTourService.initialize([
      {
        anchorId: 'rptour.step1.id',
        content:'Welcome to the Guided tour of Report search page',
        title: 'Welcome',
        nextBtnTitle: 'Get started',
        endBtnTitle:'Skip',
      },
      {
        anchorId: 'rptour.step2.id',
        content:'View the summary of all transactions from a specified date range. Please select a range of dates',
        title: 'Search by Date',
        endBtnTitle:'Skip',
        placement :'bottom'
      },
      
      {
        stepId:"4",
        anchorId: 'rptour.step4.id',
        content:'View all transactions for a specified date range. Please select a range of dates',
        title: 'Search by Date',
        endBtnTitle:'Skip',
        placement :'right'
      },
      {
        anchorId: 'rptour.step5.id',
        content:'Please select from one of the filters to filter your transactions',
        title: 'Select one of the filter options',
        endBtnTitle:'Skip',
        placement :'right'
      },
      {
        anchorId: 'rptour.step6.id',
        content:'Once you selected the filter, press this button to get all the order transactions',
        title: 'Reports Summary',
        
      },
 
     ],
     {route: 'report'}
     
     );

     this.createReportForm();
    this.createSearchForm();
     this.createItemSearchForm();
  } ;

  onLinkClick(event: MatTabChangeEvent) {
   
    this.tabShown = false;
    this.loading = (this.searchType === "AL") ? true : false;
  }
 
  dateRange = new FormGroup({
    start: new FormControl(new Date(new Date().setDate(0)).toISOString()),
    end: new FormControl(new Date()),
  });

   createReportForm(){
     this.reportForm = this.formBuilder.group({
      dateRange: [''],
      
      searchType: "AL"
     });
   }
   createItemSearchForm(){
    var day = new Date().getUTCDate();
   this.itemForm = new FormGroup({
    startItem: new FormControl(new Date(new Date().setDate(day-14)).toISOString()),
    endItem:new FormControl(new Date())
  });
}
  createSearchForm(){
    this.searchForm = this.formBuilder.group({
      dateRange1 : this.formBuilder.group({
        start: new FormControl(new Date(new Date().setDate(0)).toISOString()),
        end: new FormControl(new Date()),
      }),
      searchType: "AL",
      salesman: [''],
      buyer: [''],
      status: "",  
      orderId: [''],
      countryCode: "+91",
      phone:['']
  });
  }
  onReportFormSubmit()
  {
    
  this.startDate=this.datePipe.transform(this.dateRange.value.start,"dd/MM/yyyy")
  this.endDate=this.datePipe.transform(this.dateRange.value.end,"dd/MM/yyyy")
  this.searchType="AL"
  this.dataPopulate();
  this.isErrorMsg= false;
  }

  showTransactionSummary(){
    this.transactionSummary=true;
    this.orderDetails=false;
    this.itemWiseReport =false;
    this.tabShown=true;
    this.loading = (this.searchType === "AL") ? true : false;
  }

  showOrderDetails(){
    this.transactionSummary=false;
    this.orderDetails=true;
    this.itemWiseReport =false;
    this.tabShown = false;
    this.loading = (this.searchType === "AL") ? true : false;
  }

  showItemWiseReport(){
    this.transactionSummary=false;
    this.orderDetails=false;
    this.itemWiseReport=true;
    this.tabShown = false;
    this.loading = (this.searchType === "AL") ? true : false;
    this.getItemWiseReport();
  }



oSearchFormSubmit()
{
 this.startDate=this.datePipe.transform(this.searchForm.controls.dateRange1.value.start,"dd/MM/yyyy")
 this.endDate=this.datePipe.transform(this.searchForm.controls.dateRange1.value.end,"dd/MM/yyyy")

 if(this.buyerSelected)
 {
 this.searchType="BR"
 this.buyer=this.searchForm.value.buyer
 }
 else if(this.idSelected)
 {
 this.searchType="OI"
 this.orderId=this.searchForm.value.orderId
 }
 else if(this.statusSelected)
 {
 this.searchType="ST";
 this.status=this.searchForm.value.status
 }
 else 
  this.searchType="AL"    
if (this.searchType === "AL")
{
  
  this.isErrorMsg= true;
}
else
{ 
  this.dataPopulate();
  this.isErrorMsg= false;
}
  }
  
  applyFilterOne(filterValue: string) {
    this.reportDataSource.filter = filterValue.trim().toLowerCase();
  }
 

  ngOnInit() {

    this.curDate = new Date().getFullYear().toString().substr(-2) + '.' +(new Date().getMonth() + 1).toString();
    this.ver=MyVersion.number;
    this.getWarehousename();
    this.reportService.getDropDown()
    .subscribe((dropdownData: ReportDropDownResponse)=>
    {
      this.salesmanList=dropdownData.salesman
      this.retailerList=dropdownData.retailer
       this.ddListBuyer=this.retailerList
       this.ddListSalesman=this.salesmanList
       this.isSalesmanSelected=true
       this.disableSalesman=true
    }
    );

     
    this.reportService.getCountryCode()// to get country api
    .subscribe((countryCd: countryResponse)=>
    {
      this.countryData=countryCd.country 
    }
    );

    
    this.rpTourService.stepShow$.subscribe((res:any) => {
      if (res.stepId ==="3" ){
      this.hideSubmitStep = true;
      }
      
     } );

     this.rpTourService.stepShow$.subscribe((res:any) => {
      if (res.stepId ==="4" ){
      this.hidePrevStep = true;
      }
      
     } );

     this.rpTourService.stepHide$.subscribe((res:any) => {
      if (res.stepId ==="3" ){
      this.hideSubmitStep = false;
      }
      
     } );

     this.rpTourService.stepHide$.subscribe((res:any) => {
      if (res.stepId ==="4" ){
      this.hidePrevStep = false;
      }
      
     } );

     this.onReportFormSubmit()
     
  
    
  }
  
  public ReportTour(): void {
   
    this.rpTourService.start()
  }
  public orderDetailTour():void{
    this.rpTourService.startAt("4");
  }

  public itemWiseTour():void{
    this.rpTourService.startAt("4");
  }

  minMax(cntryCd)// to get min,max lenght of country Code
  { 
    this.suitedCountryArray = this.countryData.find( ({ countryCode }) => countryCode === cntryCd );//to get array contain passed country code
    this.phMinLenght=this.suitedCountryArray.minPhoneDigits
    this.phMaxLenght=this.suitedCountryArray.maxPhoneDigits
    this.phErrMsg="Please select one of the filter values"
  
  }

  getWarehousename(){
    this.controlPanelService.getwarehouseSettings().subscribe(
      res => {
       
        if(res){
       
        this.warehouseName=res.warehouseSettings.name;
        }
      });
  }


/*   pageChanged(obj)// to set offset for  
{
  this.pageNo=obj.page
  this.pageOffset=(this.pageNo-1)*this.pageLimit
 this.oSearchFormSubmit()
} */
getName(){
  if(this.startDateForItem && this.endDateForItem)
  return this.warehouseName+'_ItemWiseReport_'+this.startDateForItem+'_'+this.endDateForItem;
  else 
  return this.warehouseName+'_ItemWiseReport';
}
getItemWiseReport(){
  this.clearFilters();
  this.noItems=false;
  this.startDateForItem = this.datePipe.transform(this.itemForm.value.startItem,"YYYY-MM-dd")
  this.endDateForItem = this.datePipe.transform(this.itemForm.value.endItem,"YYYY-MM-dd")
  if(this.startDateForItem && this.endDateForItem)
  {
    this.reportService.getItemWiseReportWithDates(this.startDateForItem,this.endDateForItem)
    .subscribe((response:any)=>{
    if(response.data.length === 0)
    
    {
      this.noItems=true;
      this.noPdtMsg =response.acknowledgement.message;
    }
    this.itemWiseReportDataSource = new MatTableDataSource(response.data)
    this.cdr.detectChanges();
    this.paginatorLength=this.itemWiseReportDataSource.data.length
    this.itemWiseReportDataSource.paginator = this.paginatorItem;
    });
    
  }
  else { 
  this.reportService.getItemWiseReport()
  .subscribe((response:any)=>
  {
    if(response.data.length === 0)
    
    {
      this.noItems=true;
      this.noPdtMsg =response.acknowledgement.message;
    }
  this.itemWiseReportDataSource = new MatTableDataSource(response.data)
  this.cdr.detectChanges();
  this.paginatorLength=this.itemWiseReportDataSource.data.length
  this.itemWiseReportDataSource.paginator = this.paginatorItem;
  });
  
  }

 
}
applyFilter(){
  this.itemWiseReportDataSource.filter = this.searchKey.trim().toLowerCase();
}

dataPopulate()
{
  this.reportService.getDataTable(this.startDate,this.endDate,this.pageOffset,this.pageLimit,this.searchType,this.buyer,this.status,this.orderId,this.countryCode,this.phone)
  .subscribe((getBy: responseForReportTable)=>
  {
  
  this.rowCount=getBy.totalOrderCount

  switch(this.searchType)
  {
    case 'AL':
      {
        this.isTableSelected=false
        this.tabShown=true;
        this.isWhTableSelected=true
        break;
      }


    case 'SM':
    {
      
      this.isTableSelected=true
      this.tabShown=true;
      this.isWhTableSelected=false
      this.columnDefinitions[0].hide = false; //order Id 
      this.columnDefinitions[1].hide=false//customername 
      this.columnDefinitions[2].hide = true;//phoneh
      this.columnDefinitions[3].hide = true; //submitdby 
      this.columnDefinitions[10].hide = false; //status
      this.columnDefinitions[11].hide = false;
      this.columnDefinitions[8].hide = false;
      this.columnDefinitions[9].hide = false;


      break;
    }
    case 'BR':
    {
      // this.isNameSelected = false;
    
      
      this.isTableSelected=true
      this.isWhTableSelected=false
      this.tabShown=true;
      this.columnDefinitions[0].hide = false; //order Id 
      this.columnDefinitions[1].hide=true//customername 
      this.columnDefinitions[2].hide = true;//phoneh
      this.columnDefinitions[3].hide = false; //submitdby 
      this.columnDefinitions[10].hide = false; //status
      this.columnDefinitions[8].hide = false;
      this.columnDefinitions[9].hide = false;
      break;
    }
    case 'ST':
      {
     
        this.isTableSelected=true
        this.isWhTableSelected=false  
        this.tabShown=true;
        this.columnDefinitions[0].hide = false; //order Id 
        this.columnDefinitions[1].hide=false//customername 
        this.columnDefinitions[2].hide = false;//phoneh
        this.columnDefinitions[3].hide = false; //submitdby 
        this.columnDefinitions[10].hide = true; //status
        this.columnDefinitions[10].hide = false;
        this.columnDefinitions[8].hide = false;
      this.columnDefinitions[9].hide = false;
       break;
        
      }
      case 'OI':
      {
        this.tabShown=true;
        this.isTableSelected=true
        this.isWhTableSelected=false
      this.columnDefinitions[0].hide = true; //order Id 
      this.columnDefinitions[1].hide=false//customername 
      this.columnDefinitions[2].hide = true;//phoneh
      this.columnDefinitions[3].hide = false; //submitdby 
      this.columnDefinitions[10].hide = false; //status
      this.columnDefinitions[8].hide = false;
      this.columnDefinitions[9].hide = false;
      break;

      }
      case 'PH':
      {
     
        this.tabShown=true;
        this.isTableSelected=true
        this.isWhTableSelected=false
      this.columnDefinitions[0].hide = false; //order Id 
      this.columnDefinitions[1].hide= true//customername 
      this.columnDefinitions[2].hide = true;//phoneh
      this.columnDefinitions[3].hide = false; //submitdby 
      this.columnDefinitions[10].hide = false; //status
      this.columnDefinitions[8].hide = true;
      this.columnDefinitions[9].hide = true;
      break;

      }
    }

  if (this.rowCount==0) {
    
    this.reportDataSource = new MatTableDataSource()  
    this.reportDataSource.data = null
    this.isData=true
  } else {
 
    this.isData=false
    if(this.searchType=='AL')
    {
      this.allData=getBy.data

      this.reportDataSource = new MatTableDataSource(this.allData)
      this.reportDataSource.paginator = this.paginator
    }
    else{
      this.allData=[];
      
      this.currency=getBy.order[0].currency,
      getBy.order.forEach(order => {
          this.allData.push({
                          
            orderId: order.orderId,
            retailerName:order.retailer.retailerName,
            phone:order.user.phone,
            userName:order.user.userName,
            orderAmount:parseFloat(order.orderAmount).toFixed(2),
            paidAmount:parseFloat(order.paidAmount).toFixed(2),
            balanceAmount:parseFloat(order.balanceAmount).toFixed(2),
            submitedDate: moment(order.submitedDate, 'YYYY/MM/DD').format('DD MMM YYYY'),
            actualDeliveredTime: moment(order.actualDeliveredTime, 'YYYY/MM/DD').format('DD MMM YYYY'),
            prefDeliveryTime: moment(order.prefDeliveryTime,'YYYY/MM/DD').format('DD MMM YYYY'),
           
           
            status:order.status,
          })
        });
      this.reportDataSource = new MatTableDataSource(this.allData)
      this.reportDataSource.paginator = this.paginator
      this.isData=false
      
    }
  
  }
  
}
);
}




onSortData(sort: Sort) {
  let data = this.allData.slice();
  if (sort.active && sort.direction !== '') {
      data = data.sort((a, b) => {
          const isAsc = sort.direction === 'asc';
          switch (sort.active) {
              case 'orderId': return this.compare(a.orderId, b.orderId, isAsc);
              case 'customerName': return this.compare(+a.customerName, +b.customerName, isAsc);
              case 'phone': return this.compare(+a.phone, +b.phone, isAsc);
              case 'submittedBy': return this.compare(+a.submittedBy, +b.submittedBy, isAsc);
              case 'totalAmount': return this.compare(a.totalAmount, b.totalAmount, isAsc);
              case 'paid': return this.compare(+a.paid, +b.paid, isAsc);
              case 'balance': return this.compare(+a.balance, +b.balance, isAsc);
              case 'submittedDate': return this.compare(+a.submittedDate, +b.submittedDate, isAsc);
              case 'preferredDeliveryTime': return this.compare(+a.preferredDeliveryTime, +b.preferredDeliveryTime, isAsc);
              case 'actualDeliveryTime': return this.compare(+a.actualDeliveryTime, +b.actualDeliveryTime, isAsc);
              case 'status': return this.compare(+a.status, +b.status, isAsc);
             
              case 'customerId': return this.compare(+a.customerId, +b.customerId, isAsc);
              case 'warecustomerName': return this.compare(+a.warecustomerName, +b.warecustomerName, isAsc);
              case 'noOfOrders': return this.compare(+a.noOfOrders, +b.noOfOrders, isAsc);
              case 'warephone': return this.compare(+a.warephone, +b.warephone, isAsc);
              case 'waretotalAmount': return this.compare(+a.waretotalAmount, +b.waretotalAmount, isAsc);
              default: return 0;
          }
      });
  }
  this.reportDataSource = new MatTableDataSource(data)
}


private compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}


downloadInvoice(orderID){

  this.pdfURL = environment.serviceURL + "storimartseller/orders/invoice/" + orderID
  window.open(this.pdfURL)
}

createExcel(retailerId,shopId)
{
  this.reportService.createExcel(this.startDate,this.endDate,shopId,retailerId)
  .subscribe((excelData)=>
  {
    if(excelData.excelUrl && excelData.processId)
    {
    this.excelDataUrl=excelData.excelUrl;
    this.excelDataProcessId=excelData.processId;
    if(this.excelDataUrl && this.excelDataUrl)
    {
      const dialogConfig = new MatDialogConfig();

            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;

            dialogConfig.width = "40%";
            dialogConfig.height = '40%';

            dialogConfig.data = { 
              excelDataUrl :this.excelDataUrl,
              excelDataProcessId:this.excelDataProcessId,
                               }

            const dialogRef = this.dialog.open(EmailDialogComponent, dialogConfig);

          }

    }
  });
}


processExcel()
{
 this.isGeneratedClick=true
    let eventSource = new EventSource(environment.serviceURL + "storimartreport" + "/stream-sse-mvc/" + this.excelDataProcessId + "?token=" + localStorage.getItem("AuthorizationToken"))
     eventSource.onerror = (evt) => {
      eventSource.close()
    }
    eventSource.addEventListener("In Progress", (evt: any) => {
      this.progressBarAnimate(evt.data)
      eventSource.close()
    })
    eventSource.addEventListener("Completed", (evt: any) => {
      this.progressBarAnimate(evt.data)
        this.isGenerated=true
        this.isGeneratedClick=false
        eventSource.close()
      
    })
    
}


getInvoiceView(retailerId,shopId)

{

  if (retailerId && shopId && this.startDate && this.endDate)
  {
    const dialogConfig = new MatDialogConfig();

            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;

            dialogConfig.width = "60%";
            dialogConfig.height = '60%';

            dialogConfig.data = { 
              endDate :this.endDate,
              startDate:this.startDate,
              retailerId:retailerId,
              shopId:shopId
             }

            const dialogRef = this.dialog.open(InvoiceDialogComponent, dialogConfig);
  }
 
  /* this.reportService.getInvoice(this.startDate,this.endDate,shopId,retailerId)// to get invoice view
    .subscribe((invoiceData: InvoiceViewResponse)=>
    {
      // this.invoiceDataSet=invoiceData
      this.inCustomername=invoiceData.customerName
      this.inCountry=invoiceData.Address.country
      this.inCity=invoiceData.Address.city
      this.inStreet=invoiceData.Address.street
      this.inAddressLine1=invoiceData.Address.addressLine1
      this.inZipPin=invoiceData.Address.zipPin
      this.inState=invoiceData.Address.state
      this.inPhone=invoiceData.customerPhone
      this.inOrderCount=invoiceData.orderCount
      
this.inTotalAmount=invoiceData.totalAmount
this.inPaidAmount=invoiceData.paidAmount
this.inToPayAmount=this.inTotalAmount-this.inPaidAmount
this.inCurrency=invoiceData.currency
    }
    ); */
}


progressBarAnimate(limit)
{
  var i = 0;
     
  if (i == 0) {
    i = 1;
    var elem = document.getElementById("myBar");
    var width = 1;
    var id = setInterval(frame, 10);
    function frame() {
      if (width >= limit) {
        clearInterval(id);
        i = 0;
   
      
      } else {
        width++;
        elem.style.width = width + "%";
        
      }
    }
    
  }  
}

closeExcel()
{
  this.isGeneratedClick=false 
  this.isGenerated=false
}

  printInvoice()// invoice printing
  {

// window.print()
    // var divToPrint = document.getElementById('invoice');
    var windowContent = '<!DOCTYPE html>';
    //Starting HTML Tags
    windowContent += '<html>'
    
    //Setting Print Page Title
    windowContent += '<head><title>Invoice</title>';
	
//Adding Style
   windowContent += '<style>.invoice{ position: relative;background-color: #FFF; padding: 15px}.invoice header {padding: 10px 0;margin-bottom: 20px;border-bottom: 1px solid #3989c6}.invoice .company-details {text-align: right }.invoice .company-details .name {margin-top: 0;margin-bottom: 0}.invoice .contacts {margin-bottom: 20px}.invoice .invoice-to {text-align: left}.invoice .invoice-to .to {margin-top: 0;margin-bottom: 0}.invoice .invoice-details {text-align: right}.invoice .invoice-details .invoice-id {margin-top: 0;color: #3989c6}.invoice main .notices {padding-left: 6px;border-left: 6px solid #3989c6}.invoice main .notices .notice {font-size: 1.2em}.invoice table { width: 100%;border-collapse: collapse; border-spacing: 0; margin-bottom: 20px}.invoice table td,.invoice table th {padding: 15px;background: #eee;border-bottom: 1px solid #fff}.invoice table th {white-space: nowrap;font-weight: 400;font-size: 16px}.invoice table td h3 {margin: 0;font-weight: 400;color: #3989c6;font-size: 1.2em}.invoice table .qty,.invoice table .total,.invoice table .unit {text-align: right;font-size: 1.2em}.invoice table .no {color: #fff;font-size: 1.6em;background: #3989c6}.invoice table .unit { background: #ddd}.invoice table .total {background: #3989c6;color: #fff}.invoice table tbody tr:last-child td {border: none}</style>';

	
	
    windowContent += '</head>';
    //Starting Body Tag
    windowContent += '<body>'
    
    //Getting Div HTML
    windowContent +=  document.getElementById("invoice").innerHTML;
    
    //Closing Body Tag and HTML Tag
    windowContent += '</body>';
    windowContent += '</html>';
    
    //Calling Print Window
    var printWin = window.open('','','fullscreen=yes');
    
    //Opening Print Window
    printWin.document.open();
    
    //Adding Content in Print Window
    printWin.document.write(windowContent);
    
    //Closing Print Window
    printWin.document.close();
    
    //Focusing User to Print Window
    printWin.focus();
    
    //Calling Default Browser Printer
    printWin.print();
    
    //Closing Print Window
    printWin.close();
  }  

}