<tour-step-template>
    <ng-template let-step="step">
        <div class="row">
            <div>
                <button mat-icon-button [disabled]="!whTourService.hasPrev(step)"
                    [ngClass]="{'inactive-btn' : !whTourService.hasPrev(step)}" class="ml-2"
                    (click)="whTourService.prev()">
                    <mat-icon>arrow_back</mat-icon>
                </button>
            </div>
            <div class="ngxp-end">
                <button mat-flat-button (click)="whTourService.end()">{{step?.endBtnTitle}}</button>
            </div>
        </div>
        <div class="ngxp-title" [ngClass]="{'ngxp-first-step' : !whTourService.hasPrev(step)}">
            <div class="ngxp-title-text">
                {{step?.title}}
            </div>

        </div>
        <img src="../../assets/icon/guided_tour.png" width="60px">
        <div class="ngxp-content mb-3">{{step?.content}}</div>
        <div class="tour-step-navigation" style="display: flex;
        justify-content: center;
        align-items: center;">

            <button mat-raised-button color="primary" [disabled]="!whTourService.hasNext(step)"
                [ngClass]="{'inactive-btn' : !whTourService.hasNext(step)}" class="nextBtn"
                (click)="whTourService.next()">{{step?.nextBtnTitle}} »</button>
        </div>
    </ng-template>
</tour-step-template>

<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12 white-container">
           <!--  <mat-toolbar>
                <h3> Webhook Settings</h3>
            </mat-toolbar> -->
            <div class="card">
                
                
                <div class="card-body pt-4">                   
                    <div class="example-small-box mat-elevation-z4">

                        
                        <form [formGroup]="webhookSettingsForm">
                            <div class="form-horizontal pl-2 card-body ">
    
                                <div style="float:right;" class="col-md-1 text-center">
                                    <mat-icon matTooltip="Click for a guided Tour" class="pointer" (click)="webhookTour()">
                                        play_circle_outline</mat-icon>
                                    <h6 style="margin-top: 0mm;">Guided Tour</h6>
                              
                                </div>
                               
                               
                                    <div class="form-group col-md-6" tourAnchor="cptour.whstep1.id">
                                        <!-- <mat-checkbox [formControl]="webhookSettingsForm.controls['placeOrder']"
                                            [checked]="true" (change)="placeOrderReadonly($event)" [required]="true">Enable
                                            Place Order Webhook
                                        </mat-checkbox> -->
                                        <mat-slide-toggle  [formControl]="webhookSettingsForm.controls['placeOrder']" [checked]="true"
                                        (change)="placeOrderReadonly($event)" [required]="true">Enable
                                        Place Order Webhook</mat-slide-toggle>                    
                                    </div>
                                    <div class="form-group col-md-6" >
                                        <mat-form-field class="full-width" appearance="outline">
                                            <mat-label>Enter Place-Order Url</mat-label>
                                            <input matInput [readonly]="readOnlyPlaceOrder"
                                            [formControl]="webhookSettingsForm.controls['placeOrderUrl']"
                                             >
                                        </mat-form-field>
    
    
                                    </div>
                                
                                    <div class="form-group col-md-6" tourAnchor="cptour.whstep2.id">
                                        <!-- <mat-checkbox [formControl]="webhookSettingsForm.controls['cancelOrder']"
                                        [checked]="true" (change)="cancelOrderReadonly($event)" [required]="true">Enable Cancel Order Webhook</mat-checkbox> -->
                                        <mat-slide-toggle  [formControl]="webhookSettingsForm.controls['cancelOrder']" [checked]="true"
                                        (change)="cancelOrderReadonly($event)" [required]="true">Enable Cancel Order Webhook</mat-slide-toggle>
                                    </div>
    
                                    <div  class="form-group col-md-6" >
                                        <mat-form-field class="full-width" appearance="outline">
                                            <mat-label>Enter Cancel Order Url</mat-label>
                                            <input matInput [readonly]="readOnlyCancelOrder" [formControl]="webhookSettingsForm.controls['cancelOrderUrl']"
                                                  >
                                        </mat-form-field>
                                    </div>
                               
                                    <div class="form-group col-md-6" tourAnchor="cptour.whstep3.id">
                                        <!-- <mat-checkbox [formControl]="webhookSettingsForm.controls['updateOrder']"
                                        [checked]="true" (change)="updateOrderReadonly($event)" [required]="true">Enable Update Order Webhook</mat-checkbox> -->
                                        <mat-slide-toggle  [formControl]="webhookSettingsForm.controls['updateOrder']" [checked]="true"
                                        (change)="updateOrderReadonly($event)" [required]="true">Enable Update Order Webhook</mat-slide-toggle>
                                    </div>
    
                                    <div  class="form-group col-md-6" >
                                        <mat-form-field class="full-width" appearance="outline">
                                            <mat-label>Enter Update Order Url</mat-label>
                                            <input matInput  [readonly]="readOnlyUpdateOrder" [formControl]="webhookSettingsForm.controls['updateOrderUrl']"
                                              >
                                        </mat-form-field>
                                    </div>
                                    <div class="form-group col-md-6" tourAnchor="cptour.whstep4.id">
                                        <!-- <mat-checkbox [formControl]="webhookSettingsForm.controls['deliveryAddress']"
                                        [checked]="true" (change)="deliveryAddressReadonly($event)" [required]="true">Enable Delivery Adrress Webhook</mat-checkbox> -->
                                        <mat-slide-toggle  [formControl]="webhookSettingsForm.controls['deliveryAddress']" [checked]="true"
                                        (change)="deliveryAddressReadonly($event)" [required]="true">Enable Delivery Adrress Webhook</mat-slide-toggle>
                                    </div>
    
                                    <div  class="form-group col-md-6">
                                        <mat-form-field class="full-width" appearance="outline">
                                            <mat-label>Enter Delivery Address Webhook Url</mat-label>
                                            <input matInput  [readonly]="readOnlyDeliveryAddress" [formControl]="webhookSettingsForm.controls['deliveryAddressUrl']"
                                              >
                                        </mat-form-field>
                                    </div>
                               
                                    <div class="form-group col-md-6" tourAnchor="cptour.whstep5.id">
                                        <!-- <mat-checkbox [formControl]="webhookSettingsForm.controls['addBuyer']"
                                        [checked]="true" (change)="buyerReadonly($event)" [required]="true">Enable Add Buyer Webhook</mat-checkbox> -->
                                        <mat-slide-toggle  [formControl]="webhookSettingsForm.controls['addBuyer']" [checked]="true"
                                        (change)="buyerReadonly($event)" [required]="true">Enable Add Buyer Webhook</mat-slide-toggle>
                                    </div>
    
                                    <div  class="form-group col-md-6" >
                                        <mat-form-field class="full-width" appearance="outline">
                                            <mat-label>Enter Add Buyer Webhook Url</mat-label>
                                            <input matInput [readonly]="readOnlyBuyer" [formControl]="webhookSettingsForm.controls['addBuyerUrl']"
                                                  >
                                        </mat-form-field>
                                    </div>
                               
                                    <div class="form-group col-md-6" tourAnchor="cptour.whstep6.id">
                                        <!-- <mat-checkbox [formControl]="webhookSettingsForm.controls['addProduct']"
                                        [checked]="true" (change)="productReadonly($event)" [required]="true">Enable Add Product Webhook</mat-checkbox> -->
                                        <mat-slide-toggle  [formControl]="webhookSettingsForm.controls['addProduct']" [checked]="true"
                                        (change)="productReadonly($event)" [required]="true">Enable Add Product Webhook</mat-slide-toggle>
                                    </div>
    
                                    <div  class="form-group col-md-6" tourAnchor="cptour.whstep7.id">
                                        <mat-form-field class="full-width" appearance="outline">
                                            <mat-label>Enter Add product Webhook Url</mat-label>
                                            <input matInput [readonly]="readOnlyProduct" [formControl]="webhookSettingsForm.controls['addProductUrl']"
                                              >
                                        </mat-form-field>
                                    </div>                        
                                
                                <div class="mt-2  pt-2 align-items-center" tourAnchor="cptour.wstep8.id">
                                    <button type="submit" mat-button mat-raised-button class="green mb-2"
                                        (click)="submitWebhookSettings()"> Save
                                    </button>
                                </div>
                            </div>
                        </form>
                          </div>
                </div>
            </div>
        </div>
    </div>
</div>