import { authService } from './../Service/authService';
import { ReportService } from './../Service/report.service';
import { notificationResponse } from './../Models/Response/notificationResponse';
import { ServiceService } from './../Service/service.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { alertSummeryResponse } from '../Models/Response/alertSummeryResponse';
import { alertDetailsResponse } from '../Models/Response/alertDetailsResponse';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { BnNgIdleService } from 'bn-ng-idle';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../src/environments/environment';
import {ToastrService} from 'ngx-toastr';


@Component({
  selector: 'app-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.scss']
})
export class MenusComponent implements OnInit {


  catalogURL = environment.catalogURL
  ofsURL = environment.ofsURL
  MenuFlag = false
  alertFlag = false
  NotFlag = false
  hideMatBadge: boolean;
  hideMatBadgen: boolean;
  dbactive = false
  rptactive = false
  showModel = false
  notsData: any
  notsMessageDate
  notsMessageDatemill
  notAcceeptedCount: string;
  notDeliveredCount: string;
  alertAccountId: number;
  countNots: number;
  notAccepted: string;
  notDelivered: string;
  // areaFlag=true
  displayedColumns: string[] = ['orderId', 'customerBusinuss', 'phone', 'customerUser', 'submittedTime', 'expectedDeliveryTime', 'lastUpdateTime', 'totalCost', 'paymentMethod', 'status'];
  alertDetailsData
  alertData


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  alertCount: string;
  alertMessage: string;
  businessName: string;
  returnUrl: any;
  logoutFlaf: boolean;

  applyFilter(filterValue: string) {
    // const filterValue = (event.target as HTMLInputElement).value;
    this.alertDetailsData.filter = filterValue.trim().toLowerCase();
  }
  constructor(
    private toastr:ToastrService,
    private service: ServiceService,
    private reportService: ReportService,
    private bnIdle: BnNgIdleService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: authService) {
    this.businessName = localStorage.getItem("CompanyName")
    this.bnIdle.startWatching(900).subscribe((res) => {
      if (res) {
        alert("session expired. Please login");
        this.authService.logoutHandler()
        this.bnIdle.stopTimer();
      }
    })


  }

  ngOnInit() {

    this.service.getNotification()
      .subscribe((nots: notificationResponse) => {
        if (nots.acknowledgement.status === "SUCCESS") {
          this.notsData = nots.data
          if (this.notsData != null) {
            this.countNots = this.notsData.length;
          }
          else {
            this.hideMatBadgen = true;
          }
        }

      });

    this.reportService.getAlertSummery()
      .subscribe((alerts: alertSummeryResponse) => {
        this.notAcceeptedCount = alerts.notAccepted
        this.notDeliveredCount = alerts.notDelivered
        this.alertAccountId = alerts.accountId
        this.notAccepted = "notAccepted"
        this.notDelivered = "notDelivered"
        if (this.notAcceeptedCount == '0') {
          this.hideMatBadge = true;
        }
      });
  }

  reLoad(): void{
    this.router.navigateByUrl('/dashboard');
    this.toastr.success("Page Refreshed")
  }
  

  toggleclass() {
    this.MenuFlag = !this.MenuFlag;
  }


  toggleAlert() {

    this.alertFlag = !this.alertFlag;
    if (this.alertFlag == true) {
      this.NotFlag = false
      this.logoutFlaf = false
    }
  }

  togglenotification() {

    this.service.getNotification()
      .subscribe((nots: notificationResponse) => {
        this.notsData = nots.data

        // this.notsMessageDatemill=this.notsData.map(x=> x.data3)
      }

      );

    this.NotFlag = !this.NotFlag;
    if (this.NotFlag == true) {
      this.alertFlag = false
      this.logoutFlaf = false
    }

  }

  toggleLogout() {

    this.logoutFlaf = !this.logoutFlaf;
    if (this.logoutFlaf == true) {
      this.alertFlag = false
      this.NotFlag = false
    }

  }

  logout() {
    this.authService.logoutHandler()
  }

  toggleShowModel(accountId, alertType) {

    this.alertDetailsData = new MatTableDataSource();
    this.alertDetailsData = null

    switch (alertType) {
      case 'notAccepted':
        {
          this.alertCount = this.notAcceeptedCount
          this.alertMessage = "Orders not accepted after 15 minutes of expected delivery time."
          break;
        }
      case 'notDelivered':
        {
          this.alertCount = this.notDeliveredCount
          this.alertMessage = "Orders not delivered after 2 hour of expected delivery time."
          break;
        }
    }

    this.reportService.getAlertDetails(accountId, alertType)
      .subscribe((alertsDetails: alertDetailsResponse) => {
        this.alertData = alertsDetails.order

        this.alertDetailsData = new MatTableDataSource(this.alertData);
        this.alertDetailsData.paginator = this.paginator;

      }
      );
    this.showModel = !this.showModel;
  }

}



