<div class="example-container">
    <div class="constraints">

        
        <h2>Add Buyer</h2>
        
        <div [formGroup]="buyerForm" (submit)="submitBuyerForm()">
            
            
            <div class="row">
                <div class="col">
                    
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Business Name</mat-label>
                        <input formControlName="name" matInput placeholder="Business Name" required>
                        <mat-error *ngIf="buyerForm.controls['name']?.errors?.required && buyerForm.controls['name'].touched">
                            This field is required</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                
                <div class="row">
                        <div class="col code">
                            <mat-form-field appearance="outline">
                                <mat-select placeholder="Code" (selectionChange)="onCountrySelect($event.value)">
                                    <mat-option *ngFor="let item of countryList" [value]="item">
                                        {{item.countryCode}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>Phone</mat-label>
                                <input formControlName="phone" matInput placeholder="Mobile" required>
                                <mat-error *ngIf="buyerForm.controls['phone']?.errors?.required && buyerForm.controls['phone'].touched">
                                    This field is required
                                </mat-error>
                                <mat-error *ngIf="buyerForm.controls['phone']?.errors?.pattern">
                                    Please enter a valid Phone number
                                </mat-error>

                            </mat-form-field>
                        </div>

                            <div class="col">
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>Email</mat-label>
                                    <input matInput placeholder="Enter Email" formControlName="email">
                                    
                                    <mat-error *ngIf="buyerForm.controls['email']?.errors?.pattern">
                                        Please enter a valid Email address  </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    
                                    <mat-form-field appearance="outline" class="full-width">
                                        <mat-label>Address</mat-label>
                                        <input formControlName="addressLine1" matInput placeholder="Address" required>
                                        <mat-error *ngIf="buyerForm.controls['addressLine1']?.errors?.required && buyerForm.controls['addressLine1'].touched">
                                            This field is required</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <mat-form-field appearance="outline" class="full-width">
                                            <mat-label>City</mat-label>
                                            <input formControlName="city" matInput placeholder="City" required>
                                        </mat-form-field>
                                    </div>
                                    
                                    <div class="col">
                                        
                                        <mat-form-field appearance="outline" class="full-width">
                                            <mat-label>State</mat-label>
                                            <input formControlName="state" matInput placeholder="State">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <mat-form-field appearance="outline" class="full-width">
                                            <mat-label>Country</mat-label>
                                            <mat-select formControlName="countryId" placeholder="Country" required>
                                                <mat-option *ngFor="let item of countryList" [value]="item.countryCode" required>
                                                    {{item.countryName}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="buyerForm.controls['countryId']?.errors?.required && buyerForm.controls['countryId'].touched">
                                                This field is required</mat-error>
                                            </mat-form-field>             
                                        </div>
                                        <div class="col">
                                            <mat-form-field appearance="outline" class="full-width">
                                                <mat-label>Zipcode</mat-label>
                                                <input formControlName="pin" matInput placeholder="Zip/Pin">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <mat-form-field appearance="outline" class="full-width">
                                                <mat-label>Latitude</mat-label>
                                                <input formControlName="latitude" matInput placeholder="Latitude">
                                            </mat-form-field>               
                                        </div>
                                        <div class="col">
                                            <mat-form-field appearance="outline" class="full-width">
                                                <mat-label>Longitude</mat-label>
                                                <input formControlName="longitude" matInput placeholder="Longitude">
                                                
                                            </mat-form-field>
                                            <mat-slide-toggle class="example-margin" formControlName="buyerAsAppUser">Buyer is an App User</mat-slide-toggle>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <mat-form-field appearance="outline" class="full-width">
                                                <mat-label>Tax Number</mat-label>
                                                <input formControlName="taxNumber" matInput placeholder="Tax Number" >
                                            </mat-form-field>
                                        </div>
                                        <div class="col">
                                            <mat-form-field appearance="outline" class="full-width">
                                                <mat-label>TimeZone</mat-label>
                                                <mat-select formControlName="timezoneId" required>
                                                    <mat-option *ngFor="let item of timeZoneList" [value]="item.timeZoneId">
                                                        {{item.zoneName}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="buyerForm.controls['timeZoneId']?.errors?.required && buyerForm.controls['timeZoneId'].touched">
                                                    This field is required</mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        
                                        <div class="row">
                                            <div class="col">
                                                <mat-form-field appearance="outline" class="full-width">
                                                    <mat-label>Buyer Group</mat-label>
                                                    <mat-select formControlName="groupId" required>
                                                        <mat-option *ngFor="let item of buyerGroups" [value]="item.id" required>
                                                            {{item.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error>
                                                        This is a required field
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        
                                        
                                        
                                        
                                    </div>
                                    
                                    <div align="start">
                                        <button class="mat-raised-button"(click)="close()">Close</button>
                                        &nbsp;&nbsp;&nbsp;
                                        <button class="mat-raised-button mat-primary"(click)="submitBuyerForm()">Add buyer</button>
                                    </div>
                                </div>
                            </div>
                                