import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { authService } from '../Service/authService';
@Injectable({
    providedIn: 'root'
  })
  export class AuthGuard implements CanActivate {
  
    constructor(private authService: authService, private router: Router) { }
  
    canActivate() {
      if (!this.authService.isLoggedIn()) {
        this.router.navigate(['/login']);
        return false;
      }
      return true;
    }
  }