

<div class="example-container" >
    <h2 mat-dialog-title>Add an App User</h2>

    <mat-dialog-content [formGroup]="appUserForm" (submit)="save()">
    <div class="row">
        <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
          <input formControlName="name" matInput placeholder="Name" required>
          <mat-error *ngIf="appUserForm.controls['name']?.errors?.required && appUserForm.controls['name'].touched">
            This field is required</mat-error>
        </mat-form-field>
    </div>
     <div class="row">  
         
        <mat-form-field appearance="outline">
            <mat-label>Country</mat-label>
        <mat-select formControlName="countryId" placeholder="Country Code">
            <mat-option *ngFor="let item of countryList" [value]="item.countryCode" required>
                {{item.countryCode}}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="appUserForm.controls['countryId']?.errors?.required && appUserForm.controls['countryId'].touched">
            This field is required</mat-error>
      </mat-form-field> 
    </div>
    <div class="row">   
        <mat-form-field appearance="outline">
            <mat-label>Phone</mat-label>
          <input formControlName="phone" matInput placeholder="Mobile" required>
          <mat-error *ngIf="appUserForm.controls['phone']?.errors?.required && appUserForm.controls['phone'].touched">
            This field is required</mat-error>
            <mat-error *ngIf="appUserForm.controls['phone']?.errors?.pattern">
                Please enter a valid Phone number  </mat-error>
        </mat-form-field>
    </div> 

<div class="row">
        <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Enter Email" formControlName="email">
            
            <mat-error *ngIf="appUserForm.controls['email']?.errors?.required && appUserForm.controls['email'].touched">
              This field is required</mat-error>
              <mat-error *ngIf="appUserForm.controls['email']?.errors?.pattern">
                  Please enter a valid Email address  </mat-error>
        </mat-form-field>
    </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button class="mat-raised-button"(click)="close()">Close</button>
        &nbsp;&nbsp;&nbsp;
        <button class="mat-raised-button mat-primary"(click)="save()">Add App User</button>
    </mat-dialog-actions>

</div>
