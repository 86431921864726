import { MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { AppInterceptors } from './http-interceptors/httpInterceptors';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReportComponent } from './report/report.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MenusComponent } from './menus/menus.component';
import { DatePipe, CommonModule } from '@angular/common';
import { DbComponent } from './db/db.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { Summarypipe, Currencypipe,ThousandSuffixesPipe } from './shared/pipes/livesummarypipe';
import {MatTableModule} from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DateAgoPipe } from './shared/pipes/date-ago.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import {MatCheckboxModule} from '@angular/material/checkbox';
import{numbersOnly} from './Directives/numbersOnly';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatStepperModule } from '@angular/material/stepper';
import {MatTreeModule} from '@angular/material/tree';
import { LoginComponent } from './login/login.component';
import { NewuserComponent } from './newuser/newuser.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { SetpasswordComponent } from './setpassword/setpassword.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import {MatSelectModule} from '@angular/material/select';
import { from } from 'rxjs';
import { ControlPanelComponent } from './control-panel/control-panel.component';
import { BillingInfoComponent } from './control-panel/billing-info/billing-info.component';
import { TourNgxPopperModule } from 'ngx-tour-ngx-popper';
import { NgxPopperModule } from 'ngx-popper';
import { SubscriptionDialogComponent } from './subscription/subscription-dialog/subscription-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ToastrModule } from 'ngx-toastr';
import {MatBadgeModule} from '@angular/material/badge';
import { BuyersComponent } from './buyers/buyers.component';
import { BuyerFormComponent } from './buyers/buyer-form/buyer-form.component';
import {MatGridListModule} from '@angular/material/grid-list';
import { ExcelMappingComponent } from './buyers/excel-mapping/excel-mapping.component';
import { BuyerSettingsComponent } from './buyers/buyer-settings/buyer-settings.component';
import { AddappuserComponent } from './buyers/addappuser/addappuser.component';
import { AppUsersComponent } from './buyers/app-users/app-users.component';
import { AppUserSettingsComponent } from './buyers/app-user-settings/app-user-settings.component';
import { BuyergroupFormComponent } from './buyers/buyergroup-form/buyergroup-form.component';
import {MatRadioModule} from '@angular/material/radio';
import { BuyergroupsComponent } from './buyers/buyergroups/buyergroups.component';
import { BuyergroupsettingsComponent } from './buyers/buyergroupsettings/buyergroupsettings.component';
import { MyNavbarComponent } from './my-navbar/my-navbar.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatListModule } from '@angular/material/list';
import { DashComponent } from './dash/dash.component';
import { AlertDialogComponent } from './my-navbar/alert-dialog/alert-dialog.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { SettingsComponent } from './settings/settings.component';
import { WarehouseComponent } from './settings/warehouse/warehouse.component';
import { GeneralComponent } from './settings/general/general.component';
import { NotificationsComponent } from './settings/notifications/notifications.component';
import { ProfileComponent } from './settings/profile/profile.component';
import { ImagesComponent } from './settings/images/images.component';
import { FootersettingsComponent } from './settings/footersettings/footersettings.component';
import { OfsComponent } from './settings/ofs/ofs.component';
import { SSOComponent } from './sso/sso.component';
import { EmailDialogComponent } from './report/email-dialog/email-dialog.component';
import { InvoiceDialogComponent } from './report/invoice-dialog/invoice-dialog.component';
import { ERPsettingsComponent } from './settings/erpsettings/erpsettings.component';
import { WebhooksettingsComponent } from './settings/webhooksettings/webhooksettings.component';
import {AvatarModule } from 'ngx-avatar';
import { ConfirmDialogComponent } from './my-navbar/confirm-dialog/confirm-dialog/confirm-dialog.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ConfirmdeleteComponent } from './settings/images/confirmdelete/confirmdelete.component';
import {BuyerFormPageComponent} from './buyers/buyer-form-page/buyer-form-page.component';
import { SalesMenComponent } from './sales-men/sales-men.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { LoadingBarComponent } from './shared/loading-bar/loading-bar.component';
import { StockComponent } from './stock/stock.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {NgxPaginationModule} from 'ngx-pagination';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { ShowReportComponent } from './stock/show-report/show-report.component';
import { CatalogManagerComponent } from './catalog-manager/catalog-manager.component';
import { ProductUploadComponent } from './product-upload/product-upload.component';


@NgModule({
  declarations: [
    AppComponent,
    // SidenavComponent,
    ReportComponent,
    MenusComponent,
    DbComponent,
    Summarypipe,
    Currencypipe,
    ThousandSuffixesPipe,
    DateAgoPipe,
    numbersOnly,
    LoginComponent,
    NewuserComponent,
    ResetpasswordComponent,
    SetpasswordComponent,
    SubscriptionComponent,
    PagenotfoundComponent,
    ControlPanelComponent,
    BillingInfoComponent,
    SubscriptionDialogComponent,
    BuyersComponent,
    BuyerFormComponent,
    ExcelMappingComponent,
    BuyerSettingsComponent,
    AddappuserComponent,
    AppUsersComponent,
    AppUserSettingsComponent,
    BuyergroupFormComponent,
    BuyergroupsComponent,
    BuyergroupsettingsComponent,
    MyNavbarComponent,
    DashComponent,
    AlertDialogComponent,
    SettingsComponent,
    WarehouseComponent,
    GeneralComponent,
    NotificationsComponent,
    ProfileComponent,
    ImagesComponent,
    FootersettingsComponent,
    OfsComponent,
    SSOComponent,
    EmailDialogComponent,
    InvoiceDialogComponent,
    ERPsettingsComponent,
    WebhooksettingsComponent,
    ConfirmDialogComponent,
    ConfirmdeleteComponent,
    BuyerFormPageComponent,
    SalesMenComponent,
    LoadingBarComponent,
    StockComponent,
    ShowReportComponent,
    CatalogManagerComponent,
    ProductUploadComponent
    
    
     
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    MatTableModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    FontAwesomeModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    CommonModule, 
    MatToolbarModule,
    MatButtonModule, 
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatDatepickerModule,
    NgxCaptchaModule,
    ToastrModule.forRoot({positionClass:'toast-bottom-right', closeButton:true ,preventDuplicates:true}),
    NgxPopperModule,
    TourNgxPopperModule.forRoot(),
    MatSelectModule,
    MatTooltipModule,
    MatBadgeModule,
    MatGridListModule,
    MatRadioModule,
    LayoutModule,
    MatListModule,
    MatExpansionModule,
    AvatarModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatTableExporterModule,
    ShowHidePasswordModule,
    MatTreeModule,
    ScrollingModule,
    NgxPaginationModule,
    MatButtonToggleModule
  ],
  entryComponents: [
    BuyerFormComponent,
    ExcelMappingComponent,
    BuyerSettingsComponent,
    AddappuserComponent,
    AppUsersComponent,
    AppUserSettingsComponent,
    BuyergroupFormComponent,
    BuyergroupsComponent,
    BuyergroupsettingsComponent,
    ShowReportComponent,
    EmailDialogComponent,
    InvoiceDialogComponent,
    AlertDialogComponent,
    ConfirmDialogComponent,
    ConfirmdeleteComponent,
  ],

  exports: [
    LoadingBarComponent
    // CommonModule,
    //  MatToolbarModule, 
    //  MatButtonModule, 
    //  MatCardModule, 
    //  MatInputModule, 
    //  MatDialogModule, 
    //  MatTableModule, 
    //  MatMenuModule,
    //  MatIconModule,
    //  MatProgressSpinnerModule,
    // RouterModule
     ],
  providers: [
    {provide:HTTP_INTERCEPTORS,useClass:AppInterceptors,multi:true},
    DatePipe,
    Summarypipe,
    Currencypipe,
    BnNgIdleService,
   
    ThousandSuffixesPipe,
    {provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true, displayDefaultIndicatorType: false }},
      
],
  bootstrap: [AppComponent]
   
})
export class AppModule { }
