
<body class="authentication-bg pb-0" >

    <div class="auth-fluid">
        <!--Auth fluid left content -->
        <div class="auth-fluid-right"  style="background-color:whitesmoke; padding: 0 !important;">
          <span ><img src="../assets/icon/graphics2.png"></span> 
            
        </div>
        <div class="auth-fluid-form-box">
            <div class="align-items-center d-flex h-100">
                <div class="card-body">

                    <!-- Logo -->
                    <div class="auth-brand text-center text-lg-left">
                        <a href="" class="logo-dark">
                            <span><img src="../assets/icon/sm-logo_new.png" alt="" height="70"></span>
                        </a>
                       
                    </div>

                    <!-- title-->
                     <h4 class="mt-0 mb-3">Set Password</h4>
                    <!-- <p class="text-muted mb-4">Enter your email address and password to access account.</p> --> 
                   

                    <!-- form -->
                    
                          <form [formGroup]="setPasswordForm" (ngSubmit)="onSubmit()">
                            <!-- <h2>Log In</h2> -->
                            

                            <div class="error-messages">
                                <ng-container *ngFor="let error of error_messages.password">
                                    <div class="error-message" *ngIf="setPasswordForm.get('password').hasError(error.type) && (setPasswordForm.get('password').dirty || setPasswordForm.get('password').touched)">
                                        {{ error.message }}
                                    </div>
                                </ng-container>
                            </div>
                            <mat-form-field appearance="outline">
                                <mat-label>Password</mat-label>
                                <input matInput [type]="hide ? 'password' : 'text'"
                                placeholder="Enter Password"
                                formControlName="password" >
                                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <!-- <mat-error>
                                    Please provide a valid password
                                </mat-error> -->
                              </mat-form-field>
                              
                            <div class="error-messages ">
                                <ng-container *ngFor="let error of error_messages.confirmpassword">
                                    <div class="error-message" *ngIf="setPasswordForm.get('confirmpassword').hasError(error.type) && (setPasswordForm.get('confirmpassword').dirty || setPasswordForm.get('confirmpassword').touched)">
                                        {{ error.message }}
                                    </div>
                                </ng-container>
                            </div>
                              <mat-form-field appearance="outline">
                                <mat-label>Re-Enter Password</mat-label>
                                <input matInput [type]="rehide ? 'password' : 'text'"
                                placeholder="Re-Enter Password"
                                formControlName="confirmpassword" >
                                <button mat-icon-button matSuffix (click)="rehide = !rehide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                <mat-icon>{{rehide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <!-- <mat-error>
                                    Please provide a valid password
                                </mat-error> -->
                              </mat-form-field>
                              <mat-error *ngIf="setPasswordForm.hasError('notSame')">
                                Passwords do not match
                            </mat-error>  
                              <button mat-raised-button [class.spinner]="loading"  [disabled]="!setPasswordForm.valid" color="primary" class="mt-2"  >Submit</button>
                          </form>
                    
                          <div  *ngIf="isSubmittedSuccess">
                            
                    <p class="text-muted mt-2"> <a [routerLink]="['/login']" class="text-muted ml-1"><b>Login</b></a></p>

                              <!-- <a href="" routerlink="/login">Login</a> -->
                            
                          </div>
                   
                </div> <!-- end .card-body -->
            </div> <!-- end .align-items-center.d-flex.h-100-->
        </div>
        <!-- end auth-fluid-form-box-->

        <!-- Auth fluid right content -->
       
        <!-- end Auth fluid right content -->
    </div>
    <!-- end auth-fluid-->

    <!-- bundle -->
  
</body>
