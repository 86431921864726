import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ControlPanelService } from '../../control-panel/control-panel.service';
import { WarehouseSettings } from '../../control-panel/models/WarehouseSettings.model'
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HostListener } from "@angular/core";
import { TourService } from 'ngx-tour-ngx-popper';
import { HttpErrorResponse } from '@angular/common/http';
import {SettingsComponent} from '../settings.component'

@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.scss']
})
export class WarehouseComponent implements OnInit {

  public warehouseForm: FormGroup;
  tempPrevTourStepCount = 0;
  tempPrevTourStep;
  successMessage: any;
  warehouseSettingsDetails: any;
  errorMessage: any;
  newUser: boolean;
  id = 0;
  timeZoneList: any;
  countryList:any;
  countrySelected=false;
  showContinueButton=false;

  constructor(private fb: FormBuilder,
    private controlPanelService: ControlPanelService,
    public activateRoute: ActivatedRoute,
    private router: Router,
    public whTourService: TourService,
    private toastr: ToastrService,
    public settings:SettingsComponent) { }

  ngOnInit(): void {


    this.buildItemForm()
    this.getDetails();

    const newUserQueryParam = this.activateRoute.snapshot.queryParamMap.get('newUser');
    if (newUserQueryParam) {
      this.newUser = newUserQueryParam.toLowerCase() === 'true';
    }

  }

  getDetails() {

    this.controlPanelService.timeZoneList().subscribe(
      res => {
        this.timeZoneList = res.timeZone;
      }
    );
    this.controlPanelService.tenantCountryList().subscribe(
      res => {
        this.countryList = res.country;
      }
    );
    this.controlPanelService.getwarehouseSettings().subscribe(
      res => {
        this.warehouseSettingsDetails = res.warehouseSettings;
        if(this.warehouseSettingsDetails){
        this.id = this.warehouseSettingsDetails.shopId;
        this.warehouseForm.controls['name'].setValue(this.warehouseSettingsDetails.name);
        this.warehouseForm.controls['address'].setValue(this.warehouseSettingsDetails.address);
        this.warehouseForm.controls['street'].setValue(this.warehouseSettingsDetails.street);
        this.warehouseForm.controls['city'].setValue(this.warehouseSettingsDetails.city);
        this.warehouseForm.controls['state'].setValue(this.warehouseSettingsDetails.state);
        this.warehouseForm.controls['country'].setValue(this.warehouseSettingsDetails.country);
        if(this.warehouseSettingsDetails.country)
        this.countrySelected=true;
        this.warehouseForm.controls['zipCode'].setValue(this.warehouseSettingsDetails.zipCode);
        }


      }
    );
    this.whTourService.initialize([
      {
        stepId: 'cptour.wstep1.id',        
        anchorId: 'cptour.wstep1.id',
        content: 'Lets get you started with the registartion process.Please fill in the details of your Warehouse',
        title: 'Welcome',
        nextBtnTitle: 'Get Started',
        endBtnTitle:'Skip',
        // popperSettings:{
        //   closeOnClickOutside : false,
        // }
      },
           
      {
        stepId: 'cptour.wstep2.id',
        anchorId: 'cptour.wstep2.id',
        content: 'Please fill in your warehouse name,this will be used in invoice generation',
        title: 'Warehouse Name',
        placement:"right",
        endBtnTitle:'Skip',
      // popperSettings:{
      //   closeOnClickOutside : false,
      // }
     
    },
    {
      stepId: 'cptour.wstep3.id',
      anchorId: 'cptour.wstep3.id',
      content: 'Please fill in address of the Warehouse,this will be used in invoice generation',
      title: 'Warehouse Address',
      placement:'right',
      endBtnTitle:'Skip',
      // popperSettings:{
      //   closeOnClickOutside : false
      // }
     
    },

    {
      stepId: 'cptour.wstep4.id',
      anchorId: 'cptour.wstep4.id',
      content: 'Please fill in the street number, if any',
      title: 'Warehouse Address',
      placement:'right',
      endBtnTitle:'Skip',
    },

    {
      stepId: 'cptour.wstep5.id',
      anchorId: 'cptour.wstep5.id',
      content: 'Please enter the City',
      title: 'City',
      placement:'right',
      endBtnTitle:'Skip',
      // popperSettings:{
      //   closeOnClickOutside : false
      // }
      
    },
    {
      stepId: 'cptour.wstep6.id',
      anchorId: 'cptour.wstep6.id',
      content: 'Please enter the State',
      title: 'State', 
      placement:'right',
      endBtnTitle:'Skip',
      // popperSettings:{
      //   closeOnClickOutside : false
      // }
     
    },
    {
      stepId:'cptour.wstep7.id',
      anchorId: 'cptour.wstep7.id',
      content: 'Please enter the Country',
      title: 'Country',
      placement:'right',
      endBtnTitle:'Skip',
      
    },
    {
      anchorId: 'cptour.wstep8.id',
      content: 'Please enter the Zipcode',
      title: 'Zipcode',
      placement:'right',
      endBtnTitle:'Skip',
      
    },

    {
      stepId:'Submit',
      anchorId: 'cptour.wstep9.id',
      content: 'Please press this button to save all the above information',
      title: 'Save and Submit',
      placement:'right',
      
    },
 ],
      {route: 'settings',
      });
  }

      @HostListener('click',['$event'])mouseover(event) {  
        if (this.whTourService.currentStep) {
          const nextStep = $(event.target).closest(".ws-guided-tour").attr("tourAnchor")
          if (nextStep && this.tempPrevTourStep !== nextStep) {
  
            this.whTourService.startAt(nextStep);
            this.tempPrevTourStep = nextStep;
          }      
        }
      };
      @HostListener('document:keydown.tab', ['$event'])
      onKeydownHandler(event: KeyboardEvent) {
       if (this.whTourService.currentStep) {
             this.whTourService.next();
       
         }    
       }
    


  buildItemForm() {

    this.warehouseForm = this.fb.group({
      name: ['' || '', Validators.required],
      address: ['' || '', Validators.required],
      street: ['' || '',],
      city: ['' || '', Validators.required],
      state: ['' || '',],
      country: ['' || '',],
      zipCode: ['' || '', Validators.required],
    });
  }

  public warehouseTour(): void {
    this.newUser = false;
    this.whTourService.disableHotkeys();
    this.whTourService.start();
  }

  continueToGeneral(){
    this.settings.openGeneralSettings();
    this.settings.toggleColor(2);
  }

  SubmitWarehouse() {
    this.warehouseForm.markAllAsTouched();
    if (this.warehouseForm.valid) {
      var obj: WarehouseSettings = this.warehouseForm.value
      obj['shopId'] = this.id;

      this.controlPanelService.savewarehouseSettings(obj).subscribe(
        (res: any) => {
          if (res.acknowledgement.status === "SUCCESS") {
            this.successMessage = res.acknowledgement.message
            this.toastr.success(this.successMessage, "SUCCESS"),
            this.showContinueButton=true;
            this.controlPanelService.getprofileSettings().subscribe(
              res => {
                if(res.profileSettings === null)
                this.toastr.info("Please update General and Profile settings before publishing the Warehouse", "INFO")
              });
          }
          else {
            this.errorMessage = res.acknowledgement.message
            this.toastr.error(this.errorMessage, "ERROR")
          }

        },
        (err: HttpErrorResponse) => {
          if (err) {
            this.errorMessage = err.error.acknowledgement.message
            this.toastr.error(this.errorMessage, "ERROR")
          }
          else {
            this.toastr.error("Sorry, the information was not updated.", "ERROR")
          }

        }
      );
    }
  }
}
