import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ControlPanelService } from './control-panel.service';
import { GeneralSettings,FooterSettings } from './models/GeneralSettings.model';
import { WarehouseSettings } from './models/WarehouseSettings.model';
import { DataService } from '../data.service';
import { MatStepper } from '@angular/material/stepper';
import { NotificationSettings } from './models/NotificationSetting.model';
import { ProfileSettings } from './models/ProfileSettings.model';
import { OfsSettings } from './models/OfsSettings.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HostListener} from "@angular/core";
import { TourService } from 'ngx-tour-ngx-popper';
import { HttpErrorResponse } from '@angular/common/http';
import { BannerImage, UpdateImageRequest } from './models/BannerImage.model';
import {MyVersion} from 'src/my-version';
import {formatDate } from '@angular/common';

@Component({
  selector: 'app-control-panel',
  templateUrl: './control-panel.component.html',
  styleUrls: ['./control-panel.component.css']
})
export class ControlPanelComponent implements OnInit {



  @ViewChild('stepper', { static: true }) private stepper: MatStepper;

  isLinear = false;
  newUser = false;
  isUploading=false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  public warehouseForm: FormGroup;
  public generalSettingsForm: FormGroup;
  public productSettingsForm: FormGroup;
  public notificationForm: FormGroup;
  public profileSettingsForm: FormGroup;
  public ofsSettingsForm: FormGroup;
  public imageSettingsForm :  FormGroup;
  public footerSettingsForm : FormGroup;
  public posiboltSettingsForm : FormGroup;
  public posiboltGeneralSettingsForm : FormGroup;
  public syncScheduleSettingsForm : FormGroup;

  file : any;
  logo:any;
  profileSettingsDetails: any;
  notificationSettingsDetail: any;
  ofsId: string;
  selectedFile: File;
  // nameControl: any;
  tempPrevTourStepCount = 0;
  tempPrevTourStep;
  ofsSettingsDetails: any;
  sid=0;
  successMessage: any;
  warehouseSettingsDetails: any;
  bannerdetails:any;
  countryList: any;
  id = 0;
  saveGeneralSettings: GeneralSettings;
  generalSettingsDetails: any;
  timeZoneList: any;
  flag:false;
  hideSubmitStep = false;
  hidePrevStep = false;
  passwordChange= false;
  errorMessage:any;
  imagesFile = [];
  imageError:string ="";
  bannerImages:any;
  shopId;
  fileName:string="";
  logoName:string="";
  imageFileName:string[]=[];
  updateImageBody={} as UpdateImageRequest;
  isEditable=false;
  checked = false;
  uploadLogoVar=false;
  curDate;
  today= new Date();
  jstoday = '';
  ver:number;
  schedules: string[] = ['Repeated Times', 'Daily', 'Twice Daily', 'Weekly', 'Monthly'];
  selectedSchedule:string;
  @ViewChild('stepper') private myStepper: MatStepper;

 

  
  
  // id =0;

  
  
  get data(): string {
    return this.dataService.sharedData;
  }
 
  constructor(
    private fb: FormBuilder,
    private controlPanelService: ControlPanelService,
    private dataService: DataService,
    public activateRoute: ActivatedRoute,
    private router: Router,
    public cpTourService: TourService,
    private toastr: ToastrService,
  
  ) 
  {

   
  }
  public warehouseTour(): void {
     this.newUser = false;   
    this.cpTourService.disableHotkeys();
    this.cpTourService.start(); 
  }
  public genSettingsTour(): void {
    this.cpTourService.startAt("cptour.gstep1.id");
  }
  public notificationTour(): void {
    
    this.cpTourService.startAt("cptour.nstep1.id");
 }

  public ofsTour(): void{
    this.cpTourService.startAt("cptour.ostep1.id"); 
  }
  
  public profileTour():void{
    this.cpTourService.startAt("cptour.pstep1.id");
  }
  public imageTour():void{
    this.cpTourService.startAt("cptour.istep1.id");
  }
  public footerTour():void{
    this.cpTourService.startAt("cptour.fstep1.id");
  }
  public posiboltTour():void{
    this.cpTourService.startAt("cptour.pbstep1.id");
  }
  public posiboltGeneralTour():void{
    this.cpTourService.startAt("cptour.pbgstep1.id");
  }
  public schedulerTour():void{
    this.cpTourService.startAt("cptour.shstep1.id");
  }



  ngOnInit(): void {

    this.curDate = new Date().getFullYear().toString().substr(-2) + '.' +(new Date().getMonth() + 1).toString();
    this.ver=MyVersion.number;
    this.jstoday = formatDate(this.today, 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');

    // console.log(this.route.params)
    // (+) converts string 'id' to a number
    // .switchMap((params: Params) => this.yourProductService.getProductById(+params['id']))
    // .subscribe((product) => this.product = product);

    this.cpTourService.stepShow$.subscribe((res:any) => {
      if (res.title === "Save and Submit" ){
      this.hideSubmitStep = true;
      }
      if ((res.stepId === "cptour.gstep1.id") || (res.stepId === "cptour.nstep1.id") || (res.stepId === "cptour.ostep1.id") ||(res.stepId === "cptour.pstep1.id" )||(res.stepId === "cptour.istep1.id" )||(res.stepId === "cptour.fstep1.id" )||(res.stepId === "cptour.pbstep1.id" ) )
      {
        this.hidePrevStep =true;
      }
    }
    );
    this.cpTourService.stepHide$.subscribe((res:any) => {
      if (res.title === "Save and Submit" ){
      this.hideSubmitStep = false;
      }
      if ((res.stepId === "cptour.gstep1.id") || (res.stepId === "cptour.nstep1.id") || (res.stepId === "cptour.ostep1.id") ||(res.stepId === "cptour.pstep1.id" )||(res.stepId === "cptour.istep1.id" )||(res.stepId === "cptour.fstep1.id" ) ||(res.stepId === "cptour.pbstep1.id" ))
      {
        this.hidePrevStep =false;
      }
    });

    this.ofsId='new'

    if (this.dataService.sharedData) {
      setTimeout(() => {
        
      }, 1000);
    }
    this.buildItemForm()
    this.getDetails();
   
  /*   this.cpTourService.initialize([
      {
        stepId: 'cptour.wstep1.id',        
        anchorId: 'cptour.wstep1.id',
        content: 'Lets get you started with the registartion process.Please fill in the details of your Warehouse',
        title: 'Welcome',
        nextBtnTitle: 'Get Started',
        endBtnTitle:'Skip',
        // popperSettings:{
        //   closeOnClickOutside : false,
        // }
      },
           
      {
        stepId: 'cptour.wstep2.id',
        anchorId: 'cptour.wstep2.id',
        content: 'Please fill in your warehouse name,this will be used in invoice generation',
        title: 'Warehouse Name',
        placement:"right",
        endBtnTitle:'Skip',
      // popperSettings:{
      //   closeOnClickOutside : false,
      // }
     
    },
    {
      stepId: 'cptour.wstep3.id',
      anchorId: 'cptour.wstep3.id',
      content: 'Please fill in address of the Warehouse,this will be used in invoice generation',
      title: 'Warehouse Address',
      placement:'right',
      endBtnTitle:'Skip',
      // popperSettings:{
      //   closeOnClickOutside : false
      // }
     
    },
    {
      stepId: 'cptour.wstep4.id',
      anchorId: 'cptour.wstep4.id',
      content: 'Please enter the City',
      title: 'City',
      placement:'right',
      endBtnTitle:'Skip',
      // popperSettings:{
      //   closeOnClickOutside : false
      // }
      
    },
    {
      stepId: 'cptour.wstep5.id',
      anchorId: 'cptour.wstep5.id',
      content: 'Please enter the State',
      title: 'State', 
      placement:'right',
      endBtnTitle:'Skip',
      // popperSettings:{
      //   closeOnClickOutside : false
      // }
     
    },
    {
      stepId:'cptour.wstep6.id',
      anchorId: 'cptour.wstep6.id',
      content: 'Please enter the Country',
      title: 'Country',
      placement:'right',
      endBtnTitle:'Skip',
      
    },
    {
      anchorId: 'cptour.wstep7.id',
      content: 'Please enter the Zipcode',
      title: 'Zipcode',
      placement:'right',
      endBtnTitle:'Skip',
      
    },

    {
      stepId:'Submit',
      anchorId: 'cptour.wstep8.id',
      content: 'Please press this button to save all the above information',
      title: 'Save and Submit',
      placement:'right',
      
    },

     {  
     
      stepId : 'cptour.gstep1.id',
      anchorId: 'cptour.gstep1.id',
      content: 'Warehouse name as seen by Buyers' ,
      title: 'Display name',
      endBtnTitle:'Skip',
      placement:"right",
      
      
     
    },
    {  
      stepId : 'cptour.gstep2.id',
      anchorId: 'cptour.gstep2.id',
      content: 'Currency used for the transaction' ,
      title: 'Currency',
      placement:'right',
      endBtnTitle:'Skip',
      
    },

{  
      stepId:'cptour.gstep3.id',
      anchorId: 'cptour.gstep3.id',
      content: 'Shipping cost for a transaction' ,
      title: 'Shipping cost',
      placement:'right',
      endBtnTitle:'Skip',
      },
    {
      
      stepId:'cptour.gstep4.id',
      anchorId: 'cptour.gstep4.id',
      content: 'Please select the timezone you are in' ,
      title: 'Time Zone',
      placement:'right',
      endBtnTitle:'Skip',
     
    },
    {
      stepId:'cptour.gstep5.id',
      anchorId: 'cptour.gstep5.id',
      title: 'Minimum Order Quantity',
      content: 'The global setting for Minimum Order quantity' ,
      placement:'right',
      endBtnTitle:'Skip',
      
      
    },

    {
      stepId:'cptour.gstep6.id',
      anchorId: 'cptour.gstep6.id',
      title: 'Minimum Order Value' ,
      content: 'The global setting for Minimum Order Value' ,
      placement:'right',
      endBtnTitle:'Skip',
      
      
    },
    {
      stepId:'cptour.gstep7.id',
      anchorId: 'cptour.gstep7.id',
      title: 'Tax information' ,
      content: 'Please enter the Tax or GST number here' ,
      placement:'right',
      endBtnTitle:'Skip',
      
      
    },
    {
      stepId:'cptour.gstep8.id',
      anchorId: 'cptour.gstep8.id',
      title: 'Enable Inventory',
      content: 'Please check the box if you want the buyer to see the inventory/stock information' ,
      placement:'right',
      endBtnTitle:'Skip',
      
      
    },
    {
      anchorId: 'cptour.gstep9.id',
      content: 'Please press this button to save all the above information',
      title: 'Save and Submit',
      placement:'right',
      
    },
  
      
    {
      stepId : 'cptour.nstep1.id',
     anchorId: 'cptour.nstep1.id',
     content: 'Email address to sent the notification about Orders' ,
     title: 'Email',
     placement:'right',
     endBtnTitle:'Skip',
     
    
  
   },
   { 
    stepId:'cptour.nstep2.id', 
     anchorId: 'cptour.nstep2.id',
     content: 'Mobile number for the Order notification' ,
     title: 'Phone Number',
     placement:'right',
     endBtnTitle:'Skip',
     
   },
   {
    stepId:'cptour.nstep3.id',
    anchorId: 'cptour.nstep3.id',
    content: 'Please press this button to save all the above information',
    title: 'Save and Submit',
    placement:'right',
    
  },
   {
    stepId : 'cptour.ostep1.id',
   anchorId: 'cptour.ostep1.id',
   content: 'Username' ,
   title:'Order Fulfillment Username ',
   placement:'right',
   endBtnTitle:'Skip',
   
  
 },
 { 
  stepId: 'cptour.ostep2.id',
   anchorId: 'cptour.ostep2.id',
   title: 'Login ID' ,
   content: 'Login id used to login in OFS', 
   placement:'right',
   endBtnTitle:'Skip',
  
   
 },

{  
  stepId: 'cptour.ostep3.id',
   anchorId: 'cptour.ostep3.id',
   title: 'Change Password' ,
   content:'Check if you need to update password',
   placement:'right',
   endBtnTitle:'Skip',
   
 },
 
 {
  anchorId: 'cptour.ostep4.id',
  content: 'Please press this button to save all the above information',
  title: 'Save and Submit',
  placement:'right',
  
},
      
  {
    stepId : 'cptour.pstep1.id',
   anchorId: 'cptour.pstep1.id',
   content: 'Profile name of the user.This will be dispalyed to buyers.' ,
   title: 'Display Name',
   placement:'right',
   endBtnTitle:'Skip',
    
  
 },
 { 
  stepId: 'cptour.pstep2.id',
   anchorId: 'cptour.pstep2.id',
   content: 'Street Address ' ,
   title: 'Address',
   placement:'right',
   endBtnTitle:'Skip',
 },
{  
  stepId: 'cptour.pstep3.id',
   anchorId: 'cptour.pstep3.id',
   content: 'Please enter your city name' ,
   title:'City',
   placement:'right',
   endBtnTitle:'Skip',
   
 },
 {
  stepId: 'cptour.pstep4.id',
   anchorId: 'cptour.pstep4.id',
   content: 'Please enter the state ' ,
   title: 'State',
   placement:'right',
   endBtnTitle:'Skip',
      
 },
 {
  stepId: 'cptour.pstep5.id',
   anchorId: 'cptour.pstep5.id',
   title: 'Country',
   content: 'Please select the country' ,
   placement:'right',
   endBtnTitle:'Skip',
      
   
 },

 {
  stepId: 'cptour.pstep6.id',
   anchorId: 'cptour.pstep6.id',
   title: 'ZipCode/PIN',
   content: 'Please enter the Zip/pin number' ,
   placement:'right',
   endBtnTitle:'Skip',
   
   
 },
 
 {
  stepId: 'cptour.pstep7.id',
   anchorId: 'cptour.pstep7.id',
   content: 'Email ' ,
  title: 'Email',
  endBtnTitle:'Skip',
   placement:'right',
      
 },
 {
  stepId: 'cptour.pstep8.id',
   anchorId: 'cptour.pstep8.id',
   content: 'Mobile number' ,
   title: 'Phone',
   placement:'right',
   endBtnTitle:'Skip',
     
 },

 {
  anchorId: 'cptour.pstep9.id',
  content: 'Please press this button to save all the above information.',
  title: 'Save and Submit',
  placement:'right',
  
},

{  
     
  stepId : 'cptour.istep1.id',
  anchorId: 'cptour.istep1.id',
  content: 'Please Upload the Image of your Logo' ,
  title: 'Logo',
  endBtnTitle:'Skip',
  placement:"right",
  
 
},
{  
     
  stepId : 'cptour.istep2.id',
  anchorId: 'cptour.istep2.id',
  content: 'Here is the current list of all your uploaded Banner slides, press the Delete button to remove them' ,
  title: 'Current Banners',
  endBtnTitle:'Skip',
  placement:"top",
  
 
},
{  
     
  stepId : 'cptour.istep3.id',
  anchorId: 'cptour.istep3.id',
  content: 'You can upload more Banners here one image at a time.Please select the image and press the Upload button',
  title: 'Add Banners',
  endBtnTitle:'Skip',
  placement:"top",
  
 
},

{
  anchorId: 'cptour.istep4.id',
  content: 'Please press this button to Submit and move to next section.',
  title: 'Save and Submit',
  placement:'right',
  
},

{  
     
  stepId : 'cptour.fstep1.id',
  anchorId: 'cptour.fstep1.id',
  content: 'Please write asmall description about your warehouse for the consumers to view' ,
  title: 'About-Us',
  endBtnTitle:'Skip',
  placement:"right", 
 
},
  
 {    
stepId : 'cptour.fstep2.id',
anchorId: 'cptour.fstep2.id',
content: 'Please input the link to your Facebook Page' ,
title: 'Facebook',
endBtnTitle:'Skip',
placement:"right", 

},
{    
  stepId : 'cptour.fstep3.id',
  anchorId: 'cptour.fstep3.id',
  content: 'Please input the link to your Linkedn Page' ,
  title: 'Linkedn',
  endBtnTitle:'Skip',
  placement:"right", 
  
  },
  {    
    stepId : 'cptour.fstep4.id',
    anchorId: 'cptour.fstep4.id',
    content: 'Please input the link to your Instagram Page' ,
    title: 'Instagram',
    endBtnTitle:'Skip',
    placement:"right", 
    
    },
    {    
      stepId : 'cptour.fstep5.id',
      anchorId: 'cptour.fstep5.id',
      content: 'Please input the link to your Twitter Page' ,
      title: 'Twitter',
      endBtnTitle:'Skip',
      placement:"right", 
      
      },
      {    
        stepId : 'cptour.fstep6.id',
        anchorId: 'cptour.fstep6.id',
        content: 'Please input the link to your YouTube Page' ,
        title: 'YouTube',
        endBtnTitle:'Skip',
        placement:"right", 
        
        },

      {
        anchorId: 'cptour.fstep7.id',
        content: 'Please press this button to Save the above information.',
        title: 'Save and Submit',
        placement:'right',
        
      },
      {  
     
        stepId : 'cptour.pbstep1.id',
        anchorId: 'cptour.pbstep1.id',
        content: 'Please enter the posibolt Domain URL here' ,
        title: 'Posibolt Domain Url',
        endBtnTitle:'Skip',
        placement:"right", 
       
      },
        
       {    
      stepId : 'cptour.pbstep2.id',
      anchorId: 'cptour.pbstep2.id',
      content: 'Please enter your Authentication UserName' ,
      title: 'UserName',
      endBtnTitle:'Skip',
      placement:"right", 
      
      },
      {    
        stepId : 'cptour.pbstep3.id',
        anchorId: 'cptour.pbstep3.id',
        content: 'Please enter your Authentication Password' ,
        title: 'Password',
        endBtnTitle:'Skip',
        placement:"right", 
        
        },
        {    
          stepId : 'cptour.pbstep4.id',
          anchorId: 'cptour.pbstep4.id',
          content: 'Please enter your Posibolt UserName' ,
          title: 'UserName',
          endBtnTitle:'Skip',
          placement:"right", 
          
          },
          {    
            stepId : 'cptour.pbstep5.id',
            anchorId: 'cptour.pbstep5.id',
            content: 'Please enter your Posibolt Password' ,
            title: 'Password',
            endBtnTitle:'Skip',
            placement:"right", 
            
            },
            {    
              stepId : 'cptour.pbstep6.id',
              anchorId: 'cptour.pbstep6.id',
              content: 'Please enter the Terminal' ,
              title: 'Terminal',
              endBtnTitle:'Skip',
              placement:"right", 
              
              },
      
            {
              anchorId: 'cptour.pbstep7.id',
              content: 'Please press this button to Save the above information.',
              title: 'Save and Submit',
              placement:'right',
              
            },
],
{route: 'controlPanel',
}); */

const newUserQueryParam = this.activateRoute.snapshot.queryParamMap.get('newUser');
      if (newUserQueryParam)
      {
        this.newUser = newUserQueryParam.toLowerCase() === 'true';
      }

  }

  @HostListener('click',['$event']) mouseover(event) {  
    if (this.cpTourService.currentStep) {

      // if ($(event.target).closest(".ws-guided-tour").attr("tourAnchor") !== this.tempPrevTourStep || this.tempPrevTourStepCount === 0) {
      //   this.tempPrevTourStepCount = 0;
      // if ($(event.target).closest(".ws-guided-tour").attr("tourAnchor") !== this.tempPrevTourStep) {
        const nextStep = $(event.target).closest(".ws-guided-tour").attr("tourAnchor")
        if (nextStep && this.tempPrevTourStep !== nextStep) {

          this.cpTourService.startAt(nextStep);
          this.tempPrevTourStep = nextStep;
        }        
      // }

      // if ($(event.target).closest(".ws-guided-tour").attr("tourAnchor") === this.tempPrevTourStep) {
      //   this.tempPrevTourStepCount++;
      // }
    }
  };

  
  /* @HostListener('click' , ['$event'])
  DoEm (event) {
    if(this.cpTourService.currentStep){
    if ($(event.target).closest(".ws-guided-tour-1").attr("tourAnchor") !== this.tempPrevTouService || this.tempPrevTouServiceCount === 0){
      this.tempPrevTouServiceCount=0;
      this.cpTourService.startAt($(event.target).closest(".ws-guided-tour-1").attr("tourAnchor"));
      this.tempPrevTouService=$(event.target).closest(".ws-guided-tour-1").attr("tourAnchor");
     
    }
    if($(event.target).closest(".ws-guided-tour-1").attr("tourAnchor")==this.tempPrevTouService){
        this.tempPrevTouServiceCount++;
    }
  }
  }  */
  
  @HostListener('document:keydown.tab', ['$event'])
   onKeydownHandler(event: KeyboardEvent) {
    if (this.cpTourService.currentStep) {
          this.cpTourService.next();
    
      }    
    }
  
   
  /* @HostListener('document:keydown.ArrowUp', ['$event'])
    onKeydownHandler2(event: KeyboardEvent) {
      if(this.cpTourService.currentStep){
      this.cpTourService.prev();
      }
    }  */

  getDetails() {
    this.controlPanelService.timeZoneList().subscribe(
      res => {
        this.timeZoneList = res.timeZone;
      }
    );
    this.controlPanelService.countryList().subscribe(
      res => {
        this.countryList = res.country;
      }
    );
    this.controlPanelService.getgeneralSettings().subscribe(
      res => {
        this.generalSettingsDetails = res.generalSettings;
        console.log(this.generalSettingsDetails);  
        this.generalSettingsForm.controls['displayName'].setValue(this.generalSettingsDetails.displayName);
        this.generalSettingsForm.controls['timeZone'].setValue(this.generalSettingsDetails.timeZone);
        this.generalSettingsForm.controls['mov'].setValue(this.generalSettingsDetails.mov);
        this.generalSettingsForm.controls['moq'].setValue(this.generalSettingsDetails.moq);
        this.generalSettingsForm.controls['currency'].setValue(this.generalSettingsDetails.currency);
        this.generalSettingsForm.controls['shippingCost'].setValue(this.generalSettingsDetails.shippingCost);

      }
    );
   
    this.controlPanelService.getwarehouseSettings().subscribe(
      res => {
        this.warehouseSettingsDetails = res.warehouseSettings;
       
        this.id = this.warehouseSettingsDetails.shopId;
        this.warehouseForm.controls['name'].setValue(this.warehouseSettingsDetails.name);
        this.warehouseForm.controls['address'].setValue(this.warehouseSettingsDetails.address);
        this.warehouseForm.controls['city'].setValue(this.warehouseSettingsDetails.city);
        this.warehouseForm.controls['state'].setValue(this.warehouseSettingsDetails.state);
        this.warehouseForm.controls['country'].setValue(this.warehouseSettingsDetails.country);
        this.warehouseForm.controls['zipCode'].setValue(this.warehouseSettingsDetails.zipCode);

        

      }
    );
    this.controlPanelService.getnotificationSettings().subscribe(
      res => {
        this.notificationSettingsDetail = res.notificationSettings;
       
        this.notificationForm.controls['email'].setValue(this.notificationSettingsDetail.email);
        this.notificationForm.controls['phone'].setValue(this.notificationSettingsDetail.phone);
      }
    );
    this.controlPanelService.getofsSettings().subscribe(
      res => {
        this.ofsSettingsDetails = res.ofsSettings;
        
       if(this.ofsSettingsDetails.userId >0){
       this.ofsId =this.ofsSettingsDetails.userId;
       }
        this.ofsSettingsForm.controls['name'].setValue(this.ofsSettingsDetails.name);
        this.ofsSettingsForm.controls['loginId'].setValue(this.ofsSettingsDetails.loginId);
        this.ofsSettingsForm.controls['password'].setValue(this.ofsSettingsDetails.password);
        this.ofsSettingsForm.controls['confirmPassword'].setValue(this.ofsSettingsDetails.password);
         
      }
      );
      this.controlPanelService.getprofileSettings().subscribe(
        res => {
          this.profileSettingsDetails = res.profileSettings;
         
          this.profileSettingsForm.controls['name'].setValue(this.profileSettingsDetails.name);
          this.profileSettingsForm.controls['address'].setValue(this.profileSettingsDetails.address);
          this.profileSettingsForm.controls['city'].setValue(this.profileSettingsDetails.city);
          this.profileSettingsForm.controls['state'].setValue(this.profileSettingsDetails.state);
          this.profileSettingsForm.controls['country'].setValue(this.profileSettingsDetails.country);
          this.profileSettingsForm.controls['zipCode'].setValue(this.profileSettingsDetails.zipCode);
          this.profileSettingsForm.controls['email'].setValue(this.profileSettingsDetails.email);
          this.profileSettingsForm.controls['phone'].setValue(this.profileSettingsDetails.phone);
           
        }
        );

        this.controlPanelService.getwarehouseSettings().subscribe(
          res => {
            this.warehouseSettingsDetails = res.warehouseSettings;
            this.shopId = this.warehouseSettingsDetails.shopId;
            
            this.controlPanelService.getBannerImages(this.shopId).subscribe(
              result => {
                this.bannerImages = result.images;
               
              }
            
            );
            this.controlPanelService.getFooterDetails(this.shopId).subscribe(
              resu => {
               console.log(resu); 
               this.footerSettingsForm.controls['aboutUs'].setValue(resu.aboutUs);
               this.footerSettingsForm.controls['facebookUrl'].setValue(resu.facebookUrl);
               this.footerSettingsForm.controls['instagramUrl'].setValue(resu.instagramUrl);
               this.footerSettingsForm.controls['linkedinUrl'].setValue(resu.linkedinUrl);
               this.footerSettingsForm.controls['twitterUrl'].setValue(resu.twitterUrl);

               this.footerSettingsForm.controls['youtubeUrl'].setValue(resu.youtubeUrl);
              }
            
            );

          });


      

  }

  onFileChangedLogo(event){
    console.log(event);

    if (event.target.files && event.target.files[0]) {
      this.logoName="";
      this.imageError = null;
      this.logo = event.target.files[0];
     
      this.logoName = event.target.files[0].name;
     
            // Size Filter Bytes
            const max_size = 20971520;
            const allowed_types = ['image/png', 'image/jpeg'];
            const max_height = 15200;
            const max_width = 25600;

            if (event.target.files[0].size > max_size) {
                this.imageError =
                    'Maximum size allowed is ' + max_size / 1000 + 'Mb';

                return false;
            }
      
      
              var reader = new FileReader();
 
              reader.onload = (event:any) => {
               
                
 
                 this.imageSettingsForm.patchValue({
                    fileSource: this.logo
                 });
              }

              reader.readAsDataURL(event.target.files[0]);
      
  }


  }
  
disableImageUpload(index){
  
  this.imageSettingsForm.get('banners').disable();
    
}


  onFileChanged(event) {

    if (event.target.files && event.target.files[0]) {
      this.fileName="";
      this.imageError = null;
      this.file = event.target.files[0];
     
      this.fileName = event.target.files[0].name;
     
            // Size Filter Bytes
            const max_size = 20971520;
            const allowed_types = ['image/png', 'image/jpeg'];
            const max_height = 15200;
            const max_width = 25600;

            if (event.target.files[0].size > max_size) {
                this.imageError =
                    'Maximum size allowed is ' + max_size / 1000 + 'Mb';

                return false;
            }
      
      
              var reader = new FileReader();
 
              reader.onload = (event:any) => {
               
                
 
                 this.imageSettingsForm.patchValue({
                    fileSource: this.file
                 });
              }

              reader.readAsDataURL(event.target.files[0]);
      
  }
  }

 

 
  buildItemForm() {

    this.warehouseForm = this.fb.group({
      name: ['' || '', Validators.required],
      address: ['' || '', Validators.required],
      city: ['' || '', Validators.required],
      state: ['' || '',],
      country: ['' || '',],
      zipCode: ['' || '', Validators.required],
    });

    this.generalSettingsForm = this.fb.group({
      displayName: ['' || '', Validators.required],
      timeZone: ['' || '', Validators.required],
      mov: ['' || '',],
      moq: ['' || ''],
      shippingCost: ['' || ''],
      currency: ['' || '', Validators.required],
      inventory:[''||''],
      tax:[''||'']
     });

    this.notificationForm = this.fb.group({
      email: ['', {
        validators: [
            Validators.required,
            Validators.email
        ],
        updateOn: 'blur'
     }],
      phone: ['',Validators.compose([ Validators.required,
        Validators.pattern("[0-9]{10}")])]
    });
    this.productSettingsForm = this.fb.group({
      productimageflag: ['' || '', Validators.required],

    });
    this.ofsSettingsForm = this.fb.group({
      name: ['' || '', Validators.required],
      loginId: ['' || '', Validators.required],
      password: [''|| '', Validators.required],
      confirmPassword: ['' || '',Validators.required],
      changePassword:['' || '']
    },
    {
      validator: this.MustMatch('password', 'confirmPassword'),
    }
    );
    
    this.profileSettingsForm = this.fb.group({
      name: ['' || '', Validators.required],
      address: ['' || '', Validators.required],
      city: ['' || '', Validators.required],
      state: ['' || ''],
      country: ['' || '', ],
      zipCode: ['' || '',Validators.required],
      email: ['', {
        validators: [
            Validators.required,
            Validators.email
        ],
        updateOn: 'blur'
     }],
      phone: ['',Validators.compose([ Validators.required,Validators.pattern("[0-9]{10}"),
      Validators.minLength(10), Validators.maxLength(10)])],
    });

    this.imageSettingsForm = this.fb.group({
      logo : [""|| ""],
      bannerText: ['', ''],
      bannerDescription: ['', ''],
      bannerImage: ['', Validators.required]
      
    });

    this.footerSettingsForm = this.fb.group({
      aboutUs: [""|| ""],
      facebookUrl: [""|| ""],
      twitterUrl : [""|| ""],
      linkedinUrl: [""|| ""],
      instagramUrl : [""|| ""],
      youtubeUrl: [""|| ""]

    });

    this.posiboltSettingsForm =  this.fb.group({
      domainUrl: [""|| ""],
      authUserName: [""|| ""],
      authPassword : [""|| ""],
      posiboltUserName: [""|| ""],
      posiboltPassword : [""|| ""],
      terminal: [""|| ""]
    });

    this.posiboltGeneralSettingsForm =  this.fb.group({
      saveProduct: [""|| ""],
      changeStock: [""|| ""],
      sendShipping : [""|| ""],
      backgroundImage: [""|| ""],
      allocateInvoice: [""|| ""],
      syncEmails: [""|| ""]
    });




  }    

  submitPosiboltGeneralSettings(){
    
  }

 
  deleteBanner(imageId)
  {
    this.updateImageBody.shopId=this.shopId;
    this.updateImageBody.imageId =imageId.toString();
    
    this.controlPanelService.updateBanner(this.updateImageBody).subscribe(
      (res: any) => {
        console.log(res);
        if (res.acknowledgement.status === "SUCCESS") {
          this.successMessage = res.acknowledgement.message
          this.toastr.success( this.successMessage,"SUCCESS")
          this.controlPanelService.getBannerImages(this.shopId).subscribe(
            result => {
              this.bannerImages = result.images;
             
            }
          
          );
        }
        else{
          this.errorMessage=res.acknowledgement.message
          this.toastr.error(this.errorMessage,"ERROR")
        }
        
      },
      (err:HttpErrorResponse) => {
        if(err)
        {
           this.errorMessage=err.error.acknowledgement.message
           this.toastr.error(this.errorMessage,"ERROR")
        }
        else{
          this.toastr.error("Sorry, Image was not deleted","ERROR")
        }

     }
  
    );
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}
  Reset() {
    this.generalSettingsForm.reset();
  }
  goForward() {
   
    this.stepper.next();
  }
   
  move(index: number) {
    this.stepper.selectedIndex = index;
  }

  uploadLogo()
  {
    this.uploadLogoVar=true;
    const formData = new FormData();
    formData.set('logoFile',this.logo);
    var shop =this.warehouseSettingsDetails.shopId.toString();
    formData.set('shopId',shop);
    this.controlPanelService.saveLogo(formData).subscribe(
      (res: any) => {
        if (res.acknowledgement.status === "SUCCESS") {
          this.successMessage = res.acknowledgement.message
          this.toastr.success( this.successMessage,"SUCCESS")
          this.uploadLogoVar=false;
        }
        else {
          this.errorMessage=res.acknowledgement.message
          this.toastr.error(this.errorMessage,"ERROR")
          this.uploadLogoVar=false;
        }
        
      },
      (err:HttpErrorResponse) => {
        if(err)
        {
           this.errorMessage=err.error.acknowledgement.message
           this.toastr.error(this.errorMessage,"ERROR")
           this.uploadLogoVar=false;
        }
        else{
          this.toastr.error("Sorry, Image was not uploaded","ERROR")
          this.uploadLogoVar=false;
        }

     }
    ); 

  }
  uploadBanner(){
    
    this.isUploading=true;
    if(this.imageSettingsForm.valid){
  
    const formData = new FormData();
    formData.set('imageFile',this.file);
    formData.set('name',this.fileName);
    formData.set('shopId',this.warehouseSettingsDetails.shopId);
    formData.set('title',this.imageSettingsForm.value.bannerText);
    formData.set('description',this.imageSettingsForm.value.bannerDescription);
    console.log(formData);
     this.controlPanelService.saveBanner(formData).subscribe(
      (res: any) => {
        if (res.acknowledgement.status === "SUCCESS") {
          this.successMessage = res.acknowledgement.message
          this.toastr.success( this.successMessage,"SUCCESS")
          this.isUploading=false;
          this.imageSettingsForm.patchValue({
            bannerText: "",
            bannerImage: ""
       });
          this.controlPanelService.getBannerImages(this.shopId).subscribe(
            result => {
              this.bannerImages = result.images;
             
            }
          
          );
        }
        else{
          this.errorMessage=res.acknowledgement.message
          this.toastr.error(this.errorMessage,"ERROR")
          this.isUploading=false;
        }
        
      },
      (err:HttpErrorResponse) => {
        if(err)
        {
           this.errorMessage=err.error.acknowledgement.message
           this.toastr.error(this.errorMessage,"ERROR")
           this.isUploading=false;
        }
        else{
          this.toastr.error("Sorry, Image was not uploaded","ERROR")
          this.isUploading=false;
        }

     }
    ); 

  
}
else {
  this.toastr.error("Sorry, please check if you selected an image","ERROR")
  this.isUploading=false;

}
  }
  

  SubmitWarehouse() {
    this.warehouseForm.markAllAsTouched();
    if (this.warehouseForm.valid) {
      var obj: WarehouseSettings = this.warehouseForm.value
      obj['shopId'] = this.id;
     
      this.controlPanelService.savewarehouseSettings(obj).subscribe(
        (res: any) => {
          if (res.acknowledgement.status === "SUCCESS") {
            this.successMessage = res.acknowledgement.message
            this.stepper.selectedIndex = 1;
            this.toastr.success( this.successMessage,"SUCCESS")
          }
          else{
            this.errorMessage=res.acknowledgement.message
            this.toastr.error(this.errorMessage,"ERROR")
          }
          
        },
        (err:HttpErrorResponse) => {
          if(err)
          {
             this.errorMessage=err.error.acknowledgement.message
             this.toastr.error(this.errorMessage,"ERROR")
          }
          else{
            this.toastr.error("Sorry, the information was not updated.","ERROR")
          }

       }
      );
    }
  }
  
  Submitnotification() {
    this.notificationForm.markAllAsTouched();
    if(this.notificationForm.valid){
    var obj: NotificationSettings = this.notificationForm.value
    
    this.controlPanelService.savenotificationSettings(obj).subscribe(
      (res: any) => {
        if(res.acknowledgement.status==="SUCCESS"){
         this.stepper.selectedIndex = 3;
         this.successMessage=res.acknowledgement.message
         this.toastr.success( this.successMessage,"SUCCESS")
        }
        else{
          this.errorMessage=res.acknowledgement.message
          this.toastr.error(this.errorMessage,"ERROR")
        }
        
      },
      
        (err:HttpErrorResponse) => {
         
          if(err)
          {
             this.errorMessage=err.error.acknowledgement.message
             this.toastr.error(this.errorMessage,"ERROR")
          }
          else{
            this.toastr.error("Sorry,The information was not updated,Please try again later","ERROR")
          }

       }
      
    );
  }
}
submitOfs(){
  this.ofsSettingsForm.markAllAsTouched();
  if(this.ofsSettingsForm.valid){
  var obj: OfsSettings = this.ofsSettingsForm.value;
  obj['changePassword'] = true;
  obj['userId'] = this.ofsId;
  obj['shopId'] = this.warehouseSettingsDetails.shopId
 
  this.controlPanelService.saveofsSettings(obj).subscribe(
    (res: any) => {
      if(res.acknowledgement.status==="SUCCESS"){
        this.successMessage=res.acknowledgement.message
        this.stepper.selectedIndex = 4;
        this.toastr.success( this.successMessage,"SUCCESS")
      }
     
       
    },
        (err:HttpErrorResponse) => {
         
          if(err)
          {
             this.errorMessage=err.error.acknowledgement.message
             this.toastr.error(this.errorMessage,"ERROR")
          }
          else{
            this.toastr.error("Sorry,the information was not updated,Please try again later","ERROR")
          }

       }
  );
}
}
submitProfile() {
  this.profileSettingsForm.markAllAsTouched();
  if(this.profileSettingsForm.valid){

  var obj: ProfileSettings = this.profileSettingsForm.value
  
  this.controlPanelService.saveprofileSettings(obj).subscribe(
    (res: any) => {
      if(res.acknowledgement.status==="SUCCESS"){
        this.successMessage=res.acknowledgement.message
        this.stepper.selectedIndex = 5;
        this.toastr.success(this.successMessage,"SUCCESS")
      }
      
       
    },
     
        (err:HttpErrorResponse) => {
          if(err)
          {
             this.errorMessage=err.error.acknowledgement.message
             this.toastr.error(this.errorMessage,"ERROR")
          }
          else{
            this.toastr.error("Sorry,the information was not updated,Please try again later","ERROR")
          }
       }
  );
}

}
  Submit() {
    this.generalSettingsForm.markAllAsTouched();
    if (this.generalSettingsForm.valid) {
      var obj: GeneralSettings = this.generalSettingsForm.value
      obj['shopId'] = this.warehouseSettingsDetails.shopId
     
      this.controlPanelService.savegeneralSettings(obj).subscribe(
        (res: any) => {
          if (res.acknowledgement.status === "SUCCESS") {
    this.stepper.selectedIndex = 2;
            this.successMessage = res.acknowledgement.message
            this.toastr.success( this.successMessage,"SUCCESS")
          }
          
        },
       
        (err:HttpErrorResponse) => {
          
          if(err)
          {
             this.errorMessage=err.error.acknowledgement.message
            this.toastr.error(this.errorMessage,"ERROR")
          }
          else{
            this.toastr.error("Sorry,the information was not updated,Please try again later","ERROR")
          }
       }
      );
    }


  }
submitImages(){
  console.log("here");
  this.toastr.success( "Saved","SUCCESS")
  this.stepper.selectedIndex = 6;
}
 
submitPosiboltSettings(){
  console.log("here");
  this.toastr.success( "Saved","SUCCESS");
}
  submitFooter(){
  
  this.footerSettingsForm.markAllAsTouched();
  if (this.footerSettingsForm.valid) {
    var footer: FooterSettings = this.footerSettingsForm.value
    footer['shopId'] = this.warehouseSettingsDetails.shopId;
   
    this.controlPanelService.saveFooterSettings(footer).subscribe(
      (res: any) => {
        if (res.acknowledgement.status === "SUCCESS") {
          this.successMessage = res.acknowledgement.message
          this.toastr.success( this.successMessage,"SUCCESS")
        }
        
      },
     
      (err:HttpErrorResponse) => {
        
        if(err)
        {
           this.errorMessage=err.error.acknowledgement.message
          this.toastr.error(this.errorMessage,"ERROR")
        }
        else{
          this.toastr.error("Sorry,the information was not updated,Please try again later","ERROR")
        }
     }
    );
  }



  }

}