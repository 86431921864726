<tour-step-template>
    <ng-template let-step="step">
      <div class="row">
        <div>
          <button mat-icon-button [disabled]="!sTourService.hasPrev(step)"
            [ngClass]="{'inactive-btn' : !sTourService.hasPrev(step)}" class="ml-2" (click)="sTourService.prev()">
            <mat-icon>arrow_back</mat-icon>
          </button>
        </div>
        <div class="ngxp-end">
          <button mat-flat-button (click)="sTourService.end()">{{step?.endBtnTitle}}</button>
        </div>
      </div>
      <div class="ngxp-title" [ngClass]="{'ngxp-first-step' : !sTourService.hasPrev(step)}">
        <div class="ngxp-title-text">
          {{step?.title}}
        </div>
  
      </div>
      <img src="../../assets/icon/guided_tour.png" width="60px">
      <div class="ngxp-content mb-3">{{step?.content}}</div>
      <div class="tour-step-navigation" style="display: flex;
        justify-content: center;
        align-items: center;">
  
        <button mat-raised-button color="primary" [disabled]="!sTourService.hasNext(step)"
          [ngClass]="{'inactive-btn' : !sTourService.hasNext(step)}" class="nextBtn"
          (click)="sTourService.next()">{{step?.nextBtnTitle}} »</button>
      </div>
    </ng-template>
</tour-step-template>



<div class="container-fluid">
  <div *ngIf="ifCategoryLoading" class="loading-indicator">
    <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
  </div>
    <div class="row">
        <div style="padding-right: 0;" class="col-lg-3 parent">
          <div  style="align-items: center;">
              <button  mat-raised-button class="ml-2 green" [class.spinner]="ifImport" [disabled]="ifImport" (click)="fileInput.click()"> Import
              <input #fileInput type="file" id="file" accept=".xlsx, .xls, .csv" (click)="fileInput.value = null"  value=""
                (change)="onExcel($event)" style="display: none;">
              </button>
              

            <button mat-raised-button class="ml-2 green" [class.spinner]="ifExport" [disabled]="ifExport" (click)="downloadStock()"> Export </button>
            
            <h5 class="pt-1 pb-1 background-highlight" style="text-align: center">Categories</h5> 

        </div>
     
      <cdk-virtual-scroll-viewport itemSize="tempTree.length" class="example-viewport" tourAnchor="stour.step4.id">
        <div style="padding:5px;">
          <div class="sticky-search">
            <mat-form-field class="pl-2 pb-1 mt-1"  appearance="outline">
          
              <input style="position:sticky;" matInput (keyup)="filter($event.target.value)" placeholder="Search">
              <mat-icon matSuffix>search</mat-icon>
            
            </mat-form-field>
          </div>
          
        
        
        <mat-nav-list>
          <div *ngIf="showTree && !tempTree.length">
            <span style="margin: 20px;color: red;">
              No Records found</span>
          </div>
            <span *ngFor="let item of tempTree">
              <span *ngIf="item.categories && item.categories.length > 0">
                 <mat-accordion class="app-nav-accordion">
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <div  style="text-overflow: ellipsis;overflow-x: hidden !important;white-space: pre-wrap !important;" [ngClass]="{red: clickedItem === item.categoryId}"> 
                         <!-- <img src={{editImgUrl}} height="15" width="15" alt="">  --> 
                          <button mat-button style="text-overflow: ellipsis;" (click)="treeClicked(item.categoryId)">&nbsp;{{item.categoryName}}</button>
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <span *ngFor="let categories of item.categories;index as i">
                     <!--  <span *ngIf="categories.categories && categories.categories.length > 0">
                        <mat-accordion class="app-nav-accordion">
                          <mat-expansion-panel>
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                <div  style="text-overflow: ellipsis;overflow-x: hidden !important;white-space: pre-wrap !important;"> 
                                 <img src={{editImgUrl}} height="15" width="15" alt=""> 
                                  <button mat-button style="text-overflow: ellipsis;">&nbsp;{{categories.categoryName}}</button>
                                </div>
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                            <span *ngFor="let categories of categories.categories;index as i">
                              <mat-list-item style="overflow: hidden;
                              text-overflow: ellipsis;
                              white-space: nowrap;" [ngClass]="{red: clickedItem === categories.categoryId}">
                                
                                <div> 
                                   <img src={{editImgUrl}} height="15" width="15" alt="">
                                  <button mat-button  (click)="treeClicked(categories.categoryId)">{{categories.categoryName}}</button>
                                </div>
                              </mat-list-item>
                            </span>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </span> -->
                      <mat-list-item (click)="treeClicked(categories.categoryId)" style="overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;" [ngClass]="{red: clickedItem === categories.categoryId}">
                        
                        <div> 
                          <!-- <img src={{editImgUrl}} height="15" width="15" alt=""> -->
                          <button mat-button >{{categories.categoryName}}</button>
                        </div>
                      </mat-list-item>
                    </span>
                  </mat-expansion-panel>
                </mat-accordion>
              </span>
              <span *ngIf="!item.categories || item.categories.length === 0">
                <mat-list-item class="noPadding" style="overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;" [ngClass]="{red: clickedItem === item.categoryId}">
                  <div>
                    <!-- <img src={{editImgUrl}} height="15" width="15" alt=""> -->
                    <button mat-button  style="text-overflow: ellipsis;" (click)="treeClicked(item.categoryId)">{{item.categoryName}}</button>
                  </div>
                </mat-list-item>
              </span>
            </span>
          </mat-nav-list>
      
    </div>
    </cdk-virtual-scroll-viewport>
  

    </div>
    <div style="padding-left: 0;" class="col-lg-9">
       <!--  <mat-form-field class="col-lg-3 mt-2 pl-1 ml-1" appearance="outline">
            <mat-label>Product Id</mat-label>
            <input matInput placeholder="Product ID" [(ngModel)]="productId">
        </mat-form-field>
    
        <mat-form-field class="col-lg-3 mt-2 pl-1 ml-1" appearance="outline">
            <mat-label>Sku Id</mat-label>
            <input matInput placeholder="SKU ID" [(ngModel)]="skuId">
        </mat-form-field> -->
      
        <mat-form-field class="col-lg-3 mt-2 ml-1" appearance="outline">
            <mat-label>Sku Name</mat-label>
            <input matInput placeholder="SKU Name" [(ngModel)]="skuName">
            <button mat-icon-button  matSuffix matTooltip="Get Stock Info">
              <mat-icon (click)="getStock()">search</mat-icon>
          </button>
        </mat-form-field>

        
        <mat-slide-toggle class="ml-2" [(ngModel)]="checked" [color]="'primary'" (change)=" editEnabled()">
        Edit Mode</mat-slide-toggle>

      
        
       <!--  <button mat-icon-button   matTooltip="Enable Editing" class="mt-2 ml-1">
            <mat-icon (click)="editEnabled()">edit</mat-icon>
        </button> -->
        <!-- <div style="float: right;" class="col-md-1 text-center">
            <mat-icon matTooltip="Click for a guided Tour" class="pointer" (click)="stockTour()">play_circle_outline
            </mat-icon>
            <h6 style="margin-top: 0mm;">Guided Tour</h6>
        </div> -->
        <p *ngIf="isError" style="color: red;padding-left: 20px;">{{errorMsg}}</p>
        
        
        <mat-card style="margin: 20px;" *ngIf="!showTable && !ifDownloading"><h5 style="color:#435CC8;text-align: center;margin: 150px;"> Please select a category/search to get stock </h5></mat-card>
        <mat-card style="margin: 10px;" *ngIf="showTable" class="pt-2 tabel-view" >
         
          
            <div  class="pb-1">
                
                 
              <mat-form-field appearance="outline">
                <input matInput style="text-align:center;" [(ngModel)]="filterString" (keyup)="applyFilter($event.target.value)" placeholder="Search">
                <mat-icon  matSuffix>search</mat-icon>
              </mat-form-field>
  
              <button *ngIf="enableEdit" mat-flat-button class="green" style="float: right;" [class.spinner]="ifUpdate" [disabled]="ifUpdate" (click)="updateAll()">Update Selection
              
              </button>
           
            </div>
           
            <div class="example-container mat-elevation-z8">
            <table mat-table [dataSource]="tableSource | paginate: { id: 'server', itemsPerPage: 20, currentPage: p, totalItems:totalProducts }" class="mat-elevation-z8">
  
             
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox #ref class="pt-1" [color]="'primary'" (change)="$event ? masterToggle(ref) : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()" >
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox style="margin-top: 5px;" (click)="$event.stopPropagation()" [color]="'primary'"
                  (change)="$event ? selection.toggle(row) : null;isSomeSelected()"
                    [checked]="selection.isSelected(row)" >
                  </mat-checkbox>
                </td>
  
              </ng-container>
  
  
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef [hidden]="true"> ID</th>
                <td mat-cell *matCellDef="let post" [hidden]="true"> {{post.skuId}} </td>
              </ng-container>
  
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> NAME </th>
                <td mat-cell *matCellDef="let post" style="text-align: left;padding-left:10px;"> {{post.skuName}} </td>
              </ng-container>
  
              <ng-container matColumnDef="catalog">
                <th mat-header-cell *matHeaderCellDef> CATALOG </th>
                <td mat-cell *matCellDef="let post" style="text-align: left;padding-left:10px;"> {{removeFinalComma(post.catalog)}} </td>
              </ng-container>
  
  
              <ng-container matColumnDef="cstock">
                <th mat-header-cell *matHeaderCellDef> CURRENT STOCK </th>
                <td mat-cell *matCellDef="let post">
                  {{post.stock}}
                </td>
  
              </ng-container>
  
              <ng-container matColumnDef="update">
                <th mat-header-cell *matHeaderCellDef> UPDATE (+/-)</th>
                <td mat-cell *matCellDef="let post;let index">
                  <mat-form-field class="pt-1 pb-1" appearance="outline" style="width:100px!important;" [style.fontSize.px]=12>
                    <input matInput [(ngModel)]="post.update" [disabled]="!enableEdit" onkeypress="return String.fromCharCode(event.charCode).match(/[^0-9+-.]/g) === null"></mat-form-field>
                    <!-- <p *ngIf="getNewStock(post.stock,post.update)" class="error">Invalid Stock</p> -->
                  </td>
               
              </ng-container>
  
              
  
  
              <ng-container matColumnDef="actions" class="text-center">
                <th mat-header-cell *matHeaderCellDef> ACTIONS </th>
                <td mat-cell *matCellDef="let post;let row;" (click)="$event.stopPropagation()">
  
                  <button *ngIf="enableEdit" mat-icon-button matTooltip="Save" [disabled]="ifSave" (click)="saveStock(post)">
                    <mat-icon style="color:#435cc8;">check</mat-icon>
                  </button>
                  <button *ngIf="ifRefreshId !==post.skuId" mat-icon-button  (click)="refreshRow(post)" [disabled]="ifRefresh" matTooltip="Refresh">
                    <mat-icon style="color:#435cc8;">refresh</mat-icon>
                   
                  </button>
                  <button *ngIf="ifRefreshId ==post.skuId" mat-icon-button  (click)="refreshRow(post)" [disabled]="ifRefresh" matTooltip="Refresh">
                    <mat-icon [class.spinner]="ifRefresh" style="color:#435cc8;">loader</mat-icon>
                  </button>
                                   
                
  
              </ng-container>
  
  
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns ;"></tr>
              <div *matNoDataRow> <span style="color: red;">No Records found</span></div>
            </table>
          </div>
  
            <mat-paginator showFirstLastButtons  [length]="totalProducts"
            [pageSize]="20" 
            showFirstLastButtons  
            (page)="pageChanged($event)"></mat-paginator>
  
            <mat-card-footer> 
                <div *ngIf="totalProducts > 20 && enableEdit">
                <span style="float: right;color:red;padding-right: 12px;">Unsaved data will be lost if refreshed or moved to other pages.</span><br>
              </div>
              <!-- <p style="text-align:right;padding-right:10px;">{{now}}</p> -->
            </mat-card-footer>
          </mat-card>
  
          
  <!-- <div *ngIf="ifDownloading" class="site-preloader site-preloader::before .site-preloader__fade" >

  </div> -->
          
           <div class="center">
              <mat-spinner *ngIf="ifDownloading" diameter="100" strokeWidth="5" style="margin:0 auto;"></mat-spinner>
            </div>
   
    
    </div>

    </div>
</div>