import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BuyersService } from '../buyers.service';
import { Buyer } from '../models/buyer.model';

@Component({
  selector: 'app-buyer-form-page',
  templateUrl: './buyer-form-page.component.html',
  styleUrls: ['./buyer-form-page.component.css']
})
export class BuyerFormPageComponent implements OnInit {

  buyerForm: FormGroup;
  obj: Buyer
  timeZoneList: any;
  countryList: any;
  buyerGroups: any;
  errorMessage;
  minPhoneDigits: number;
  maxPhoneDigits: number;

  constructor(private BuyerService: BuyersService, private toastr: ToastrService, public fb: FormBuilder, private router: Router) { }

  ngOnInit(): void {
    this.BuyerService.timeZoneList().subscribe(
      res => {
        console.log(res);
        this.timeZoneList = res.timeZone;
      }
    );
    this.BuyerService.tenantCountryList().subscribe(
      res => {
        console.log(res);
        this.countryList = res.country;
      }
    );
    this.BuyerService.getListOfBuyergroups().subscribe(
      res => {
        this.buyerGroups = res.groups;
      }
    );
    this.buyerForm = this.fb.group({
      name: ['' || '', Validators.required],
      phone: ['', Validators.compose([Validators.required])],
      email: ['', {
        validators: [

          Validators.email
        ],
        updateOn: 'blur'
      }],
      addressLine1: ['' || '', Validators.required],
      city: ['' || '', Validators.required],
      state: ['' || ''],
      countryId: ['' || '', Validators.required],
      pin: ['' || ''],
      latitude: ['' || ''],
      longitude: ['' || ''],
      taxNumber: ['' || '',],
      timezoneId: ['' || '', Validators.required],
      groupId: ['' || '', Validators.required],
      buyerAsAppUser: [false],
    });
  }

  onCountrySelect(country: any) {
    this.minPhoneDigits = country.minPhoneDigits;
    this.maxPhoneDigits = country.maxPhoneDigits;
    this.updatePhoneValidators();
  }

  updatePhoneValidators() {
    const phoneControl = this.buyerForm.get('phone');

    phoneControl.setValidators([
      Validators.required,
      Validators.pattern(`[0-9]{${this.minPhoneDigits},${this.maxPhoneDigits}}`)
    ]);

    phoneControl.updateValueAndValidity();
  }

  submitBuyerForm() {
    if (!this.buyerForm.valid) {
      console.log("Not Valid Form")
      this.toastr.error("Please check if you have entered all the required  * fields", "Error")
    }
    else {
      console.log(this.buyerForm.value);
      if (this.buyerForm.value.groupId) {
        this.buyerForm.value.groupId = this.buyerForm.value.groupId.toString();
      }
      this.BuyerService.addBuyer(this.buyerForm.value).subscribe(
        (res: any) => {
          if (res.acknowledgement.status === "SUCCESS") {
            this.toastr.success("Buyer added Successfully", "SUCCESS")
            this.router.navigate(['buyers'])


          }
          else {
            this.errorMessage = res.acknowledgement.message
            this.toastr.error(this.errorMessage, "ERROR")
          }
        },
        (err: HttpErrorResponse) => {
          if (err) {
            this.errorMessage = err.error.message
            this.toastr.error(this.errorMessage, "ERROR")
          }
          else {
            this.toastr.error("Sorry, the buyer could not be added", "ERROR")
          }
        }
      )


    }
  }

  close() {
    this.router.navigate(['buyers'])

  }

}
