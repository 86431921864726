import { Component, OnInit,Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { FormGroup,FormBuilder,FormArray} from '@angular/forms';

@Component({
  selector: 'app-excel-mapping',
  templateUrl: './excel-mapping.component.html',
  styleUrls: ['./excel-mapping.component.css']
})

export class ExcelMappingComponent implements OnInit {

  mappingForm: FormGroup;
  headers :string[]=[];
  buyers : string[]=[];
  buyerFields: string[] = ["addressLine1","buyerAsAppUser","city","country","email","groupName","name","state","phone",
  "pin","rowNumber","timezone","taxNumber"];
  buyerFields1: string[] = ["addressLine1","buyerAsAppUser","city","country","email","groupName","name","state","phone",
  "pin","rowNumber","timezone","taxNumber"];
  
  selectedFields:string[]=[];
  mappedFields:string[]=[];

  constructor(public fb: FormBuilder ,private dialogRef: MatDialogRef<ExcelMappingComponent>,
   
    @Inject(MAT_DIALOG_DATA) data) {
      this.headers =[];
      this.headers = data.excel;
      this.buyers =data.buyers;

  }

  ngOnInit(): void {
    if(this.headers.length <6)
    this.dialogRef.updateSize('750px','200px');
    else{


    }    

    this.mappingForm = this.fb.group({
      tickets: this.fb.array([
        this.fb.control('')
      ])
    });
    for ( let i=0; i<(this.headers.length-1);i++){
      this.tickets.push(this.fb.control(''));
     
  }
}

  get tickets()
   {
     return this.mappingForm.get('tickets') as FormArray;
     }

  save() {
    if (!this.mappingForm.valid) {
      this.dialogRef.close();
    }
    else{
    console.log(this.mappingForm.value);
    console.log(this.tickets.value);
    if(this.tickets.length != 0){
    for (let i=0;i<this.tickets.length;i++){
      console.log(String(this.tickets.value[i]));
       this.selectedFields.push((this.tickets.value[i]));
       
    }
   
    
    this.dialogRef.close(this.selectedFields);
  }
  else{
    this.headers =[];
    this.dialogRef.close();
  }
    
}
  }

close() {
  console.log(this.mappingForm.value);
  this.dialogRef.close();
}


}