import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { ActivatedRoute, Router,NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-sales-men',
  templateUrl: './sales-men.component.html',
  styleUrls: ['./sales-men.component.css']
})
export class SalesMenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  addSalesMan()
  {
    console.log("SalesMan Add Function")
  }
}
