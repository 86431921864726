<div class="app sidebar-mini pace-done " [ngClass]= "{'sidenav-toggled':MenuFlag,'': !MenuFlag}">
    <!-- Navbar-->
    <header class="app-header" style="background-color: #1B50A2;">
      <!-- Sidebar toggle button--><div class="app-sidebar__toggle" href="#"  aria-label="Hide Sidebar" (click)="toggleclass()"></div>
      <img src="../assets/icon/sm-logo.png"  alt="" style="height: 45px;" class="pt-1 pl-1"> 
      
      <!-- Navbar Right Menu-->
      <ul class="app-nav">
        <li class="dropdown" data-toggle="tooltip" title="Refresh dashboard" data-original-title="Refresh dashboard" [ngClass]= "{'show':logoutFlaf,'': !logoutFlaf}"><div class="app-nav__item"><mat-icon (click)="reLoad()">refresh</mat-icon> </div>
          <!-- <ul class="dropdown-menu settings-menu dropdown-menu-right" [ngClass]= "{'show':logoutFlaf,'': !logoutFlaf}"> -->
            <!-- <li><a class="dropdown-item" href="" (click)="logout()"><i class="fa fa-shopping-cart "></i>Logout</a></li> -->
            
          <!-- </ul> -->
        </li>

         <li class="dropdown" data-toggle="tooltip" title="Catalog Manager" data-original-title="storimart Admin" [ngClass]= "{'show':logoutFlaf,'': !logoutFlaf}"><a class="app-nav__item" href={{catalogURL}} target="_blank"><mat-icon>launch</mat-icon> </a>
          <!-- <ul class="dropdown-menu settings-menu dropdown-menu-right" [ngClass]= "{'show':logoutFlaf,'': !logoutFlaf}"> -->
            <!-- <li><a class="dropdown-item" href="" (click)="logout()"><i class="fa fa-shopping-cart "></i>Logout</a></li> -->
            
          <!-- </ul> -->
        </li>
        <li class="dropdown"  data-toggle="tooltip" title="Order Manager" data-original-title="stori Order Fulfillment"  [ngClass]= "{'show':logoutFlaf,'': !logoutFlaf}"><a class="app-nav__item" href={{ofsURL}} target="_blank"><mat-icon>shopping_cart</mat-icon></a>
          <!-- <ul class="dropdown-menu settings-menu dropdown-menu-right" [ngClass]= "{'show':logoutFlaf,'': !logoutFlaf}"> -->
            <!-- <li><a class="dropdown-item" href="" (click)="logout()"><i class="fa fa-shopping-cart "></i>Logout</a></li> -->
            
          <!-- </ul> -->
        </li>
        <li class="dropdown" data-toggle="tooltip" title="Alerts" data-original-title="Alerts"  [ngClass]= "{'show':alertFlag,'': !alertFlag}" ><div class="app-nav__item" href=""  aria-label="Show notifications" >
        
          <mat-icon matBadge={{notAcceeptedCount}} matBadgeColor="warn" [matBadgeHidden]="hideMatBadge" (click)="toggleAlert()">warning</mat-icon></div>
          <ul class="app-notification dropdown-menu dropdown-menu-right " [ngClass]= "{'show':alertFlag,'': !alertFlag}">
            

            <li class="app-notification__title" style="background-color: rgb(255, 255, 255);">New Alerts</li>
            <div class="app-notification__content">
              <li><a class="app-notification__item" href="javascript:;"><span class="app-notification__icon">

              </span>
                  <div>
                    <p class="app-notification__message text-muted"  data-toggle="modal" data-target="#exampleModal" (click)="toggleShowModel(alertAccountId,notAccepted)">
                      {{notAcceeptedCount}} Orders not accepted after 15 minutes of submission</p>
                    
                  </div></a>
                </li>
                <li><a class="app-notification__item" href="javascript:;"><span class="app-notification__icon"></span>
                  <div>
                    <p class="app-notification__message text-muted"  data-toggle="modal" data-target="#exampleModal" (click)="toggleShowModel(alertAccountId,notDelivered)">
                      {{notDeliveredCount}} Orders not delivered after 2 hour of preferred delivery time</p>
                  </div></a>
                </li>
            </div>
            <!-- <li class="app-notification__footer"><a href="#">See all notifications.</a></li> -->
          </ul>
        </li>


        <li class="dropdown" data-toggle="tooltip" title="Notifications" data-original-title="Notifications"  [ngClass]= "{'show':NotFlag,'': !NotFlag}" ><div class="app-nav__item" href=""  aria-label="Show notifications">
          <mat-icon matBadge={{countNots}} matBadgeColor="warn" [matBadgeHidden]="hideMatBadgen" (click)="togglenotification()">notification_important</mat-icon></div>
          <ul class="app-notification dropdown-menu dropdown-menu-right " 
          [ngClass]= "{'show':NotFlag,'': !NotFlag}">
            

            <li class="app-notification__title" style="background-color: rgb(255, 255, 255);">Notifications</li>
            <div class="app-notification__content">
              <li><a *ngFor="let x of notsData"  class="app-notification__item" href="javascript:;"><span class="app-notification__icon"></span>
                  <div>
                    <p class="app-notification__message text-muted">{{x.data2}}</p>
                    <p class="app-notification__meta">{{x.data3 | dateAgo }}</p>
                  </div></a>
                </li>
            </div>
           
          </ul>
        </li>
        <!-- User Menu-->
        <li class="dropdown" data-toggle="tooltip" title="Log Out" data-original-title="Log Out" [ngClass]= "{'show':logoutFlaf,'': !logoutFlaf}"><a class="app-nav__item" href="#" data-toggle="dropdown" aria-label="Open Profile Menu"><mat-icon (click)="toggleLogout()">account_circle</mat-icon></a>
          <ul class="dropdown-menu settings-menu dropdown-menu-right" [ngClass]= "{'show':logoutFlaf,'': !logoutFlaf}">
            <li><a class="dropdown-item" href="" (click)="logout()"><i class="fa fa-cog fa-lg"></i>Logout</a></li>
            
          </ul>
        </li>
      </ul>
    </header>
    <!-- Sidebar menu-->
    <div class="app-sidebar__overlay" data-toggle="sidebar"></div>
    <aside class="app-sidebar">
      <div class="app-sidebar__user">
 <img class="app-sidebar__user-avatar" src="../../assets/icon/warehouse_logo.jpg" width="80" alt="User Image"> 
        <div>
          <p class="app-sidebar__user-name">Welcome</p>
          <p class="app-sidebar__user-designation">{{businessName}}</p>
         
        </div>
      </div>
      <ul class="app-menu">
        <li><a class="app-menu__item"   [routerLink]="['/dashboard']"><i class="app-menu__icon fa fa-dashboard"></i><span class="app-menu__label" >Dashboard</span></a></li>
        <li><a class="app-menu__item"  [routerLink]="['/report']" ><i class="app-menu__icon fa fa-file-o"></i><span class="app-menu__label"  >Report</span></a></li>
        <li><a class="app-menu__item"   [routerLink]="['/subscription']" ><i class="app-menu__icon fa  fa-bookmark-o"></i><span class="app-menu__label"  >Subscriptions</span></a></li>
        <li><a class="app-menu__item"   [routerLink]="['/controlPanel']" ><i class="app-menu__icon fa fa-address-book-o"></i><span class="app-menu__label"  >Control Panel</span></a></li>
        <li><a class="app-menu__item"   [routerLink]="['/buyers']" ><i class="app-menu__icon fa fa-user-o"></i><span class="app-menu__label"  >Buyers</span></a></li>
      </ul>



    <a class="app-menu__item" href={{catalogURL}} title="Will open in a new tab when clicked" target="_blank"><mat-icon>launch</mat-icon><span class="app-menu__label ml-2">Catalog Manager</span></a>
    <a class="app-menu__item" href={{ofsURL}} title="Will open in a new tab when clicked" target="_blank"><mat-icon>shopping_cart</mat-icon><span class="app-menu__label ml-2">Order Manager</span></a>
  
    </aside>
    <main class="app-content">
     
      <router-outlet></router-outlet> 
     
    </main>

</div>


<div class="modal fade bd-example-modal-xl" id="exampleModal" [ngClass]= "{'show':showModel,'': !showModel}" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content" >
      <div class="modal-header">
        <h5 class="modal-title alert alert-danger" id="exampleModalLabel">{{alertCount}} {{alertMessage}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- <p class="alert-danger">{{}}</p>  -->
        <div class="example-container">
          <!-- <h2>Striped Rows</h2> -->
        
            
          <table mat-table [dataSource]="alertDetailsData" >
          
            <!-- Position Column -->
            <ng-container matColumnDef="orderId">
              <th mat-header-cell *matHeaderCellDef> Order Id </th>
              <td mat-cell *matCellDef="let element"> {{element.header.orderId}} </td>
            </ng-container>
          
            <!-- Name Column -->
            <ng-container matColumnDef="customerBusinuss">
              <th mat-header-cell *matHeaderCellDef> Customer Businuss </th>
              <td mat-cell *matCellDef="let element"> {{element.header.retailerShop.retailerShopName}}</td>
            </ng-container>
          
            <!-- Weight Column -->
            <ng-container matColumnDef="phone">
              <th mat-header-cell *matHeaderCellDef> Phone </th>
              <td mat-cell *matCellDef="let element"> {{element.header.retailerShop.phone}} </td>
            </ng-container>
          
            <!-- Symbol Column -->
            <ng-container matColumnDef="customerUser">
              <th mat-header-cell *matHeaderCellDef> Customer User </th>
              <td mat-cell *matCellDef="let element"> {{element.header.user.userName}} </td>
            </ng-container>

            <ng-container matColumnDef="submittedTime">
              <th mat-header-cell *matHeaderCellDef> Submitted Time </th>
              <td mat-cell *matCellDef="let element"> {{element.header.orderedDate}} </td>
            </ng-container>
          
            <!-- Name Column -->
            <ng-container matColumnDef="expectedDeliveryTime">
              <th mat-header-cell *matHeaderCellDef> Expected Delivery Time </th>
              <td mat-cell *matCellDef="let element"> {{element.header.prefDeliveryTime}} </td>
            </ng-container>
          
            <!-- Weight Column -->
            <ng-container matColumnDef="lastUpdateTime">
              <th mat-header-cell *matHeaderCellDef> Last Update Time </th>
              <td mat-cell *matCellDef="let element"> {{element.header.lastUpdatedTime}} </td>
            </ng-container>
          
            <!-- Symbol Column -->
            <ng-container matColumnDef="totalCost">
              <th mat-header-cell *matHeaderCellDef> Total Cost </th>
              <td mat-cell *matCellDef="let element"> {{element.header.totalAmount.currency}} {{element.header.totalAmount.value}} </td>
            </ng-container>

            <ng-container matColumnDef="paymentMethod">
              <th mat-header-cell *matHeaderCellDef> Payment Method </th>
              <td mat-cell *matCellDef="let element"> {{element.paymentMethod}} </td>
            </ng-container>
          
            <!-- Symbol Column -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> Status </th>
              <td mat-cell *matCellDef="let element"> {{element.header.status}} </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          </div>
          <mat-paginator [pageSizeOptions]="[7]" showFirstLastButtons></mat-paginator>
        
        <div class="row">
        </div>
      </div>
    </div>
  </div>
</div>

