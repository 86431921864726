import { HttpHandler, HttpRequest, HttpEvent, HttpInterceptor,HttpResponseBase } from "@angular/common/http";
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable()
export class AppInterceptors implements HttpInterceptor
{
  // constructor()
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        req=req.clone({
            setHeaders:{
         'Authorization':localStorage.getItem("AuthorizationToken")
                //    'Authorization':'Basic ZW1hZC5hc2hlckBzdG9yaWxhYnMuY29tOm9rYXkxMjM0'
                //  'Authorization':'Basic a2FzaW0ubWFuc29vckBzdG9yaWxhYnMuY29tOmthc2ltMTIzNA=='
                
            }
        })
        return next.handle(req);
        // throw new Error("Method not implemented.");
    }
    
}

