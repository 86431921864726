import { authService } from './../Service/authService';
import { SetPassword } from './models/SetPassword.model'
import {MyVersion} from 'src/my-version';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { from } from 'rxjs';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-setpassword',
  templateUrl: './setpassword.component.html',
  styleUrls: ['./setpassword.component.css']
})
export class SetpasswordComponent implements OnInit {
  
  curDate;
  ver:number;
  hide = true;
  rehide=true;
  setPasswordForm: FormGroup;
  token: string;
 setPassword:SetPassword;
  error_messages = {
    'password': [
      { type: 'required', message: 'password is required.' },
      { type: 'minlength', message: 'Minimum password length is 8.' },
      { type: 'maxlength', message: 'Maximum password length is 16.' }
    ],
    'confirmpassword': [
      { type: 'required', message: 'Confirm password is required.' },
      { type: 'minlength', message: 'Minimum password length is 8.' },
      { type: 'maxlength', message: 'Maximum password length is 16.' }
    ],
  }
  returnUrl: any;
  errorMessage: any;
  resetInvalid: boolean;
  isSubmittedSuccess: boolean;
  loading:boolean =false;
  successMessage: any;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private authService:authService,
    private toastr : ToastrService,
    
    
  ) {
    this.setPasswordForm = this.fb.group({            
      password: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(16)
      ])),
      confirmpassword: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(16)
      ])),
    },
    { 
      validators: [this.passwordCheck.bind(this)], 
      updateOn: "blur" 
    });
   }

  ngOnInit(): void {

    this.token = this.route.snapshot.params.authtoken;
    if(this.token)
    localStorage.setItem("authenticateuserToken",this.token)
}

onSubmit()
{     
  this.loading =true;
if(this.setPasswordForm.valid)
{
  try {
    var obj: SetPassword={
      "password":this.setPasswordForm.get('password').value,
      "confirmpassword":this.setPasswordForm.get('confirmpassword').value
            }
            this.authService.passwordSet(obj)
            .subscribe((passwordSetResponse)=>{
              if(passwordSetResponse){
                
              
              if(passwordSetResponse.acknowledgement.status==="SUCCESS")
              {
              this.isSubmittedSuccess=true
              this.successMessage=passwordSetResponse.acknowledgement.message
                // this.router.navigate([this.returnUrl]);
                this.resetInvalid = false
                this.loading=false;
                this.toastr.success("",this.successMessage)
              }
              else
              {
                this.isSubmittedSuccess=false
                this.resetInvalid = true
                this.loading=false;
                this.errorMessage=passwordSetResponse.acknowledgement.message
                this.toastr.error("",this.errorMessage);
              }
              
            }
           }, (err:HttpErrorResponse) => {
            if(err)
            {
              if(err.error)
              {
              this.errorMessage=err.error.message
              }
              else
              {
              this.errorMessage="Sorry, Please try again later."
              }
              this.resetInvalid = true
              this.loading = false;
              this.toastr.error("",this.errorMessage);
              // this.isSubmittedSuccess=false
            }
           
         });
            
      }
 catch{
            // this.loginInvalid = true;
           
          }
}


}

passwordCheck(formGroup: FormGroup) {
  const { value: password } = formGroup.get('password');
  const { value: confirmPassword } = formGroup.get('confirmpassword');
  return ((formGroup.get('password').dirty || formGroup.get('password').touched) && 
          (formGroup.get('confirmpassword').dirty || formGroup.get('confirmpassword').touched) && 
           password !== confirmPassword)? { notSame: true } : null;
}

  
}
