
<div class="authentication-bg" >

    <div class="auth-fluid">
        <!--Auth fluid left content -->
        <div class="auth-fluid-right"  style="background-color:whitesmoke; padding: 0 !important;">
          <span ><img src="../assets/icon/graphics2.png"></span> 
            
        </div>
        <div class="auth-fluid-form-box">
            <div class="align-items-center d-flex h-100">
                <div class="card-body" (keyup.enter)="onSubmit()">

                    <!-- Logo -->
                    <div class="auth-brand text-center text-lg-left">
                        <a href="" class="logo-dark">
                            <span><img src="../assets/icon/sm-logo_new.png" alt="" height="80"></span>
                        </a>
                       
                    </div>

                    <!-- title-->
                     <h4 class="mt-0 mb-3">Login</h4>
                    <!-- <p class="text-muted mb-4">Enter your email address and password to access account.</p> --> 
                   

                    <!-- form -->
                    
                          <form [formGroup]="form" >
                            <!-- <h2>Log In</h2> -->
                          
                            
                                <mat-form-field appearance="outline">
                                  <mat-label>Email</mat-label>
                                  <input matInput placeholder="Enter Email"
                                  formControlName="username"
                                  required>
                                  <mat-icon matSuffix>email</mat-icon>
                                  <!-- <mat-hint>Hint</mat-hint> -->
                                  <mat-error>
                                    Please provide a valid email address
                                  </mat-error>
                                </mat-form-field>
                              
                              <mat-form-field appearance="outline">
                                <mat-label>Password</mat-label>
                                <input matInput [type]="hide ? 'password' : 'text'"
                                placeholder="Enter Password"
                                formControlName="password" 
                                required>
                                <button mat-icon-button matSuffix (click)="hide = !hide">
                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <mat-error>
                                    Please provide a valid password
                                </mat-error>
                              </mat-form-field>
                              <!-- <div class="g-recaptcha" data-sitekey="6LfaVOoUAAAAAIxT0Q38vMwPzxhs4khguWfFgzZN"></div> -->
                              <!-- <div class="g-recaptcha" id="rcaptcha" style="margin-left: 90px;" data-sitekey="6LcIVuoUAAAAAATnp4fG8X4f8LTvLv5CpMWdLtty"></div> -->
                              <!-- <re-captcha (resolved)="resolved($event)" siteKey="6LcIVuoUAAAAAATnp4fG8X4f8LTvLv5CpMWdLtty"></re-captcha> -->
                              <!-- <div class="g-recaptcha" 
                              data-sitekey="6LcIVuoUAAAAAATnp4fG8X4f8LTvLv5CpMWdLtty" 
                              ></div> -->
                              <ngx-recaptcha2 #captchaElem
                              [siteKey]="siteKey"
                              (success)="handleSuccess($event)"
                              formControlName="recaptcha" required
                              >
                            </ngx-recaptcha2>

                            <div class="alert alert-danger" role="alert" *ngIf="isRobort">
                              <strong> Please verify </strong>  you are not a robot.
                          </div>
                             
                              <button mat-raised-button cdkFocusInitial type ="submit" color="primary" [class.spinner]="loading" [disabled]="loading" [disabled]="!form.valid" (keyup.enter)="onSubmit()" (click)="onSubmit()" class="mt-2">Login</button>
                          </form>
                    <a [routerLink]="['/resetpassword']"><p class="mt-4">Forgot Password ?</p></a> 
                    <p class="text-muted">Don't have an account? <a [routerLink]="['/signup']" class="text-muted ml-1"><b>Register</b></a></p>
                
                    <!-- Footer-->
                    <footer class="footer footer-alt">
                     
                                                            
                          
                        
                            
                          <a [href]="youtubeUrl" target="_blank">
                                <img src="assets/icon/youtubeIcon.png" alt="" matTooltip="Youtube"
                                 matTooltipPosition="above">
                            </a>
                           
                            <a [href]="facebookUrl" target="_blank">
                                <img src="assets/icon/facebookIcon.png" alt="" matTooltip="Facebook" matTooltipPosition="above">
                            </a>
                           
                            <a [href]="linkedinUrl" target="_blank">
                                <img src="assets/icon/linkedinIcon.png" alt="" matTooltip="LinkedIn" matTooltipPosition="above">
                            </a>
                            
                          
                        
                  
                    </footer>

                </div> <!-- end .card-body -->
            </div> <!-- end .align-items-center.d-flex.h-100-->
        </div>
        <!-- end auth-fluid-form-box-->

        <!-- Auth fluid right content -->
       
        <!-- end Auth fluid right content -->
    </div>
    <!-- end auth-fluid-->

    <!-- bundle -->
  
  </div>
