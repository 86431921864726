import { Component, OnInit,Inject } from '@angular/core';
import { FormGroup,FormBuilder ,Validators} from '@angular/forms';
import {BuyersService} from './../buyers.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-app-user-settings',
  templateUrl: './app-user-settings.component.html',
  styleUrls: ['./app-user-settings.component.css']
})
export class AppUserSettingsComponent implements OnInit {

  appUserSettings:FormGroup;
  countryList:any;
  element:any;

  constructor(public dialogRef: MatDialogRef<AppUserSettingsComponent>,public fb: FormBuilder,
    private BuyersService:BuyersService, @Inject(MAT_DIALOG_DATA) data) { 
      this.element = data; 
     }

  ngOnInit(): void {

    console.log(this.element);

    this.BuyersService.tenantCountryList().subscribe(
      res => {
        this.countryList = res.country;
      }
    );

    this.appUserSettings = this.fb.group({
      userId:[''||''],
      name: ['' || '', Validators.required],
      countryId:['',''],
      phone: ['' || '',Validators.compose([ Validators.required,
        Validators.pattern("[0-9]{10}")])],
        email: [''||'', {
          validators: [
              Validators.email
          ],
          updateOn: 'blur'
       }],

  } );
  this.appUserSettings.setValue(
    {
      userId:this.element.id.toString(),
      name: this.element.name,
      countryId:this.element.countryCode,
      phone: this.element.phone,
      email:this.element.email
    });

}

save(){
  
  
  this.dialogRef.close(this.appUserSettings.value); 
    
}

close() {
  console.log(this.appUserSettings.value);
  this.dialogRef.close();  
}
}


