<div class="example-container">
    <div class="constraints">

        <div class="row">

            <div class="product-count col">
                <h4>Product Count for Excel Conversion</h4>
                &nbsp;&nbsp;
                <div class="blue-circle">
                    <span class="product-number">{{ productNumber }}</span>
                </div>
            </div>

            <div class="col text-right">
                <button class="mat-raised-button mat-primary" (click)="generateAndDownloadExcel()"
                    [disabled]="downloadingExcel">
                    <span *ngIf="downloadingExcel" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                    <span *ngIf="!downloadingExcel">Generate & Download Excel</span>
                </button>
            </div>
        </div>

        <div [formGroup]="buyerForm" (submit)="submitProductForm()">

            <div class="row">
                <div class="col">

                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Product Name</mat-label>
                        <input formControlName="productName" matInput placeholder="Product Short Name" required>

                        <mat-error *ngIf="buyerForm.get('productName').errors?.maxlength">
                            Maximum limit exceeded (maximum length: 128)</mat-error>
                    </mat-form-field>
                </div>
                <div class="col">

                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Category</mat-label>
                        <input formControlName="categoryName" matInput placeholder="Category" required>
                        <mat-error
                            *ngIf="buyerForm.controls['category']?.errors?.required && buyerForm.controls['category'].touched">
                            This field is required</mat-error>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Sub-Category</mat-label>
                        <input formControlName="subcategoryName" matInput placeholder="Sub-Category" required>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">

                <div class="col">
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Product Code/UPC</mat-label>
                        <input formControlName="productCode" matInput placeholder="Product Code/UPC" required>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Tax %</mat-label>
                        <input type="number" formControlName="tax" matInput placeholder="Tax %" required>

                    </mat-form-field>

                </div>
                <div class="col">

                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Unit Of Measure</mat-label>
                        <mat-select formControlName="unitOfMeasure" placeholder="Unit of Measure" required>

                            <mat-option *ngFor="let uom of uomList" [value]="uom.uomShort">
                                {{ uom.uomName }} ({{ uom.uomShort }})
                            </mat-option>

                        </mat-select>
                        <mat-error
                            *ngIf="buyerForm.controls['unitOfMeasure']?.errors?.required && buyerForm.controls['unitOfMeasure'].touched">
                            This field is required</mat-error>
                    </mat-form-field>

                </div>

            </div>
            <div class="row">

                <div class="col">
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Price</mat-label>
                        <input type="number" formControlName="price" matInput placeholder="Price" required>
                    </mat-form-field>
                </div>

                <div class="col" tourAnchor="cptour.istep1.id">
                    <input #myInput type="file" accept=".jpg, .jpeg, .png" class="upload-box"
                        (change)="onFileChanged($event)" formControlName="image">

                    <p style="color: red;">{{ imageError }}</p>
                    <div>
                        <button class="mat-raised-button mat-primary" [class.spinner]="uploadingImage"
                            [disabled]="uploadingImage || continue" (click)="uploadImg()">
                            <span *ngIf="uploadingImage" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span>
                            <span *ngIf="!uploadingImage">Upload Image</span>
                        </button>

                    </div>
                </div>

                <div class="col">
                    <mat-slide-toggle class="example-margin" formControlName="taxIncluded">Tax
                        Included</mat-slide-toggle>
                </div>

            </div>

            <br><br>
            <div class="info-message" *ngIf="productNumber >= maxProductLimit">
                <mat-icon class="info-icon">info</mat-icon>
                <span>Maximum {{ maxProductLimit }} product limit per session.Submit a new form for more.</span>
            </div>

            <div align="start" *ngIf="productNumber != maxProductLimit || editingIndex !== null">
                <!--
                                        <button class="mat-raised-button"(click)="close()">Close</button>
                                        &nbsp;&nbsp;&nbsp;
                                        -->

                <button class="mat-raised-button mat-primary custom-button" (click)="submitProductForm()"
                    [disabled]="productNumber > maxProductLimit">Add Product</button>
            </div>
        </div>



        <div class="search-box row" *ngIf="originalData.length>0 || productQueue.length>0">


            <div class="search-box-area">
                <input type="text" placeholder="Search" [(ngModel)]="searchTerm" (input)="searchData()">
                <button mat-icon-button class="search-icon" title="Cancel" (click)="clearSearch()"
                *ngIf="searchTerm && searchTerm.length > 0">
                    <mat-icon>cancel</mat-icon>
                </button>
            </div>
            <div>
                <button mat-icon-button (click)="refreshData()" class="example-icon" style="color: rgb(63 81 181);">
                    <mat-icon>refresh</mat-icon>
                </button>
            </div>

            <div>

                <button mat-icon-button (click)="deleteSelected()" *ngIf="hasSelectedProducts()" class="example-icon"
                    style="color: rgb(63 81 181);">
                    <mat-icon>delete</mat-icon>
                </button>


            </div>


        </div>

        <br>
        <div class="row">

            <div class="col">

                <table class="rwd-table" *ngIf="productQueue.length>0" matSort (matSortChange)="sortData($event)">
                    <tbody>

                        <tr>

                            <th class="text-left custom-checkbox"><mat-checkbox (change)="selectAll($event.checked)"
                                    [color]="color"></mat-checkbox></th>
                            <th class="text-left">SN</th>
                            <th class="text-left" mat-sort-header="productName">Product Name</th>
                            <th class="text-left" mat-sort-header="category">Category</th>
                            <th class="text-left" mat-sort-header="subCategory">Sub-Category</th>
                            <th class="text-left" mat-sort-header="productCode">Product Code</th>
                            <th class="text-left" mat-sort-header="tax">Tax %</th>
                            <th class="text-left">UOM</th>
                            <th class="text-left" mat-sort-header="price">Price</th>
                            <th class="text-left" mat-sort-header="taxIncluded">Tax Included</th>
                            <th class="text-left">Actions</th>

                        </tr>
                        <tr *ngFor="let product of productQueue; let i = index">
                            <td class="text-left">
                                <mat-checkbox [color]="color" (change)="toggleSelectProduct($event.checked, product)"
                                    [(ngModel)]="product.selected"></mat-checkbox>
                            </td>
                            <td class="text-left">{{i+1}}</td>
                            <td class="text-left">{{product.productName}}</td>
                            <td class="text-left">{{product.categoryName}}</td>
                            <td class="text-left">{{product.subcategoryName}}</td>
                            <td class="text-left">{{product.productCode}}</td>
                            <td class="text-left">{{product.tax}}</td>
                            <td class="text-left">{{product.unitOfMeasure}}</td>
                            <td class="text-left">{{product.price}}</td>
                            <td class="text-left">{{product.taxIncluded}}</td>
                            <td class="text-left">

                                <mat-icon class="remove-icon" style="color: red;"
                                    (click)="confirmDelete(i)">clear</mat-icon> &nbsp;
                                <mat-icon class="edit-icon"
                                    style="font-size: large; vertical-align: middle; margin-bottom: 10px;"
                                    (click)="editProduct(i)"
                                    [class.disabled]="editingIndex !== null && editingIndex !== i">edit</mat-icon>

                            </td>
                        </tr>

                    </tbody>
                </table>

            </div>

        </div>

    </div>