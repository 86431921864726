import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ControlPanelService } from '../control-panel.service';

import { BillingInfoSettings } from '../models/BillingInfo.model';
@Component({
  selector: 'app-billing-info',
  templateUrl: './billing-info.component.html',
  styleUrls: ['./billing-info.component.css']
})
export class BillingInfoComponent implements OnInit {
  public billingInfoForm: FormGroup;
  generalSettingsDetails: any;
  // id =0;

  billlingInfoSettingsDetails: any;
  countryList: any;
  constructor(
    private fb: FormBuilder,
    private controlPanelService: ControlPanelService,
  ) { }

  ngOnInit(): void {
  this.buildItemForm()  
  this.getDetails()
  }
  getDetails()
{
  this.controlPanelService.getwarehouseSettings().subscribe(
    res => {
      this.billlingInfoSettingsDetails = res.body;
      console.log("look",res)
    }
    );
    this.controlPanelService.tenantCountryList().subscribe(
      res => {
        this.countryList = res.country;
      }
      );
}  



  buildItemForm() {

  this.billingInfoForm = this.fb.group({
    contactName: ['' || '', Validators.required],
    companyName: ['' || '', Validators.required],
    address: ['' || '', Validators.required],
    city: ['' || '', Validators.required],
    state: ['' || ''],
    country: ['' || '', Validators.required],
    zipCode: ['' || '', Validators.required],
  });
  }
  Reset(){
    this.billingInfoForm.reset();
  }
  Submiit(){
    var obj: BillingInfoSettings = this.billingInfoForm.value
    console.log(obj)
    this.controlPanelService.savewarehouseSettings(obj).subscribe(
      (res: any) => {
        console.log(res)
      }
    );

  }
}
