import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { authService } from '../../../Service/authService'

export interface DialogData {
  title: string;
  btn1:string;
  btn2:string;
}

@Component({
  selector: 'app-confirmdelete',
  templateUrl: './confirmdelete.component.html',
  styleUrls: ['./confirmdelete.component.css']
})
export class ConfirmdeleteComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmdeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,private authService:authService,) { }

  ngOnInit(): void {
  }

  onConfirm(): void {
    
    this.dialogRef.close(true);
  }

onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
}
}
