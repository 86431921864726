import {Component, Inject,OnInit} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';

export interface DialogData {
failedCount:number;
mandatoryIdMissingCount:number;
failureDetails: string;
updatedCount :number;
requestedUpdateCount :number;
negativeStockRequestCount :number;
noDbSkuCount :number;
invalidStockCount:number;
invalidStockMessage:string;
}


@Component({
  selector: 'app-show-report',
  templateUrl: './show-report.component.html',
  styleUrls: ['./show-report.component.css']
})
export class ShowReportComponent implements OnInit {
  
  reqStock: string;
  updatedStock: string;
  failDetails: string;
  negStock: string;
  noStock: string;
  failCt: string;
  mandMsg: string;
  failedStock: string;
  invalidMsg :string;
  msg: string;
  

  constructor(public dialogRef: MatDialogRef<ShowReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) 
    
    { }

  ngOnInit(): void {

    
    this.reqStock ="No: of SKUs processed : "+ this.data.requestedUpdateCount
    this.updatedStock = "Success : "+ this.data.updatedCount
    this.failedStock = "Failed : " + this.data.failedCount
    if(this.data.failedCount > 0)
    this.failDetails = this.data.failureDetails
    if(this.data.negativeStockRequestCount > 0)
    this.negStock = "Negative stock value in : "+ this.data.negativeStockRequestCount + " row/s";
    if(this.data.noDbSkuCount > 0)
    this.noStock ="Invaid SKU details in : "+ this.data.noDbSkuCount + " row/s";
    if(this.data.mandatoryIdMissingCount > 0)
    this.mandMsg="Mandatory IDs missing (Sku Id / Sku Mfr Part Num / Erp Src Id) : "+ this.data.mandatoryIdMissingCount + " row/s";
    if(this.data.invalidStockCount > 0)
    {
    this.invalidMsg ="Invaid Stock values in : "+this.data.invalidStockCount + " row/s" 
    this.msg = this.data.invalidStockMessage
    }
    
  }

}
