import { headers } from './../Models/Response/alertDetailsResponse';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpBackend,HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {environment} from '../../environments/environment'
import {ToastrService} from 'ngx-toastr'
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root',
})
export class authService {
    
  obj:any;
  oldAdmin:{
    phone?:string,
    otp?:string
  } ={};
  private http: HttpClient;
    baseUrl=environment.serviceURL+"storimartadmin";
    ofsUrl=environment.ofsURL;

    httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response'
    };

    passwordSetOptions={
      headers:new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization':localStorage.getItem("authenticateuserToken"),
        }
      )
    };

    loginOptions = {      
        headers: new HttpHeaders({
          'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'
        })      
    };

    ofsOptions = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
    };
  

    returnUrl: any;
    
    constructor
    (
      handler: HttpBackend,
      private route: ActivatedRoute,
      private router: Router,
      private toastr:ToastrService,
      private snackBar: MatSnackBar,
      )
       {
      this.http= new HttpClient(handler);
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/login';
      }
 
    ssoLogin(token):Observable<HttpResponse<any>>

    {
     
      return this.http.post<any>(this.baseUrl+"/sso/login/",token,this.httpOptions);   
    }

    /* openOldAdmin():Observable<HttpResponse<any>>{
      var formData =new FormData();
      formData.set('phone',localStorage.getItem('userName'));
      formData.set('otp',localStorage.getItem('passWord'));
      
      return this.http.post<any>(this.baseUrl+"/loginvalidation",formData, this.loginOptions);
    } */

    openOldAdmin():Observable<HttpResponse<any>>{
      const body=new HttpParams()
      .set('phone',localStorage.getItem('userName'))
      .set('otp',localStorage.getItem('passWord'));
      
      return this.http.post<any>(this.baseUrl+"/loginvalidation",body.toString(),
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      }
    );
      }
    
    /* openOfs():Observable<HttpResponse<any>>{
      var formData =new FormData();
      formData.set('username',localStorage.getItem('userName'));
      formData.set('password',localStorage.getItem('passWord'));
      return this.http.post<any>(this.ofsUrl+"loginvalidation",formData,this.ofsOptions);
    } */
    openOfs():Observable<HttpResponse<any>>{
    const body = new HttpParams()
    .set('username', localStorage.getItem('userName'))
    .set('password', localStorage.getItem('passWord'));

    return this.http.post<any>(this.ofsUrl+"loginvalidation",body.toString(),
    {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
    }
  );
    }

    
      login(loginObj):Observable<HttpResponse<any>>
    {
      
    //  return this.http.get(this.baseUrl+"/country")
   return this.http.post<any>(this.baseUrl+"/login",loginObj,this.httpOptions);  
    }
  
    countryList():Observable<any>
    {
     return this.http.get(this.baseUrl+"/country" );
    }
    newUserRegister(newUserObj):Observable<any>
     {
      return this.http.post(this.baseUrl+"/register",newUserObj)
    }

    passwordSet(passwordObj):Observable<any>
    {
      return this.http.post(this.baseUrl+"/resetuserpassword",passwordObj,{
        headers: new HttpHeaders()
          
          .set('Authorization',localStorage.getItem('authenticateuserToken'))
        
      }
          );
    }

    resetPasswordMail(forgotObj):Observable<any>
     {
      return this.http.post(this.baseUrl+"/forgotpassword",forgotObj)
    }
   
  
    isLoggedIn() {

    if(localStorage.getItem("isLogined") === "true")
    return true
    else
    return false
    // return true
    }
  
    logoutHandler()
    {
      localStorage.removeItem("AuthorizationToken");
      localStorage.removeItem("CompanyName");
      localStorage.removeItem("userName");
      localStorage.removeItem("passWord");
      localStorage.setItem("isLogined","false");

      this.router.navigate([this.returnUrl]);
      this.toastr.success("Logged out Successfully","Success")
      this.showSnackbarAction('Please dont forget to publish if you updated anything',"Dismiss")
    }

    showSnackbarAction(content,action) {
      this.snackBar.open(content,action, {
        duration: 4000,
        verticalPosition: 'top', // Allowed values are  'top' | 'bottom'
        horizontalPosition: 'center', // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
        panelClass: ["custom-style"]
      });
    }
    
}