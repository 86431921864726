<div class="container-fluid">
   <!-- <div><h2>Subscription</h2></div> -->
    <div class="row"> 
        <div class="col-xl-3 col-md-6 col-sm-12">
           
          <div class=" exam text-center">
            <h3 style="color: white;" class="pt-2"><span>Basic</span></h3> 
         </div>

            <div class="card test">
                <!-- Logo -->
                

                <div class="card-body p-3">
                    <div class="text-center">
                        <h1 class=""><i class="fa fa-usd"></i>17</h1>
                        <h4 class="text-uppercase" style="color:#FE4831">Per Month</h4>
                    </div>
                    <mat-divider class="material-divider"></mat-divider>
                    <div class="mt-2">
                      <ul class="price">
                        <li>400 Orders</li>
                        <li>1000 SKUs</li>
                        <li>75 Buyers</li>
                        <li>5 Salesmen</li>
                        <li>1000 Push Notifications</li>
                        <li>800 SMS</li>
                        <li>Buy additional 100 orders and 100 SMS for $5</li>
                        <!-- <li>Of Accounts<i class="fa fa-check fa-2"></i></li>
                        <li>Support><i class="fa fa-times"></i></li>
                        <li>Accounts Integration<i class="fa fa-check fa-2"></i></li>
                        <li>CRM Integration<i class="fa fa-check fa-2"></i></li>
                        <li>Web Ordering<i class="fa fa-times"></i></li>
                        <li>Sub Domain<i class="fa fa-times"></i></li>
                        <li>SSO<i class="fa fa-check fa-2"></i></li>
                        <li>Daily Backup<i class="fa fa-check fa-2"></i></li> -->
                      </ul>
                        
                    </div>
                        <div class="text-center mt-3">
                            <button mat-raised-button class="green btn-block" (click)="openDialog(17)">Select</button>
                         </div>
                </div> <!-- end card-body-->
            </div>
        
         </div>

         <div class="col-xl-3 col-md-6 col-sm-12">
           
           
           <div class="exam text-center">
    
       <h3 style="color: white;"  class="pt-2"><span>Standard</span></h3> 
    </div>
          <div class="card test">
             

              <div class="card-body p-3">
                  <div class="text-center">
                      <h1 class=""><i class="fa fa-usd"></i>35</h1>
                      <h4 class="text-uppercase" style="color:#FE4831">Per Month</h4>
                  </div>

                  <mat-divider class="material-divider"></mat-divider>
                  <div class="mt-2">
                    <ul class="price">
                      <li>900 Orders</li>
                      <li>2200 SKUs</li>
                      <li>150 Buyers</li>
                      <li>12 Salesmen</li>
                      <li>2000 Push Notifications</li>
                      <li>1800 SMS</li>
                      <li>Buy additional 100 orders and 100 SMS for $5</li>
                      <!-- <li>Of Accounts<i class="fa fa-check fa-2"></i></li>
                      <li>Support><i class="fa fa-times"></i></li>
                      <li>Accounts Integration<i class="fa fa-check fa-2"></i></li>
                      <li>CRM Integration<i class="fa fa-check fa-2"></i></li>
                      <li>Web Ordering<i class="fa fa-times"></i></li>
                      <li>Sub Domain<i class="fa fa-times"></i></li>
                      <li>SSO<i class="fa fa-check fa-2"></i></li>
                      <li>Daily Backup<i class="fa fa-check fa-2"></i></li> -->
                    </ul>
                      
                  </div>
                  <div class="text-center mt-3">
                    <button mat-raised-button class="green btn-block" (click)="openDialog(35)">Select</button>
                 </div>
              </div> <!-- end card-body-->
          </div>
      
       </div>

       <div class="col-xl-3 col-md-6 col-sm-12">
           
        <div class="exam text-center">
          <h3 style="color: white;" class="pt-2"><span>Business</span></h3> 
       </div>
        <div class="card test">
                    

            <div class="card-body p-3">
                <div class="text-center">
                    <!-- <h1 class=""><i class="fa fa-usd"></i>93</h1>
                    <h4 class="text-uppercase"style="color:#FE4831">Per Month</h4> -->
                   <h3 class="contact_sales">Please contact sales for cost</h3> 
                </div>
                <mat-divider class="material-divider"></mat-divider>
                <div class="mt-2">
                  <ul class="price">
                    <li>2500 Orders</li>
                    <li>5000 SKUs</li>
                    <li>450 Buyers</li>
                    <li>30 Salesmen</li>
                    <li>Unlimited Push Notifications</li>
                    <li>5000 SMS</li>
                    <li>Custom Buyer UI & ERP Integrations</li>
                    <!-- <li>Of Accounts<i class="fa fa-check fa-2"></i></li>
                    <li>Support><i class="fa fa-times"></i></li>
                    <li>Accounts Integration<i class="fa fa-check fa-2"></i></li>
                    <li>CRM Integration<i class="fa fa-check fa-2"></i></li>
                    <li>Web Ordering<i class="fa fa-times"></i></li>
                    <li>Sub Domain<i class="fa fa-times"></i></li>
                    <li>SSO<i class="fa fa-check fa-2"></i></li>
                    <li>Daily Backup<i class="fa fa-check fa-2"></i></li> -->
                  </ul>
                    
                </div>
                <div class="text-center mt-3">
                    <button mat-raised-button class="green btn-block" (click)="openDialog(93)">Contact Sales</button>
                 </div>
            </div> <!-- end card-body-->
        </div>
    
     </div>

     <div class="col-xl-3 col-md-6 col-sm-12">
           
      <div class="exam text-center">
        <h3 style="color: white;" class="pt-2"><span>Enterprise</span></h3> 
     </div>
      <div class="card test">

          <div class="card-body p-3">
              <div class="text-center">
                <h3 class="contact_sales">Please contact sales for cost</h3>  
              </div>
              <mat-divider class="material-divider"></mat-divider>
              <div class="mt-2">
                <ul class="price">
                  <li>20000 Orders</li>
                  <li>20000 SKUs</li>
                  <li>Unlimited Buyers</li>
                  <li>Unlimited Salesmen</li>
                  <li>Unlimited Push Notifications</li>
                  <li>Unlimited SMS</li>
                  <li>Custom Buyer UI,ERP & Payment Gateway Integrations</li>
                  <!-- <li>Of Accounts<i class="fa fa-check fa-2"></i></li>
                  <li>Support><i class="fa fa-times"></i></li>
                  <li>Accounts Integration<i class="fa fa-check fa-2"></i></li>
                  <li>CRM Integration<i class="fa fa-check fa-2"></i></li>
                  <li>Web Ordering<i class="fa fa-times"></i></li>
                  <li>Sub Domain<i class="fa fa-times"></i></li>
                  <li>SSO<i class="fa fa-check fa-2"></i></li>
                  <li>Daily Backup<i class="fa fa-check fa-2"></i></li> -->
                </ul>
                  
              </div>
              <div class="text-center mt-3">
                <button mat-raised-button class="green btn-block">Contact Sales</button>
             </div>
          </div> <!-- end card-body-->
      </div>
  
   </div>

    </div>
</div>
