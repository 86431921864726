import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import {environment} from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class ServiceService {
   baseUrl=environment.serviceURL+"storimartdashboard"



  constructor(private httpclient: HttpClient) { }
  getNotification():Observable<any>
  {
   
     return this.httpclient.get(this.baseUrl+"/notification");
  }
  


 getLiveStatus():Observable<any>
 {
    
    return this.httpclient.get(this.baseUrl+"/livesummary");
 }

 getDashboardStatus():Observable<any>
 {
      return this.httpclient.get(this.baseUrl+"/packagestatus");
   
 }

 getYearlyVolume(syear):Observable<any>
 {
     return this.httpclient.get(this.baseUrl+"/monthlyvolumestatistics/"+syear);

 }

//  getBuyers():Observable<any>
//  {
 
//     return this.httpclient.get("https://api.myjson.com/bins/19f7f2");
//  }

 getTopFiveBuyers():Observable<any>
 {
    
    return this.httpclient.get(this.baseUrl+"/topfiveretailers");
 }

 getTopFiveSales():Observable<any>
 {
    return this.httpclient.get(this.baseUrl+"/topfivesales");
 }



 getBuyersStatistics(syearmonth):Observable<any>
 {
     return this.httpclient.get(this.baseUrl+"/allcustomermonthlyvolume/"+syearmonth);
   
 }


}
