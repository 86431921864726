import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { authService } from '../../../Service/authService'

export interface DialogData {
  title: string;
 
}


@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,private authService:authService,) { }

  ngOnInit(): void {
  }

  onConfirm(): void {
    
    this.dialogRef.close(true);
}

onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
}

}
