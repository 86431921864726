<form [formGroup]="billingInfoForm" >
    <div class="form-horizontal card-body ">
        <!-- <div style="float: right;" class="col-md-1">
            <mat-icon class="pointer"  (click)="billinginfoTour()">play_circle_outline</mat-icon> 
           </div> -->
        <div class="form-group col-md-6 step1">
            <label for="simpleinput">Primary Contact</label>
            <input type="text" id="simpleinput" [formControl]="billingInfoForm.controls['contactname']"  class="form-control">
        </div> 
        <div class="form-group col-md-6 step2">
            <label for="simpleinput">Company Name</label>
            <input type="text" id="simpleinput" [formControl]="billingInfoForm.controls['companyname']"  class="form-control">
        </div> 
        <div class="form-group col-md-6 step2">
            <label for="simpleinput">Address</label>
            <textarea type="text" id="simpleinput" [formControl]="billingInfoForm.controls['address']"  class="form-control"></textarea>
        </div> 
        <div class="form-group col-md-6 step4">
            <label for="simpleinput">City </label>
            <input type="text" id="simpleinput" [formControl]="billingInfoForm.controls['city']"  class="form-control">
        </div>
        <div class="form-group col-md-6 step5">
            <label for="simpleinput">State</label>
            <input type="text" id="simpleinput" [formControl]="billingInfoForm.controls['state']"  class="form-control">
        </div>
        <div class="form-group col-md-6 step3">
            <label for="example-multiselect">Country</label>
            <select class="custom-select mb-3" [formControl]="billingInfoForm.controls['country']" >
                <option   *ngFor="let item of countryList" [value]="item.countryId">{{item.countryName}}</option>
            </select>  
        </div>
        <div class="form-group  col-md-6 step6">
            <label for="simpleinput">Zip/pin</label>
            <input type="text" id="simpleinput" [formControl]="billingInfoForm.controls['zipCode']" class="form-control" >
        </div>
      <div fxFlex="100" class="mt-1">
            <button mat-raised-button color="primary" (click)="Submiit()">Submit</button>
            <button mat-button color="warn" type="button" class="popup_cancel_btn" (click)="Reset()">Close</button>
        </div> 
    </div>
</form> 