<app-loading-bar></app-loading-bar>
 
 <router-outlet></router-outlet> 
<!-- <app-menus></app-menus> -->
<footer class="footer footer-alt text-right">
                        
    <p>2021 © Storilabs System Technologies Ver {{curDate}}.{{ver}}</p>
      
  </footer>
  

<tour-step-template>
 
</tour-step-template>