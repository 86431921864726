

<body class="authentication-bg pb-0" >

    <div class="auth-fluid">
        <!--Auth fluid left content -->
        <div class="auth-fluid-right"  style="background-color:whitesmoke; padding: 0 !important;">
          <span ><img src="../assets/icon/graphics2.png"></span> 
            
        </div>
        <div class="auth-fluid-form-box">
            <div class="align-items-center d-flex h-100">
                <div class="card-body">

                    <!-- Logo -->
                    <div class="auth-brand text-center text-lg-left">
                        <a href="" class="logo-dark">
                            <span><img src="../assets/icon/sm-logo_new.png" alt="" height="70"></span>
                        </a>
                    
                    </div>
                    
                    <!-- title-->
                     <h4 class="mt-0 mb-3">Forgot password ?</h4>
                    <!-- <p class="text-muted mb-4">Enter your email address and password to access account.</p> --> 
                   
                    
                    
                    
                          <form [formGroup]="resetform">
                          
                            
                                  <mat-form-field appearance="outline">
                                    <mat-label>Email</mat-label>
                                    <input matInput placeholder="Enter Email"
                                    formControlName="username"
                                    required>
                                    <mat-icon matSuffix>email</mat-icon>
                                    <!-- <mat-hint>Hint</mat-hint> -->
                                    <mat-error>
                                      Please provide Email 
                                    </mat-error>
                                  </mat-form-field>
                            
                                 
                                    <ngx-recaptcha2 #captchaElem
                                      [siteKey]="siteKey"
                                      (success)="handleSuccess($event)"
                                      formControlName="recaptcha" required>
                                    </ngx-recaptcha2>
                                    <div class="alert alert-danger" role="alert" *ngIf="isRobort">
                                      <strong> Verify ! </strong>  You are not a robot
                                  </div>

                           
                          
                            <button mat-raised-button color="primary" [class.spinner]="loading" [disabled]="loading" [disabled]="!resetform.valid" (click)="onSubmit()" class="mt-2">Submit</button>
                          </form>
                         
                          
                    <p class="text-muted mt-2">Remember your password ? <a [routerLink]="['/login']" class="text-muted ml-1"><b>Login</b></a></p>

                  

                </div> <!-- end .card-body -->
            </div> <!-- end .align-items-center.d-flex.h-100-->
        </div>
        <!-- end auth-fluid-form-box-->

        <!-- Auth fluid right content -->
       
        <!-- end Auth fluid right content -->
    </div>
    <!-- end auth-fluid-->

    <!-- bundle -->
  
</body>
