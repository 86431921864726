<tour-step-template>
    <ng-template let-step="step">
        <div class="row">
        <div>
        <button mat-icon-button [disabled]="!dbTourService.hasPrev(step)"
        [ngClass]="{'inactive-btn' : !dbTourService.hasPrev(step)}" class="ml-2"
        (click)="dbTourService.prev()"> <mat-icon>arrow_back</mat-icon></button>
    </div>
    <div class="ngxp-end">
        <button mat-flat-button (click)="dbTourService.end()">{{step?.endBtnTitle}}</button>
    </div>
    </div>
        <div class="ngxp-title" [ngClass]="{'ngxp-first-step' : !dbTourService.hasPrev(step)}">
            <div class="ngxp-title-text">
                {{step?.title}}
        </div> 
           
        </div>
        <img src="../../assets/icon/guided_tour.png">
        <div class="ngxp-content mb-3">{{step?.content}}</div>
        <div class="tour-step-navigation" style="display: flex;
        justify-content: center;
        align-items: center;">
            
            <button mat-raised-button color="primary" [disabled]="!dbTourService.hasNext(step)"
                [ngClass]="{'inactive-btn' : !dbTourService.hasNext(step)}" class="nextBtn"
                (click)="dbTourService.next()">{{step?.nextBtnTitle}} »</button>
        </div>
    </ng-template>
</tour-step-template>





<div class="container-fluid ">

    <!-- <div class="dashboard">DashBoard</div> -->
    <div style="float: right;" class="col-md-1 text-center">
        <mat-icon matTooltip="Click for a guided Tour" class="pointer" (click)="dashboardTour()">play_circle_outline
        </mat-icon>
        <h6 style="margin-top: 0mm;">Guided Tour</h6>
        <button mat-icon-button class="example-icon" >
            <mat-icon matTooltip="Refresh Dashboard" (click)="ngOnInit()">refresh</mat-icon>
        </button>
        <h6 style="margin-top: 0mm;">Refresh</h6>
    </div>


    <div class="row mt-2" tourAnchor="dbtour.step2.id">
        <div *ngFor="let x of ds;let i = index" class="col-xl mb-1">
            <div class="card">

                <div class="card-body" style="overflow: hidden;">

                    <div style="float:left; width:50%;">
                        <img src={{iconImages[i]}} style="color: #ffffff;background-color:#7285d6;height:55px;
            width:55px;
            padding:10px;
            border-radius: 50px;
            display: inline-block;">
                    </div>
                    <div style="float:right; width:50%; text-align: end; font-weight: bold;">
                        <div>
                            {{x.data2}}
                        </div>
                        <div>
                        {{x.data1}}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>


    <div class="row pl-2">


        <div class="col-xl-4">
            <div class="card" tourAnchor="dbtour.step1.id">
                <div class="card-body step2">
                    <!-- <div class="dropdown float-right">
                  <a href="#" class="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false">
                      <i class="mdi mdi-dots-vertical"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                      <a href="javascript:void(0);" class="dropdown-item">Sales Report</a>
                      <a href="javascript:void(0);" class="dropdown-item">Export Report</a>
                  </div>
              </div> -->
                    <h4 class="header-title">Top Selling Products</h4>

                    <div class="mb-2 mt-3 chartjs-chart " tourAnchor="dbtour.step3.id">
                        <canvas id="topfivesaleschart" style="height: 231px;"></canvas>
                    </div>

                    <!-- <div class="chart-widget-list">
                  <p>
                      <i class="mdi mdi-square text-primary"></i> Direct
                      <span class="float-right">$300.56</span>
                  </p>
                  <p>
                      <i class="mdi mdi-square text-danger"></i> Affilliate
                      <span class="float-right">$135.18</span>
                  </p>
                  <p>
                      <i class="mdi mdi-square text-success"></i> Sponsored
                      <span class="float-right">$48.96</span>
                  </p>
                  <p class="mb-0">
                      <i class="mdi mdi-square"></i> E-mail
                      <span class="float-right">$154.02</span>
                  </p>
              </div> -->
                </div> <!-- end card-body-->
            </div> <!-- end card-->
        </div>
        <div class="col-xl-4 ">
            <div class="card">
                <div class="card-body step3">
                    <!-- <div class="dropdown float-right">
                  <a href="#" class="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false">
                      <i class="mdi mdi-dots-vertical"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                      <a href="javascript:void(0);" class="dropdown-item">Sales Report</a>
                    
                      <a href="javascript:void(0);" class="dropdown-item">Export Report</a>
                  </div>
              </div> -->
                    <h4 class="header-title">Top Five Retailers</h4>

                    <div class="mb-2 mt-3 chartjs-chart" tourAnchor="dbtour.step4.id">
                        <canvas id="topfiveChart" style="height: 231px;"></canvas>
                    </div>

                    <!-- 
              <div class="chart-widget-list">
                  <p>
                      <i class="mdi mdi-square text-primary"></i> Direct
                      <span class="float-right">$300.56</span>
                  </p>
                  <p>
                      <i class="mdi mdi-square text-danger"></i> Affilliate
                      <span class="float-right">$135.18</span>
                  </p>
                  <p>
                      <i class="mdi mdi-square text-success"></i> Sponsored
                      <span class="float-right">$48.96</span>
                  </p>
                  <p class="mb-0">
                      <i class="mdi mdi-square"></i> E-mail
                      <span class="float-right">$154.02</span>
                  </p>
              </div> -->
                </div> <!-- end card-body-->
            </div> <!-- end card-->
        </div> <!-- end col-->

        <div class="col-xl-4">
            <div class="card">
                <div class="card-body step4" tourAnchor="dbtour.step5.id">

                    <h4 class="header-title mb-3">Package Status</h4>
                    <!-- <div class="mb-4 mt-4">
                  <div id="world-map-markers" style="height: 224px"></div>
              </div> -->

                    <div *ngFor="let x of dashboardStatus" class="mb-1  font-weight-normal">{{x.data1}} [
                        {{x.data3}}/{{x.data2}} ]
                        <div class="progress-w-percent">
                            <span class="progress-value font-weight-bold">{{x.data4}}% </span>
                            <div class="progress progress-sm">
                                <div class="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0"
                                    aria-valuemax="100" [ngStyle]="{'width':x.data4+'%'}"></div>
                            </div>
                        </div>
                    </div>


                    <!-- <h5 class="mb-1 mt-4 font-weight-normal">Buyers Count</h5>
              <div class="progress-w-percent">
                  <span class="progress-value font-weight-bold">72% </span>
                  <div class="progress progress-sm">
                      <div class="progress-bar" role="progressbar" style="width: 72%;" aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
              </div>

              <h5 class="mb-1 mt-3 font-weight-normal">Sku Count</h5>
              <div class="progress-w-percent">
                  <span class="progress-value font-weight-bold">39% </span>
                  <div class="progress progress-sm">
                      <div class="progress-bar" role="progressbar" style="width: 39%;" aria-valuenow="39" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
              </div>

              <h5 class="mb-1 mt-3 font-weight-normal">Message Count</h5>
              <div class="progress-w-percent">
                  <span class="progress-value font-weight-bold">25% </span>
                  <div class="progress progress-sm">
                      <div class="progress-bar" role="progressbar" style="width: 39%;" aria-valuenow="39" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
              </div>

              <h5 class="mb-1 mt-3 font-weight-normal">Salesman Count</h5>
              <div class="progress-w-percent mb-0">
                  <span class="progress-value font-weight-bold">61% </span>
                  <div class="progress progress-sm">
                      <div class="progress-bar" role="progressbar" style="width: 61%;" aria-valuenow="61" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
              </div> -->
                </div> <!-- end card-body-->
            </div> <!-- end card-->
        </div> <!-- end col-->



        <!-- end col-->

    </div>

    <div class="row pl-2">

        <div class="col-xl-6">

            <div class="card step5" id="monthlyvolume">
                <div class="card-body">
                    <!-- <div class="dropdown float-right">
                  <a href="#" class="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false">
                      <i class="mdi mdi-dots-vertical"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right show" >
                      <a class="dropdown-item" (click)="monthlyvolumedd()">Download</a>   
                  </div>
              </div> -->
                    <h4 class="header-title mb-3" tourAnchor="dbtour.step6.id">Monthly Volume Statistics</h4>

                    <div class="pull-in chart-content-bg">
                        <div class="row text-center">
                            <div class="col-md-6">
                                <h4 class="text-muted mb-0 mt-3">Yearly Total Volume</h4>
                                <h4 class="font-weight-normal mb-3" style="color: red;">
                                    <small class=" align-middle mr-1"></small>
                                    <span><i class="" [ngClass]="currencyCode | currencypipe"></i>{{ yVolume |
                                        thousandSuff : 2 }}</span>



                                </h4>
                            </div>
                            <div class="col-md-1"></div>
                            <div class="col-md-4">
                                <div class="yearselect mt-3">
                                    <div class="form-group has-feedback">                                          
                                       
                                        <input type="text" class="form-control" bsDatepicker #d="bsDatepicker" [maxDate]="maxDate" id="testdate" name="testdate"
                                            [bsConfig]="{dateInputFormat:'YYYY', isAnimated: true,adaptivePosition: true ,containerClass:'theme-dark-blue' }"
                                            (onShown)="onOpenYear($event)" [ngModel]="currentYear"
                                            (bsValueChange)="getVolumeChartData($event)"> 
                                                                                
                                     </div>
                                </div>
                               
                            </div>
                            <div class="col-md-1"></div>
                        </div>
                    </div>

                    <!-- <div class="dash-item-overlay d-none d-md-block">
                  <h5>Today's Earning: $2,562.30</h5>
                  <p class="text-muted font-13 mb-3 mt-2">Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui.
                      Etiam rhoncus...</p>
                  <a href="javascript: void(0);" class="btn btn-outline-primary">View Statements
                      <i class="mdi mdi-arrow-right ml-2"></i>
                  </a>
              </div> -->

                    <div class="mt-3 chartjs-chart">
                       <canvas id="monthlychart"></canvas> 
                        
                    </div>
                     <div>
                        <span class="alert alert-danger no-data-available" *ngIf="yVolume === '0.0'" style="color: red;">NO DATA ON THIS
                            TIME INTERVAL</span>
                    </div>
                </div> <!-- end card-body-->
            </div> <!-- end card-->
        </div> <!-- end col-->

        <div class="col-xl-6">
            <div class="card">
                <div class="card-body step6">
                    <!-- <div class="dropdown float-right">
                  <a href="#" class="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false">
                      <i class="mdi mdi-dots-vertical"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                      <a href="javascript:void(0);" class="dropdown-item">Sales Report</a>
                      <a href="javascript:void(0);" class="dropdown-item">Export Report</a>
                  </div>
              </div> -->
                    <h4 class="header-title mb-3" tourAnchor="dbtour.step7.id">Buyers Monthly Statistics</h4>

                    <div class="pull-in chart-content-bg">
                        <div class="row text-center">
                            <div class="col-md-6">
                                <h4 class="text-muted mb-0 mt-3">Total Monthly Volume</h4>
                                <h4 class="font-weight-normal mb-3" style="color:red;">
                                    <small class=" align-middle mr-1" ></small>
                                    <span><i class="" [ngClass]="currencyCode | currencypipe"></i>{{ mVolume |
                                        thousandSuff : 2 }}</span>

                                </h4>
                            </div>
                            <div class="col-md-1"></div>
                            <div class="col-md-4">
                                <div class="yearmonthselect mt-3">
                                    <div class="form-group">
                                        <input type="text" class="form-control" bsDatepicker [maxDate]="maxDate"
                                            [bsConfig]="{dateInputFormat: 'MMM-YYYY', isAnimated: true, adaptivePosition: true, containerClass:'theme-dark-blue'}"
                                            (onShown)="onOpenYearMonth($event)"
                                            (bsValueChange)="getBuyersStatistics($event)" [ngModel]="currentMonth">

                                            
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1"></div>

                        </div>
                    </div>

                    <!-- <div class="dash-item-overlay d-none d-md-block">
                  <h5>Today's Earning: $2,562.30</h5>
                  <p class="text-muted font-13 mb-3 mt-2">Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui.
                      Etiam rhoncus...</p>
                  <a href="javascript: void(0);" class="btn btn-outline-primary">View Statements
                      <i class="mdi mdi-arrow-right ml-2"></i>
                  </a>
              </div> -->

                    <div class="mt-3 chartjs-chart">
                        <canvas id="monthlyBuyersChart"></canvas>
                        
                    </div>
                    <div *ngIf="mVolume === '0.0'" class="text-danger align-items-center">
                        <span class="alert alert-danger no-data-available" *ngIf="mVolume === '0.0'" style="color: red;">NO DATA ON THIS
                            TIME INTERVAL</span>
                    </div>



                </div> <!-- end card-body-->
            </div> <!-- end card-->
        </div> <!-- end col-->