
<h2 mat-dialog-title>Package Subscription Details</h2>

<mat-dialog-content >
  
    <h4>Are you sure you want to subscribe for this package? 
        <br>
        If yes, you have three options:</h4>

    <ol>
        <li>Subscribe for 3 Months - 3 * {{data}} = <i class="fa fa-usd"></i> {{3*data}} </li>
        <li>Subscribe for 6 Months - 6 * {{data}} = <i class="fa fa-usd"></i> {{6*data}} </li>
        <li>Subscribe for 1 Year &nbsp;&nbsp; -  12 *{{data}} = <i class="fa fa-usd"></i> {{12*data}} </li>
    </ol> 
</mat-dialog-content>

<mat-dialog-actions>

    <button mat-button mat-dialog-close > Cancel</button>
    <button class="mat-raised-button mat-primary ml-5"(click)="save()">3 Months</button>
    <button class="mat-raised-button mat-primary ml-5"(click)="save()">6 Months</button>
    <button class="mat-raised-button mat-primary ml-5"(click)="save()">1 Year</button>

</mat-dialog-actions>