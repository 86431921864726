import { Component, OnInit } from '@angular/core';
import { authService } from './../Service/authService';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-catalog-manager',
  templateUrl: './catalog-manager.component.html',
  styleUrls: ['./catalog-manager.component.css']
})
export class CatalogManagerComponent implements OnInit {

  constructor(private authService: authService,) { }

  ngOnInit(): void {
    var url = '';
    this.authService.openOldAdmin()
      .subscribe(loginData => {
        //window.location.href = loginData.url;
        window.open(loginData.url,"_self");
        console.log(loginData);
      }, (err: HttpErrorResponse) => {
        //window.location.href = err.url;
        window.open(err.url,"_self");
      });
  }

}
