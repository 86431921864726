import { Component, OnInit,Inject } from '@angular/core';
import { ReportService } from '.././../Service/report.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { InvoiceViewResponse } from '../../Models/Response/InvoiceViewResponse';

@Component({
  selector: 'app-invoice-dialog',
  templateUrl: './invoice-dialog.component.html',
  styleUrls: ['./invoice-dialog.component.scss']
})
export class InvoiceDialogComponent implements OnInit {

  inCustomername: string;
  inCountry: string;
  inCity: string;
  inStreet: string;
  inAddressLine1: string;
  inZipPin: string;
  inState: string;
  inTotalAmount
  inPaidAmount
  inToPayAmount
  inOrderCount
  inCurrency: string;
  inPhone: string;
  startDate;
  endDate;
  shopId;
  retailerId;
  bsValue = new Date();

  constructor(public dialogRef: MatDialogRef<InvoiceDialogComponent>, @Inject(MAT_DIALOG_DATA) data,
  private reportService: ReportService,) 
  {
    
    this.bsValue.setDate(this.bsValue.getDate() - 30);
    this.endDate=data.endDate,
    this.startDate=data.startDate;
    this.shopId = data.shopId;
    this.retailerId = data.retailerId;
   }

  ngOnInit(): void {
  
   if(this.endDate&&this.startDate&&this.shopId&&this.retailerId )
    this.getInvoiceView(this.retailerId,this.shopId)
  }

getInvoiceView(retailerId,shopId)
{
 
  this.reportService.getInvoice(this.startDate,this.endDate,shopId,retailerId)// to get invoice view
    .subscribe((invoiceData: InvoiceViewResponse)=>
    {
      // this.invoiceDataSet=invoiceData
      this.inCustomername=invoiceData.customerName
      this.inCountry=invoiceData.Address.country
      this.inCity=invoiceData.Address.city
      this.inStreet=invoiceData.Address.street
      this.inAddressLine1=invoiceData.Address.addressLine1
      this.inZipPin=invoiceData.Address.zipPin
      this.inState=invoiceData.Address.state
      this.inPhone=invoiceData.customerPhone
      this.inOrderCount=invoiceData.orderCount
      
this.inTotalAmount=invoiceData.totalAmount
this.inPaidAmount=invoiceData.paidAmount
this.inToPayAmount=this.inTotalAmount-this.inPaidAmount
this.inCurrency=invoiceData.currency
    }
    );
}

printInvoice()// invoice printing
{

// window.print()
  // var divToPrint = document.getElementById('invoice');
  var windowContent = '<!DOCTYPE html>';
  //Starting HTML Tags
  windowContent += '<html>'
  
  //Setting Print Page Title
  windowContent += '<head><title>Invoice</title>';

//Adding Style
 windowContent += '<style>.invoice{ position: relative;background-color: #FFF; padding: 15px}.invoice header {padding: 10px 0;margin-bottom: 20px;border-bottom: 1px solid #3989c6}.invoice .company-details {text-align: right }.invoice .company-details .name {margin-top: 0;margin-bottom: 0}.invoice .contacts {margin-bottom: 20px}.invoice .invoice-to {text-align: left}.invoice .invoice-to .to {margin-top: 0;margin-bottom: 0}.invoice .invoice-details {text-align: right}.invoice .invoice-details .invoice-id {margin-top: 0;color: #3989c6}.invoice main .notices {padding-left: 6px;border-left: 6px solid #3989c6}.invoice main .notices .notice {font-size: 1.2em}.invoice table { width: 100%;border-collapse: collapse; border-spacing: 0; margin-bottom: 20px}.invoice table td,.invoice table th {padding: 15px;background: #eee;border-bottom: 1px solid #fff}.invoice table th {white-space: nowrap;font-weight: 400;font-size: 16px}.invoice table td h3 {margin: 0;font-weight: 400;color: #3989c6;font-size: 1.2em}.invoice table .qty,.invoice table .total,.invoice table .unit {text-align: right;font-size: 1.2em}.invoice table .no {color: #fff;font-size: 1.6em;background: #3989c6}.invoice table .unit { background: #ddd}.invoice table .total {background: #3989c6;color: #fff}.invoice table tbody tr:last-child td {border: none}</style>';



  windowContent += '</head>';
  //Starting Body Tag
  windowContent += '<body>'
  
  //Getting Div HTML
  windowContent +=  document.getElementById("invoice").innerHTML;
  
  //Closing Body Tag and HTML Tag
  windowContent += '</body>';
  windowContent += '</html>';
  
  //Calling Print Window
  var printWin = window.open('','','fullscreen=yes');
  
  //Opening Print Window
  printWin.document.open();
  
  //Adding Content in Print Window
  printWin.document.write(windowContent);
  
  //Closing Print Window
  printWin.document.close();
  
  //Focusing User to Print Window
  printWin.focus();
  
  //Calling Default Browser Printer
  printWin.print();
  
  //Closing Print Window
  printWin.close();
}  

closeInvoice()
{
  
  this.dialogRef.close();
}

}
