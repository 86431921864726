<tour-step-template>
    <ng-template let-step="step">
        <div class="ngxp-title" [ngClass]="{'ngxp-first-step' : !cpTourService.hasPrev(step)}">
            <div class="ngxp-title-text">
                {{step?.title}}
            </div>
            <div class="ngxp-end">
                <button class="ngxp-btn" (click)="cpTourService.end()">{{step?.endBtnTitle}}</button>
            </div>
        </div>
        <hr>
        <div class="ngxp-content mb-3">{{step?.content}}</div>
        <div class="tour-step-navigation">
            <button [disabled]="!cpTourService.hasPrev(step)" [ngClass]="{'inactive-btn' : !cpTourService.hasPrev(step),'prev-step-hide': hidePrevStep}" class="ngxp-btn ngxp-prev" (click)="cpTourService.prev()">« {{step?.prevBtnTitle}}</button>
            <button [disabled]="!cpTourService.hasNext(step)" [ngClass]="{'inactive-btn' : !cpTourService.hasNext(step), 'submit-step-hide': hideSubmitStep}"  class="ngxp-btn ngxp-next" (click)="cpTourService.next()">{{step?.nextBtnTitle}} »</button>
        </div>
      </ng-template>  
</tour-step-template>

<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <div class="card" >
                <div class="card-body pt-4">

                    
                    <div class="card border-success border mt-2" *ngIf="newUser">
                        <div class="card-body">
                         <h5><div class="card-title text-success">Welcome!</div></h5>
                            <div class="card-text">
                                <h5 style="color:green">Looks like, its your first time here .Please click on the Guided tour to help you navigate</h5>  
                                <button class="btn btn-primary" (click)="warehouseTour()">Guided Tour</button>
                            </div>
                        </div>
                    </div>
                    
                    <mat-horizontal-stepper #stepper labelPosition="bottom">
                        <mat-step label="Warehouse settings" [stepControl]="warehouseForm" errorMessage="This form has some errors">
                        
                            <div class="example-large-box mat-elevation-z4">
                               
                                <form [formGroup]="warehouseForm">
                                    <div class="form-horizontal card-body" >
                                         <div style="float: right;" class="col-md-1 text-center" tourAnchor="cptour.wstep1.id" >
                                            <mat-icon matTooltip="Click for a guided Tour" class="pointer"  (click)="warehouseTour()">play_circle_outline</mat-icon> 
                                            <h6 style="margin-top: 0mm;">Guided Tour</h6>
                                        </div>

                                        
                                        <div class="form-group col-md-6 ws-guided-tour  wstep1"  tourAnchor="cptour.wstep2.id">
                                            <label for="warehouseName">Warehouse Name *</label>
                                            <input type="text" id="warehouseName" [formControl]="warehouseForm.controls['name']" (keydown.enter)="false" class="form-control" required>
                                            <mat-error *ngIf="warehouseForm.controls['name']?.errors?.required && warehouseForm.controls['name'].touched">
                                                This is a required field</mat-error>           
                                        </div> 

                                        <div class="form-group col-md-6 ws-guided-tour wstep2" tourAnchor="cptour.wstep3.id" >
                                            <label for="warehouseAddress"> Warehouse Address * </label>
                                            <input type="text" id="warehouseAddress" [formControl]="warehouseForm.controls['address']" (keydown.enter)="false" class="form-control">
                                             <mat-error *ngIf="warehouseForm.controls['address']?.errors?.required && warehouseForm.controls['address'].touched">
                                                This is a required field   </mat-error>
                                        </div>  
                                        
                                         
                                         <div class="form-group col-md-6 ws-guided-tour wstep3" tourAnchor="cptour.wstep4.id" >
                                            <label for="warehouseCity">City *</label>
                                            <input type="text" id="warehouseCity" [formControl]="warehouseForm.controls['city']" (keydown.enter)="false"  class="form-control">
                                          <mat-error *ngIf="warehouseForm.controls['city']?.errors?.required && warehouseForm.controls['city'].touched">
                                               This is a required field</mat-error> 
                                        </div> 

                                         <div class="form-group col-md-6 ws-guided-tour wstep4" tourAnchor="cptour.wstep5.id">
                                            <label for="warehouseState">State</label>
                                            <input type="text" id="warehouseState" [formControl]="warehouseForm.controls['state']" (keydown.enter)="false" class="form-control">
                                          <mat-error *ngIf="warehouseForm.controls['state']?.errors?.required && warehouseForm.controls['state'].touched">
                                                This is a required field</mat-error> 
                                        </div>

                                     <div class="form-group col-md-6 ws-guided-tour wstep5 " tourAnchor="cptour.wstep6.id" >
                                            <label for="country-multiselect">Country</label>
                                            <select class="custom-select" [formControl]="warehouseForm.controls['country']"  >
                                       <option   *ngFor="let item of countryList" [value]="item.countryName">{{item.countryName}}</option>
                                            </select>  
                                        </div> 
                                     <div class="form-group  col-md-6 ws-guided-tour wstep6" tourAnchor="cptour.wstep7.id" >
                                            <label for="warehouseZip">Zip/pin</label>
                                            <input type="text" id="warehouseZip" [formControl]="warehouseForm.controls['zipCode']" (keydown.enter)="false" class="form-control">
                                            <mat-error *ngIf="warehouseForm.controls['zipCode']?.errors?.required && warehouseForm.controls['zipCode'].touched">
                                                this field required</mat-error>
                                        </div>
                                     <div  class="mt-2  col-md-6 align-items-center" tourAnchor="cptour.wstep8.id">
                                            <button type="submit" mat-button mat-raised-button color="primary"  (click)="SubmitWarehouse()"> Save and Continue </button>
                                           
                                            <!-- <button class="wnext " mat-button (click)="goForward(stepper)" >Next</button>  -->        
                                            <!-- <button mat-button color="warn" type="button" class="popup_cancel_btn" (click)="Reset()">Close</button> -->
                                        </div> 
                                       
                                    </div>
                                </form>
                            </div>
                        </mat-step>
                        <mat-step label="General Settings " [stepControl]="generalSettingsForm" errorMessage="This form has some errors">
                            <div class="example-small-box mat-elevation-z4">
                                <form [formGroup]="generalSettingsForm">
                                    <div class="form-horizontal card-body ">
                                        <div style="float: right;" class="col-md-1 text-center">
                                            <mat-icon matTooltip="Click for a guided Tour" class="pointer" (click)="genSettingsTour()">play_circle_outline
                                            </mat-icon>
                                            <h6 style="margin-top: 0mm;">Guided Tour</h6>
                                        </div>
                                        <div class="form-group col-md-6 ws-guided-tour step1" tourAnchor="cptour.gstep1.id"  >
                                            <label class="oo" for="displayName">Display Name *</label>
                                            <input type="text" id="displayName"
                                                [formControl]="generalSettingsForm.controls['displayName']"
                                                (keydown.enter)="false"
                                                class="form-control ">
                                                 <mat-error *ngIf="generalSettingsForm.controls['displayName']?.errors?.required && generalSettingsForm.controls['displayName'].touched">
                                                    This field is required</mat-error>
                                        </div> 
                                         <div class="form-group col-md-6 ws-guided-tour step2" tourAnchor="cptour.gstep2.id">
                                            <label class="oo" for="currency-multiselect">Currency *</label>
                                            <select class="custom-select "
                                                [formControl]="generalSettingsForm.controls['currency']" (keydown.enter)="false">
                                                <option   *ngFor="let item of countryList" [value]="item.currency">{{item.currency}}</option>
                                          
                                            </select>
                                             <mat-error *ngIf="generalSettingsForm.controls['currency']?.errors?.required && generalSettingsForm.controls['currency'].touched">
                                                This field is required</mat-error>
                                        </div> 
                                         <div class="form-group col-md-6 ws-guided-tour step3" tourAnchor="cptour.gstep3.id">
                                            <label class="oo" for="shippingcost">Shipping Cost</label>
                                            <input type="number" min="0" oninput="validity.valid||(value='');"
                                               id="shippingcost"
                                                [formControl]="generalSettingsForm.controls['shippingCost']"
                                                (keydown.enter)="false" class="form-control ">
                                            <mat-error *ngIf="generalSettingsForm.controls['shippingCost']?.errors?.required && generalSettingsForm.controls['shippingCost'].touched">
                                                    This field is required</mat-error>
                                        </div> 
                                         <div class="form-group col-md-6 ws-guided-tour step4" tourAnchor="cptour.gstep4.id">
                                            <label  for="timezone-multiselect">Time Zone *</label>
                                            <select class="custom-select"
                                                [formControl]="generalSettingsForm.controls['timeZone']">
                                                <option *ngFor="let item of timeZoneList" [value]="item.timeZoneId">
                                                    {{item.zoneName}}</option>
                                            </select>
                                             <mat-error *ngIf="generalSettingsForm.controls['timeZone']?.errors?.required && generalSettingsForm.controls['timeZone'].touched">
                                                This field is required</mat-error>
                                         </div> 

                                        <div class="form-group col-md-6 ws-guided-tour step5" tourAnchor="cptour.gstep5.id">
                                            <label class="oo" for="moq">MOQ</label>
                                            <input type="number"  min="0" id="moq" oninput="validity.valid||(value='');"
                                                [formControl]="generalSettingsForm.controls['moq']"
                                                class="form-control ">
                                        </div>
                                         <div class="form-group col-md-6 ws-guided-tour step6" tourAnchor="cptour.gstep6.id">
                                            <label  for="mov">MOV</label>
                                            <input type="number" min="0" id="mov" oninput="validity.valid||(value='');"
                                                [formControl]="generalSettingsForm.controls['mov']"
                                                class="form-control"> 
                                               
                                        </div>
                                       
                                       
                                        <div class="form-group col-md-6 ws-guided-tour step6" tourAnchor="cptour.gstep7.id">
                                            
                                            <label  for="taxNumber">Tax-Number/GST</label>
                                            <input [formControl]="generalSettingsForm.controls['tax']"
                                                class="form-control"> 
                                               
                                        </div>

                                        <div class="inventory" tourAnchor="cptour.gstep8.id">
                                            <mat-checkbox [formControl]="generalSettingsForm.controls['inventory']"
                                            [(ngModel)]="checked">Enable Inventory</mat-checkbox>
                                        </div>


                                         <!-- <div class="form-group  col-md-6 step7" tourAnchor="tour1.step13.id">
                                            <label  for="logo">Logo</label>
                                            <input type="file" id="logo"
                                                [formControl]="generalSettingsForm.controls['logo']"
                                                class="form-control-file" accept="image/*">
                                        </div>
                                        <div tourAnchor="tour1.step14.id"></div>
                                        
                                        <div class="form-group  col-md-6 step8" >
                                            <label for="banner">Banner</label>
                                            <input type="file" id="banner"
                                                [formControl]="generalSettingsForm.controls['banner']"
                                                class="form-control-file" accept="image/*">
                                        </div> 
                                        <form [formGroup]="productSettingsForm">
                                                <div class="form-group  col-md-6 mt-3 ">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" id="showproductimages">
                                                        <label class="custom-control-label" for="showproductimages"> Show Product Images</label>
                                                    </div>
                                                </div>
                                        </form> -->

                                       <div fxFlex="100" class="mt-1 col-md-6" tourAnchor="cptour.gstep9.id">
                                            <button mat-raised-button color="primary" (click)="Submit()"
                                                >Save and Continue</button> 
                                            <!-- <button class="wnext" mat-button (click)="goForward(stepper)" >Next</button> -->         

                                            <!-- <button mat-button color="warn" type="button" class="popup_cancel_btn"
                                                (click)="Reset()">Close</button> -->
                                         </div> 
                                       
                                    </div>
                                </form>
                              
                            </div>
                        </mat-step>
                      
                        <mat-step label="Notification settings " [stepControl]="notificationForm" errorMessage="This form has some errors">
                            <div class="example-large-box mat-elevation-z4">
                                <!-- <app-notification-settings></app-notification-settings> -->
                                <form [formGroup]="notificationForm" >
                                    <div class="form-horizontal card-body">
                                        <div style="float: right;" class="col-md-1 text-center">
                                            <mat-icon matTooltip="Click for a guided Tour" class="pointer"  (click)="notificationTour()">play_circle_outline</mat-icon> 
                                            <h6 style="margin-top: 0mm;">Guided Tour</h6>
                                        </div>
                                         <div class="form-group col-md-6 ws-guided-tour nstep1" tourAnchor="cptour.nstep1.id">
                                            <label for="email">Email *</label>
                                            <input type="email"  id="email" [formControl]="notificationForm.controls['email']"  class="form-control" required>
                                             <!-- <mat-error *ngIf="notificationForm.controls['email'].invalid">not valid</mat-error> --> 
                                             <mat-error *ngIf="notificationForm.controls['email'].hasError('required') && notificationForm.controls['email'].touched" class="form-error-msg">
                                                Email is required</mat-error>
                                                <mat-error *ngIf="notificationForm.controls['email'].hasError('email')">
                                                    Email is not Valid!
                                                </mat-error>
                                         </div> 
                                      <div class="form-group col-md-6 ws-guided-tour nstep2" tourAnchor="cptour.nstep2.id">
                                            <label for="mobile">Mobile Number *</label>
                                            <input type="number" id="mobile" [formControl]="notificationForm.controls['phone']"  class="form-control">
                                             <mat-error *ngIf="notificationForm.controls['phone']?.errors?.required&& notificationForm.controls['phone'].touched">
                                                This is a required field</mat-error> 
                                                <mat-error *ngIf="notificationForm.controls['phone']?.errors?.pattern">
                                                Please enter a valid Phone number  </mat-error>
                                                

                                        </div> 
                                      <div fxFlex="100" class="form-group col-md-6 mt-1" tourAnchor="cptour.nstep3.id">
                                            <button mat-raised-button   color="primary" (click)="Submitnotification()">Save and continue</button>
                                            <!-- <button class="wnext" mat-button (click)="goForward(stepper)" >Next</button>   -->       

                                            <!-- <button mat-button color="warn" type="button" class="popup_cancel_btn" (click)="dialogRef.close(false)">Close</button> -->
                                        </div> 
                                     
                                    </div>
                                </form> 
                            </div>
                        </mat-step>
                        <mat-step label="OFS settings" [stepControl]="ofsSettingsForm" errorMessage="This form has some errors">
                            <div class="example-small-box mat-elevation-z4">
                                <!-- <app-ofs-settings></app-ofs-settings> -->
                                <form [formGroup]="ofsSettingsForm" >
                                    <div class="form-horizontal card-body">
                                        <div style="float: right;" class="col-md-1 text-center">
                                            <mat-icon matTooltip="Click for a guided Tour" class="pointer"  (click)="ofsTour()">play_circle_outline</mat-icon> 
                                            <h6 style="margin-top: 0mm;">Guided Tour</h6>
                                        </div>
                                        <div class="form-group col-md-6 ws-guided-tour ostep1" tourAnchor="cptour.ostep1.id">
                                            <label for="OFname">Name *</label>
                                            <input type="text" id="OFname" [formControl]="ofsSettingsForm.controls['name']"  class="form-control">
                                             <mat-error *ngIf="ofsSettingsForm.controls['name']?.errors?.required && ofsSettingsForm.controls['name'].touched">
                                                this field required</mat-error>
                                        </div> 
                                        <div class="form-group col-md-6 ws-guided-tour ostep2" tourAnchor="cptour.ostep2.id">
                                            <label for="OFlogin">Login ID *</label>
                                            <input type="text" id="OFlogin" [formControl]="ofsSettingsForm.controls['loginId']"  class="form-control">
                                            <mat-error *ngIf="ofsSettingsForm.controls['loginId']?.errors?.required && ofsSettingsForm.controls['loginId'].touched">
                                                this field required</mat-error>
                                        </div> 
                                         <div class="form-group col-md-6 ws-guided-tour ostep3 " tourAnchor="cptour.ostep3.id">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" [formControl]="ofsSettingsForm.controls['changePassword']"  id="customCheck1" (change)="passwordChange=!passwordChange;">
                                                <label class="custom-control-label" for="customCheck1"> Change Password</label>
                                            </div>
                                         </div> 
                                       <div *ngIf = "passwordChange"> 
                                     <div class="form-group col-md-6 " >
                                            <label for="OFpwd">Password *</label>
                                            <input type="password" id="OFpwd" [formControl]="ofsSettingsForm.controls['password']"  class="form-control">
                                             <mat-error *ngIf="ofsSettingsForm.controls['password']?.errors?.required && ofsSettingsForm.controls['password'].touched">
                                                this field required</mat-error>
                                        </div> 
                                        <div class="form-group col-md-6" >
                                            <label for="OFcpwd">Confirm Password *</label>
                                            <input type="password" id="OFcpwd" [formControl]="ofsSettingsForm.controls['confirmPassword']"  class="form-control">
                                            <mat-error *ngIf="ofsSettingsForm.controls['confirmPassword']?.errors?.required && ofsSettingsForm.controls['confirmPassword'].touched">
                                                this field required</mat-error>
                                             <mat-error *ngIf="ofsSettingsForm.controls['confirmPassword']?.errors?.mustMatch  && ofsSettingsForm.controls['confirmPassword'].touched"> Passwords must match </mat-error>   
                                        </div>
                                    </div> 
                                      <div fxFlex="100" class="form-group col-md-6 mt-2" tourAnchor="cptour.ostep4.id">
                                            <button mat-raised-button color="primary" (click)="submitOfs()">Save and continue</button>
                                           <!--  <button class="wnext" mat-button (click)="goForward(stepper)" >Next</button>   -->       
                                            
                                            <!-- <button mat-button color="warn" type="button" class="popup_cancel_btn" (click)="dialogRef.close(false)">Close</button> -->
                                        </div> 
                                    </div>
                                </form> 
                            </div>
                        </mat-step>
                        <!-- <mat-step label="Billing Info">
                            <div class="example-large-box mat-elevation-z4">
                                <app-billing-info></app-billing-info>
                            </div>
                        </mat-step>
                        <mat-step label="Invoice Settings">
                            <div class="example-large-box mat-elevation-z4"> 
                                Large content5
                            </div>
                        </mat-step> -->
                        <mat-step label="Profile settings" [stepControl]="profileSettingsForm" errorMessage="This form has some errors">
                            <div class="example-large-box mat-elevation-z4">
                              <!-- <app-profile-settings></app-profile-settings> -->
                              <form [formGroup]="profileSettingsForm" >
                                <div class="form-horizontal card-body ">
                                    <div style="float: right;" class="col-md-1 text-center">
                                        <mat-icon matTooltip="Click for a guided Tour" class="pointer"  (click)="profileTour()">play_circle_outline</mat-icon> 
                                        <h6 style="margin-top: 0mm;">Guided Tour</h6>
                                    </div>
                                    <div class="form-group col-md-6 ws-guided-tour pstep1" tourAnchor="cptour.pstep1.id">
                                        <label for="profilename">Name *</label>
                                        <input type="text" id="profilename" [formControl]="profileSettingsForm.controls['name']"  class="form-control">
                                        <mat-error *ngIf="profileSettingsForm.controls['name']?.errors?.required && profileSettingsForm.controls['name'].touched">
                                            this field required</mat-error>
                                    </div> 
                                     <div class="form-group col-md-6 ws-guided-tour pstep2" tourAnchor="cptour.pstep2.id">
                                        <label for="profileaddress">Address *</label>
                                        <input type="text" id="profileaddress" [formControl]="profileSettingsForm.controls['address']"  class="form-control">
                                        <mat-error *ngIf="profileSettingsForm.controls['address']?.errors?.required && profileSettingsForm.controls['address'].touched">
                                            this field required</mat-error>
                                    </div> 
                                   
                                     
                                    <div class="form-group col-md-6 ws-guided-tour pstep3" tourAnchor="cptour.pstep3.id">
                                        <label for="profilecity">City *</label>
                                        <input type="text" id="profilecity" [formControl]="profileSettingsForm.controls['city']"  class="form-control">
                                         <mat-error *ngIf="profileSettingsForm.controls['city']?.errors?.required && profileSettingsForm.controls['city'].touched">
                                            this field required</mat-error>
                                    </div>
                                    <div class="form-group col-md-6 ws-guided-tour pstep4" tourAnchor="cptour.pstep4.id">
                                        <label for="profilestate">State</label>
                                        <input type="text" id="profilestate" [formControl]="profileSettingsForm.controls['state']"  class="form-control">
                                    </div> 
                                    <div class="form-group col-md-6 ws-guided-tour pstep5" tourAnchor="cptour.pstep5.id">
                                        <label for="example-multiselect">Country *</label>
                                        <select class="custom-select" [formControl]="profileSettingsForm.controls['country']" >
                                   <option   *ngFor="let item of countryList" [value]="item.countryName">{{item.countryName}}</option>
                                        </select>  
                                    </div>
                                    <div class="form-group  col-md-6 ws-guided-tour pstep6" tourAnchor="cptour.pstep6.id">
                                        <label for="profilezip">Zip/pin</label>
                                        <input type="text" id="profilezip" [formControl]="profileSettingsForm.controls['zipCode']" class="form-control">
                                        <mat-error *ngIf="profileSettingsForm.controls['zipCode']?.errors?.required && profileSettingsForm.controls['zipCode'].touched">
                                            this field required</mat-error>
                                    </div>
                                    <div class="form-group col-md-6 ws-guided-tour pstep7" tourAnchor="cptour.pstep7.id">
                                        <label for="profilemail">Email </label>
                                        <input type="text" id="profileemail" [formControl]="profileSettingsForm.controls['email']"  class="form-control">
                                        <mat-error *ngIf="profileSettingsForm.controls['email']?.errors?.required && profileSettingsForm.controls['email'].touched">
                                            Email is required</mat-error>
                                            <mat-error *ngIf="profileSettingsForm.controls['email'].hasError('email')">
                                                Email is not Valid!
                                            </mat-error>
                                    </div>
                                    <div class="form-group col-md-6 ws-guided-tour pstep8" tourAnchor="cptour.pstep8.id">
                                        <label for="profilemobile">Mobile Number *</label>
                                        <input type="number" id="profilemobile" [formControl]="profileSettingsForm.controls['phone']"  class="form-control">
                                        <mat-error *ngIf="profileSettingsForm.controls['phone']?.errors?.required && profileSettingsForm.controls['phone'].touched">
                                            This field is required</mat-error>
                                            <mat-error *ngIf="profileSettingsForm.controls['phone']?.errors?.pattern || profileSettingsForm.controls['phone']?.errors?.maxlength || profileSettingsForm.controls['phone']?.errors?.minlength">
                                                Please enter a valid Phone number  </mat-error>
                                    </div>
                                  <div fxFlex="100" class="form-group col-md-6 mt-1" tourAnchor="cptour.pstep9.id">
                                        <button mat-raised-button color="primary" (click)="submitProfile()">Save and continue</button>
                                            <!-- <button class="wnext" mat-button (click)="goForward(stepper)" >Next</button>          -->

                                        <!-- <button mat-button color="warn" type="button" class="popup_cancel_btn" (click)="Reset()">Close</button> -->
                                    </div> 
                                    
                                </div>
                            </form> 
                            </div>
                        </mat-step>
                        <mat-step label="Image Upload" [stepControl]="imageSettingsForm">
                            <div class="example-large-box mat-elevation-z4">
                                <form [formGroup]="imageSettingsForm" >
                                    <div class="form-horizontal card-body ">
                                        <div style="float: right;" class="col-md-1 text-center">
                                            <mat-icon matTooltip="Click for a guided Tour" class="pointer"  (click)="imageTour()">play_circle_outline</mat-icon> 
                                            <h6 style="margin-top: 0mm;">Guided Tour</h6>
                                        </div>
                                        <div class="row">
                                        <div class="form-group col-md-6"  tourAnchor="cptour.istep1.id">
                                            <label for="logo">Logo</label>
                                            <input 
                                            [formControl]="imageSettingsForm.controls['logo']" 
                                                id="logo" 
                                                type="file" 
                                                class="form-control"
                                                multiple="" 
                                                (change)="onFileChangedLogo($event)">
                                         </div>
                                         <div>
                                            <button class="mt-3  ml-3 mr-2"  mat-raised-button color="primary" [disabled]="uploadLogoVar" (click)="uploadLogo()">
                                               Upload Logo</button>
                                           </div>
                                        </div>
                                      <div class="form-group pl-2" tourAnchor="cptour.istep2.id"><strong>Slides for Banner</strong></div>
                                      
                                      <div class="col-md-6 pb-2" *ngFor="let slides of bannerImages; let i=index" >
                                        <mat-grid-list cols="2" rowHeight="50px">
                                          <mat-grid-tile>
                                        {{i+1}} .   {{slides.name}}
                                    </mat-grid-tile>
                                <mat-grid-tile>
                                        <button class="ml-2"  color="primary" mat-raised-button  (click)="deleteBanner(slides.id)">Delete Slide</button>
                                    </mat-grid-tile>
                                     
                                    </mat-grid-list>
                                </div>

                                    <h4 class="ml-2 pb-2" tourAnchor="cptour.istep3.id">Upload New Slides</h4>
                                     
                        
                                              <div class="row">
                                          
                                      <div class="ml-2 col-md-3 pb-2">
                                            <label for="title">Title for Slide</label>
                                            <input type="text"  id="title" formControlName="bannerText" class="form-control">
                                        </div>
                                        <!--
                                        <div class="col-md-3 pb-2">
                                            <label for="description">Description for Slide</label>
                                            <input type="text" id="description" formControlName='bannerDescription'  class="form-control">
                                        </div> -->

                                         <div class="col-md-6 ml-2 pb-2">
                                            <label for="file">Slide Image</label>
                                            {{imageError}}
                                            <input 
                                            formControlName='bannerImage'
                                           
                                                id="file" 
                                                type="file" 
                                                class="form-control"
                                                multiple="" 
                                                (change)="onFileChanged($event)">
                                         </div>
<div>
                                         <button class="mt-3  ml-3 mr-2"  mat-raised-button color="primary" [disabled]="isUploading" (click)="uploadBanner()">
                                            Upload Image</button>
                                        </div>
                                    </div>
                                        



                                        <div fxFlex="100" class="form-group col-md-6 mt-3"  tourAnchor="cptour.istep4.id" >
                                            <button mat-raised-button color="primary" (click)="submitImages()">Save and continue</button>
                                             
    
                                            <!-- <button mat-button color="warn" type="button" class="popup_cancel_btn" (click)="Reset()">Close</button> -->
                                        </div> 
                                    
                                    </div>
                                 </form>

                           </div>

                        </mat-step>

                        <mat-step label="Footer links" [stepControl]="footerSettingsForm">
                            <div class="example-large-box mat-elevation-z4">
                                <form [formGroup]="footerSettingsForm" >
                                    <div class="form-horizontal card-body ">
                                        <div style="float: right;" class="col-md-1 text-center">
                                            <mat-icon matTooltip="Click for a guided Tour" class="pointer"  (click)="footerTour()">play_circle_outline</mat-icon> 
                                            <h6 style="margin-top: 0mm;">Guided Tour</h6>
                                        </div>
                                        <div class="form-group col-md-6" tourAnchor="cptour.fstep1.id">
                                            <label for="AboutUs">About Us </label>
                                            <input type="textarea" id="AboutUs" [formControl]="footerSettingsForm.controls['aboutUs']"  class="form-control">
                                    </div>

                                    <div class="form-group col-md-6" tourAnchor="cptour.fstep2.id">
                                        <label for="facebookUrl" >Facebook</label>
                                        <input type="text" id="facebookUrl" [formControl]="footerSettingsForm.controls['facebookUrl']"  class="form-control">
                                    </div>
                                           
                                    <div class="form-group col-md-6" tourAnchor="cptour.fstep3.id">
                                        <label for="linkedinUrl">Linkedin</label>
                                        <input type="text" id="linkedinUrl" [formControl]="footerSettingsForm.controls['linkedinUrl']"  class="form-control">
                                    </div>
                                    <div class="form-group col-md-6" tourAnchor="cptour.fstep4.id">
                                        <label for="instagramUrl">Instagram </label>
                                        <input type="text" id="instagramUrl" [formControl]="footerSettingsForm.controls['instagramUrl']"  class="form-control">
                                    </div>
                                    <div class="form-group col-md-6" tourAnchor="cptour.fstep5.id">
                                        <label for="twitterURL">Twitter </label>
                                        <input type="text" id="twitterUrl" [formControl]="footerSettingsForm.controls['twitterUrl']"  class="form-control">
                                    </div>
                                   
                                    <div class="form-group col-md-6" tourAnchor="cptour.fstep6.id">
                                        <label for=" youtubeUrl">Youtube </label>
                                        <input type="text" id=" youtubeUrl" [formControl]="footerSettingsForm.controls['youtubeUrl']"  class="form-control">
                                    </div>
                                            

                                        <div fxFlex="100" class="form-group col-md-6 mt-1" tourAnchor="cptour.fstep7.id">
                                            <button mat-raised-button color="primary" (click)="submitFooter()">Save</button>
                                             
                                        </div> 

                                    </div>
                                 </form>

                           </div>

                        </mat-step> 
                        <mat-step label="Posibolt Settings" [stepControl]="posiboltSettingsForm">
                            <div class="example-large-box mat-elevation-z4">
                                <form [formGroup]="posiboltSettingsForm" >
                                    <div class="form-horizontal card-body ">
                                        <div style="float: right;" class="col-md-1 text-center">
                                            <mat-icon matTooltip="Click for a guided Tour" class="pointer"  (click)="posiboltTour()">play_circle_outline</mat-icon> 
                                            <h6 style="margin-top: 0mm;">Guided Tour</h6>
                                        </div>
                                        <div class="form-group col-md-6" tourAnchor="cptour.pbstep1.id">
                                            <label for="DomainUrl">Posibolt Domain Url</label>
                                            <input type="text" id="DomainUrl" [formControl]="posiboltSettingsForm.controls['domainUrl']"  class="form-control">
                                    </div>

                                    <div class="form-group col-md-6" tourAnchor="cptour.pbstep2.id">
                                        <label for="authUsername" >Auth UserName</label>
                                        <input type="text" id="authUsername" [formControl]="posiboltSettingsForm.controls['authUsername']"  class="form-control">
                                    </div>
                                           
                                    <div class="form-group col-md-6" tourAnchor="cptour.pbstep3.id">
                                        <label for="authPassword">Auth Password</label>
                                        <input type="text" id="authPassword" [formControl]="posiboltSettingsForm.controls['authPassword']"  class="form-control">
                                    </div>
                                    <div class="form-group col-md-6" tourAnchor="cptour.pbstep4.id">
                                        <label for="posiboltUsername">Posibolt UserName </label>
                                        <input type="text" id="posiboltUsername" [formControl]="posiboltSettingsForm.controls['posiboltUsername']"  class="form-control">
                                    </div>
                                    <div class="form-group col-md-6" tourAnchor="cptour.pbstep5.id">
                                        <label for="posiboltPassword">Posibolt Password </label>
                                        <input type="text" id="posiboltPassword" [formControl]="posiboltSettingsForm.controls['posiboltPassword']"  class="form-control">
                                    </div>
                                   
                                    <div class="form-group col-md-6" tourAnchor="cptour.pbstep6.id">
                                        <label for="terminal">Terminal </label>
                                        <input type="text" id="terminal" [formControl]="footerSettingsForm.controls['terminal']"  class="form-control">
                                    </div>
                                            

                                        <div fxFlex="100" class="form-group col-md-6 mt-1" tourAnchor="cptour.fstep7.id">
                                            <button mat-raised-button color="primary" (click)="submitPosiboltSettings()">Save</button>
                                             
                                        </div> 

                                    </div>
                                 </form>

                           </div>

                        </mat-step>
                        <mat-step label="Posibolt General Settings" [stepControl]="posiboltGeneralSettingsForm">
                            <div class="example-large-box mat-elevation-z4">
                                <form [formGroup]="posiboltGeneralSettingsForm" >
                                    <div class="form-horizontal card-body ">
                        <div class="inventory" tourAnchor="cptour.pbgstep1.id">
                            <mat-checkbox [formControl]="posiboltGeneralSettingsForm.controls['saveProduct']"
                            [(ngModel)]="checked">Save products to draft before downloading Image </mat-checkbox>
                        </div>
                        <div class="inventory" tourAnchor="cptour.pbgstep2.id">
                            <mat-checkbox [formControl]="posiboltGeneralSettingsForm.controls['changeStock']"
                            [(ngModel)]="checked">Change in Stock to Low Stock</mat-checkbox>
                        </div>
                        <div class="inventory" tourAnchor="cptour.pbgstep1.id">
                            <mat-checkbox [formControl]="posiboltGeneralSettingsForm.controls['sendShipping']"
                            [(ngModel)]="checked">Send Shipping charge to Posibolt</mat-checkbox>
                        </div>
                        <div class="inventory" tourAnchor="cptour.pbgstep1.id">
                            <mat-checkbox [formControl]="posiboltGeneralSettingsForm.controls['backgroundImage']"
                            [(ngModel)]="checked">Enable Backgroung Image Sync</mat-checkbox>
                        </div>
                        <div class="inventory" tourAnchor="cptour.pbgstep1.id">
                            <mat-checkbox [formControl]="posiboltGeneralSettingsForm.controls['allocateInvoice']"
                            [(ngModel)]="checked">Automatically allocate payment when generating an invoice</mat-checkbox>
                        </div>

                        <div class="form-group col-md-6" tourAnchor="cptour.fstep1.id">
                            <label for="syncEmail">Sync Notification Emails (Seperated by Commas)</label>
                            <input type="textarea" id="syncEmail" [formControl]="footerSettingsForm.controls['syncEmail']"  class="form-control">
                    </div>



                        <div fxFlex="100" class="form-group col-md-6 mt-1" tourAnchor="cptour.fstep7.id">
                            <button mat-raised-button color="primary" (click)="submitPosiboltGeneralSettings()">Save</button>
                             
                        </div> 
                        </div>
                        </form>
                        </div>
                    </mat-step>
                    <mat-step>

                       <h4>Set Sync Schedule {{jstoday}}</h4> 

                        <mat-radio-group aria-label="Select Cron For">
                            <mat-radio-button value="1">Data Sync</mat-radio-button>
                            <mat-radio-button value="2">Image Sync</mat-radio-button>
                        </mat-radio-group>
                        <div>
                        <label id="example-radio-group-label">Schedule</label>
                            <mat-radio-group
                            aria-labelledby="example-radio-group-label"
                             class="example-radio-group"
                             [(ngModel)]="selectedSchedule">
                            <mat-radio-button class="example-radio-button" *ngFor="let schedule of schedules" [value]="schedule">
                             {{schedule}}
                             </mat-radio-button>
                        </mat-radio-group>
                        </div>
                        <div>Your Selected Schedule: {{selectedSchedule}}</div>

                        <div>
                            <h4>Scheduling Process</h4>

                            
                        </div>

                    </mat-step>

                    </mat-horizontal-stepper>
                </div>
            </div>
        </div>
    </div>
</div>

