
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ControlPanelService } from '../../control-panel/control-panel.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HostListener} from "@angular/core";
import { TourService } from 'ngx-tour-ngx-popper';
import { HttpErrorResponse } from '@angular/common/http';
import {WebhookConf, WebhookSettingsConf} from '../Models/webhookSettings';


@Component({
  selector: 'app-webhooksettings',
  templateUrl: './webhooksettings.component.html',
  styleUrls: ['./webhooksettings.component.css']
})
export class WebhooksettingsComponent implements OnInit {

  public webhookSettingsForm : FormGroup;
  hideSubmitStep = false;
  hidePrevStep = false;
  checked = false;
  tempPrevTourStepCount = 0;
  tempPrevTourStep;
  readOnlyTextArea = true;
  readOnlyCancelOrder = true;
  readOnlyPlaceOrder = true;
  readOnlyUpdateOrder = true;
  readOnlyDeliveryAddress = true;
  readOnlyProduct = true;
  readOnlyBuyer = true;
  showhideTextArea: boolean;
  warehouseSettingsDetails: any;
  webhookSettingsDetails:any;
  id:string="";
  sellerId:string="";
  erpType:string="";
  webhookSettings = {} as WebhookSettingsConf;
  webhookConf = [] ;
  
  successMessage:string="";
  errorMessage:string="";

  constructor(private fb: FormBuilder,
    private controlPanelService: ControlPanelService,
    public activateRoute: ActivatedRoute,
    public whTourService: TourService,
    private toastr: ToastrService) { }

    public webhookTour(): void {
        
     this.whTourService.disableHotkeys();
     this.whTourService.start(); 
   }

  ngOnInit(): void {

    this.webhookSettingsForm =  this.fb.group({
      placeOrder: ['' || '',],
      placeOrderUrl: ['' || '',],
      cancelOrder: ['' || '',],
      cancelOrderUrl: ['' || '',],
      updateOrder: ['' || '',],
      updateOrderUrl: ['' || '',],
      deliveryAddress: ['' || '',],
      deliveryAddressUrl: ['' || '',],
      addBuyer: ['' || '',],
      addBuyerUrl: ['' || '',],
      addProduct: ['' || '',],
      addproductUrl: ['' || '',],
    });



  this.whTourService.initialize([
    {   
     
      stepId : 'cptour.whstep1.id',
      anchorId: 'cptour.whstep1.id',
      content: 'Please check if you are enabling' ,
      title: 'Order Webhook',
      endBtnTitle:'Skip',
      placement:"right", 
     
    },
      
     {    
    stepId : 'cptour.whstep2.id',    
    anchorId: 'cptour.whstep2.id',
    content: 'Please enter the URL' ,
    title: 'Order Webhook',
    endBtnTitle:'Skip',
    placement:"right", 
    
    },
    {    
      stepId : 'cptour.whstep3.id',
      anchorId: 'cptour.whstep3.id',
      content: 'Please check if you are enabling' ,
      title: 'Order webhook',
      endBtnTitle:'Skip',
      placement:"right", 
      
      },
      {    
        stepId : 'cptour.whstep4.id',
        anchorId: 'cptour.whstep4.id',
        content: 'Please enter the URL' ,
        title: 'Order Webhook',
        endBtnTitle:'Skip',
        placement:"right", 
        
        },
        {    
          stepId : 'cptour.whstep5.id',
          anchorId: 'cptour.whstep5.id',
          content: 'Please check if you are enabling',
          title: 'UserName',
          endBtnTitle:'Skip',
          placement:"right", 
          
          },
    
          {
            anchorId: 'cptour.whstep6.id',
            content: 'Please enter the URL.',
            title: 'Save and Submit',
            placement:'right',
            
          },
],
{route: 'webhooksettings',
});

this.controlPanelService.getwarehouseSettings().subscribe(
  res => {
    this.warehouseSettingsDetails = res.warehouseSettings;

    this.id = this.warehouseSettingsDetails.shopId;
  }
);

this.controlPanelService.getWebhookSettings().subscribe(
  res => {
    this.webhookSettingsDetails = res;
    console.log(this.webhookSettingsDetails);
    this.sellerId = this.webhookSettingsDetails.sellerId;
    this.erpType =this.webhookSettingsDetails.erpType;
    this.webhookSettingsForm.controls['addBuyer'].setValue(this.webhookSettingsDetails.configurations[0].configValue);
    this.webhookSettingsForm.controls['addBuyerUrl'].setValue(this.webhookSettingsDetails.configurations[1].configValue);
    this.webhookSettingsForm.controls['addProduct'].setValue(this.webhookSettingsDetails.configurations[2].configValue);
    this.webhookSettingsForm.controls['addProductUrl'].setValue(this.webhookSettingsDetails.configurations[3].configValue);
    this.webhookSettingsForm.controls['cancelOrder'].setValue(this.webhookSettingsDetails.configurations[4].configValue);
    this.webhookSettingsForm.controls['cancelOrderUrl'].setValue(this.webhookSettingsDetails.configurations[5].configValue);
  }
  );






    }

    @HostListener('click',['$event'])mouseover(event) {  
      if (this.whTourService.currentStep) {
        const nextStep = $(event.target).closest(".ws-guided-tour").attr("tourAnchor")
        if (nextStep && this.tempPrevTourStep !== nextStep) {

          this.whTourService.startAt(nextStep);
          this.tempPrevTourStep = nextStep;
        }      
      }
    };
    @HostListener('document:keydown.tab', ['$event'])
    onKeydownHandler(event: KeyboardEvent) {
     if (this.whTourService.currentStep) {
           this.whTourService.next();
     
       }    
     }

    submitWebhookSettings(){

      console.log(this.webhookSettingsForm.value);
     
      if(this.webhookSettingsForm.controls['addBuyer'].value === true){
        let conf={
          "configKey":"ENABLE_CREATE_BUYER_WH",
​​​         "configValue":"true"
        }
        this.webhookConf.push(conf);
        let conf1={
          "configKey": "CREATE_BUYER_WH_URL",
          ​"configValue":this.webhookSettingsForm.controls['addBuyerUrl'].value
        }
        this.webhookConf.push(conf1)
       }
      else {
        let conf={
          "configKey":"ENABLE_CREATE_BUYER_WH",
​​​         "configValue":"false"
        }
        this.webhookConf.push(conf);
        let conf1={
          "configKey": "CREATE_BUYER_WH_URL",
          ​"configValue":""
        }
        this.webhookConf.push(conf1)
      }

      if(this.webhookSettingsForm.controls['addProduct'].value === true)
      {
        let conf2={
          "configKey":"ENABLE_CREATE_PRODUCT_WH",
          ​​ "configValue":"true"
        }
        this.webhookConf.push(conf2);
        let conf3={
          "configKey": "CREATE_PRODUCT_WH_URL",
          ​"configValue":this.webhookSettingsForm.controls['addproductrUrl'].value
        }
        this.webhookConf.push(conf3)
      }
      else{
        let conf2={
          "configKey":"ENABLE_CREATE_PRODUCT_WH",
          ​​ "configValue":"false"
        }
        this.webhookConf.push(conf2);
        let conf3={
          "configKey": "CREATE_PRODUCT_WH_URL",
          ​"configValue":""
        }
        this.webhookConf.push(conf3)
      }

      if(this.webhookSettingsForm.controls['cancelOrder'].value === true){

        let conf4={
          "configKey":"ENABLE_CANCEL_ORDER_WH",
          "configValue":"true"
        }
        this.webhookConf.push(conf4);
        let conf5={
          "configKey":"CANCEL_ORDER_WH_URL",
          "configValue":this.webhookSettingsForm.controls['cancelOrderUrl'].value
        }
        this.webhookConf.push(conf5);
      } 
      else {
        let conf4={
          "configKey":"ENABLE_CANCEL_ORDER_WH",
          "configValue":"false"
        }
        this.webhookConf.push(conf4);
        let conf5={
          "configKey":"CANCEL_ORDER_WH_URL",
          "configValue":""
        }
        this.webhookConf.push(conf5);

      }

      if(this.webhookSettingsForm.controls['deliveryAddress'].value === true){
        let conf6={
          "configKey":"ENABLE_DELIVERY_ADDRESS_WH",
          "configValue":"true"
        }
        this.webhookConf.push(conf6);
        let conf7={
          "configKey":"DELIVERY_ADDRESS_WH_URL",
          "configValue":this.webhookSettingsForm.controls['deliveryAddressUrl'].value
        }
        this.webhookConf.push(conf7);
        
        
      }
      else{

        let conf6={
          "configKey":"ENABLE_DELIVERY_ADDRESS_WH",
          "configValue":"false"
        }
        this.webhookConf.push(conf6);
        let conf7={
          "configKey":"DELIVERY_ADDRESS_WH_URL",
          "configValue":""
        }
        this.webhookConf.push(conf7);
      }

      if(this.webhookSettingsForm.controls['placeOrder'].value === true){
        let conf8={
          "configKey":"ENABLE_CREATE_ORDER_WH",
          "configValue":"true"
        }
        this.webhookConf.push(conf8);
        let conf9={
          "configKey": "CREATE_ORDER_WH_URL",
          "configValue":this.webhookSettingsForm.controls['placeOrderUrl'].value
        }
        this.webhookConf.push(conf9); 
      }      
      else{
        let conf8={
          "configKey":"ENABLE_CREATE_ORDER_WH",
          "configValue":"false"
        }
        this.webhookConf.push(conf8);
        let conf9={
          "configKey": "CREATE_ORDER_WH_URL",
          "configValue":""
        }
        this.webhookConf.push(conf9); 

      }

      if(this.webhookSettingsForm.controls['updateOrder'].value === true){
        let conf10={
          "configKey":"ENABLE_UPDATE_ORDER_WH",
          "configValue":"true"
        }
        this.webhookConf.push(conf10);
        let conf11={
          "configKey": "UPDATE_ORDER_WH_URL",
          "configValue":this.webhookSettingsForm.controls['updateOrderUrl'].value
        }
        this.webhookConf.push(conf11); 
      }
      else{
        let conf10={
          "configKey":"ENABLE_UPDATE_ORDER_WH",
          "configValue":"false"
        }
        this.webhookConf.push(conf10);
        let conf11={
          "configKey": "UPDATE_ORDER_WH_URL",
          "configValue":''
        }
        this.webhookConf.push(conf11); 

      }

      if(this.webhookConf.length === 12){

        this.webhookSettings={
          "sellerId" : this.sellerId,
          "erpType":this.erpType,
          "configurations":this.webhookConf
        }

        if(this.webhookSettings){
          this.controlPanelService.saveWebhookSettings(this.webhookSettings).subscribe(
            (res: any) => {
              if (res.acknowledgement.status === "SUCCESS") {
                this.successMessage = res.acknowledgement.message
                this.toastr.success(this.successMessage, "SUCCESS")
              }
              else {
                this.errorMessage = res.acknowledgement.message
                this.toastr.error(this.errorMessage, "ERROR")
              }
    
            },
            (err: HttpErrorResponse) => {
              if (err) {
                this.errorMessage = err.error.acknowledgement.message
                this.toastr.error(this.errorMessage, "ERROR")
              }
              else {
                this.toastr.error("Sorry, the information was not updated.", "ERROR")
              }
    
            }
          );
        }

      }

      
    }

    textAreaReadonly( value ) {
      this.readOnlyTextArea = !this.readOnlyTextArea;
    }

    cancelOrderReadonly( value ) {
      this.readOnlyCancelOrder = !this.readOnlyCancelOrder;
    }

    placeOrderReadonly( value ) {
      this.readOnlyPlaceOrder = !this.readOnlyPlaceOrder;
    }

    updateOrderReadonly( value ) {
      this.readOnlyUpdateOrder = !this.readOnlyUpdateOrder;
    }

    deliveryAddressReadonly( value ) {
      this.readOnlyDeliveryAddress = !this.readOnlyDeliveryAddress;
    }

    productReadonly( value ) {
      this.readOnlyProduct = !this.readOnlyProduct;
    }

    buyerReadonly( value ) {
      this.readOnlyBuyer = !this.readOnlyBuyer;
    }


    



}

