import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ControlPanelService } from '../../control-panel/control-panel.service';
import { WarehouseSettings } from '../../control-panel/models/WarehouseSettings.model'
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HostListener } from "@angular/core";
import { TourService } from 'ngx-tour-ngx-popper';
import { HttpErrorResponse } from '@angular/common/http';
import { OfsSettings } from '../../control-panel/models/OfsSettings.model';
import { SettingsComponent } from '../settings.component';

@Component({
  selector: 'app-ofs',
  templateUrl: './ofs.component.html',
  styleUrls: ['./ofs.component.css']
})
export class OfsComponent implements OnInit {
 public ofsSettingsForm: FormGroup;
 ofsSettingsDetails: any;
 hideSubmitStep = false;
 hidePrevStep = false;
 successMessage:string="";
  errorMessage:string="";
  countryList:any;
  timeZoneList:any;
  ofsId:string="";
  warehouseSettingsDetails: any;
  id:any;
  continue=false;
  tempPrevTourStep;
  checked:boolean=false;
  codeSelected =false;
  loginSelected =false;
  passwordSelected =false;
  showContinueButton= false;

  constructor( private fb: FormBuilder,
    private controlPanelService: ControlPanelService,
    public activateRoute: ActivatedRoute,
    private router: Router,
    public oTourService: TourService,
    private toastr: ToastrService,private settings:SettingsComponent) { }

  ngOnInit(): void {

    this.ofsSettingsForm = this.fb.group({
      name: ['' || '', Validators.required],
      phone: ['' || ''],
      countryCode: ['' || ''],
      loginId: ['' || '', Validators.required],
      password: [''|| '', Validators.required],
      confirmPassword: ['' || '',Validators.required],
      changePassword:['' || '']
    },
    {
      validator: this.MustMatch('password', 'confirmPassword'),
    }
    );

    this.controlPanelService.getwarehouseSettings().subscribe(
      res => {
        this.warehouseSettingsDetails = res.warehouseSettings;
        if(this.warehouseSettingsDetails === null)
        {
        this.toastr.error("Please Update the WareHouse Settings first to Continue","ERROR")
          this.continue=true;
        }
        else
        this.id = this.warehouseSettingsDetails.shopId;
      });

      this.controlPanelService.tenantCountryList().subscribe(
        res => {
          this.countryList = res.country;
        });

    this.controlPanelService.getofsSettings().subscribe(
      res => {
        this.ofsId="NEW";
        this.ofsSettingsDetails = res.ofsSettings;
        
       
       
       
       if(this.ofsSettingsDetails){
        if(this.ofsSettingsDetails.userId >0)
        this.ofsId =this.ofsSettingsDetails.userId;
        this.ofsSettingsForm.controls['name'].setValue(this.ofsSettingsDetails.name);
        this.ofsSettingsForm.controls['countryCode'].setValue(this.ofsSettingsDetails.countryCode);
        if(this.ofsSettingsDetails.countryCode)
        this.codeSelected=true;

        this.ofsSettingsForm.controls['phone'].setValue(this.ofsSettingsDetails.phone);
        this.ofsSettingsForm.controls['loginId'].setValue(this.ofsSettingsDetails.loginId);
        if(this.ofsSettingsDetails.loginId)
        this.loginSelected=true;
        this.ofsSettingsForm.controls['password'].setValue(this.ofsSettingsDetails.password);
        if(this.ofsSettingsDetails.password)
        this.passwordSelected=true;
        this.ofsSettingsForm.controls['confirmPassword'].setValue(this.ofsSettingsDetails.password);
       }
      }
      );

      this.oTourService.initialize([
        {
          stepId : 'cptour.ostep1.id',
         anchorId: 'cptour.ostep1.id',
         content: 'Username' ,
         title:'Order Fulfillment Username ',
         placement:'right',
         endBtnTitle:'Skip',
         
        
       },
       {
        stepId : 'cptour.ostep2.id',
       anchorId: 'cptour.ostep2.id',
       content: 'Phone' ,
       title:'Order Fulfillment Phone ',
       placement:'right',
       endBtnTitle:'Skip',
       
      
     },

       { 
        stepId: 'cptour.ostep3.id',
         anchorId: 'cptour.ostep3.id',
         title: 'Login ID' ,
         content: 'Login id used to login in OFS', 
         placement:'right',
         endBtnTitle:'Skip',
        
         
       },
      
      {  
        stepId: 'cptour.ostep4.id',
         anchorId: 'cptour.ostep4.id',
         title: 'Change Password' ,
         content:'Check if you need to update password',
         placement:'right',
         endBtnTitle:'Skip',
         
       },
       
       {
        anchorId: 'cptour.ostep5.id',
        content: 'Please press this button to save all the above information',
        title: 'Save and Submit',
        placement:'right',
        
      } ],
        {route: 'settings',
        });
    }
  
        @HostListener('click',['$event'])mouseover(event) {  
          if (this.oTourService.currentStep) {
            const nextStep = $(event.target).closest(".ws-guided-tour").attr("tourAnchor")
            if (nextStep && this.tempPrevTourStep !== nextStep) {
    
              this.oTourService.startAt(nextStep);
              this.tempPrevTourStep = nextStep;
            }      
          }
        };
        @HostListener('document:keydown.tab', ['$event'])
        onKeydownHandler(event: KeyboardEvent) {
         if (this.oTourService.currentStep) {
               this.oTourService.next();
         
           }    
         }
      
  
  public ofsTour(): void {
  
    this.oTourService.disableHotkeys();
    this.oTourService.start(); 
  }
  passwordChange(){
    this.passwordSelected=false;
    this.ofsSettingsForm.controls['password'].reset();
    this.ofsSettingsForm.controls['confirmPassword'].reset();
  }

  submitOfs(){
    this.ofsSettingsForm.markAllAsTouched();
    if(this.ofsSettingsForm.valid){
    var obj: OfsSettings = this.ofsSettingsForm.value;
    if(this.checked === true)
    obj['changePassword'] = true;
    else
    obj['changePassword'] = false;
    obj['userId'] = this.ofsId;
    obj['shopId'] = this.warehouseSettingsDetails.shopId
   
    this.controlPanelService.saveofsSettings(obj).subscribe(
      (res: any) => {
        if(res.acknowledgement.status==="SUCCESS"){
          this.showContinueButton=true;
          this.successMessage=res.acknowledgement.message
          this.toastr.success( this.successMessage,"SUCCESS")
        }
        else{
          this.successMessage=res.acknowledgement.message
          this.toastr.error(this.successMessage,"ERROR")
        }
       
         
      },
          (err:HttpErrorResponse) => {
           
            if(err)
            {
               this.errorMessage=err.error.acknowledgement.message
               this.toastr.error(this.errorMessage,"ERROR")
            }
            else{
              this.toastr.error("Sorry,the information was not updated,Please try again later","ERROR")
            }
  
         }
    );
  }
  }

  continueToProfile(){
    this.settings.openProfileSettings();
    this.settings.toggleColor(5);
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}

}
