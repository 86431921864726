import { Component, OnInit,Inject } from '@angular/core';
import { FormGroup,FormBuilder,Validators } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {BuyersService} from './../buyers.service'
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-buyergroupsettings',
  templateUrl: './buyergroupsettings.component.html',
  styleUrls: ['./buyergroupsettings.component.css']
})
export class BuyergroupsettingsComponent implements OnInit {

  editBuyergroupForm: FormGroup;
  groupInfo:any;
  groupName:string="";
  groupOffer:string="";
  groupMoq:string="";
  groupDeviation:string="";
  value:string="";
  checkedBase:boolean=false;
  checkedPercent:boolean=false;
  offerYes:boolean=false;
  offerNo:boolean=true;


  itemsList = [
    {name: 'Deviation of Base price in value',value :'value'},
    {name: 'Deviation of Base price in percentage',value:'percentage'},
    
  ];

 
  statusOfParameters:string[]=["A","A","A","A"];
  result:any;
  
    

  constructor(private buyersService:BuyersService,
    private toastr:ToastrService,
    public fb: FormBuilder,
    public dialog: MatDialog,private dialogRef: MatDialogRef<BuyergroupsettingsComponent>,
   
    @Inject(MAT_DIALOG_DATA) data) { 
      this.groupInfo =data.group;
      this.groupName=data.name;
      console.log(data)
    }

  ngOnInit(): void {
    
    this.editBuyergroupForm = this.fb.group({
      groupName: [this.groupName || '', Validators.required],
      groupDesc:[''||''],
      valueOrPercent:[''||''],
      deviation: ['' || ''],
      paramOffer:[''||''],
      paramMoq:[''||''],
    });

    if (this.groupInfo)
    {
    for(var i=0;i<this.groupInfo.length;i++){
      switch (this.groupInfo[i].parameter) {
        case "Offer Price":
          this.offerYes=true;
          this.offerNo=false;
          this.groupOffer = this.groupInfo[i].value;
          break;
          case "Minimum ordering quantity (MOQ)":
          this.groupMoq = this.groupInfo[i].value;
          break;
          case "Deviation of Base price in value":
          this.checkedBase =true;
          this.groupDeviation = this.groupInfo[i].value;
          break;
          case "Deviation of Base price in percentage":
          this.checkedPercent=true;
          this.groupDeviation = this.groupInfo[i].value;
          break;
        default:
          break;
      }
    
    }

    this.editBuyergroupForm.patchValue({
    deviation:this.groupDeviation,
    paramOffer:this.groupOffer,
    paramMoq:this.groupMoq

    });
  }

  } 


  save(){
    if (!this.editBuyergroupForm.valid) {
      console.log("Not valid Form")
      this.toastr.error("Please check if you have entered all the required  * fields","Error")
    } 
    else{
    console.log(this.editBuyergroupForm.value);
    
    this.dialogRef.close(this.editBuyergroupForm.value); 
    } 
  }
  
  close() {
    this.dialogRef.close();  


  }

}
