<div class="example-container">

    <h2 mat-dialog-title>Buyer details</h2>
    <hr>

    <mat-dialog-content [formGroup]="buyerSettings" (submit)="save()">
  
       <div class ="row">
        <div class="col">
            <h5>Business Name</h5>
        
        <mat-form-field appearance="outline">
         <input formControlName="name" matInput required>
          <mat-error>
            This is a required field
          </mat-error>
        </mat-form-field>
    </div>
    <div class="col">
            
      
        <h5>Buyer Groups</h5>
            <mat-form-field appearance="outline">
                <!-- <mat-label>Buyer Group</mat-label> -->
                <mat-select formControlName="groupId" [placeholder]="selected" >
                  <mat-option *ngFor="let item of buyerGroups" [value]="item.id" required>
                    {{item.name}}
                  </mat-option>
                </mat-select>
                <mat-error>
                    This is a required field
                  </mat-error>
            </mat-form-field>
       
    </div>
    <div class="col">
        <h5>Phone</h5>
    
        <mat-form-field appearance="outline">
            
          <input formControlName="phone" matInput >
          
        </mat-form-field>
    </div> 
   
</div>
        <div class="row">
            <div class="col">
                <h5>Email</h5>
                <mat-form-field appearance="outline">
                    
                    <input matInput formControlName="email" required>
                    
                    <mat-error>
                      Please provide a valid email address
                    </mat-error>
                </mat-form-field>
            </div>
       
       <div class="col">
            <h5>Address</h5>
                <mat-form-field appearance="outline">
                    
                    <input formControlName="addressLine1" matInput>
                </mat-form-field>
            </div>
            <div class="col">
                <h5>State</h5>
                    <mat-form-field appearance="outline">
                        
                        <input formControlName="state" matInput>
                    </mat-form-field>
                </div>
                
        </div>
    <div class="row">
        <div class="col">
            <h5>City</h5>
                <mat-form-field appearance="outline">
                    
                    <input formControlName="city" matInput>
                </mat-form-field>
            </div>
        <div class="col">
            <h5>Latitude</h5>
                <mat-form-field appearance="outline">
                    
                    <input formControlName="latitude" matInput>
                </mat-form-field>
            </div>
            <div class="col">
                <h5>Longitude</h5>
                    <mat-form-field appearance="outline">
                        
                        <input formControlName="longitude" matInput>
                    </mat-form-field>
                </div>
               
        </div>

        <div class="row">
            <div class="col">
                <h5>Pin</h5>
                    <mat-form-field appearance="outline">
                        
                        <input formControlName="pin" matInput>
                    </mat-form-field>
                </div>
          
         <div class="col">
            <h5>Country</h5>
            <mat-form-field appearance="outline">
                <mat-label>Country</mat-label>
            <mat-select formControlName="countryId" placeholder="Country">
                <mat-option *ngFor="let item of countryList" [value]="item.countryCode" required>
                    {{item.countryName}}
                </mat-option>
            </mat-select>
            <mat-error>
                This is a required field
              </mat-error>
          </mat-form-field>   
        <!-- <h5>Registered Time</h5>
            <mat-form-field appearance="outline" readonly>
                
                <input formControlName="registeredTime" matInput >
            </mat-form-field> -->
        </div>
        <div class="col">
            <h5>Tax Number</h5>
            <mat-form-field appearance="outline">
                     
                 <input formControlName="taxNumber" matInput required>
                 <mat-error>
                    This is a required field
                  </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <h5>Time Zone</h5>  
        <mat-form-field appearance="outline">
            <mat-label>TimeZone</mat-label>
            <mat-select formControlName="timezoneId">
              <mat-option *ngFor="let item of timeZoneList" [value]="item.timeZoneId" required>
                {{item.zoneName}}
              </mat-option>
            </mat-select>
            <mat-error>
                This is a required field
              </mat-error>
          </mat-form-field>
    </div>
   
</div>
       
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <button class="mat-raised-button"(click)="close()">Close</button>
            &nbsp;&nbsp;&nbsp;
            <button class="mat-raised-button mat-primary"(click)="save()">Save Changes</button>
        </mat-dialog-actions>

          </div>


