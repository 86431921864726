<div class="example-container mat-elevation-z8">

   
    <div class="invoice overflow-auto">
        <!-- <div style="min-width: 600px"> -->
           
                <div mat-dialog-title class="row">
                    <div class="col">
                        <a target="_blank">
                            <img src="../assets/icon/sm-logo_small.png" data-holder-rendered="true" />
                            </a>
                    </div>
                    <div class="col company-details">
                        <h4 class="name">
                            <a target="_blank">
                          Date of Invoice: {{bsValue | date}}
                        <!-- <div>10/10/2020</div> -->

                            </a>
                          </h4>
                       
                    </div>
                </div>
           
            <mat-dialog-content>
                <div class="row contacts">
                    <div class="col invoice-to">
                        <div class="text-gray-light">INVOICE TO:</div>
                        <h3 class="to">{{inCustomername}}</h3>
                        <div class="address">{{inStreet}}</div>
                        <div class="address">{{inCity}}, {{inState}}, {{inCountry}}</div>
                        <div class="address">Ph: {{inPhone}}</div>
                        <div class="address">Pin/Zip: {{inZipPin}}</div>
                        <!-- <div class="email"><a href="mailto:john@example.com">john@example.com</a></div> -->
                    </div>
                    <div class="col invoice-details">
                        <h4 class="invoice-id">Orders</h4>
                        <div class="date">From: {{startDate}}</div>
                        <div class="date">To: {{endDate}}</div>
                    </div>
                </div>
                <table border="0" cellspacing="0" cellpadding="0">
                    <thead>
                        <tr>
                            <th >#</th>
                            <th class="text-left" >No of Orders</th>
                            <th class="text-right" >Total Amount</th>
                            <th class="text-right" >Total Payments Received</th>
                            <th class="text-right" >To pay</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        <tr>
                            <td class="no">01</td>
                            <td class="text-left"><h3>{{inOrderCount}}</h3></td>
                            <td class="unit">{{inTotalAmount}}</td>
                            <td class="qty">{{inPaidAmount}}</td>
                            <td class="total">{{inToPayAmount}}</td>
                        </tr>
  
                    </tbody>
                    <tfoot class="pt-4">
                        
                        <tr>
                            <td colspan="3"></td>
                            <td colspan="1">Total Invoice Amount:</td>
                            <td>{{inCurrency}} {{inToPayAmount}}</td>
                        </tr>
                    </tfoot>
                </table>
              
                  <div class="notices"> 
                    <!-- <div>......</div> -->
                    <div class="notice">Thank You</div>
                </div> 
            </mat-dialog-content>
            <!-- <footer>
                Invoice was created on a computer and is valid without the signature and seal.
            </footer> -->
        
        <!--DO NOT DELETE THIS div. IT is responsible for showing footer always at the bottom-->
    </div>
    <mat-dialog-actions>
    <div class="toolbar hidden-print">
      <div class="text-right">
          <button id="printInvoice" class="btn btn-info" (click)=printInvoice()><i class="fa fa-print"></i> Print</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeInvoice()">Close</button>
      </div>
      <hr>
  </div>
</mat-dialog-actions>
</div>

  