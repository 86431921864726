<tour-step-template class="guidedtour">
  <ng-template let-step="step">
    <div class="row">
      <div>
        <button mat-icon-button [disabled]="!rpTourService.hasPrev(step)"
          [ngClass]="{'inactive-btn' : !rpTourService.hasPrev(step),'prev-step-hide': hidePrevStep}"
          class="ngxp-btn ngxp-prev ml-2" (click)="rpTourService.prev()">
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>
      <div class="ngxp-end">
        <button mat-flat-button (click)="rpTourService.end()">{{step?.endBtnTitle}}</button>
      </div>
    </div>
    <div class="ngxp-title" [ngClass]="{'ngxp-first-step' : !rpTourService.hasPrev(step)}">
      <div class="ngxp-title-text">
        {{step?.title}}
      </div>

    </div>
    <img src="../../assets/icon/guided_tour.png" width="60px">
    <div class="ngxp-content mb-3">{{step?.content}}</div>
    <div class="tour-step-navigation" style="display: flex;
      justify-content: center;
      align-items: center;">

      <button mat-raised-button color="primary" [disabled]="!rpTourService.hasNext(step)"
        [ngClass]="{'inactive-btn' : !rpTourService.hasNext(step), 'submit-step-hide': hideSubmitStep}" class="nextBtn"
        (click)="rpTourService.next()">{{step?.nextBtnTitle}}</button>


    </div>
  </ng-template>
</tour-step-template>
<div class="container-fluid">


  <div class="row">
    <div class="col-lg-3 pr-0 white-container">
      <mat-nav-list>
        <mat-list-item>
          <button mat-flat-button class="btn-block test"
            [ngClass]="this.selected == 1 ? 'tab_selected' : 'tab_unselected'" (click)="toggleColor(1)"
            (click)="showTransactionSummary()">
            <img src={{buyerImgUrl}} alt="" style="height:20px;" class="pr-1">
            Transaction Summary</button>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>

          <button class="btn-block test" [ngClass]="this.selected == 2 ? 'tab_selected' : 'tab_unselected'"
            (click)="toggleColor(2)" (click)="showOrderDetails()" mat-flat-button>
            <img src={{groupImgUrl}} alt="" style="height:20px" class="pr-1"> Order details</button>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>

          <button class="btn-block test" [ngClass]="this.selected == 3 ? 'tab_selected' : 'tab_unselected'"
            (click)="toggleColor(3)" (click)="showItemWiseReport()" mat-flat-button>
            <img src={{topSellingImgUrl}} alt="" style="height:20px" class="pr-1">ItemWise Report</button>
        </mat-list-item>
        <mat-divider></mat-divider>

      </mat-nav-list>
    </div>

    <div class="col-lg-9 pl-0">


      <div *ngIf="transactionSummary" class="card pt-2" tourAnchor="rptour.step1.id">
        <!-- <form [formGroup]="reportForm" (ngSubmit)="onReportFormSubmit()"> -->
        <!-- <h5>Please select a  preferred date range </h5> -->

        <div class="card-title">
          <div style="display: flex; float:right" class="col-md-1 text-center">
            <mat-icon matTooltip="Click for a guided Tour" class="pointer" (click)="ReportTour()">play_circle_outline
            </mat-icon>
            <h6 style="margin-top: 0mm; ">Guided Tour</h6>
          </div>
        </div>


        <div class="pl-2 card-body" tourAnchor="rptour.step2.id">

          <mat-form-field appearance="legacy">

            <mat-label>Choose Date Range</mat-label>

            <mat-date-range-input [rangePicker]="picker" [formGroup]="dateRange">
              <input matStartDate formControlName="start" placeholder="Start Date">
              <input matEndDate formControlName="end" placeholder="End Date" (dateChange)="onChangeEvent($event)">
            </mat-date-range-input>

            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>

            <mat-date-range-picker #picker></mat-date-range-picker>

          </mat-form-field>


               


          
          </div>

          
      

        <div class="example-container" *ngIf="isWhTableSelected && tabShown">

          <mat-table [dataSource]="reportDataSource" matSort (matSortChange)="onSortData($event)">
            <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->

            <!-- Position Column -->
            <ng-container matColumnDef="customerId">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Customer Id</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{ element.customerId }} </mat-cell>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="warecustomerName">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Customer Name</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{ element.customerName }} </mat-cell>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="noOfOrders">
              <mat-header-cell *matHeaderCellDef mat-sort-header>No of Orders</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{ element.orderCount }} </mat-cell>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="warephone">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Phone</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{ element.customerPhone }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="waretotalAmount">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Total Amount</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.currency}} {{element.totalAmount }} </mat-cell>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <button mat-button (click)="createExcel(element.customerId,element.shopId)">Excel</button>

                <!--  <a href  data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#bs-example-modal-lg" (click)="createExcel(element.customerId,element.shopId)"><i class="">Excel </i></a>  -->

              </mat-cell>

            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumnsWarehouse"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsWarehouse"></mat-row>


          </mat-table>
          <mat-paginator [length]="10"
              [pageSize]="10"
              [pageSizeOptions]="[5, 10, 25]" showFirstLastButtons></mat-paginator>
        </div>
        <div class="col-sm-12 col-md-12 pb-2">
          <span class="alert alert-danger" *ngIf="isData" style="color: red;">NO DATA ON THIS TIME INTERVAL</span>
        </div>
       

      </div>
      <div *ngIf="itemWiseReport" class="card pt-2" tourAnchor="rptour.step11.id">
        <!-- <form [formGroup]="reportForm" (ngSubmit)="onReportFormSubmit()"> -->
        <!-- <h5>Please select a  preferred date range </h5> -->

        <div class="card-title">
          <div style="display: flex; float:right" class="col-md-1 text-center">
            <mat-icon matTooltip="Click for a guided Tour" class="pointer" (click)="itemWiseTour()">play_circle_outline
            </mat-icon>
            <h6 style="margin-top: 0mm; ">Guided Tour</h6>
          </div>
        </div>


        <div class="pl-2 card-body" tourAnchor="rptour.step12.id">

          <mat-form-field appearance="legacy">

            <mat-label>Choose Date Range</mat-label>

            <mat-date-range-input [rangePicker]="picker" [formGroup]="itemForm"  [max]="maxDate">
              <input matStartDate formControlName="startItem" placeholder="Start Date">
              <input matEndDate formControlName="endItem" placeholder="End Date" (dateChange)="onChangeEventItem($event)">
            </mat-date-range-input>

            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>

            <mat-date-range-picker #picker></mat-date-range-picker>

          </mat-form-field>


         


          
          </div>

          
      

        <div class="example-container">
        
          <mat-form-field class="col-lg-3"  appearance="outline">
            <input matInput [(ngModel)]="searchKey" placeholder="Search" (keyup)="applyFilter()">
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
         
         
          <button class="col-lg-3" *ngIf="!noItems" mat-raised-button class="green"  (click)="exporter.exportTable('xlsx',{fileName:getName()})">Download as excel</button>
      
          <mat-table [dataSource]="itemWiseReportDataSource" matSort (matSortChange)="onSortData($event)" matTableExporter #exporter="matTableExporter">
           
            <ng-container matColumnDef="sn">
              <mat-header-cell *matHeaderCellDef mat-sort-header> SN. </mat-header-cell>
              <mat-cell *matCellDef="let i =index;"> {{ (i+1) + (paginatorItem.pageIndex * paginatorItem.pageSize) }}</mat-cell>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="productName">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Product</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{ element.data1 }} </mat-cell>
            </ng-container>

            <!-- Quantity Column -->
            <ng-container matColumnDef="quantity">
              <mat-header-cell *matHeaderCellDef mat-sort-header style="justify-content:flex-end!important;">Quantity</mat-header-cell>
              <mat-cell *matCellDef="let element" style="justify-content:flex-end!important;"> {{ element.data2 }} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumnsItemWise"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsItemWise"></mat-row>
            <div *matNoDataRow> <span style="color: red;">No Records found</span></div>

          </mat-table>
          <mat-paginator #paginatorItem [length]="paginatorLength"
              [pageSize]="10"
              [pageSizeOptions]="[5, 10, 25]" showFirstLastButtons></mat-paginator>
        </div>

        <div class="col-sm-12 col-md-12 pb-2">
          <span class="alert alert-danger" *ngIf="noItems" style="color: red;">{{noPdtMsg}}</span>
        </div>
       
       

      </div>

      <div *ngIf="orderDetails" class="card pt-4">

        <div class="card-title">
        <div style="display:flex; float:right;" class="col-md-1 text-center">
          <mat-icon matTooltip="Click for a guided Tour" class="pointer" (click)="orderDetailTour()">play_circle_outline
          </mat-icon>
          <h6 style="margin-top: 0mm; ">Guided Tour</h6>
        </div>
      </div>


        <div class="card-body">
          <form [formGroup]="searchForm" (ngSubmit)="oSearchFormSubmit()">
            <div formGroupName="dateRange1">
            <div class="form-group">
            <div class= "pb-2 step1"  tourAnchor="rptour.step4.id">
             
              <mat-form-field appearance="legacy">

                <mat-label>Choose Date Range</mat-label>
    
                <mat-date-range-input [rangePicker]="picker">
                  <input matStartDate formControlName="start" placeholder="Start Date">
                  <input matEndDate formControlName="end" placeholder="End Date">
                </mat-date-range-input>
    
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    
                <mat-date-range-picker #picker></mat-date-range-picker>
    
              </mat-form-field>
  
            </div>
            </div>
            </div>
           
            <h5 tourAnchor="rptour.step5.id">Please select a filter value</h5>

            <div class="row pl-2">
              <mat-form-field>
                <mat-label>Select an option</mat-label>
                <mat-select>
                    <mat-option *ngFor="let obj of filters" (click)="get(obj.value)" [value]="obj.value"> {{obj.viewValue}}</mat-option>
                </mat-select>
            </mat-form-field>
            
          

              <div *ngIf="isSelected && buyerSelected" class="col-md-3 step6">
                <mat-form-field>
                  <mat-label>Please select the buyer </mat-label>
                <mat-select formControlName="buyer">
                 
                  
                  <mat-option  *ngFor="let i of ddListBuyer" [value]="i.id">{{i.name}}</mat-option>
                </mat-select>
              </mat-form-field>
              </div>

              <div *ngIf="isSelected && idSelected" class="col-md-3 pt-2 step2 ">
                <input type="text" class="form-control "  name="orderId" formControlName="orderId"
                  placeholder="Order ID">
              </div>

              <div *ngIf="isSelected && statusSelected" class="col-md-3 step3">
                <mat-form-field>
                  <mat-label>Please select the status</mat-label>
                  <mat-select formControlName="status">
                   
                    <mat-option value="Submitted">Submitted</mat-option>
                    <mat-option value="Accepted">Accepted</mat-option>
                    <mat-option value="Shipped">Shipped</mat-option>
                    <mat-option value="Cancelled">Cancelled</mat-option>
                    <mat-option value="Delivered">Delivered</mat-option>
                    <mat-option value="Rejected">Rejected</mat-option>
  
                  </mat-select>
  
              </mat-form-field>
              </div>

              <div class="col-md-3 align-items-center pt-2"  tourAnchor="rptour.step6.id">
                <button type="submit" class="btn green"><span>Order Details</span> </button>
              </div>
            </div>
            <div *ngIf="isErrorMsg" style="color:red">
              {{phErrMsg}}
            </div>
            
          </form>
        </div>
        
        <div class="example-container" *ngIf="isTableSelected">

          <h5 class="header-title mb-3">REPORTS</h5>

        <mat-form-field>
          <input matInput (keyup)="applyFilterOne($event.target.value)" placeholder="Search" />
        </mat-form-field>
          <mat-table [dataSource]="reportDataSource" matSort (matSortChange)="onSortData($event)">
            <!-- For ID -->
            <ng-container matColumnDef="orderId">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
              <mat-cell *matCellDef="let post" style="text-align: center;"> {{post.orderId}} </mat-cell>
            </ng-container>

            <!-- For User ID -->
            <ng-container matColumnDef="customerName">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Customer Name </mat-header-cell>
              <mat-cell *matCellDef="let post"> {{post.retailerName}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="phone">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Phone</mat-header-cell>
              <mat-cell *matCellDef="let post"> {{post.phone}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="submittedBy">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Submitted By</mat-header-cell>
              <mat-cell *matCellDef="let post" style="text-align: center;"> {{post.userName}} </mat-cell>
            </ng-container>
            <!-- For Title -->
            <ng-container matColumnDef="totalAmount">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Total Amount ({{currency}})</mat-header-cell>
              <mat-cell *matCellDef="let post" style="text-align: center;">{{post.orderAmount}} </mat-cell>
            </ng-container>

            <!-- For Body -->
            <ng-container matColumnDef="paid">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Paid ({{currency}})</mat-header-cell>
              <mat-cell *matCellDef="let post" style="text-align: center;"> {{post.paidAmount}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="balance">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Balance ({{currency}})</mat-header-cell>
              <mat-cell *matCellDef="let post"style="text-align: center;"> {{post.balanceAmount}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="submittedDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Submitted</mat-header-cell>
              <mat-cell *matCellDef="let post"style="text-align: center;"> {{post.submitedDate}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="preferredDeliveryTime">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Preferred Delivery</mat-header-cell>
              <mat-cell *matCellDef="let post" style="text-align: center;"> {{post.prefDeliveryTime}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actualDeliveryTime">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Actual Delivery</mat-header-cell>
              <mat-cell *matCellDef="let post"style="text-align: center;"> {{post.actualDeliveredTime}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
              <mat-cell *matCellDef="let post"style="text-align: center;"> {{post.status}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
              <mat-cell *matCellDef="let post">
                <button mat-button (click)="getInvoiceView(post.retailer.retailerId,post.shop.shopId)">Invoice</button>
                <!--  <a href  data-toggle="modal" data-target=".bd-example-modal-lg" (click)="getInvoiceView(post.retailer.retailerId,post.shop.shopId)"><i class="">Invoice </i></a> &nbsp;&nbsp; -->
                <!-- <ng-container matColumnDef="actions">
               <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
               <mat-cell *matCellDef="let post">
                 <a href  data-toggle="modal" data-target=".bd-example-modal-lg" (click)="getInvoiceView(post.customerId,post.shopId)"><i class="">Invoice </i></a> &nbsp;&nbsp; -->
                <!--  <a href (click)="downloadInvoice(post.orderId)" ><i class="">Invoice</i></a> &nbsp;&nbsp; -->


              </mat-cell>

            </ng-container>



            <mat-header-row *matHeaderRowDef="getDisplayedColumns()"></mat-header-row>
            <mat-row *matRowDef="let row; columns: getDisplayedColumns()"></mat-row>

            <!-- <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>  
                       <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>   -->
          </mat-table>
          <mat-paginator
              [pageSize]="10"
              [pageSizeOptions]="[5, 10, 25]" showFirstLastButtons></mat-paginator>
          <div class="col-sm-12 col-md-12">
            <span class="alert alert-danger" *ngIf="isData" style="color: red;">NO DATA ON THIS TIME INTERVAL</span>
          </div>
         
        </div>

        
      </div>
    </div>




    

   