<div class="example-container">
<mat-card>
    <h2 mat-dialog-title>Buyer Group Details</h2>
    <hr>

    <mat-dialog-content [formGroup]="buyergroupForm" (submit)="save()">
     
       
        <div class="row">
         <div class="col">
        <h5>Name</h5>
      </div>
      <div class="col">
        <mat-form-field appearance="outline">
          <input formControlName="groupName" matInput required>
           <mat-error>
             This is a required field
           </mat-error>
         </mat-form-field>
        </div>
        </div><!-- 
        <div class="row"> 
          <div class="col">  
  <mat-radio-group formControlName="valueOrPercent">
   
       <mat-radio-button  *ngFor="let item of itemsList; let i = index;" value={{item.value}} (change)="onItemChange(item, i)">
             {{item.name}}
        </mat-radio-button>
   
</mat-radio-group>

          </div>
          <div class="col">
     <mat-form-field appearance="outline">
    <input matInput  formControlName="deviation">   
  </mat-form-field>
  <mat-icon  [matTooltip]="matHint[isVisible]">info</mat-icon>
</div>
</div>
<div class="row"> 
  <div class="col"> 
  <h5>Offer Price</h5>
  </div>
     
  <div class="col ml-4"> 
     <mat-radio-group label="Offer price" formControlName="paramOffer">
      <mat-radio-button value="true"> Yes </mat-radio-button>
      &nbsp;
      <mat-radio-button value="false"> No </mat-radio-button>
      <mat-icon matSuffix  [matTooltip]="matHint[2]">info</mat-icon>
    </mat-radio-group>
  </div>
</div>
<div class="row"> 
  <div class="col">

  <h5>Minimum Order Quantity</h5>
  </div>
  <div class="col">
  <mat-form-field appearance="outline">
   
    <input matInput formControlName="paramMoq">
    
  </mat-form-field>
  <mat-icon [matTooltip]="matHint[3]">info</mat-icon>
</div>
</div> -->
<div class="row"> 
  <div class="col">
  <h5>Description</h5>
</div>
<div class="col">
  <mat-form-field appearance="outline" >
   
    <textarea matInput formControlName="groupDesc"></textarea>
  </mat-form-field>
</div>
</div>
</mat-dialog-content>

<mat-dialog-actions>
  <mat-dialog-actions align="end">
      <button class="mat-raised-button" (click)="close()">Close</button>
      &nbsp;&nbsp;
      <button class="mat-raised-button mat-primary" (click)="save()">Save Group</button>
  </mat-dialog-actions>
</mat-dialog-actions>
</mat-card>

</div>
