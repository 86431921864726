


<div>
    <mat-card>
    <mat-card-content>
        <button mat-flat-button style="margin-right:30px" (click)="addSalesMan()" color="primary">
          <mat-icon style="font-size:18px; vertical-align: middle;">add</mat-icon>Add Sales Executive User
        </button>
    
        
      </mat-card-content>
     </mat-card>
</div>

