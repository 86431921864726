import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment'
import { BehaviorSubject } from 'rxjs';
import { ifStmt } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  baseUrl = environment.serviceURL + 'storimartadmin';
 

  constructor(private httpclient: HttpClient) { }

  getCatalogList(): Observable<any> {
    return this.httpclient.get(this.baseUrl + "/cataloguelist");
  }

  getCategories(): Observable<any> {
    return this.httpclient.get(this.baseUrl + "/categories");
  }

  getStock(categoryId,skuId,productId,skuName,limit,offset): Observable<any> {
    let httpParamsGetStock=new HttpParams()
    .set('categoryId',categoryId.toString())
    .set('skuName',skuName)
    .set('productId',productId)
    .set('skuId',skuId)
    .set('limit',limit)
    .set('offset',offset)
     return this.httpclient.get(this.baseUrl+"/V2getstockdetails",{params:httpParamsGetStock});
     
  }

  updateStock(obj): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.set('type','VARIANCE');
    return this.httpclient.post(this.baseUrl+"/V2updatestock",obj,{params});
  }

  downloadStockReport(categoryId,shopId):Observable<any> {
    if(categoryId){
    let httpParamsDwnld=new HttpParams()
    .set('categoryId',categoryId)
    .set('shopId',shopId)
    return this.httpclient.get(this.baseUrl +"/downloadStockReport",{params:httpParamsDwnld});
    }
    else{
      let httpParamsDwnld=new HttpParams()
     
      .set('shopId',shopId)
      return this.httpclient.get(this.baseUrl +"/downloadStockReport",{params:httpParamsDwnld});
    }
  }

  uploadStockDataFile(uploadType,formdata){
    let httpParamsUpld=new HttpParams()
     
      .set('stockUpdateType',uploadType)
    return this.httpclient.post(this.baseUrl +"/uploadStockDataFile",formdata,{params:httpParamsUpld});

  }


}
