<tour-step-template>
    <ng-template let-step="step">
        <div class="row">
        <div>
        <button mat-icon-button [disabled]="!erpTourService.hasPrev(step)"
        [ngClass]="{'inactive-btn' : !erpTourService.hasPrev(step)}" class="ml-2"
        (click)="erpTourService.prev()"> <mat-icon>arrow_back</mat-icon></button>
    </div>
    <div class="ngxp-end">
        <button mat-flat-button (click)="erpTourService.end()">{{step?.endBtnTitle}}</button>
    </div>
    </div>
        <div class="ngxp-title" [ngClass]="{'ngxp-first-step' : !erpTourService.hasPrev(step)}">
            <div class="ngxp-title-text">
                {{step?.title}}
        </div> 
           
        </div>
        <img src="../../assets/icon/guided_tour.png" width="60px">
        <div class="ngxp-content mb-3">{{step?.content}}</div>
        <div class="tour-step-navigation" style="display: flex;
        justify-content: center;
        align-items: center;">
            
            <button mat-raised-button color="primary" [disabled]="!erpTourService.hasNext(step)"
                [ngClass]="{'inactive-btn' : !erpTourService.hasNext(step)}" class="nextBtn"
                (click)="erpTourService.next()">{{step?.nextBtnTitle}} »</button>
        </div>
    </ng-template>
</tour-step-template>


    <div class="col-lg-12 white-container">  
            <!-- <mat-toolbar>
                <h3> ERP Settings</h3>
            </mat-toolbar> -->
            <div class="card">
               
                <div class="card-body pt-4">


<div>

    <mat-tab-group>
        <mat-tab label="Erp Settings"> 
            <form [formGroup]="erpSettingsForm" >
       
                <div style="float: right;" class="col-md-1 text-center">
                        <mat-icon matTooltip="Click for a guided Tour" class="pointer"  (click)="erpTour()">play_circle_outline</mat-icon> 
                        <h6 style="margin-top: 0mm;">Guided Tour</h6>
                </div>
        
                <div class="form-group col-md-6 pt-4" >
                <h5 class="pb-3">Please Select your Erp Type</h5>
                <mat-form-field appearance="outline" class="full-width">
                 <mat-label>ERP Type</mat-label>
                  <mat-select  [(ngModel)]="selectedValue"  formControlName="erpType" (selectionChange)="onSelectType(selectedValue)">
                    <mat-option *ngFor="let erp of erps " [value]="erp.value">
                      {{erp.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                
            </div>
        
            <div *ngIf="onSelected">
        
                <div class="form-group col-md-6 pt-1" tourAnchor="cptour.pbstep1.id">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label> Domain Url</mat-label>
                        <input matInput type="text" id="DomainUrl" [formControl]="erpSettingsForm.controls['domainUrl']">
                    </mat-form-field>
                        <!-- <label class="bold" for="DomainUrl">Domain Url</label> -->
                </div>
        
                <div class="form-group col-md-6" tourAnchor="cptour.pbstep2.id">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label >Client ID</mat-label>
                        <input matInput type="text" id="authUserName" [formControl]="erpSettingsForm.controls['authUserName']">
                    </mat-form-field>
        
                    <!-- <label class="bold" for="authUserName" >Client ID</label> -->
                </div>
                       
                <div class="form-group col-md-6" tourAnchor="cptour.pbstep3.id">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Client_Secret</mat-label>
                        <input matInput type="text" id="authPassword" [formControl]="erpSettingsForm.controls['authPassword']" >
                    </mat-form-field>
                    <!-- <label class="bold" for="authPassword">Client_Secret</label> -->
                </div>
                <div class="form-group col-md-6" tourAnchor="cptour.pbstep4.id">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Username</mat-label>
                        <input matInput type="text" id="posiboltUserName" [formControl]="erpSettingsForm.controls['posiboltUserName']" >
                    </mat-form-field>
                    <!-- <label class="bold" for="posiboltUserName">UserName </label> -->
                </div>
                <div class="form-group col-md-6" tourAnchor="cptour.pbstep5.id">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Password</mat-label>
                        <input matInput  type="text" id="posiboltPassword" [formControl]="erpSettingsForm.controls['posiboltPassword']"  >
                    </mat-form-field>
                    <!-- <label class="bold" for="posiboltPassword">Password </label> -->
                </div>
               
                <div class="form-group col-md-6" tourAnchor="cptour.pbstep6.id">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Terminal</mat-label>
                        <input matInput type="text" id="terminal" [formControl]="erpSettingsForm.controls['terminal']">
                    </mat-form-field>
                    <!-- <label class="bold" for="terminal">Terminal </label> -->
                </div>
                <div fxFlex="100" class="form-group col-md-6 mt-1" tourAnchor="cptour.pbstep7.id">
                    <button mat-raised-button class="green" (click)="submitErpSettings()">Save</button>
                     
                </div> 
            </div>
            </form>
                </mat-tab>
                
        
      </mat-tab-group>

</div>
</div>
</div>
</div>


