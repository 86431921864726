import { Component, Input, OnInit, ViewChild, ChangeDetectorRef,EventEmitter, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BuyerFormComponent } from './buyer-form/buyer-form.component';
import { ExcelMappingComponent } from './excel-mapping/excel-mapping.component';
import { BuyerSettingsComponent } from './buyer-settings/buyer-settings.component'
import { AddappuserComponent } from './addappuser/addappuser.component'
import { AppUsersComponent } from './app-users/app-users.component'
import {
  Buyer, UsersList, excelBuyer, excelObject, buyerAsAppUser, BuyerEditSettings,
  AppUser, DashboardStatus, BuyerGroupStatus, AddBuyerGroupRequestBody, GroupInfo, ParametersList
} from './models/buyer.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { BuyersService } from '../buyers/buyers.service'
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import * as XLSX from 'xlsx';
import { isEqual, sortBy, zipObject } from 'lodash';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { ControlPanelService } from './../control-panel/control-panel.service'
import { BuyergroupFormComponent } from './buyergroup-form/buyergroup-form.component'
import { ServiceService } from '../../app/Service/service.service'
import { BuyergroupsComponent } from './buyergroups/buyergroups.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { BuyergroupsettingsComponent } from './buyergroupsettings/buyergroupsettings.component';
import { MyVersion } from 'src/my-version';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { TourService } from 'ngx-tour-ngx-popper';
import { formatDate } from '@angular/common';
import 'rxjs/Rx';

@Component({
  selector: 'app-buyers',
  templateUrl: './buyers.component.html',
  styleUrls: ['./buyers.component.css']
})
export class BuyersComponent implements OnInit {



  curDate;
  ver: number;
  data1: any;
  buyerAsAppUser = {} as buyerAsAppUser;
  buyerGroupStatus = {} as BuyerGroupStatus;
  addGroupRequestBody = {} as AddBuyerGroupRequestBody;
  editGroupRequestBody = {} as AddBuyerGroupRequestBody;
  groupInfo = {} as GroupInfo;
  parametersList = {} as ParametersList;
  usersList: UsersList[];
  result = true;
  retailer: excelBuyer[];
  retailer1: excelBuyer[] = [];
  object: excelObject;
  jsonData: any;
  warehouseSettingsDetails: any;
  shopid: any;
  bannerdetails: any;
  statusBody = {} as AppUser;
  displayedColumns: string[] = ['id', 'name', 'phone', 'email', 'groupname', 'status', 'pin', 'actions', 'address', 'street', 'city', 'state', 'country', 'zip', 'appusername', 'appuseremail', 'appuserphone', 'appuserpin', 'taxnumber'];
  displayedColumnsForGroup: string[] = ['id', 'name', 'status', 'actions']
  @ViewChild(MatPaginator) paginator: MatPaginator; 
  @ViewChild('paginator1') paginator1: MatPaginator;
  @ViewChild(MatSort) sort: MatSort; sort1: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;
  dataSource: any;
  dataBuyerGroup: any;
  baseUrl = environment.serviceURL + 'storimartadmin';
  errorMessage;
  dataExcel: [][];
  file: File;
  fileName: string = "";
  arrayBuffer: any;
  filelist: any;
  ifDownloading: boolean = false;

  buyerFields: string[] = ["addressLine1", "buyerAsAppUser", "city", "country", "email", "groupName", "name", "state", "phone",
    "pin", "rowNumber", "timezone", "taxNumber"];
  reqBuyerFields: string[] = ["addressLine1", "city", "country", "groupName", "name", "phone", "timezone"]
  defaultExcelFileHeaders: string[] = ["Sr.No", "Name", "Phone", "Email", "Address", "City", "State", "Country", "Zip/Pin", "TimeZone", "TaxNumber", "GroupName", "BuyerAsAppUser"]
  worksheet: any;
  obj: Buyer;
  buyerEditSettings: BuyerEditSettings;
  obj1: string[] = [];
  headers: string[] = [];
  dataLength;
  dataLengthBuyer;
  licenseCount: number;
  dashboardStatus;
  value: any;
  index: string;
  mySubscription: any;
  buyerImgUrl = '../assets/icon/buyer_black.png';
  groupImgUrl = '../assets/icon/groups.png'
  selected;
  buyerForExcelFile: any;
  showBuyer: boolean = true;
  showGroups: boolean = false;
  ifUploading: boolean;
  warehouseName: any;
  page:any;
  myDate: any;
  constructor(public dialog: MatDialog,
    private httpclient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private BuyersService: BuyersService,
    private toastr: ToastrService,
    private controlPanelService: ControlPanelService,
    private serviceService: ServiceService,
    public bTourService: TourService,
    private cdr:ChangeDetectorRef
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {

        this.router.navigated = false;
      }
    });
  }

  toggleColor(n) {
    this.selected = n;
  }

  openBuyer() {
    this.showBuyer = true;
    this.showGroups = false;
  }

  openGroups() {
    this.showBuyer = false;
        this.showGroups = true;
    this.BuyersService.getListOfBuyergroups().subscribe(
      (result: any) => {
        if(result.groups)
        {
        this.dataBuyerGroup = new MatTableDataSource(result.groups);
        
        this.cdr.detectChanges();
        this.dataLengthBuyer = result.groups.length;
        this.dataBuyerGroup.paginator = this.paginator1;
       
        }
        else
        this.toastr.error("No BuyerGroups info available")
      }
    );
  }

  startDownload() {
    this.ifDownloading = true;
  }

  endDownload() {
    this.ifDownloading = false;
  }

  ngOnInit(): void {

    this.index = this.route.snapshot.paramMap.get('id');

    this.controlPanelService.getwarehouseSettings().subscribe(
      res => {
        this.warehouseSettingsDetails = res.warehouseSettings;
        this.shopid = this.warehouseSettingsDetails.shopId;
      });

    this.BuyersService.getBuyersDetails(this.shopid,"20","0").subscribe(
      (res: any) => {

        console.log(res);
        this.dataSource = new MatTableDataSource(res.customerDetails);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataLength = res.totalCustomerCount;
      }
    );

    /* this.BuyersService.getListOfBuyergroups().subscribe(
      (result: any) => {

        this.dataBuyerGroup = new MatTableDataSource(result.groups);
       
        this.dataBuyerGroup.paginator = this.paginator1;
        this.dataBuyerGroup.sort = this.sort1;
        this.dataLengthBuyer = result.groups.length;

      }
    ); */

    this.getLicenseCount();

    this.controlPanelService.getwarehouseSettings().subscribe(
      res => {

        if (res) {
          this.warehouseName = res.warehouseSettings.name;

        }

      });

    this.bTourService.initialize([
      {
        anchorId: 'btour.step1.id',
        content: 'Click this button to add a new Buyer induvidually',
        title: 'Add Buyer',
        nextBtnTitle: 'Next',
        endBtnTitle: 'Skip',
      },
      {
        anchorId: 'btour.step2.id',
        content: 'Click this to upload buyers using excel collectively',
        title: 'Add Buyer-Excel',
        nextBtnTitle: 'Next',
        endBtnTitle: 'Skip',
      },
      {
        anchorId: 'btour.step3.id',
        content: 'Download a sample excel file to fill in for adding buyers',
        title: 'Download',
        nextBtnTitle: 'Next',
        endBtnTitle: 'Skip',
      },
      {
        anchorId: 'btour.step4.id',
        content: 'This is the current number of licences left for you.',
        title: 'Licence',
        nextBtnTitle: 'Next',
        endBtnTitle: 'Skip',
      },
      {
        anchorId: 'btour.step5.id',
        content: 'This is the current list of buyers registered with your Warehouse.',
        title: 'Current Buyers',
        nextBtnTitle: 'Next',
        endBtnTitle: 'Skip',
      },
      {
        anchorId: 'btour.step6.id',
        content: 'Search from the buyer table',
        title: 'Filter',
        nextBtnTitle: 'Next',
        endBtnTitle: 'Skip',
      },

      {
        anchorId: 'btour.step8.id',
        content: 'Click the icons in the column to to add or view an appuser for this buyer',
        title: 'Add/View App-user',
        nextBtnTitle: 'Next',
        endBtnTitle: 'Skip',
      },
      {
        anchorId: 'btour.step9.id',
        content: 'You can see buttons to Activate/Deactivate and Edit any information about this Buyer once you hover over the row',
        title: 'Actions',
        nextBtnTitle: 'Next',
        endBtnTitle: 'End',
      },
      {
        anchorId: 'btour.step10.id',
        content: 'Click this tab to go to Buyer-Groups Page',
        title: 'Buyer-Groups',
        nextBtnTitle: 'Next',
        endBtnTitle: 'Skip',
      },
      {
        anchorId: 'btour.step11.id',
        content: 'Click this button to add a new Buyer-Groups',
        title: 'Add Buyer-Groups',
        nextBtnTitle: 'Next',
        endBtnTitle: 'Skip',
      },
      {
        anchorId: 'btour.step12.id',
        content: 'This table displays the current Buyer-Groups under your warehouse',
        title: 'Current Buyer-Groups',
        nextBtnTitle: 'Next',
        endBtnTitle: 'Skip',
      },
      {
        anchorId: 'btour.step12.id',
        content: 'Search for Buyer Groups here',
        title: 'Filter',
        nextBtnTitle: 'Next',
        endBtnTitle: 'Skip',
      },
      {
        anchorId: 'btour.step13.id',
        content: 'You can see buttons to Activate/Deactivate and Edit any information about this Buyer once you hover over the row',
        title: 'Actions',
        nextBtnTitle: 'Next',
        endBtnTitle: 'End',
      },







    ])
  }


  
  pageChanged(page){
    this.ifDownloading=true;
    this.page = page;
    this.BuyersService.getBuyersDetails(this.shopid,page.pageSize, page.pageIndex * page.pageSize).subscribe(
      (res: any) => {
        this.ifDownloading=false;
        console.log(res);
        this.dataSource = new MatTableDataSource(res.customerDetails);
        
      }
    );
  }

  getLicenseCount() {
    this.serviceService.getDashboardStatus()
      .subscribe((dbstatus: DashboardStatus) => {

        this.dashboardStatus = dbstatus.data
        this.licenseCount = this.dashboardStatus[0].data2 - this.dashboardStatus[0].data3
      });

  }

  downloadApi() {
    this.BuyersService.getBuyersDetails(this.shopid,"","").subscribe(
      (res: any) => {
        /*  const blob = new Blob([res],{ type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
       
         const url= window.URL.createObjectURL(blob);
   window.open(url); */
        this.buyerForExcelFile = res.customerDetails;
        console.log(this.buyerForExcelFile);
        const workBook = XLSX.utils.book_new();
        const workSheet = XLSX.utils.json_to_sheet(this.buyerForExcelFile);

        XLSX.utils.book_append_sheet(workBook, workSheet, 'data');
        XLSX.writeFile(workBook, 'temp.xlsx');


      }
    );

    // initiate a file download in browser
  }

  getName(): string {
    this.myDate = formatDate(new Date(), 'dd/MM/yyyy', 'en')
    if (this.warehouseName === 'undefined')
      return 'BuyersList_' + this.myDate;
    else
      return this.warehouseName + '_BuyersList_' + this.myDate;
  }

  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  onLinkClick(event: MatTabChangeEvent) {

  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  applyFilterBuyerGroup(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataBuyerGroup.filter = filterValue;
  }

  buyerTour() {
    this.bTourService.start();
  }

  buyerGroupTour() {
    this.bTourService.startAt('btour.step10.id');
  }

  onExcel(event: any) {
    /* const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
     
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

     
      this.data1 = (XLSX.utils.sheet_to_json(ws, { header: 1 }));
     
      console.log(this.data1);
     
      
    }
    reader.readAsBinaryString(target.files[0]);
   
    this.makeObject(this.data1); */

    this.file = event.target.files[0];
    this.fileName = event.target.files[0].name;
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);


    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;

      var data = new Uint8Array(this.arrayBuffer);

      var arr = new Array();
      for (var i = 0; i != data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" })
      var first_sheet_name = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[first_sheet_name];


      this.data1 = (XLSX.utils.sheet_to_json(this.worksheet, { header: 1 }));
      if (isEqual(this.data1[0], this.defaultExcelFileHeaders)) {
        this.worksheet['A1'].v = this.worksheet['A1'].w = 'rowNumber';
        this.worksheet['B1'].v = this.worksheet['B1'].w = 'name';
        this.worksheet['C1'].v = this.worksheet['C1'].w = 'phone';
        this.worksheet['D1'].v = this.worksheet['D1'].w = 'email';
        this.worksheet['E1'].v = this.worksheet['E1'].w = 'addressLine1';
        this.worksheet['F1'].v = this.worksheet['F1'].w = 'city';
        this.worksheet['G1'].v = this.worksheet['G1'].w = 'state';
        this.worksheet['H1'].v = this.worksheet['H1'].w = 'country';
        this.worksheet['I1'].v = this.worksheet['I1'].w = 'pin';
        this.worksheet['J1'].v = this.worksheet['J1'].w = 'timezone';
        this.worksheet['K1'].v = this.worksheet['K1'].w = 'taxNumber';
        this.worksheet['L1'].v = this.worksheet['L1'].w = 'groupName';
        this.worksheet['M1'].v = this.worksheet['M1'].w = 'buyerAsAppUser';
      }

      var range = XLSX.utils.decode_range(this.worksheet['!ref']);
      var C, hdr, R = range.s.r;
      for (C = range.s.c; C <= range.e.c; ++C) {
        var cell = this.worksheet[XLSX.utils.encode_cell({ c: C, r: R })]


        if (cell && cell.t)
          hdr = XLSX.utils.format_cell(cell);

        this.headers.push(hdr);

      }

      this.result = (isEqual((sortBy(this.headers)), (sortBy(this.buyerFields)))) || (isEqual((sortBy(this.headers)), (sortBy(this.reqBuyerFields))));
      console.log(this.result);

      if (this.result === false) {
        console.log(this.headers);
        console.log(this.buyerFields);

        this.openDialog(this.headers);

      }


      else {
        this.ifUploading = true;
        this.retailer = XLSX.utils.sheet_to_json(this.worksheet, { raw: false });
        this.jsonData = XLSX.utils.sheet_to_json(this.worksheet, { raw: false });
        this.headers = [];
        this.object = {
          fileName: this.fileName,
          retailer: this.retailer
        };

        this.BuyersService.addExcelBuyers(this.object).subscribe(
          (res: any) => {
            this.ifUploading = false;
            if (res.status === "SUCCESS") {


              this.toastr.success(res.message, "SUCCESS");
              this.refreshTable();
            }
            else {
              this.errorMessage = res.message
              this.toastr.error(this.errorMessage, "ERROR")
            }

          },
          (err: HttpErrorResponse) => {
            if (err) {
              this.errorMessage = err.error.message
              this.toastr.error(this.errorMessage, "ERROR")
            }
            else {
              this.toastr.error("Sorry,the buyer could not be added", "ERROR")
            }

          }
        );
      }
    }

  }

  downloadMyFile() {
    const link = document.createElement('a');
    link.setAttribute('target', '_self');
    link.setAttribute('href', 'assets/files/BuyersList.xlsx');
    link.setAttribute('download', `BuyersList.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  makeObject(ob, data1) {
    this.retailer1=[];
    if (data1) {
      for (var i = 1; i < data1.length; i++) {
        const test = zipObject(ob, data1[i]);
        this.retailer1.push(test);
      }
      console.log(this.retailer1);
    }
  }

  getUsersList(retailerId) {

    this.BuyersService.getUserListOfBuyer(this.shopid, retailerId).subscribe(
      (res: any) => {
        if (res) {
          this.usersList = res.Users;
          console.log(this.usersList);
          if (this.usersList) {
            const dialogConfig = new MatDialogConfig();

            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;

            dialogConfig.width = "70%";
            dialogConfig.height = '80%';

            dialogConfig.data = {
              users: this.usersList,
              shopid: this.shopid,
              retailerid: retailerId
            }

            const dialogRef = this.dialog.open(AppUsersComponent, dialogConfig);

          }
          else {
            this.toastr.error("Sorry,No App Users Added Yet", "")
          }
        }
      }
    );
  }

  addAppUser(id) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "30%";
    const dialogRef = this.dialog.open(AddappuserComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.buyerAsAppUser.name = data.name;
        this.buyerAsAppUser.shopId = this.shopid;
        this.buyerAsAppUser.retailerShopId = id;
        this.buyerAsAppUser.email = data.email;
        this.buyerAsAppUser.countryId = data.countryId;
        this.buyerAsAppUser.phone = data.phone;

        console.log(this.buyerAsAppUser);

        this.BuyersService.addBuyerAsAppUser(this.buyerAsAppUser).subscribe(
          (res: any) => {
            if (res.acknowledgement.status === "SUCCESS") {

              this.toastr.success("App user added Successfully", "SUCCESS")
            }
            else {
              this.errorMessage = res.acknowledgement.message
              this.toastr.error(this.errorMessage, "ERROR")
            }

          },
          (err: HttpErrorResponse) => {
            if (err) {
              this.errorMessage = err.error.message
              this.toastr.error(this.errorMessage, "ERROR")
            }
            else {
              this.toastr.error("Sorry,App user could not be added", "ERROR")
            }

          }
        );
      }
    });
  }

  activateBuyer(status, retailerId) {

    this.statusBody["shopId"] = this.shopid;
    this.statusBody["status"] = status;
    this.statusBody["retailerShopId"] = retailerId;

    this.BuyersService.changeStatus(this.statusBody).subscribe(
      (res: any) => {
        if (res.acknowledgement.status === "SUCCESS") {

          this.toastr.success("Buyer status Updated", "SUCCESS")
          this.refreshTable();
        }
        else {
          this.errorMessage = res.acknowledgement.message
          this.toastr.error(this.errorMessage, "ERROR")
        }

      },
      (err: HttpErrorResponse) => {
        if (err) {
          this.errorMessage = err.error.message
          this.toastr.error(this.errorMessage, "ERROR")
        }
        else {
          this.toastr.error("Sorry,the buyer status could not be updated", "ERROR")
        }

      }
    );
  }
  openBuyerSettings(element) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.data = element;
    const dialogRef = this.dialog.open(BuyerSettingsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.buyerEditSettings = data;
      if (this.buyerEditSettings) {
        console.log(this.buyerEditSettings);
        this.buyerEditSettings['shopId'] = this.shopid;
        this.buyerEditSettings['retailerShopId'] = element.id;
        this.buyerEditSettings.groupId = this.buyerEditSettings.groupId.toString();
        this.BuyersService.updateBuyer(this.buyerEditSettings).subscribe(
          (res: any) => {
            if (res.acknowledgement.status === "SUCCESS") {

              this.toastr.success("Buyer updated Successfully", "SUCCESS")
              this.refreshTable();
            }
            else {
              this.errorMessage = res.acknowledgement.message
              this.toastr.error(this.errorMessage, "ERROR")
            }

          },
          (err: HttpErrorResponse) => {
            if (err) {
              this.errorMessage = err.error.message
              this.toastr.error(this.errorMessage, "ERROR")
            }
            else {
              this.toastr.error("Sorry,the buyer could not be updated", "ERROR")
            }

          }
        );
      }
    }
    );

  }
  openDialog(excelHeaders) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;

    dialogConfig.width = '60%',
      dialogConfig.height = '90%',


      dialogConfig.data = {
        excel: excelHeaders,
        buyers: this.buyerFields
      }


    const dialogRef = this.dialog.open(ExcelMappingComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.obj1 = data;
      this.headers = [];
      console.log(this.obj1);
      if (this.obj1) {
        this.makeObject(this.obj1, this.data1)

        this.object = {
          fileName: this.fileName,
          retailer: this.retailer1
        };
        if (this.object) {
          this.BuyersService.addExcelBuyers(this.object).subscribe(
            (res: any) => {
              if (res.status === "SUCCESS") {

                this.toastr.success(res.message, "SUCCESS")
                this.refreshTable();
              }
              else {
                this.errorMessage = res.message
                this.toastr.error(this.errorMessage, "ERROR")
              }

            },
            (err: HttpErrorResponse) => {
              if (err) {
                this.errorMessage = err.error.message
                this.toastr.error(this.errorMessage, "ERROR")
              }
              else {
                this.toastr.error("Sorry,the buyer list could not be added", "ERROR")
              }

            }
          );
        }
      }
    }
    );

  }

  openBuyerForm() {
    this.router.navigate(['buyer-form'])
    /* const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    const dialogRef = this.dialog.open(BuyerFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.obj = data;
      if (this.obj) {
        console.log(this.obj);
        this.BuyersService.addBuyer(this.obj).subscribe(
          (res: any) => {
            if (res.acknowledgement.status === "SUCCESS") {

              this.toastr.success("Buyer added Successfully", "SUCCESS")
              this.refreshTable();
            }
            else {
              this.errorMessage = res.acknowledgement.message
              this.toastr.error(this.errorMessage, "ERROR")
            }

          },
          (err: HttpErrorResponse) => {
            if (err) {
              this.errorMessage = err.error.message
              this.toastr.error(this.errorMessage, "ERROR")
            }
            else {
              this.toastr.error("Sorry,the buyer could not be added", "ERROR")
            }

          }
        );
      }
    }
    );

 */
  }


  refreshTable() {
    if(this.page)
    {
    this.BuyersService.getBuyersDetails(this.shopid,this.page.pageSize.toString(), (this.page.pageIndex * this.page.pageSize).toString()).subscribe(
      (result2: any) => {

        this.dataSource = new MatTableDataSource(result2.customerDetails);
        
      }
    );
    }
else
{
    this.BuyersService.getBuyersDetails(this.shopid,"20","0").subscribe(
      (result2: any) => {

        this.dataSource = new MatTableDataSource(result2.customerDetails);
        
      }
    );
   }
    this.getLicenseCount();



  }

  refreshGroupTable() {
    this.BuyersService.getListOfBuyergroups().subscribe(
      (result1: any) => {

        this.dataBuyerGroup = new MatTableDataSource(result1.groups);
        this.dataBuyerGroup.paginator = this.paginator1;
        this.dataBuyerGroup.sort = this.sort1;
        this.dataLengthBuyer = this.dataBuyerGroup.length;
      }
    );

  }

  openBuyerGroupForm() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "50%";
    const dialogRef = this.dialog.open(BuyergroupFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {

      if (data) {
        console.log(this.obj);
        this.addGroupRequestBody.groupId = "";
        this.addGroupRequestBody.groupName = data.groupName;
        this.addGroupRequestBody.groupDesc = data.groupDesc;
        this.addGroupRequestBody.paramMoq = data.paramMoq;
        this.addGroupRequestBody.paramOffer = data.paramOffer;
        if (data.valueOrPercent === "value")
          this.addGroupRequestBody.paramPrice = data.deviation;
        else
          this.addGroupRequestBody.paramPricePercent = data.deviation;
        this.BuyersService.addBuyerGroup(this.addGroupRequestBody).subscribe(
          (res: any) => {
            if (res.acknowledgement.status === "SUCCESS") {

              this.toastr.success("Buyer Group added successfully", "SUCCESS")
              this.refreshGroupTable();
            }
            else {
              this.errorMessage = res.acknowledgement.message
              this.toastr.error(this.errorMessage, "ERROR")
            }

          },
          (err: HttpErrorResponse) => {
            if (err) {
              this.errorMessage = err.error.message
              this.toastr.error(this.errorMessage, "ERROR")
            }
            else {
              this.toastr.error("Sorry,the buyer group could not be added", "ERROR")
            }

          }
        );
      }
    }
    );



  }

  activateBuyerGroup(action, id) {
    this.buyerGroupStatus.groupId = id.toString();
    this.buyerGroupStatus.action = action;
    this.BuyersService.changeStatusOfGroup(this.buyerGroupStatus).subscribe(
      (res: any) => {
        if (res.acknowledgement.status === "SUCCESS") {

          this.toastr.success("Buyer group status Updated", "SUCCESS")
          this.refreshGroupTable();
        }
        else {
          this.errorMessage = res.acknowledgement.message
          this.toastr.error(this.errorMessage, "ERROR")
        }
      }
    );
  }

  viewGroupInfo(groupId) {

    this.BuyersService.getGroupInfo(groupId).subscribe(
      (res: any) => {
        if (res) {
          this.groupInfo = res.groupInfoArray;
          if (this.groupInfo) {
            const dialogConfig = new MatDialogConfig();

            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;

            dialogConfig.width = "50%";

            dialogConfig.data = this.groupInfo;

            const dialogRef = this.dialog.open(BuyergroupsComponent, dialogConfig);

          }
          else {
            this.toastr.error("Sorry,No information available", "")
          }
        }
      }
    );
  }


  editGroupInfo(groupId, groupName) {
    this.BuyersService.getGroupInfo(groupId).subscribe(
      (res: any) => {
        if (res) {
          console.log(res);
          this.groupInfo = res.groupInfoArray;

          const dialogConfig = new MatDialogConfig();

          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;

          dialogConfig.width = "50%";

          dialogConfig.data = {
            group: this.groupInfo,
            name: groupName
          }

          const dialogRef = this.dialog.open(BuyergroupsettingsComponent, dialogConfig);
          dialogRef.afterClosed().subscribe(data => {

            if (data) {
              console.log(this.obj);
              this.editGroupRequestBody.groupId = groupId.toString();
              this.editGroupRequestBody.groupName = data.groupName;
              this.editGroupRequestBody.groupDesc = data.groupDesc;
              this.editGroupRequestBody.paramMoq = data.paramMoq;
              this.editGroupRequestBody.paramOffer = data.paramOffer;
              if (data.valueOrPercent === "value") {
                this.editGroupRequestBody.paramPrice = data.deviation;
                this.editGroupRequestBody.paramPricePercent = "";
              }
              else {
                this.editGroupRequestBody.paramPricePercent = data.deviation;
                this.editGroupRequestBody.paramPrice = "";
              }
              this.BuyersService.editBuyerGroup(this.editGroupRequestBody).subscribe(
                (res: any) => {
                  if (res.acknowledgement.status === "SUCCESS") {

                    this.toastr.success("Buyer Group Edited successfully", "SUCCESS")
                    this.refreshGroupTable();
                  }
                  else {
                    this.errorMessage = res.acknowledgement.message
                    this.toastr.error(this.errorMessage, "ERROR")
                  }

                },
                (err: HttpErrorResponse) => {
                  if (err) {
                    this.errorMessage = err.error.message
                    this.toastr.error(this.errorMessage, "ERROR")
                  }
                  else {
                    this.toastr.error("Sorry,the buyer group could not be edited", "ERROR")
                  }

                }
              );
            }
          }
          );




        }
      }
    );

  }



}

