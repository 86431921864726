

<tour-step-template>
    <ng-template let-step="step">
        <div class="row">
        <div>
        <button mat-icon-button [disabled]="!whTourService.hasPrev(step)"
        [ngClass]="{'inactive-btn' : !whTourService.hasPrev(step)}" class="ml-2"
        (click)="whTourService.prev()"><mat-icon>arrow_back</mat-icon></button>
    </div>
    <div class="ngxp-end">
        <button mat-flat-button (click)="whTourService.end()">{{step?.endBtnTitle}}</button>
    </div>
    </div>
        <div class="ngxp-title" [ngClass]="{'ngxp-first-step' : !whTourService.hasPrev(step)}">
            <div class="ngxp-title-text">
                {{step?.title}}
        </div> 
           
        </div>
        <img src="../../assets/icon/guided_tour.png" width="60px">
        <div class="ngxp-content mb-3">{{step?.content}}</div>
        <div class="tour-step-navigation" style="display: flex;
        justify-content: center;
        align-items: center;">
            
            <button mat-raised-button color="primary" [disabled]="!whTourService.hasNext(step)"
                [ngClass]="{'inactive-btn' : !whTourService.hasNext(step)}" class="nextBtn"
                (click)="whTourService.next()">{{step?.nextBtnTitle}} »</button>
        </div>
    </ng-template>
</tour-step-template>
<div class="container">
<div class="row">
    <div class="col-lg-12 white-container">
        <div class="card border-success border mt-2" *ngIf="newUser">
            <div class="card-body">
                <h5>
                    <div class="card-title text-success">Welcome!</div>
                </h5>
                <div class="card-text">
                    <h5 style="color:green">Looks like, its your first time here .Please click on the Guided
                        tour to help you navigate</h5>
                    <button class="btn btn-primary" (click)="warehouseTour()">Guided Tour</button>
                </div>
            </div>
        </div>



       <!--  <mat-toolbar>
            WareHouse Settings
        </mat-toolbar> -->
        <div class="card">
        <div class="card-body pt-4">
            <div class="example-small-box mat-elevation-z4">
                <form [formGroup]="warehouseForm">
                    <div class="form-horizontal pl-2 pt-2">
                        <div style="float: right;" class="col-md-1 text-center" tourAnchor="cptour.wstep1.id">
                            <mat-icon matTooltip="Click for a guided Tour" class="pointer" (click)="warehouseTour()">
                                play_circle_outline</mat-icon>
                            <h6 style="margin-top: 0mm;">Guided Tour</h6>
                        </div>
        
        
                        <div style="font-size: 12px" class="form-group col-md-6 ws-guided-tour pt-2 wstep1" tourAnchor="cptour.wstep2.id">
                            <!-- <label for="warehouseName"><b>Warehouse Name *</b></label> -->
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>Warehouse Name</mat-label>
                                <input matInput  type="text" id="warehouseName" placeholder="Warehouse Name" [formControl]="warehouseForm.controls['name']"
                                (keydown.enter)="false" required>
                                <mat-error
                                *ngIf="warehouseForm.controls['name']?.errors?.required && warehouseForm.controls['name'].touched">
                                This is a required field</mat-error>
                            </mat-form-field>
                        </div>
        
                        <div style="font-size: 12px" class="form-group col-md-6 ws-guided-tour wstep2" tourAnchor="cptour.wstep3.id">
                            <!-- <label for="warehouseAddress"><b>Warehouse Address * </b> </label> -->
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>Warehouse Address</mat-label>
                                <input matInput type="text" placeholder="Warehouse Address" id="warehouseAddress" [formControl]="warehouseForm.controls['address']"
                                (keydown.enter)="false" >
                                <mat-error
                                *ngIf="warehouseForm.controls['address']?.errors?.required && warehouseForm.controls['address'].touched">
                                This is a required field </mat-error>
                            </mat-form-field>
                        </div>

                        <div style="font-size: 12px" class="form-group  col-md-6 ws-guided-tour" tourAnchor="cptour.wstep5.id">
                            <!-- <label for="warehouseCity"><b>City *</b></label> -->
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Street</mat-label>
                                <input matInput type="text" placeholder="Street" id="warehouseStreet" [formControl]="warehouseForm.controls['street']"
                                (keydown.enter)="false" >
                                
                            </mat-form-field>
                        </div>
        
        
                        <div style="font-size: 12px" class="form-group  col-md-6 ws-guided-tour wstep3" tourAnchor="cptour.wstep6.id">
                            <!-- <label for="warehouseCity"><b>City *</b></label> -->
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>City</mat-label>
                                <input matInput type="text" placeholder="City" id="warehouseCity" [formControl]="warehouseForm.controls['city']"
                                (keydown.enter)="false" >
                                <mat-error
                                *ngIf="warehouseForm.controls['city']?.errors?.required && warehouseForm.controls['city'].touched">
                                This is a required field</mat-error>
                            </mat-form-field>
                        </div>
        
                        <div style="font-size: 12px" class="form-group col-md-6  ws-guided-tour wstep4" tourAnchor="cptour.wstep7.id">
                            <!-- <label for="warehouseState"><b>State</b></label> -->
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>State</mat-label>
                                <input matInput type="text" placeholder="State" id="warehouseState" [formControl]="warehouseForm.controls['state']"
                                (keydown.enter)="false">
                                <mat-error
                                *ngIf="warehouseForm.controls['state']?.errors?.required && warehouseForm.controls['state'].touched">
                                This is a required field</mat-error>
                            </mat-form-field>
                        </div>
        
                        <div style="font-size: 12px" class="form-group col-md-6 ws-guided-tour wstep5 " tourAnchor="cptour.wstep8.id">
                            <!-- <label for="country-multiselect"><b>Country</b></label> -->
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Country</mat-label>
                                <mat-select placeholder="Country" [disabled]="countrySelected" [formControl]="warehouseForm.controls['country']">
                                    <mat-option *ngFor="let item of countryList" [value]="item.countryName">{{item.countryName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div style="font-size: 12px" class="form-group col-md-6 ws-guided-tour wstep6" tourAnchor="cptour.wstep9.id">
                            <!-- <label for="warehouseZip"><b>Zip/pin</b></label> -->
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Warehouse Zip</mat-label>
                                <input matInput type="text" id="warehouseZip" placeholder="Zip/Pin" [formControl]="warehouseForm.controls['zipCode']"
                                (keydown.enter)="false" >
                                <mat-error
                                *ngIf="warehouseForm.controls['zipCode']?.errors?.required && warehouseForm.controls['zipCode'].touched">
                                this field required</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="mt-2  pt-2 align-items-center" tourAnchor="cptour.wstep10.id">
                            <button type="submit" mat-button mat-raised-button class="green mb-2" (click)="SubmitWarehouse()">Save
                            </button>
                           
        
                            
                             <button *ngIf="showContinueButton" mat-button color="accent" mat-raised-button type="button" class="ml-3" (click)="continueToGeneral()">Continue</button>
                        </div>
        
                    </div>
                </form>
               </div>
        </div></div>
    </div>
</div>
</div>