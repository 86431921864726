
<tour-step-template>
    <ng-template let-step="step">
    <div class="row">
        <div>
        <button mat-icon-button [disabled]="!fTourService.hasPrev(step)"
        [ngClass]="{'inactive-btn' : fTourService.hasPrev(step)}" class="ml-2"
        (click)="fTourService.prev()"> <mat-icon>arrow_back</mat-icon></button>
    </div>
    <div class="ngxp-end">
        <button mat-flat-button (click)="fTourService.end()">{{step?.endBtnTitle}}</button>
    </div>
    </div>
        <div class="ngxp-title" [ngClass]="{'ngxp-first-step' : !fTourService.hasPrev(step)}">
            <div class="ngxp-title-text">
                {{step?.title}}
        </div> 
           
        </div>
        <img src="../../assets/icon/guided_tour.png" width="60px">
        <div class="ngxp-content mb-3">{{step?.content}}</div>
        <div class="tour-step-navigation" style="display: flex;
        justify-content: center;
        align-items: center;">
            
            <button mat-raised-button color="primary" [disabled]="!fTourService.hasNext(step)"
                [ngClass]="{'inactive-btn' : !fTourService.hasNext(step)}" class="nextBtn"
                (click)="fTourService.next()">{{step?.nextBtnTitle}} »</button>
        </div>
    </ng-template>
</tour-step-template>

<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12 white-container">  
           <!--  <mat-toolbar>
                <h3> Footer Settings</h3>
            </mat-toolbar> -->
            <div class="card">
               
                <div class="card-body pt-4">
                
              
            
                    <form [formGroup]="footerSettingsForm" >
                        <div class="form-horizontal pl-2 card-body ">
                            <div style="float: right;" class="col-md-1 text-center">
                                <mat-icon matTooltip="Click for a guided Tour" class="pointer"  (click)="footerTour()">play_circle_outline</mat-icon> 
                                <h6 style="margin-top: 0mm;">Guided Tour</h6>
                            </div>
                            <div class="form-group col-md-6 ws-guided-tour step1" tourAnchor="cptour.fstep1.id">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>About Us</mat-label>
                                    <!-- <label for="AboutUs">About Us </label> -->
                                    <textarea matInput  id="AboutUs" [formControl]="footerSettingsForm.controls['aboutUs']"  ></textarea>
                                </mat-form-field>
                        </div>
    
                        <div class="form-group col-md-6 ws-guided-tour step2" tourAnchor="cptour.fstep2.id">
                            <!-- <label for="facebookUrl" >Facebook</label> -->
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Facebook URL</mat-label>
                                <input matInput type="text" id="facebookUrl" [formControl]="footerSettingsForm.controls['facebookUrl']"  >
                            </mat-form-field>
                        </div>
                               
                        <div class="form-group col-md-6 ws-guided-tour step3" tourAnchor="cptour.fstep3.id">
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Linkedin URL</mat-label>
                                <!-- <label for="linkedinUrl">Linkedin</label> -->
                                <input matInput type="text" id="linkedinUrl"  [formControl]="footerSettingsForm.controls['linkedinUrl']"  >
                            </mat-form-field>
                        </div>
                        <div class="form-group col-md-6 ws-guided-tour step4" tourAnchor="cptour.fstep4.id">
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Instagram URL</mat-label>
                                <!-- <label for="instagramUrl">Instagram </label> -->
                                <input matInput type="text" id="instagramUrl" [formControl]="footerSettingsForm.controls['instagramUrl']"  >
                            </mat-form-field>
                        </div>
                        <div class="form-group col-md-6 ws-guided-tour step5" tourAnchor="cptour.fstep5.id">
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Twitter URL</mat-label>
                                <!-- <label for="twitterURL">Twitter </label> -->
                                <input matInput type="text" id="twitterUrl" [formControl]="footerSettingsForm.controls['twitterUrl']"  >
                            </mat-form-field>
                        </div>
                       
                        <div class="form-group col-md-6 ws-guided-tour step6" tourAnchor="cptour.fstep6.id">
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Youtube URL</mat-label>
                                <!-- <label for=" youtubeUrl">Youtube </label> -->
                                <input matInput type="text" id=" youtubeUrl" [formControl]="footerSettingsForm.controls['youtubeUrl']"  >
                            </mat-form-field>
                        </div>
                                
    
                            <div fxFlex="100" class="form-group col-md-6 mt-1 ws-guided-tour step7" tourAnchor="cptour.fstep7.id">
                                <button mat-raised-button class="green" [disabled]="continue" (click)="submitFooter()">Save</button>
                                 
                            </div> 
    
                        </div>
                     </form>
           </div>
        
        </div>
    </div>
    </div>
    </div>


