import { DashboardStatus } from './../Models/Response/dashboardStatus';
import { Component, OnInit, ViewChild, ElementRef,ChangeDetectorRef  } from '@angular/core';
import { Chart } from 'chart.js';
import { monthlyBuyersResponse } from '../Models/Response/monthlyBuyersResponse';
import { ServiceService } from './../Service/service.service';
import { DatePipe } from '@angular/common';
import { yearlyVolume } from '../Models/Response/yearlyVolume';
import * as moment from 'moment';
import { TourService } from 'ngx-tour-ngx-popper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BsDatepickerConfig, BsDatepickerViewMode } from 'ngx-bootstrap/datepicker';
import {MyVersion} from 'src/my-version';
import {MatGridListModule} from '@angular/material/grid-list';



@Component({
  selector: 'app-dash',
  templateUrl: './dash.component.html',
  styleUrls: ['./dash.component.scss']
})

export class DashComponent implements OnInit {
 
  topfiveBuyersChart: Chart;
  topfiveSalesChart: Chart;
  myLineChart: Chart;
  monthlybuyerschart:Chart;
  maxDate = new Date();
  myYear;
  myYearMonth;
  ds;
  dashboardStatus;
  yVolume;
  xaxis;
  yaxis;
  yearData;
  currentYear;
  currentMonth;
  topFiveBuyersLegend;
  topFiveBuyersVolume;
  topbuyersData;
  topFiveSalesLegend;
  topFiveSalesCount;
  topSalesData;
  mVolume;
  buyersStatiData;
  buyersNames;
  deliveredCount;
  undeliveredCount;
  rejectedOrderCount;
  cancelledOrderCount;
  currencyCode;
  curDate;
  minMode: BsDatepickerViewMode = 'month';
  ver:number;
  iconImages:string[]= [
    "../../assets/icon/order.png",
    "../../assets/icon/test1.png",
    "../../assets/icon/test.png",
    "../../assets/icon/ship.png",
    "../../assets/icon/deliver.png",
  ];
  
  onOpenYear(yearselect) {
    yearselect.yearSelectHandler = (event: any): void => {
      yearselect._store.dispatch(yearselect._actions.select(event.date));
    };
    yearselect.setViewMode('year');
  }
  onOpenYearMonth(yearmonthselect) {
    yearmonthselect.monthSelectHandler = (event: any): void => {
      yearmonthselect._store.dispatch(yearmonthselect._actions.select(event.date));
    };
     yearmonthselect.setViewMode('month');
    
  }




  getVolumeChartData(yearSelected)
  {
    
    yearSelected =this.datePipe.transform(yearSelected,"yyyy")
    this.ServiceService.getYearlyVolume(yearSelected)
    .subscribe((yearVolume: yearlyVolume) => {
      this.yVolume=yearVolume.yearTotalVolume;
      const yv = Math.abs(Number(this.yVolume));
      this.yearData= yearVolume.data;
      this.xaxis=this.yearData.map(x => x.data1);
      this.yaxis=this.yearData.map(x =>Math.abs(Number(x.data2)));
      console.log(this.yaxis);
      this.initmonthlychart()
  
    }
    );
  }



  getBuyersStatistics(myYearMonthSelected)
  {
    myYearMonthSelected =this.datePipe.transform(myYearMonthSelected,"MMM-yyyy")
    this.ServiceService.getBuyersStatistics(myYearMonthSelected)
    
    .subscribe((buyersStatistics: monthlyBuyersResponse) => {
      this.mVolume=buyersStatistics.netOrderAmount
      this.buyersStatiData= buyersStatistics.data
      this.buyersNames=this.buyersStatiData.map(x => x.accountName);
      this.deliveredCount=this.buyersStatiData.map(x => x.delivredOrderCount);
      this.undeliveredCount=this.buyersStatiData.map(x => x.unDeliveredOrderCount);
      this.cancelledOrderCount=this.buyersStatiData.map(x => x.cancelledOrderCount);
      this.rejectedOrderCount=this.buyersStatiData.map(x => x.rejectedOrderCount);
      this.currencyCode=buyersStatistics.currency;
      this.MonthlyBuyersChart() 
    }
    );
  }
 
  
  constructor(
    private ServiceService: ServiceService,
    private datePipe:DatePipe,
    public dbTourService: TourService,
    private ref: ChangeDetectorRef) 
    { 
      setInterval(() => {
        this.ref.detectChanges();
       }, 5000);
      /* this.maxDate.setDate(this.maxDate.getDate()); */
    }
    
  ngOnInit() {

   console.log("I am being called");
  this.currentYear = moment(new Date()).format('YYYY');
  this.currentMonth=  moment(new Date()).format('MMM-YYYY');
  this.curDate = new Date().getFullYear().toString().substr(-2) + '.' +(new Date().getMonth() + 1).toString();
  this.ver=MyVersion.number;
    this.ServiceService.getLiveStatus()
    .subscribe((liveStatus: DashboardStatus) => {
     this.ds=liveStatus.data
    }
    );

    this.ServiceService.getDashboardStatus()
    .subscribe((dbstatus: DashboardStatus) => {
      console.log(dbstatus.data);
      this.dashboardStatus=dbstatus.data
    }

    
    );
   




    this.ServiceService.getTopFiveBuyers()
    .subscribe((topFive: DashboardStatus) => {
     this.topbuyersData=topFive.data
     this.topFiveBuyersLegend=this.topbuyersData.map(x => x.data1);
     this.topFiveBuyersVolume=this.topbuyersData.map(x => x.data2);
     this.initTopfiveBuyersChart()
    }
    ) ;

     
    this.ServiceService.getTopFiveSales()
    .subscribe((topFiveSales: DashboardStatus) => {
     this.topSalesData=topFiveSales.data
     this.topFiveSalesLegend=this.topSalesData.map(x => x.data1);
     this.topFiveSalesCount=this.topSalesData.map(x => x.data2);
     
     this. initTopfiveSalesChart()

    }
    );
    
        this.dbTourService.initialize([ 

          {
            anchorId: 'dbtour.step1.id',
            content: 'Looks like, its your first time here .'+ '\r\n' +'Lets explore!',
            title: 'Welcome',
            nextBtnTitle: 'Get Started',
            endBtnTitle: 'Skip',
            
           
          },
          {
          anchorId: 'dbtour.step2.id',
          content: 'The status of the current orders',          
          title: 'Current Order Status',
          endBtnTitle: 'Skip',
          
        }, {
          anchorId: 'dbtour.step3.id',
          content: 'View your top five selling products',
          title: 'Products',
          endBtnTitle: 'Skip',
        },
        {
          anchorId: 'dbtour.step4.id',
          content:'Easily find out which businesses are buying from you the most',
          title: 'Retailers',
          endBtnTitle: 'Skip',
        },
        {
          anchorId: 'dbtour.step5.id',
          content:'Check out all your features and balance from your subscription package.',
          title: 'Subscription status',
          endBtnTitle: 'Skip',
        },
        {
          anchorId: 'dbtour.step6.id',
          content:'A quick glance at the yearly sales reports will let you know how your business is doing',
          title: 'Yearly Sales report',
          endBtnTitle: 'Skip',
        },
        {
          anchorId: 'dbtour.step7.id',
          content:'View how your buyers are engaging with your products. Find out who is been spending the most.',
          title: 'Buyer statistics per month',
         
        },

      ],
      {
        route: 'dashboard',
       }
    ); 
  }
  
  public dashboardTour(): void {
   this.dbTourService.start();  
  }

  initTopfiveBuyersChart() {
    Chart.defaults.global.legend.labels.usePointStyle = true;
    Chart.defaults.global.defaultFontSize = 10;
    this.topfiveBuyersChart = new Chart('topfiveChart', {
      type: 'doughnut',
      data: {
        labels: this.topFiveBuyersLegend,
        datasets: [{
          data:  this.topFiveBuyersVolume,
          backgroundColor: ['#74A0FF', '#FFCB46', '#f2f2f4', '#f0b1c7', '#A3CDB3'],
          hoverBackgroundColor: ['#74A0FF', '#FFCB46', '#f2f2f4', '#f0b1c7', '#A3CDB3'],
          hoverBorderColor: "rgba(234, 236, 244, 1)",
        }],
      },
      options: {
        maintainAspectRatio: false,
        tooltips: {
          backgroundColor: "rgb(255,255,255)",
          bodyFontColor: "#858796",
          borderColor: '#dddfeb',
          borderWidth: 1,
          xPadding: 15,
          yPadding: 15,
          displayColors: false,
          caretPadding: 10,
        },
        legend: {
          display:true,
          position: 'bottom',
          labels:{
          itemWrap:true,
          maxWidth:100,
          },
        },

        responsive: true,
        cutoutPercentage: 80,
      },

    })
  }

  initTopfiveSalesChart() {
    Chart.defaults.global.legend.labels.usePointStyle = true;
    Chart.defaults.global.defaultFontSize = 10;
    this.topfiveSalesChart= new Chart('topfivesaleschart', {
      type: 'doughnut',
      data: {
        labels:   this.topFiveSalesLegend ,
        datasets: [{
          data:  this.topFiveSalesCount,
          backgroundColor: ['#74A0FF', '#FFCB46', '#f2f2f4', '#f0b1c7', '#A3CDB3'],
          hoverBackgroundColor: ['#74A0FF', '#FFCB46', '#f2f2f4', '#f0b1c7', '#A3CDB3'],
          hoverBorderColor: "rgba(234, 236, 244, 1)",
        }],
      },
      options: {
        
        maintainAspectRatio: false,
        tooltips: {
          backgroundColor: "rgb(255,255,255)",
          bodyFontColor: "#858796",
          borderColor: '#dddfeb',
          borderWidth: 1,
          xPadding: 15,
          yPadding: 15,
          displayColors: false,
          caretPadding: 10,
        },
        legend: {
          display:true,
          position: 'bottom',
          labels:{
          itemWrap:true,
          maxWidth:100,
          },
        },
        responsive:true,
        cutoutPercentage: 80,
      },

    })
  }

  initmonthlychart() {
    // this.myLineChart.close()
    if(this.myLineChart){
      this.myLineChart.destroy();
    }
    Chart.plugins.register({
      afterDraw: function(monthlychart) {
        if (monthlychart.data.datasets[0].data === 0) {
          // No data is present
          var ctx = monthlychart.chart.ctx;
          var ctx =monthlychart.chart.ctx;
          var width = monthlychart.chart.width;
          var height = monthlychart.chart.height
          monthlychart.clear();
          
          ctx.save();
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.font = "16px 'Helvetica Nueue'";
          ctx.fillText('No data to display', width / 2, height / 2);
          ctx.restore();
        }
      }
    });
    
    
    const canvas = <HTMLCanvasElement> document.getElementById('monthlychart');
    const ctx = canvas.getContext('2d');
    var pointRadius=[];
    this. myLineChart = new Chart(ctx, {
       type: 'line',
       data: {
         labels: this.xaxis,
         datasets: [
           {
             label: 'Monthly Volume',
             data: this.yaxis,
                             
             borderCapStyle: "rounded",
            //  borderColor: '#0685B8',
             fill: false,
             backgroundColor:"transparent",
             borderColor:"#74A0FF",
            //  borderDash:[5,5]
            pointRadius: pointRadius
 
           },
 
         ]
       },
       options: { responsive:true,
          
spanGaps:true,
        // maintainAspectRatio:!1,
legend:{display:!1},
tooltips:{intersect:!1,
  
   callbacks: {
    label: function (tooltipItem, data) {
        const tooltip = data.datasets[tooltipItem.datasetIndex];
       const value = tooltip.data[tooltipItem.index];
       if (parseInt(value) === 0)
        return  null;
       if(parseInt(value) >= 1000){
          return   tooltip.label + ': ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
       } else {
          return   tooltip.label + ': ' + value;
       }
    }} },
hover:{intersect:!0},
plugins:{filler:{propagate:!1}},
scales: {
  xAxes: [{
    display:'auto',
      gridLines: {
          display:false
      }
  }],
  yAxes: [{
      gridLines: {
          display:false
      },
      ticks:{
        beginAtZero: true,
        callback: function(value, index, values) {
          if(parseInt(value) >= 10000)
          return value / 1e6 + 'M';
          else
          return value;
      } 
    }

  }]
} 
      
      }
      
     });
     for (var i = 0; i < this.myLineChart.data.datasets[0].data.length; i++) {
      
      if ((this.myLineChart.data.datasets[0].data[i]) === 0) {
         pointRadius[i]=0;
      /*    this.myLineChart.data.labels.pop();
         this.myLineChart.data.datasets.forEach((dataset) => {
        dataset.data.pop();
    }); */
    
      }
    }

    this.myLineChart.update();
   }


   MonthlyBuyersChart() {

    if(this.monthlybuyerschart){
      this.monthlybuyerschart.destroy();
    }
    
    this.monthlybuyerschart = new Chart('monthlyBuyersChart', {
      type: 'bar',
      data: {
        labels:this.buyersNames,
        
        datasets: [
          {
            label: 'Delivered',
            data: this.deliveredCount,
           
            backgroundColor: '#FFCB46',// green
            categoryPercentage: 0.2
          },
          {
            label: 'Undelivered',
            data: this.undeliveredCount,
            
            backgroundColor: '#74A0FF',// red
            categoryPercentage: 0.2 
          },
          {
            label: 'Rejected',
            data: this.rejectedOrderCount,
            
            backgroundColor: '#f2f2f4',// green
            categoryPercentage: 0.2
          },
          {
            label: 'Cancelled',
            data: this.cancelledOrderCount,
           
            backgroundColor: '#f0b1c7',// green
            categoryPercentage: 0.2
          },

          
        ]
        
      },
      options: {
        
        legend:{display:!1},
tooltips:{intersect:!1},
hover:{intersect:!0},
plugins:{filler:{propagate:!1}},
gridLines:{color:"rgba(0,0,0,0)",fontColor:"#fff"},    
      


        scales: {
          xAxes: [{
            stacked: true,
            
            gridLines:{display:false}
          
          }
        
        ],
          yAxes: [{
            stacked: true,
           
            gridLines:{display:false}

          }]
        },
        responsive:true
        // maintainAspectRatio:true,
        // aspectRatio:2
      }
    });
  }

  
  

}