<tour-step-template>
    <ng-template let-step="step">
        <div class="row">
        <div>
        <button mat-icon-button [disabled]="!pTourService.hasPrev(step)"
        [ngClass]="{'inactive-btn' : !pTourService.hasPrev(step)}" class="ml-2"
        (click)="pTourService.prev()"> <mat-icon>arrow_back</mat-icon></button>
    </div>
    <div class="ngxp-end">
        <button mat-flat-button (click)="pTourService.end()">{{step?.endBtnTitle}}</button>
    </div>
    </div>
        <div class="ngxp-title" [ngClass]="{'ngxp-first-step' : !pTourService.hasPrev(step)}">
            <div class="ngxp-title-text">
                {{step?.title}}
        </div> 
           
        </div>
        <img src="../../assets/icon/guided_tour.png" width="60px">
        <div class="ngxp-content mb-3">{{step?.content}}</div>
        <div class="tour-step-navigation" style="display: flex;
        justify-content: center;
        align-items: center;">
            
            <button mat-raised-button color="primary" [disabled]="!pTourService.hasNext(step)"
                [ngClass]="{'inactive-btn' : !pTourService.hasNext(step)}" class="nextBtn"
                (click)="pTourService.next()">{{step?.nextBtnTitle}} »</button>
        </div>
    </ng-template>
</tour-step-template>

<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12 white-container">  
           <!--  <mat-toolbar>
                <h3> Profile Settings</h3>
            </mat-toolbar> -->
            <div class="card">
               
                <div class="card-body pt-4">



                    <form [formGroup]="profileSettingsForm">
                        <div class="form-horizontal pt-2 pl-2 card-body ">
                            <div style="float: right;" class="col-md-1 text-center">
                                <mat-icon matTooltip="Click for a guided Tour" class="pointer" (click)="profileTour()">
                                    play_circle_outline</mat-icon>
                                <h6 style="margin-top: 0mm;">Guided Tour</h6>
                            </div>
                            <div class="form-group col-md-6 ws-guided-tour pstep1" tourAnchor="cptour.pstep1.id">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>Name</mat-label>
                                    <!-- <label for="profilename">Name</label> -->
                                    <input matInput type="text" id="profilename" [formControl]="profileSettingsForm.controls['name']" required>
                                    <mat-error
                                    *ngIf="profileSettingsForm.controls['name']?.errors?.required && profileSettingsForm.controls['name'].touched">
                                    This field is required</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-md-6 ws-guided-tour pstep2" tourAnchor="cptour.pstep2.id">
                                <!-- <label for="profileaddress">Address *</label> -->
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>Address</mat-label>
                                    <input matInput type="text" id="profileaddress" [formControl]="profileSettingsForm.controls['address']"
                                    required>
                                    <mat-error
                                    *ngIf="profileSettingsForm.controls['address']?.errors?.required && profileSettingsForm.controls['address'].touched">
                                    this is a required field</mat-error>
                                </mat-form-field>
                            </div>
        
        
                            <div class="form-group col-md-6 ws-guided-tour pstep3" tourAnchor="cptour.pstep3.id">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>City</mat-label>
                                    <!-- <label for="profilecity">City *</label> -->
                                    <input matInput type="text" id="profilecity" [formControl]="profileSettingsForm.controls['city']" required
                                    >
                                    <mat-error
                                    *ngIf="profileSettingsForm.controls['city']?.errors?.required && profileSettingsForm.controls['city'].touched">
                                    this field is required</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-md-6 ws-guided-tour pstep4" tourAnchor="cptour.pstep4.id">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>State</mat-label>
                                    <!-- <label for="profilestate">State</label> -->
                                    <input matInput type="text" id="profilestate" [formControl]="profileSettingsForm.controls['state']"
                                    >
                                </mat-form-field>
                            </div>
                            <div class="form-group col-md-6 ws-guided-tour pstep5" tourAnchor="cptour.pstep5.id">
                                <mat-form-field class="full-width" appearance="outline">
        
                                    <!-- <label for="example-multiselect">Country *</label> -->
                                    <mat-label>Country</mat-label>
                                    <mat-select  [formControl]="profileSettingsForm.controls['country']" [disabled]="countrySelected" required>
                                        <mat-option *ngFor="let item of countryList" [value]="item.countryName">{{item.countryName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="form-group  col-md-6 ws-guided-tour pstep6" tourAnchor="cptour.pstep6.id">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>Zip/pin</mat-label>
                                    <!-- <label for="profilezip">Zip/pin</label> -->
                                    <input matInput type="text" id="profilezip" [formControl]="profileSettingsForm.controls['zipCode']"
                                    >
                                    <mat-error
                                    *ngIf="profileSettingsForm.controls['zipCode']?.errors?.required && profileSettingsForm.controls['zipCode'].touched">
                                    this field is required</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-md-6 ws-guided-tour pstep7" tourAnchor="cptour.pstep7.id">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>Email </mat-label>
                                    <!-- <label for="profilemail">Email </label> -->
                                    <input matInput type="text" [readonly]="true" id="profileemail" [formControl]="profileSettingsForm.controls['email']">
                                    <mat-error
                                    *ngIf="profileSettingsForm.controls['email']?.errors?.required && profileSettingsForm.controls['email'].touched">
                                    Email is required</mat-error>
                                    <mat-error *ngIf="profileSettingsForm.controls['email'].hasError('email')">
                                        Email is not Valid!
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-md-6 ws-guided-tour pstep8" tourAnchor="cptour.pstep8.id">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>Mobile Number</mat-label>
                                    <!-- <label for="profilemobile">Mobile Number *</label> -->
                                    <input matInput type="tel" [readonly]="true" id="profilemobile" [formControl]="profileSettingsForm.controls['phone']"
                                     required>
                                    <mat-error
                                    *ngIf="profileSettingsForm.controls['phone']?.errors?.required && profileSettingsForm.controls['phone'].touched">
                                    This field is required</mat-error>
                                    
                                </mat-form-field>
                                </div>
                            <div fxFlex="100" class="form-group col-md-6 mt-1" tourAnchor="cptour.pstep9.id">
                                <button mat-raised-button class="green" [disabled]="continue" (click)="submitProfile()">Save</button>
                                <button *ngIf="showContinueButton" mat-button color="accent" mat-raised-button type="button" class="ml-3" (click)="continueToImages()">Continue</button>
                                <!-- <button class="wnext" mat-button (click)="goForward(stepper)" >Next</button>          -->
        
                                <!-- <button mat-button color="warn" type="button" class="popup_cancel_btn" (click)="Reset()">Close</button> -->
                            </div>
        
                        </div>
                    </form>
        </div>
    </div>
</div>
    </div></div>
    