import { loginResponse } from './../Models/Response/loginResponse';

import { authService } from './../Service/authService';
import { Component, Input, Output, EventEmitter, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import {NgxCaptchaModule,ReCaptcha2Component} from 'ngx-captcha';
import { ControlPanelService } from '../control-panel/control-panel.service';
import { DataService } from '../data.service';
import {ToastrService} from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import {MyVersion} from 'src/my-version';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  hide = true;
  loading = false;
  form: FormGroup;
  public loginInvalid: boolean;
  private formSubmitAttempt: boolean;
  private returnUrl: string;
  status: String;
  isCheckedCaptch: boolean;
  siteKey: string;
  isRobort: boolean;
  errorMessage: any;
  youtubeUrl:string = 'https://www.youtube.com/channel/UCqMifbRHpEGhRFyhk1kpRKg';
  linkedinUrl: string = ' https://www.linkedin.com/company/storimart-b2b';
  facebookUrl:string = 'https://www.facebook.com/storimart';
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  warehouseSettingsDetails: any;
  curDate;
  ver:number;

  get data():string{
    return this.dataService.sharedData;
  }
  set data(value:string){
     this.dataService.sharedData= value;
  }
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private controlPanelService: ControlPanelService,
    private authService :authService,
    private dataService :DataService,
    private toastr: ToastrService,
  ) {
    
  }


  async ngOnInit() {

    this.siteKey= environment.siteKey;
    this.curDate = new Date().getFullYear().toString().substr(-2) + '.' +(new Date().getMonth() + 1).toString();
  this.ver=MyVersion.number;
    //  this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/dashboard';
     this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/controlPanel';
    this.form = this.fb.group({
      username: ['', Validators.email],
      password: ['', Validators.required],
      recaptcha: ['', Validators.required]
     
    });

    
  }
  
   onSubmit() {
    
    this.loginInvalid = false;
    this.loading=true;
    this.formSubmitAttempt = false;
    var response =this.form.value.recaptcha
    if(response.length == 0) 
    { 
      //reCaptcha not verified
     this. isRobort =true
    
      // form.preventDefault();
      return false;
    }
    if (this.form.valid) {
      localStorage.clear();
      localStorage.setItem('userName',this.form.get('username').value);
      localStorage.setItem('passWord',this.form.get('password').value);

      try {
var obj={
  "password":this.form.get('password').value,
  "email":this.form.get('username').value,
  "g-recaptcha-response":this.form.value.recaptcha
}
        this.authService.login(obj)
        .subscribe(loginData=>{
          console.log(loginData);
         
          if(loginData.body.acknowledgement.status==="SUCCESS")
          {
          localStorage.setItem("AuthorizationToken",loginData.headers.get("Authorization")) 
          
          localStorage.setItem("CompanyName",loginData.body.userDetails.companyName) 
          localStorage.setItem("isLogined","true")
          console.log(localStorage.getItem('isLogined'));
          this.newuserCheck()
          } 
          else {
          this.loginInvalid = true;
          }  
        }, (err:HttpErrorResponse) => {
          if(err.error.acknowledgement.status==="ERROR")
          {
            this.errorMessage=err.error.acknowledgement.message
            this.loginInvalid = true;
            // recaptcha.resetCaptcha();
            this.captchaElem.resetCaptcha();
            this.loading=false;
            this.toastr.error("",this.errorMessage)
          }
       });
      }
      catch{
        this.loginInvalid = true;
      }
    }
  }
  newuserCheck(){
    
    this.controlPanelService.getwarehouseSettings().subscribe(
      res => {
        this.warehouseSettingsDetails = res.warehouseSettings;
        if(this.warehouseSettingsDetails){
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
          this.data=''
          this.router.navigate([this.returnUrl]);
        }else{
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/settings';
          this.data='gt'
          this.router.navigate([this.returnUrl], { queryParams: { newUser: true } });
          
        }
        
        this.toastr.success("Login Successful","Success")
        this.loading=false;

      }
      );
     
  }

  handleSuccess(captchaResponse:String)
  {
    this.isRobort=false
   
  }
}

