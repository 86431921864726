<mat-toolbar>
    <span>Buyer Group Details</span>
  </mat-toolbar>
  <div class="example-container mat-elevation-z8">

    <mat-card>

        <div class="example-header">
          <mat-form-field>
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
          </mat-form-field>
        </div>
    
       
    <mat-card-content>
        
            <table mat-table [dataSource]="dataSource" matSort>
               
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef> ID </th>
                    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                  </ng-container>

                  <ng-container matColumnDef="parameter">
                    <th mat-header-cell *matHeaderCellDef> Parameter </th>
                    <td mat-cell *matCellDef="let element"> {{element.parameter}} </td>
                  </ng-container>
          
                  <ng-container matColumnDef="value">
                    <th mat-header-cell *matHeaderCellDef> Value </th>
                    <td mat-cell *matCellDef="let element"> {{element.value}} </td>
                  </ng-container>
              
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
       
    
        <mat-paginator [length]="dataLength"
        [pageSize]="5" showFirstLastButtons></mat-paginator>

    </mat-card-content>


        <mat-dialog-actions>
            <mat-dialog-actions class="align-items-end">
                <button class="mat-raised-button" (click)="close()">Close</button>
           </mat-dialog-actions>
        </mat-dialog-actions>
     
    </mat-card>
</div>
    


