

<div class="example-container mat-elevation-z2">



<h2 mat-dialog-title align="center" color="primary">{{alertCount}}&nbsp;{{alertMessage}} </h2>

<mat-dialog-content>
<table mat-table [dataSource]="alertDetailsData" >
          
    <!-- Position Column -->
    <ng-container matColumnDef="orderId">
      <th mat-header-cell *matHeaderCellDef> Id/Prefix </th>
      <td mat-cell *matCellDef="let element"> {{element.orderId}} </td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="customerBusinuss">
      <th mat-header-cell *matHeaderCellDef> Customer Business </th>
      <td mat-cell *matCellDef="let element"> {{element.retailerShopName}}</td>
    </ng-container>
  
    <!-- Weight Column -->
    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef> Phone </th>
      <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
    </ng-container>
  
    <!-- Symbol Column -->
    <ng-container matColumnDef="customerUser">
      <th mat-header-cell *matHeaderCellDef> Customer User </th>
      <td mat-cell *matCellDef="let element"> {{element.userName}} </td>
    </ng-container>

    <ng-container matColumnDef="submittedTime">
      <th mat-header-cell *matHeaderCellDef> Submitted</th>
      <td mat-cell *matCellDef="let element"> {{element.orderedDate}} </td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="expectedDeliveryTime">
      <th mat-header-cell *matHeaderCellDef> Expected Delivery </th>
      <td mat-cell *matCellDef="let element"> {{element.deliveryTime}} </td>
    </ng-container>
  
    <!-- Weight Column -->
    <ng-container matColumnDef="lastUpdateTime">
      <th mat-header-cell *matHeaderCellDef> Last Update </th>
      <td mat-cell *matCellDef="let element"> {{element.lastUpdatedTime}} </td>
    </ng-container>
  
    <!-- Symbol Column -->
    <ng-container matColumnDef="totalCost">
      <th mat-header-cell *matHeaderCellDef> Total Cost({{currency}}) </th>
      <td mat-cell *matCellDef="let element"> {{element.totalAmount}} </td>
    </ng-container>

    <ng-container matColumnDef="paymentMethod">
      <th mat-header-cell *matHeaderCellDef> Payment Method </th>
      <td mat-cell *matCellDef="let element"> {{element.paymentMethod}} </td>
    </ng-container>
  
    <!-- Symbol Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> Status </th>
      <td mat-cell *matCellDef="let element"> {{element.status}} </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="color"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[7]" showFirstLastButtons></mat-paginator>

</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close color="primary">Close</button>
</mat-dialog-actions>

</div>