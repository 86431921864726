<tour-step-template>
    <ng-template let-step="step">
    <div class="row">
        <div>
        <button mat-icon-button [disabled]="!nTourService.hasPrev(step)"
        [ngClass]="{'inactive-btn' : nTourService.hasPrev(step)}" class="ml-2"
        (click)="nTourService.prev()"> <mat-icon>arrow_back</mat-icon></button>
    </div>
    <div class="ngxp-end">
        <button mat-flat-button (click)="nTourService.end()">{{step?.endBtnTitle}}</button>
    </div>
    </div>
        <div class="ngxp-title" [ngClass]="{'ngxp-first-step' : !nTourService.hasPrev(step)}">
            <div class="ngxp-title-text">
                {{step?.title}}
        </div> 
           
        </div>
        <img src="../../assets/icon/guided_tour.png" width="60px">
        <div class="ngxp-content mb-3">{{step?.content}}</div>
        <div class="tour-step-navigation" style="display: flex;
        justify-content: center;
        align-items: center;">
            
            <button mat-raised-button color="primary" [disabled]="!nTourService.hasNext(step)"
                [ngClass]="{'inactive-btn' : !nTourService.hasNext(step)}" class="nextBtn"
                (click)="nTourService.next()">{{step?.nextBtnTitle}} »</button>
        </div>
    </ng-template>
</tour-step-template>


<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12 white-container">  
            <!-- <mat-toolbar>
                <h3> Notification Settings</h3>
            </mat-toolbar> -->
            <div class="card">
               
                <div class="card-body pt-4">

                    <form [formGroup]="notificationForm" >
                        <div class="form-horizontal pl-2 card-body">
                            <div style="float: right;" class="col-md-1 text-center">
                                <mat-icon matTooltip="Click for a guided Tour" class="pointer"  (click)="notificationTour()">play_circle_outline</mat-icon> 
                                <h6 style="margin-top: 0mm;">Guided Tour</h6>
                            </div>
                             <div class="form-group col-md-6 ws-guided-tour nstep1" tourAnchor="cptour.nstep1.id">
                                 <mat-form-field class="full-width" appearance="outline">
                                     <mat-label>Email</mat-label>
                                     <!-- <label for="email">Email *</label> -->
                                     <input matInput type="email"  id="email" [formControl]="notificationForm.controls['email']"   required>
                                     <!-- <mat-error *ngIf="notificationForm.controls['email'].invalid">not valid</mat-error> --> 
                                     <mat-error *ngIf="notificationForm.controls['email'].hasError('required') && notificationForm.controls['email'].touched" class="form-error-msg">
                                         Email is required</mat-error>
                                         <mat-error *ngIf="notificationForm.controls['email'].hasError('email')">
                                             Email is not Valid!
                                            </mat-error>
                                        </mat-form-field>
                             </div> 
                          <div class="form-group row col-md-6 ws-guided-tour nstep2" tourAnchor="cptour.nstep2.id">
                            <mat-form-field appearance="outline">
                                <mat-label>Code</mat-label>
                            <mat-select [formControl]="notificationForm.controls['countryId']" [disabled]="codeSelected" placeholder="Country Code">
                                <mat-option *ngFor="let item of countryList" [value]="item.countryId" required>
                                    {{item.countryCode}}
                                </mat-option>
                            </mat-select>
                            
                          </mat-form-field>
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Mobile Number</mat-label>
                                <!-- <label for="mobile">Mobile Number *</label> -->
                                <input matInput type="tel" id="mobile" [formControl]="notificationForm.controls['phone']" required>
                                <mat-error *ngIf="notificationForm.controls['phone']?.errors?.required&& notificationForm.controls['phone'].touched">
                                    This is a required field</mat-error> 
                                </mat-form-field>
                            </div> 
                          <div fxFlex="100" class="form-group col-md-6 mt-1" tourAnchor="cptour.nstep3.id">
                                <button mat-raised-button   class="green" [disabled]="continue" (click)="Submitnotification()">Save</button>
                                <button *ngIf="showContinueButton" mat-button color="accent" mat-raised-button type="button" class="ml-3" (click)="continueToOfs()">Continue</button>
                         
                        </div>
                        </div>
                    </form>
        


        </div>
    </div>
</div>
</div>
</div>


