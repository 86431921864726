import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ControlPanelService } from '../../control-panel/control-panel.service';
import { WarehouseSettings } from '../../control-panel/models/WarehouseSettings.model'
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HostListener } from "@angular/core";
import { TourService } from 'ngx-tour-ngx-popper';
import { HttpErrorResponse } from '@angular/common/http';
import { ProfileSettings } from '../../control-panel/models/ProfileSettings.model';
import { SettingsComponent} from '../settings.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  
  public profileSettingsForm: FormGroup;
  profileSettingsDetails: any;
  hideSubmitStep = false;
  hidePrevStep = false;
  successMessage:string="";
  errorMessage:string="";
  countryList:any;
  tempPrevTourStep;
  warehouseSettingsDetails: any;
  continue=false;
  countryId="";
  countrySelected=false;
  showContinueButton = false;



  constructor(private fb: FormBuilder,
    private controlPanelService: ControlPanelService,
    public activateRoute: ActivatedRoute,
    private router: Router,
    public pTourService: TourService,
    private toastr: ToastrService, private settings:SettingsComponent) { }

  ngOnInit(): void {

    this.controlPanelService.getwarehouseSettings().subscribe(
      res => {
        this.warehouseSettingsDetails = res.warehouseSettings;
        if(this.warehouseSettingsDetails === null)
        {
        this.toastr.error("Please Update the WareHouse Settings first to Continue","ERROR")
          this.continue=true;
        }
       
      });
    this.profileSettingsForm = this.fb.group({
      name: ['' || '', Validators.required],
      address: ['' || '', Validators.required],
      city: ['' || '', Validators.required],
      state: ['' || ''],
      country: ['' || '', ],
      zipCode: ['' || '',Validators.required],
      email: ['', {
        validators: [
            Validators.required,
            Validators.email
        ],
        updateOn: 'blur'
     }],
      phone: [''|| ''],
    });

    /* this.controlPanelService.countryList().subscribe(
      res => {
        this.countryList = res.country;
      }
    ); */

    this.controlPanelService.tenantCountryList().subscribe(
      response => {
        this.countryList = response.country;
      }
    );




    this.controlPanelService.getprofileSettings().subscribe(
      res => {
        this.profileSettingsDetails = res.profileSettings;
       if(this.profileSettingsDetails){
        this.profileSettingsForm.controls['name'].setValue(this.profileSettingsDetails.name);
        this.profileSettingsForm.controls['address'].setValue(this.profileSettingsDetails.address);
        this.profileSettingsForm.controls['city'].setValue(this.profileSettingsDetails.city);
        this.profileSettingsForm.controls['state'].setValue(this.profileSettingsDetails.state);
        this.profileSettingsForm.controls['country'].setValue(this.profileSettingsDetails.country);
        this.countryId =this.profileSettingsDetails.countryId;
        if(this.profileSettingsDetails.country)
        this.countrySelected=true;
        this.profileSettingsForm.controls['zipCode'].setValue(this.profileSettingsDetails.zipCode);
        this.profileSettingsForm.controls['email'].setValue(this.profileSettingsDetails.email);
        this.profileSettingsForm.controls['phone'].setValue(this.profileSettingsDetails.phone);
       }
      }
      );


     

      this.pTourService.initialize([
        {
          stepId : 'cptour.pstep1.id',
         anchorId: 'cptour.pstep1.id',
         content: 'Profile name of the user.This will be dispalyed to buyers.' ,
         title: 'Display Name',
         placement:'right',
         endBtnTitle:'Skip',
          
        
       },
       { 
        stepId: 'cptour.pstep2.id',
         anchorId: 'cptour.pstep2.id',
         content: 'Street Address ' ,
         title: 'Address',
         placement:'right',
         endBtnTitle:'Skip',
       },
      {  
        stepId: 'cptour.pstep3.id',
         anchorId: 'cptour.pstep3.id',
         content: 'Please enter your city name' ,
         title:'City',
         placement:'right',
         endBtnTitle:'Skip',
         
       },
       {
        stepId: 'cptour.pstep4.id',
         anchorId: 'cptour.pstep4.id',
         content: 'Please enter the state ' ,
         title: 'State',
         placement:'right',
         endBtnTitle:'Skip',
            
       },
       {
        stepId: 'cptour.pstep5.id',
         anchorId: 'cptour.pstep5.id',
         title: 'Country',
         content: 'Please select the country' ,
         placement:'right',
         endBtnTitle:'Skip',
            
         
       },
      
       {
        stepId: 'cptour.pstep6.id',
         anchorId: 'cptour.pstep6.id',
         title: 'ZipCode/PIN',
         content: 'Please enter the Zip/pin number' ,
         placement:'right',
         endBtnTitle:'Skip',
         
         
       },
       
       {
        stepId: 'cptour.pstep7.id',
         anchorId: 'cptour.pstep7.id',
         content: 'Email ' ,
        title: 'Email',
        endBtnTitle:'Skip',
         placement:'right',
            
       },
       {
        stepId: 'cptour.pstep8.id',
         anchorId: 'cptour.pstep8.id',
         content: 'Mobile number' ,
         title: 'Phone',
         placement:'right',
         endBtnTitle:'Skip',
           
       },
      
       {
        anchorId: 'cptour.pstep9.id',
        content: 'Please press this button to save all the above information.',
        title: 'Save and Submit',
        placement:'right',
        
      } ],
        {route: 'settings',
        });
    }

    continueToImages(){
      this.settings.openImageUploadSettings();
      this.settings.toggleColor(6);
    }
  
        @HostListener('click',['$event'])mouseover(event) {  
          if (this.pTourService.currentStep) {
            const nextStep = $(event.target).closest(".ws-guided-tour").attr("tourAnchor")
            if (nextStep && this.tempPrevTourStep !== nextStep) {
    
              this.pTourService.startAt(nextStep);
              this.tempPrevTourStep = nextStep;
            }      
          }
        };
        @HostListener('document:keydown.tab', ['$event'])
        onKeydownHandler(event: KeyboardEvent) {
         if (this.pTourService.currentStep) {
               this.pTourService.next();
         
           }    
         }
      
         
  

  public profileTour(): void {
  
   this.pTourService.disableHotkeys();
   this.pTourService.start(); 
 }


 submitProfile() {
  this.profileSettingsForm.markAllAsTouched();
  if(this.profileSettingsForm.valid){
  var obj: ProfileSettings = this.profileSettingsForm.value
  for(var i=0;i<this.countryList.length;i++){
    if(this.profileSettingsForm.controls['country'].value === this.countryList[i].countryName)
    this.countryId = this.countryList[i].countryId
  }
  obj['countryId'] =this.countryId;
  if(this.countryId !== ""){
  this.controlPanelService.saveprofileSettings(obj).subscribe(
    (res: any) => {
      if(res.acknowledgement.status==="SUCCESS"){
        this.showContinueButton=true;
        this.successMessage=res.acknowledgement.message
        this.toastr.success(this.successMessage,"SUCCESS")
      }
      else{
        this.successMessage=res.acknowledgement.message
        this.toastr.error(this.successMessage,"ERROR")
      }
      
       
    },
     
        (err:HttpErrorResponse) => {
          if(err)
          {
             this.errorMessage=err.error.acknowledgement.message
             this.toastr.error(this.errorMessage,"ERROR")
          }
          else{
            this.toastr.error("Sorry,the information was not updated,Please try again later","ERROR")
          }
       }
  );
  }

}

}
}
