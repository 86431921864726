

<div class="example-container" >
    <h2 mat-dialog-title>Update App User</h2>

    <mat-dialog-content [formGroup]="appUserSettings" (submit)="save()">
    <div class="row">
        <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
          <input formControlName="name" matInput required>
          <mat-error *ngIf="appUserSettings.controls['name']?.errors?.required && appUserSettings.controls['name'].touched">
            This field is required</mat-error>
        </mat-form-field>
    </div>
    <div class="row">  
        <mat-form-field appearance="outline">            <mat-label>Country</mat-label>
        <mat-select formControlName="countryId" placeholder="Country">
            <mat-option *ngFor="let item of countryList" [value]="item.countryCode" required>
                {{item.countryCode}}
            </mat-option>
        </mat-select>
      </mat-form-field> 
    </div>
    <div class="row">   
        <mat-form-field appearance="outline">
            <mat-label>Phone</mat-label>
          <input formControlName="phone" matInput  required>
          <mat-error *ngIf="appUserSettings.controls['phone']?.errors?.required && appUserSettings.controls['phone'].touched">
            This field is required</mat-error>
            <mat-error *ngIf="appUserSettings.controls['phone']?.errors?.pattern">
                Please enter a valid Phone number  </mat-error>
        </mat-form-field>
    </div> 

<div class="row">
        <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email">
            
            <mat-error *ngIf="appUserSettings.controls['email']?.errors?.required && appUserSettings.controls['email'].touched">
              This field is required</mat-error>
              <mat-error *ngIf="appUserSettings.controls['email']?.errors?.pattern">
                  Please enter a valid Email address  </mat-error>
        </mat-form-field>
    </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button class="mat-raised-button"(click)="close()">Close</button>
        &nbsp;&nbsp;&nbsp;
        <button class="mat-raised-button mat-primary"(click)="save()">Update App User</button>
    </mat-dialog-actions>

</div>

