import { Component, OnInit,Inject,ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ReportService } from '../../Service/report.service';
import { alertSummeryResponse } from '../../Models/Response/alertSummeryResponse';
import { alertDetailsResponse } from '../../Models/Response/alertDetailsResponse';
import * as moment from 'moment';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css']
})
export class AlertDialogComponent implements OnInit {
  alertDetailsData
  alertData=[];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  alertCount: string;
  alertMessage: string;
  accountId:any;
  alertType:any;
  notAcceeptedCount;
  notDeliveredCount;        
  alertAccountId;
  notAccepted;
  notDelivered;
  currency;

 

  displayedColumns: string[] = ['orderId', 'customerBusinuss', 'phone', 'customerUser', 'submittedTime', 'expectedDeliveryTime', 'lastUpdateTime', 'totalCost', 'paymentMethod', 'status'];
  
  constructor(public dialogRef: MatDialogRef<AlertDialogComponent>, @Inject(MAT_DIALOG_DATA) data,private reportService: ReportService,) {
    this.accountId =data.accountId;
    this.alertType = data.alertType;
   }

  ngOnInit(): void {

    this.alertDetailsData = new MatTableDataSource();
    this.reportService.getAlertSummery()
      .subscribe((alerts: alertSummeryResponse) => {
        this.notAcceeptedCount = alerts.notAccepted
        this.notDeliveredCount = alerts.notDelivered
        this.alertAccountId = alerts.accountId
        this.notAccepted = "notAccepted"
        this.notDelivered = "notDelivered"
      }); 
    

    switch (this.alertType) {
      case 'notAccepted':
        {
          this.alertCount = this.notAcceeptedCount
          this.alertMessage = "Orders not accepted after 15 minutes of expected delivery time."
          break;
        }
      case 'notDelivered':
        {
          this.alertCount = this.notDeliveredCount
          this.alertMessage = "Orders not delivered after 2 hour of expected delivery time."
          break;
        }
    }

    this.reportService.getAlertDetails(this.accountId, this.alertType)
      .subscribe((alertsDetails:any) => {
        this.alertData=[];
        this.currency=alertsDetails.order[0].header.totalAmount.currency,
        alertsDetails.order.forEach(order => {
          this.alertData.push({
                          
            orderId: order.header.orderId,
            retailerShopName:order.header.retailerShop.retailerShopName,
            phone:order.header.retailerShop.phone,
            userName:order.header.user.userName,
            orderedDate: moment(order.header.orderedDate, 'YYYY/MM/DD').format('DD MMM YYYY'),
            deliveryTime: moment(order.header.prefDeliveryTime, 'YYYY/MM/DD').format('DD MMM YYYY'),
            lastUpdatedTime: moment(order.header.lastUpdatedTime,'YYYY/MM/DD').format('DD MMM YYYY'),
            currency: order.header.totalAmount.currency,
            totalAmount: parseFloat(order.header.totalAmount.value).toFixed(2),
            paymentMethod:order.paymentMethod,
            status:order.header.status,
          })
        });
        
        this.alertDetailsData = new MatTableDataSource(this.alertData);
        this.alertDetailsData.paginator = this.paginator;

      }
      );
    
  }

  close() {
   
    this.dialogRef.close();
  }

  }


