<div class="example-container">

<div *ngIf="headers.length <7">
   
    <h2 mat-dialog-title>Oops, It looks like you have  <span style="color: red;">not</span> included all the necessary fields!</h2>
    <p>Please confirm if you included the <strong>Business Name, Phone, Address, City, Country,buyerGroupName and TimeZone.</strong></p>
    <button class="mat-raised-button mat-primary" (click)="close()">Close</button>
</div>

<div *ngIf="headers.length >= 7">
    <h2 mat-dialog-title>Please confirm/select the headers of Excel file uploaded</h2>
    <div [formGroup]="mappingForm" (submit)="save()">

        <mat-grid-list cols="2" rowHeight="75px">
           <div formArrayName="tickets">
              
        <div *ngFor="let header of headers; let i = index">
            <mat-grid-tile>
            <p> {{header}} </p>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-form-field appearance="outline">
                <mat-select [formControlName]="i"  [(ngModel)]="buyerFields[i]">
                    <mat-option *ngFor="let item of buyerFields1" [value]="item">
                        {{item}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </mat-grid-tile>
        </div>
    </div>
       
    
    </mat-grid-list>    

</div>


    <mat-dialog-actions>
        <mat-dialog-actions class="align-items-end">
            <button class="mat-raised-button" (click)="close()">Close</button>
            &nbsp;&nbsp;
            <button class="mat-raised-button mat-primary" (click)="save()">Save</button>
        </mat-dialog-actions>
    </mat-dialog-actions>
</div>

</div>