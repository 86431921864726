import { Component, OnInit } from '@angular/core';
import { authService } from './../Service/authService';
import { ActivatedRoute, Router,Params } from '@angular/router';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import {LoginRequest} from '../Models/Response/loginRequest';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.css']
})
export class SSOComponent implements OnInit {
  
    authToken:string="";
    tokenObj:{
      token:string;
    } ={token:''};
   
    

    constructor(private authService :authService,private router: Router,private route:ActivatedRoute) { }

    ngOnInit(): void {

      this.authToken=this.route.snapshot.params.token;
      this.tokenObj.token= this.authToken;
     // var decoded = jwt_decode(this.authToken); 
      //console.log(decoded);
      var decodedString = atob(this.authToken);
console.log(decodedString); 
      var password = decodedString.substring(decodedString.indexOf(":") + 1);
      console.log(password);
      var username = decodedString.split(':')[0];
      console.log(username)
      this.authService.ssoLogin(this.tokenObj)
    .subscribe(loginData=>{
      console.log(loginData);
     
      if(loginData.body.acknowledgement.status==="SUCCESS")
      {
      localStorage.setItem("AuthorizationToken",loginData.headers.get("Authorization")) 
      localStorage.setItem('userName',username);
      localStorage.setItem('passWord',password);
      localStorage.setItem("CompanyName",loginData.body.userDetails.companyName) 
      localStorage.setItem("isLogined","true")
      this.router.navigate(['/dashboard']);
      } 
     
    }, (err:HttpErrorResponse) => {
      if(err.error.acknowledgement.status==="ERROR")
      {
        
        
       
      }
   });
  }

 
}