<h2 mat-dialog-title>Import Report</h2>
<mat-dialog-content>
    <div>{{reqStock}}</div>
    <div style="color: green;">{{updatedStock}}</div> 
    <div *ngIf="failedStock" style="color: red;">{{failedStock}}</div>
    <p class="pt-1" *ngIf="negStock || noStock || mandMsg"><strong>Failure details:</strong></p> 
 <div *ngIf="negStock || noStock || mandMsg" class="pl-2">  
    <div *ngIf="negStock">{{negStock}}</div>
    <div *ngIf="noStock">{{noStock}}</div>
    <div *ngIf="failDetails">{{failDetails}}</div> 
    <div *ngIf="mandMsg">{{mandMsg}}</div>
    <div *ngIf="mandMsg">[Please keep atleast one of these Ids]</div>
    <div *ngIf="invalidMsg">{{invalidMsg}}</div> 
    <div *ngIf="msg">{{msg}}</div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close color="primary">Dismiss</button>
</mat-dialog-actions>
