import { Component, OnInit,Inject } from '@angular/core';
import { FormGroup,FormBuilder ,Validators} from '@angular/forms';
import {BuyersService} from './../buyers.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-buyer-settings',
  templateUrl: './buyer-settings.component.html',
  styleUrls: ['./buyer-settings.component.css']
})
export class BuyerSettingsComponent implements OnInit {

  buyerSettings:FormGroup;
  buyerGroups:any;
  element:any;
  timeZoneList:any;
  countryList:any;
  selected="";
  constructor(public dialogRef: MatDialogRef<BuyerSettingsComponent>,public fb: FormBuilder,
    private BuyersService:BuyersService, @Inject(MAT_DIALOG_DATA) data) { 
      this.element = data; 
      this.selected =this.element.group.groupName;
      console.log(this.element);
    }

  ngOnInit(): void {
    console.log (this.element);
    this.BuyersService.timeZoneList().subscribe(
      res => {
        this.timeZoneList = res.timeZone;
      }
    );
    this.BuyersService.tenantCountryList().subscribe(
      res => {
        this.countryList = res.country;
      }
    );
    this.BuyersService.getListOfBuyergroups().subscribe(
      res => {
        this.buyerGroups = res.groups;
      }
    );
    this.buyerSettings = this.fb.group({
      name: [''|| '', Validators.required],
      phone: ['' || '',Validators.compose([ Validators.required,
        Validators.pattern("[0-9]{10}")])],
        email: ['' ||'', {
          validators: [
              
              Validators.email
          ],
          updateOn: 'blur'
       }],
       addressLine1: [this.element.address.adline1 || '', Validators.required],
       
       //registeredTime:[this.element.regTime||''] ,  
      taxNumber: ['' || ''],
      city: ['' || '' ],
      state: [''|| ''],
      pin: ['' || ''],
      latitude: ['' || ''],
      longitude: ['' || ''],
      groupId:['' ||'',Validators.required],
      countryId:['' ||'', Validators.required],
      timezoneId:[ ''|| '',Validators.required]
    
    });

    this.buyerSettings.setValue(
      {
 
        name: this.element.name,
        phone: this.element.phone,
        email: this.element.email,
        addressLine1: this.element.address.adline1,
        taxNumber: this.element.taxNumber,
        city: this.element.address.city,
        state: this.element.address.state,
        pin: this.element.address.zip,
        latitude: this.element.address.latitude,
        longitude: this.element.address.longitude,
        groupId:this.element.group.id,
        countryId:this.element.countryId,
        timezoneId:this.element.timeZoneId
     });


  }


save ()
{
 console.log(this.buyerSettings.value);
 this.dialogRef.close(this.buyerSettings.value); 
}

close() {
  
  this.dialogRef.close();  
}
}

