

import { Component,AfterViewInit,Inject, NgZone, PLATFORM_ID,OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import {MyVersion} from 'src/my-version';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit ,OnInit{
  title = 'Dashboard';
  curDate;
  ver:number;
  constructor( @Inject(PLATFORM_ID) private platformId: any,
  private router: Router,
  private zone: NgZone,
  private scroller: ViewportScroller,){

  }
  ngOnInit(): void {
  this.router.events.subscribe((event) => {
    if ((event instanceof NavigationEnd)) {
        this.scroller.scrollToPosition([0, 0]);
    }
});
this.curDate = new Date().getFullYear().toString().substr(-2) + '.' +(new Date().getMonth() + 1).toString();
  this.ver=MyVersion.number;
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      
     
        this.zone.runOutsideAngular(() => {
         
          var id=setInterval(() => {
            
            const preloader = document.querySelector('.site-preloader');
            if(preloader) {  
              
              clearInterval(id);
              preloader.addEventListener('transitionend', (event: TransitionEvent) => {
                  if (event.propertyName === 'opacity') {
                      preloader.remove();
                  }
              });
              preloader.classList.add('site-preloader__fade');
            }            
            
          }, 300);

        });
    }
}
  /* showLoadingIndicator = true;

  // Inject the Angular Router
  constructor(private _router: Router) {
  this._router.events.subscribe((routerEvent: Event) => {

    // On NavigationStart, set showLoadingIndicator to ture
    if (routerEvent instanceof NavigationStart) {
      this.showLoadingIndicator = true;
      console.log("here");
    }

    // On NavigationEnd or NavigationError or NavigationCancel
    // set showLoadingIndicator to false
    if (routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationError ||
      routerEvent instanceof NavigationCancel) {
        console.log("there");
      this.showLoadingIndicator = false;
    }

  }); */
}

