
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import {ControlPanelService} from '../control-panel/control-panel.service'

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  warehouseSettings:boolean=true;
  generalSettings:boolean=false;
  showNotification:boolean=false;
  showOfs:boolean=false;
  showProfile:boolean=false;
  showImages:boolean=false;
  showFooter:boolean=false;
  showErpSettings:boolean=false;
  showWebhook:boolean=false;
  selected;
  warehouseImgUrl:string="../assets/icon/warehouse.png";
  generalImgUrl:string="../assets/icon/general.png";
  notificationImgUrl:string="../assets/icon/notification.png";
  ofsImgUrl:string="../assets/icon/ofs.png";
  profileImgUrl:string="../assets/icon/profile.png";
  imagesImgUrl:string="../assets/icon/image_upload.png";
  footerImgUrl:string="../assets/icon/footer.png";
  erpImgUrl:string="../assets/icon/erp.png"
  webhookImgUrl:string="../assets/icon/webhook.png"
  enableErp:boolean = false;
  enableWebhook:boolean=false; 
  generalSettingsDetails:any;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );



  

  constructor(private breakpointObserver: BreakpointObserver,
    private controlPanelService: ControlPanelService,
    ) { }

  ngOnInit(): void {
    this.controlPanelService.checkBoxSubject$.subscribe(res => {
      this.enableErp=res;
    });
    this.controlPanelService.webHookSubject$.subscribe(res => {
      this.enableWebhook=res;
    })
  
    this.controlPanelService.getgeneralSettings().subscribe(
      res => {

        this.generalSettingsDetails = res.generalSettings;
        if(this.generalSettingsDetails)
        {
        if(this.generalSettingsDetails.enableErp === true) 
        this.enableErp=true;
        if(this.generalSettingsDetails.enableWebhook === true) 
        this.enableWebhook=true;
        }
      }
      );
  }
  
  

  openWarehouseSettings(){
    this.warehouseSettings=true;
    this.generalSettings=false;
    this.showNotification=false;
    this.showOfs=false;
    this.showProfile=false;
    this.showImages=false;
    this.showFooter=false;
    this.showErpSettings=false;
    this.showWebhook=false;
  }

  toggleColor(n) {
    this.selected=n;
  }
  

  openGeneralSettings(){
    this.warehouseSettings=false;
    this.generalSettings=true;
    this.showNotification=false;
    this.showOfs=false;
    this.showProfile=false;
    this.showImages=false;
    this.showFooter=false;
    this.showErpSettings=false;
    this.showWebhook=false;
  }

  openNotificationSettings(){

    this.showNotification=true;
    this.warehouseSettings=false;
    this.generalSettings=false;
    this.showOfs=false;
    this.showProfile=false;
    this.showImages=false;
    this.showFooter=false;
    this.showErpSettings=false;
    this.showWebhook=false;
  }

  openOfsSettings(){

    this.showNotification=false;
    this.warehouseSettings=false;
    this.generalSettings=false;
    this.showOfs=true;
    this.showProfile=false;
    this.showImages=false;
    this.showFooter=false;
    this.showErpSettings=false;
    this.showWebhook=false;
  }

  openProfileSettings(){

    this.showNotification=false;
    this.warehouseSettings=false;
    this.generalSettings=false;
    this.showOfs=false;
    this.showProfile=true;
    this.showImages=false;
    this.showFooter=false;
    this.showErpSettings=false;
    this.showWebhook=false;
  }
  openImageUploadSettings(){

    this.showNotification=false;
    this.warehouseSettings=false;
    this.generalSettings=false;
    this.showOfs=false;
    this.showProfile=false;;
    this.showImages=true;
    this.showFooter=false;
    this.showErpSettings=false;
    this.showWebhook=false;
    
  }
  openFooterSettings(){

    this.showNotification=false;
    this.warehouseSettings=false;
    this.generalSettings=false;
    this.showOfs=false;
    this.showProfile=false;
    this.showImages=false;
    this.showFooter=true;
    this.showErpSettings=false;
    this.showWebhook=false;
   
  }
  openErpSettings(){

    this.showNotification=false;
    this.warehouseSettings=false;
    this.generalSettings=false;
    this.showOfs=false;
    this.showProfile=false;
    this.showImages=false;
    this.showFooter=false;
    this.showErpSettings=true;
    this.showWebhook=false;
   
  }

  openWebhookSettings(){

    this.showNotification=false;
    this.warehouseSettings=false;
    this.generalSettings=false;
    this.showOfs=false;
    this.showProfile=false;
    this.showImages=false;
    this.showFooter=false;
    this.showErpSettings=false;
    this.showWebhook=true;


  }


}
