import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ControlPanelService } from '../../control-panel/control-panel.service';
import { WarehouseSettings } from '../../control-panel/models/WarehouseSettings.model'
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HostListener } from "@angular/core";
import { TourService } from 'ngx-tour-ngx-popper';
import { HttpErrorResponse } from '@angular/common/http';
import { GeneralSettings,FooterSettings } from '../../control-panel/models/GeneralSettings.model';

@Component({
  selector: 'app-footersettings',
  templateUrl: './footersettings.component.html',
  styleUrls: ['./footersettings.component.css']
})
export class FootersettingsComponent implements OnInit {

  public footerSettingsForm : FormGroup;
  hideSubmitStep = false;
  hidePrevStep = false;
  successMessage:string="";
  errorMessage:string="";
  warehouseSettingsDetails: any;
  shopId:any;
  tempPrevTourStep;
  continue=false;
  constructor(private fb: FormBuilder,
    private controlPanelService: ControlPanelService,
    public activateRoute: ActivatedRoute,
    private router: Router,
    public fTourService: TourService,
    private toastr: ToastrService) { }

  ngOnInit(): void {


    this.footerSettingsForm = this.fb.group({
      aboutUs: ["" || ""],
      facebookUrl: ["" || ""],
      twitterUrl :  ["" || ""],
      linkedinUrl:  ["" || ""],
      instagramUrl :  ["" || ""],
      youtubeUrl: ["" || ""],

    });

    this.controlPanelService.getwarehouseSettings().subscribe(
      res => {
        this.warehouseSettingsDetails = res.warehouseSettings;
        if(this.warehouseSettingsDetails === null)
        {
        this.toastr.error("Please Update the WareHouse Settings first to Continue","ERROR")
          this.continue=true;
        }
        else
        {
        this.shopId = this.warehouseSettingsDetails.shopId
        
        this.controlPanelService.getFooterDetails(this.shopId).subscribe(
          resu => {
           if(resu)
           {
           this.footerSettingsForm.controls['aboutUs'].setValue(resu.aboutUs);
           this.footerSettingsForm.controls['facebookUrl'].setValue(resu.facebookUrl);
           this.footerSettingsForm.controls['instagramUrl'].setValue(resu.instagramUrl);
           this.footerSettingsForm.controls['linkedinUrl'].setValue(resu.linkedinUrl);
           this.footerSettingsForm.controls['twitterUrl'].setValue(resu.twitterUrl);

           this.footerSettingsForm.controls['youtubeUrl'].setValue(resu.youtubeUrl);
           }
           else{
            this.footerSettingsForm.controls['aboutUs'].setValue("");
            this.footerSettingsForm.controls['facebookUrl'].setValue("");
            this.footerSettingsForm.controls['instagramUrl'].setValue("");
            this.footerSettingsForm.controls['linkedinUrl'].setValue("");
            this.footerSettingsForm.controls['twitterUrl'].setValue("");
 
            this.footerSettingsForm.controls['youtubeUrl'].setValue("");
           }
          }
        
        );
      }

      });

      this.fTourService.initialize([
        {  
          stepId : 'cptour.fstep1.id',
          anchorId: 'cptour.fstep1.id',
          content: 'Please write asmall description about your warehouse for the consumers to view' ,
          title: 'About-Us',
          endBtnTitle:'Skip',
          placement:"right", 
         
        },       
         {    
        stepId : 'cptour.fstep2.id',
        anchorId: 'cptour.fstep2.id',
        content: 'Please input the link to your Facebook Page' ,
        title: 'Facebook',
        endBtnTitle:'Skip',
        placement:"right", 
        
        },
        {    
          stepId : 'cptour.fstep3.id',
          anchorId: 'cptour.fstep3.id',
          content: 'Please input the link to your Linkedn Page' ,
          title: 'Linkedn',
          endBtnTitle:'Skip',
          placement:"right", 
          
          },
          {    
            stepId : 'cptour.fstep4.id',
            anchorId: 'cptour.fstep4.id',
            content: 'Please input the link to your Instagram Page' ,
            title: 'Instagram',
            endBtnTitle:'Skip',
            placement:"right", 
            
            },
            {    
              stepId : 'cptour.fstep5.id',
              anchorId: 'cptour.fstep5.id',
              content: 'Please input the link to your Twitter Page' ,
              title: 'Twitter',
              endBtnTitle:'Skip',
              placement:"right", 
              
              },
              {    
                stepId : 'cptour.fstep6.id',
                anchorId: 'cptour.fstep6.id',
                content: 'Please input the link to your YouTube Page' ,
                title: 'YouTube',
                endBtnTitle:'Skip',
                placement:"right", 
                
                },
        
              {
                anchorId: 'cptour.fstep7.id',
                content: 'Please press this button to Save the above information.',
                title: 'Save and Submit',
                placement:'right',
                
              },
         ],
        {route: 'settings',
        });
    }
  
        @HostListener('click',['$event'])mouseover(event) {  
          if (this.fTourService.currentStep) {
            const nextStep = $(event.target).closest(".ws-guided-tour").attr("tourAnchor")
            if (nextStep && this.tempPrevTourStep !== nextStep) {
    
              this.fTourService.startAt(nextStep);
              this.tempPrevTourStep = nextStep;
            }      
          }
        };
        @HostListener('document:keydown.tab', ['$event'])
        onKeydownHandler(event: KeyboardEvent) {
         if (this.fTourService.currentStep) {
               this.fTourService.next();
         
           }    
         }
     
  


  public footerTour(): void {
    this.fTourService.disableHotkeys();
    this.fTourService.start(); 
  }

  submitFooter(){
  
    this.footerSettingsForm.markAllAsTouched();
    
    if (this.footerSettingsForm.valid) {
      var footer: FooterSettings = this.footerSettingsForm.value
      footer['shopId'] = this.warehouseSettingsDetails.shopId;
     
      this.controlPanelService.saveFooterSettings(footer).subscribe(
        (res: any) => {
          if (res.acknowledgement.status === "SUCCESS") {
            this.successMessage = res.acknowledgement.message
            this.toastr.success( this.successMessage,"SUCCESS")
          }
          else{
            this.successMessage=res.acknowledgement.message
            this.toastr.error(this.successMessage,"ERROR")
          }
          
        },
       
        (err:HttpErrorResponse) => {
          
          if(err)
          {
             this.errorMessage=err.error.acknowledgement.message
            this.toastr.error(this.errorMessage,"ERROR")
          }
          else{
            this.toastr.error("Sorry,the information was not updated,Please try again later","ERROR")
          }
       }
      );
    }
  
  
  
    }
}
