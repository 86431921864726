import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { SubscriptionDialogComponent } from './subscription-dialog/subscription-dialog.component';
import {MyVersion} from 'src/my-version';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {

  curDate;
  ver:number;

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  this.curDate = new Date().getFullYear().toString().substr(-2) + '.' +(new Date().getMonth() + 1).toString();
  this.ver=MyVersion.number;
  }


  openDialog(data) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height ='300px',
    dialogConfig.width = '600px',
    dialogConfig.data = data ,
    this.dialog.open(SubscriptionDialogComponent, dialogConfig);
}
}
