import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProductuploadService } from './productupload.service';
import { ConfirmDialogComponent } from '../my-navbar/confirm-dialog/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ThemePalette } from '@angular/material/core';



@Component({
  selector: 'app-product-upload',
  templateUrl: './product-upload.component.html',
  styleUrls: ['./product-upload.component.css']
})
export class ProductUploadComponent implements OnInit {

  productQueue: any[] = [];
  originalData: any[] = [];
  buyerForm: FormGroup;
  continue = false;
  productNumber: number = 0;
  maxProductLimit: number;
  isUploading: boolean = false;
  uploadFileVar = false;
  @ViewChild('myInput') myInputVariable: ElementRef;
  @ViewChild('myInput') myInput: ElementRef<HTMLInputElement>;
  errorMessage: string = "";
  formValid: boolean = false;
  uploadingImage: boolean = false;
  imageError: string = "";
  uomList: any;
  imageSizeError: boolean;
  fileName: string;
  file: any;
  bannerSize: any;
  imageUploaded: boolean = false;
  downloadingExcel: boolean = false;
  tinyImageUrl: string = "";
  mediumImageUrl: string = "";
  xlImageUrl: string = "";
  editingIndex: number | null = null;
  searchTerm: string = '';
  productsToDelete: any[] = [];

  sortingColumn: string = ''; // Track the currently sorted column
  sortAscending: boolean = false; // Track the sorting order
  color: ThemePalette = 'primary';




  constructor(
    private toastr: ToastrService,
    public fb: FormBuilder,
    private router: Router,
    private ProdUploadService: ProductuploadService,
    public dialog: MatDialog,


  ) { }

  ngOnInit(): void {

    setTimeout(() => {
      this.clearCachedProductList();
    }, 24 * 60 * 60 * 1000);

    this.buyerForm = this.fb.group({

      productName: ['', [Validators.required, Validators.maxLength(128)]],
      categoryName: ['', Validators.required],
      subcategoryName: ['', Validators.required],
      productCode: ['', Validators.required],
      unitOfMeasure: ['' || '', Validators.required],
      price: ['', Validators.required],
      tax: ['', Validators.required],
      taxIncluded: [false],
      image: [null,],
      tinyImageUrl: '',
      mediumImageUrl: '',
      xlImageUrl: ''
    });

    const cachedProductList = localStorage.getItem('productList');
    if (cachedProductList) {
      this.productQueue = JSON.parse(cachedProductList);
      this.productNumber = this.productQueue.length;
      this.originalData = this.getFromCache();
    }


    this.ProdUploadService.uomList().subscribe(
      res => {
        console.log('response', res);
        this.maxProductLimit = res.productLimit;
        this.uomList = res.uom;
        console.log('maxProductLimit -', this.maxProductLimit);
      }
    );

  }

  submitProductForm(): void {
    if (!this.buyerForm.valid) {
      console.log("Not Valid Form")
      this.toastr.error("Please check if you have entered all the required  * fields", "Error")
    } else if (this.buyerForm.get('image').value !== null && !this.imageUploaded) {
      this.toastr.warning("Please click the 'Upload Image' button to upload the selected image", "Warning");
    } else {

      console.log('image is available in the form or not', this.buyerForm.get('image').value)
      const taxIncludedValue = this.buyerForm.get('taxIncluded').value;
      const taxIncluded = taxIncludedValue !== null ? taxIncludedValue : false;
      const productData = this.buyerForm.value;
      if (this.imageUploaded) {
        // Update image URLs with new values
        productData.tinyImageUrl = this.tinyImageUrl;
        productData.mediumImageUrl = this.mediumImageUrl;
        productData.xlImageUrl = this.xlImageUrl;
      } else if (this.editingIndex !== null) {
        // Retain existing image URLs for edited product
        const selectedProduct = this.productQueue[this.editingIndex];
        productData.tinyImageUrl = selectedProduct.tinyImageUrl;
        productData.mediumImageUrl = selectedProduct.mediumImageUrl;
        productData.xlImageUrl = selectedProduct.xlImageUrl;
      }
      productData.taxIncluded = taxIncluded; // Set taxIncluded field

      if (this.editingIndex !== null) {

        this.productQueue[this.editingIndex] = productData;
        this.toastr.success("Product updated Successfully", "SUCCESS");
        this.editingIndex = null;
      } else {
        this.productNumber = this.productQueue.length + 1;
        this.productQueue.push(productData); // Add product to queue
        this.toastr.success("Product added Successfully", "SUCCESS");
      }
      console.log('prod que - ', this.productQueue);
      this.resetForm(); // Reset the form
      this.updateCachedProductList();

    }
  }
  resetForm() {
    this.tinyImageUrl = null;
    this.mediumImageUrl = null;
    this.xlImageUrl = null;
    this.file = null;
    this.imageUploaded = false;
    this.buyerForm.reset(); // Reset the form
    this.resetFileInput(); // Reset the file input
  }

  resetFileInput() {
    this.myInputVariable.nativeElement.value = ''; // Clear the value of the file input
    this.buyerForm.patchValue({
      image: null // Reset the value of the image control in the form group
    });
  }
  updateCachedProductList(): void {
    localStorage.setItem('productList', JSON.stringify(this.productQueue));
  }
  close() {
    this.router.navigate(['dashboard'])
  }


  onFileChanged(event) {
    if (event.target.files && event.target.files[0]) {
      this.imageSizeError = false;
      this.fileName = "";
      this.imageError = "";
      this.file = event.target.files[0];
      this.imageSizeError = false;
      this.fileName = event.target.files[0].name;
      this.bannerSize = event.target.files[0].size;
      // Size Filter Bytes
      const max_size = 10971520;
      const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
      const min_height = 1000;
      const min_width = 1000;
      console.log('File Type', event.target.files[0].type);
      if (allowed_types.includes(event.target.files[0].type)) {
        if (event.target.files[0].size > max_size) {
          this.imageError =
            'Maximum size allowed is ' + max_size / 1000000 + 'Mb';
          return false;
        }
        this.file = event.target.files[0];
        var reader = new FileReader();
        reader.onload = (event: any) => {
          const img = new Image();
          img.src = reader.result as string;
          img.onload = () => {
            const height = img.naturalHeight;
            const width = img.naturalWidth;
            console.log('Width and Height', width, height);
            if (width < min_width || height < min_height) {
              this.imageError = `Size must greater than  ${min_width} px * ${min_height} px`
              this.imageSizeError = true;
              event.target.value = null;
              return false;
            }
          };
          this.buyerForm.patchValue({
            fileSource: this.file
          });
        }
        reader.readAsDataURL(event.target.files[0]);
      }
      else {
        this.imageError = 'File type not supported'
        return false;
      }
    }
  }

  generateAndDownloadExcel() {
    console.log('payload', this.productQueue);
    if (this.productQueue.length > 0) {
      this.downloadingExcel = true;
      this.ProdUploadService.generateExcel(this.productQueue).subscribe(
        (response: Blob) => {
          this.downloadExcelFile(response);
          this.toastr.success('Excel downloaded successfully!', 'Success');
          this.clearCachedProductList();
          this.downloadingExcel = false;
        },
        error => {
          console.error('Error generating Excel:', error);
          this.toastr.error('Error generating Excel!', 'Error');
          this.downloadingExcel = false;
        }
      );
    } else {
      this.toastr.error('Product Queue is empty!', 'Error');
    }
  }
  clearCachedProductList(): void {
    localStorage.removeItem('productList');
    this.productNumber = 0;
    this.productQueue = [];
    this.originalData = [];
  }

  private downloadExcelFile(response: Blob) {
    const url = window.URL.createObjectURL(response);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    const currentDateTime = new Date();
    const year = currentDateTime.getFullYear();
    const month = (currentDateTime.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDateTime.getDate().toString().padStart(2, '0');
    const hours = currentDateTime.getHours().toString().padStart(2, '0');
    const minutes = currentDateTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentDateTime.getSeconds().toString().padStart(2, '0');

    const fileName = `product_excel_${year}_${month}_${day}_${hours}${minutes}${seconds}.xlsx`;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  uploadImg() {
    if (this.file) {
      if (!this.buyerForm.get('productName').value) {
        this.toastr.error('Please enter the product name.', 'Error');
        return;
      }
      this.uploadingImage = true;
      const productName = this.buyerForm.get('productName').value;
      console.log('product name for the image upload - ', productName)
      const formData = new FormData();
      formData.set('image', this.file);
      formData.set('productName', productName);
      this.ProdUploadService.uploadImage(formData).subscribe(
        (response: any) => {
          // Set the image URLs from the response JSON to the productData object
          this.toastr.success("Image Added Successfully", "SUCCESS")
          this.tinyImageUrl = response.rootPaths.tinyImageUrl;
          this.mediumImageUrl = response.rootPaths.mediumImageUrl;
          this.xlImageUrl = response.rootPaths.xlImageUrl;
          this.imageUploaded = true;
          this.uploadingImage = false;
        },
        (error: HttpErrorResponse) => {
          console.error('Error uploading image:', error);
          this.toastr.error('Failed to upload image. Please try again.', 'Error');
          this.uploadingImage = false;
          this.imageUploaded = false;
          this.clearFileInput();
        }
      );
    } else {
      this.toastr.error("Sorry, please check if you selected an image", "ERROR")
    }
  }
  clearFileInput() {
    if (this.myInput && this.myInput.nativeElement) {
      this.buyerForm.get('image').setValue(null);
      this.myInput.nativeElement.value = null; // Reset the value to an empty string
    }
  }

  confirmDelete(index: number): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "300px",
      data: {
        title: "Remove this Item?"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteObject(index);
      }
    });
  }

  deleteObject(index: number): void {
    this.productQueue.splice(index, 1); // Remove the item at the specified index
    this.toastr.success("Product Removed Successfully", "SUCCESS")
    if (index === this.editingIndex) {
      this.resetForm();
  }
    this.updateCachedProductList();
    this.searchTerm = '';
    this.productNumber = this.productQueue.length;
    this.productQueue = this.getFromCache();
    this.originalData = this.productQueue
  }

  editProduct(index: number): void {
    this.editingIndex = index;
    const selectedProduct = this.productQueue[index];
    this.buyerForm.patchValue({
      productName: selectedProduct.productName,
      categoryName: selectedProduct.categoryName,
      subcategoryName: selectedProduct.subcategoryName,
      productCode: selectedProduct.productCode,
      unitOfMeasure: selectedProduct.unitOfMeasure,
      price: selectedProduct.price,
      tax: selectedProduct.tax,
      taxIncluded: selectedProduct.taxIncluded,
      tinyImageUrl: selectedProduct.tinyImageUrl,
      mediumImageUrl: selectedProduct.mediumImageUrl,
      xlImageUrl: selectedProduct.xlImageUrl
    });
   

  }
  searchData() {
    if (this.searchTerm.trim() === '') {
      // If search term is empty, show all data
      this.productQueue = this.getFromCache();
      return;
    }

    this.originalData = this.getFromCache();

    // Filter productQueue based on search term
    this.productQueue = this.originalData.filter(product =>
      product.productName.toLowerCase().includes(this.searchTerm.trim().toLowerCase()) ||
      product.categoryName.toLowerCase().includes(this.searchTerm.trim().toLowerCase()) ||
      product.subcategoryName.toLowerCase().includes(this.searchTerm.trim().toLowerCase()) ||
      product.productCode.toLowerCase().includes(this.searchTerm.trim().toLowerCase())

    );
  }

  clearSearch() {
    this.searchTerm = '';
    this.productQueue = this.getFromCache();
  }

  getFromCache(): any[] {
    const cachedProductList = localStorage.getItem('productList');
    if (cachedProductList) {
      return JSON.parse(cachedProductList);
    } else {
      return [];
    }
  }

  refreshData(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "300px",
      data: {
        title: "Clear all the data ?"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.clearCachedProductList();
        this.searchTerm = '';

      } else {
        setTimeout(() => {
          const activeElement = document.activeElement as HTMLButtonElement;
          activeElement.blur();
        });
      }
    });
  }

  toggleSelectProduct(checked: boolean, product: any): void {
    // Update the selected status of the specific product
    product.selected = checked;
    this.productsToDelete = this.productQueue.filter(product => product.selected);

    console.log('selected prd count - ', this.productsToDelete)
  }

  deleteSelected() {
    if (this.productsToDelete.length > 0) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "300px",
        data: {
          title: "Delete the selected data ?"
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.productsToDelete.forEach(product => {
            const index = this.productQueue.indexOf(product);
            if (index !== -1) {
              this.productQueue.splice(index, 1);
            }
          });
          this.originalData = this.productQueue;
          this.productNumber = this.productQueue.length;
          this.updateCachedProductList();
          this.searchTerm = '';
          this.toastr.success("Products Deleted Successfully", "SUCCESS")
        }
      });
    }
  }
  hasSelectedProducts(): boolean {
    return this.productQueue.some(product => product.selected);
  }
  selectAll(checked: boolean): void {
    this.productQueue.forEach(product => product.selected = checked);
    this.productsToDelete = this.productQueue.filter(product => product.selected);
    console.log('selected prd count - ', this.productsToDelete)
  }
  sortData(sort: Sort) {
    const data = this.getFromCache().slice();
    if (!sort.active || sort.direction === '') {
      this.productQueue = data;
      return;
    }

    this.productQueue = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'productName':
          return this.compare(a.productName, b.productName, isAsc);
        case 'category':
          return this.compare(a.category, b.category, isAsc);
        case 'subCategory':
          return this.compare(a.subCategory, b.subCategory, isAsc);
        case 'productCode':
          return this.compare(a.productCode, b.productCode, isAsc);
        case 'tax':
          return this.compare(a.tax, b.tax, isAsc);
        case 'price':
          return this.compare(a.price, b.price, isAsc);
        case 'taxIncluded':
          return this.compare(a.taxIncluded, b.taxIncluded, isAsc);
        default:
          return 0;
      }
    });
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

}
