

<body class="authentication-bg pb-0" >

    <div class="auth-fluid">
        <!--Auth fluid left content -->
        <div class="auth-fluid-right"  style="background-color:whitesmoke; padding: 0 !important;">
          <span ><img src="../assets/icon/graphics2.png"></span> 
            
        </div>
        <div class="auth-fluid-form-box">
            <div class="align-items-center d-flex h-100">
                <div class="card-body">

                    <!-- Logo -->
                    <div class="auth-brand text-center text-lg-left">
                        <a href="" class="logo-dark">
                            <span><img src="../assets/icon/sm-logo_new.png" alt="" height="70"></span>
                        </a>
                        <!-- <h4><p class="mt-2">Let's get your account set up</p></h4> -->
                    </div>
                    
                    <!-- title-->
                     <h4 class="mt-0 mb-3">Register</h4>
                    <!-- <p class="text-muted mb-4">Enter your email address and password to access account.</p> --> 
                   
                    
                    
                    
                          <form [formGroup]="regform" >
                            <!-- <h2>Log In</h2> -->
                            
                                <mat-form-field appearance="outline">
                                    <mat-label>Business Name</mat-label>
                                    <input matInput placeholder="Enter Business Name"
                                    formControlName="businessname"
                                    required>
                                    <mat-icon matSuffix>store_mall_directory</mat-icon>
                                    <!-- <mat-hint>Hint</mat-hint> -->
                                    <mat-error>
                                      Please provide your Business Name
                                    </mat-error>
                                  </mat-form-field>

                                  <mat-form-field appearance="outline">
                                    <mat-label>Email (Username)</mat-label>
                                    <input matInput placeholder="Enter Email"
                                    formControlName="username"
                                    required>
                                    <mat-icon matSuffix>email</mat-icon>
                                    <!-- <mat-hint>Hint</mat-hint> -->
                                    <mat-error>
                                      Please provide a valid Email address
                                    </mat-error>
                                  </mat-form-field>
                                  <div class="row">
                                  <div class="form-group col-md-4">
                                    <mat-form-field appearance="outline">
                                    <mat-label>Code</mat-label> 
                                    <mat-select  formControlName="country" >
                                        <mat-option *ngFor="let item of countryList" [value]="item.countryId">{{item.countryCode}}</mat-option>
                                    </mat-select> 
                                  </mat-form-field> 
                                  </div>
                                  <div class="col-md-8">
                                  <mat-form-field appearance="outline">
                                    <mat-label>Mobile Number</mat-label>
                                    <input type="text" matInput  placeholder="Enter Mobile Number"
                                    formControlName="phone">
                                    <mat-icon matSuffix>phone_android</mat-icon>
                                    <mat-error *ngIf="regform.controls['phone'].hasError && regform.controls['phone'].touched">
                                      Enter Valid phone number</mat-error>
                                    </mat-form-field>
                                  </div>
                                  </div>
                                <!-- <mat-form-field>
                                  <mat-label>Select Medium</mat-label>
                                  <mat-select matInput name="adMedium" formControlName="country">
                                      <mat-option *ngFor="let row of countryList" [value]="row">{{row.countryId}}</mat-option>
                                  </mat-select>
                              </mat-form-field> -->
                                 
                                    <ngx-recaptcha2 #captchaElem
                                      [siteKey]="siteKey"
                                      (success)="handleSuccess($event)"
                                      formControlName="recaptcha">
                                    </ngx-recaptcha2>
                                    <div class="alert alert-danger" role="alert" *ngIf="isRobort">
                                      <strong> Verify ! </strong>  That you are not a robot
                                  </div>
                             
                            <p class="mt-2">By signing up you agree to Storimart's <br> <a href="http://demo.storimart.com/privacy/" target="_blank">Terms of Service</a> </p>
                            <button mat-raised-button color="primary" [class.spinner]="loading" [disabled]="regform.invalid" class="mt-2" (click)="Submit()">Sign me up!</button>
                            


                          </form>
                    <!-- <a href=""><p class="mt-4">Forgot Password ?</p></a>  -->
                    <!-- <p class="text-muted">Don't have an account? <a href="pages-register-2.html" class="text-muted ml-1"><b>Sign Up</b></a></p> -->
               <!--  <div class="card border-success border mt-2" *ngIf="isSubmittedSuccess">
                  <div class="card-body">
                   <h5><div class="card-title text-success">Success !</div></h5>
                   <div class="card-text">
                  {{successMessage}}
                    </div>
                  </div>
                </div> -->
                

                    <!-- Footer-->
                    <p class="text-muted mt-2">Already have an account? <a [routerLink]="['/login']" class="text-muted ml-1"><b>Login</b></a></p>

                    <footer class="footer footer-alt">
                     
                                                            
                          
                        
                            
                      <a [href]="youtubeUrl" target="_blank">
                            <img src="assets/icon/youtubeIcon.png" alt="" matTooltip="Youtube"
                             matTooltipPosition="above">
                        </a>
                       
                        <a [href]="facebookUrl" target="_blank">
                            <img src="assets/icon/facebookIcon.png" alt="" matTooltip="Facebook" matTooltipPosition="above">
                        </a>
                       
                        <a [href]="linkedinUrl" target="_blank">
                            <img src="assets/icon/linkedinIcon.png" alt="" matTooltip="LinkedIn" matTooltipPosition="above">
                        </a>
                        
                      
                    
              
                       
    
                </footer>

                </div> <!-- end .card-body -->
            </div> <!-- end .align-items-center.d-flex.h-100-->
        </div>
        <!-- end auth-fluid-form-box-->

        <!-- Auth fluid right content -->
       
        <!-- end Auth fluid right content -->
    </div>
    <!-- end auth-fluid-->

    <!-- bundle -->
  
</body>

