<mat-toolbar>
    <span>App Users</span>
  </mat-toolbar>
  
  <div class="example-container mat-elevation-z8">
   
    <div *ngIf="!isAppUser">
   
        <h2>No App users yet!</h2>
        <button class="mat-raised-button mat-primary" (click)="close()">Close</button>
    </div>

    <div *ngIf="isAppUser">

    <mat-card>

        <div class="example-header">
          <mat-form-field>
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
          </mat-form-field>
        </div>
   
       
  <mat-card-content>
        
            <table mat-table [dataSource]="dataSourceAppUsers" matSort>
               
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef> ID </th>
                    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                  </ng-container>

                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                  </ng-container>
          
                  <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef> Phone </th>
                    <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
                  </ng-container>
        
    
                  <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef> Email </th>
                    <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                  </ng-container>

                  <ng-container matColumnDef="pin">
                    <th mat-header-cell *matHeaderCellDef> Pin </th>
                    <td mat-cell *matCellDef="let element"> {{element.pin}} </td>
                  </ng-container>

          
                  <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> Actions</th>
                    <td mat-cell *matCellDef="let element">
              
             
              <button mat-button color="primary" (click)="openAppUserSettings(element)">Update details</button>
            </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
       
    
        <mat-paginator [length]="dataLengthAppUsers"
        [pageSize]="10" showFirstLastButtons></mat-paginator>

      </mat-card-content>


        <mat-dialog-actions>
            <mat-dialog-actions class="align-items-end">
                <button class="mat-raised-button" (click)="close()">Close</button>
                &nbsp;&nbsp;
                <button class="mat-raised-button mat-primary" (click)="save()">Save</button>
            </mat-dialog-actions>
        </mat-dialog-actions>
     
    </mat-card>
</div>
    
  </div>
