import { Component, OnInit,Inject } from '@angular/core';
import { FormGroup,FormBuilder,Validators } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {BuyersService} from './../buyers.service'
import { ToastrService } from 'ngx-toastr';
import {MatRadioModule} from '@angular/material/radio';

@Component({
  selector: 'app-buyergroup-form',
  templateUrl: './buyergroup-form.component.html',
  styleUrls: ['./buyergroup-form.component.css']
})
export class BuyergroupFormComponent implements OnInit {
 
  buyergroupForm: FormGroup;
  itemsList = [
    {name: 'Deviation of Base price in value',value :'value'},
    {name: 'Deviation of Base price in percentage',value:'percentage'},
    
  ];

 
  matHint :string[] =["Value will be added on base price to get the price of the product applicable to the buyer group,Use positive value to add and negative value for reduction"
  ,"Percentage of the base price will be added on base price to get the price of the product applicable to the buyer group , Use positive value to add and negative value for reduction",
"Do the offer price is applicable","MOQ of each product for the buyer group"];

  statusOfParameters:string[]=["A","A","A","A"];
  result:any;
  
    isVisible = -1; // if you want to show by default some input keep it 0 for first radio, 1 for second and so on. I have kept it -1 so no input is shown by default
  
  onItemChange(item, i) {
    
     this.isVisible = i;
  }
  
  constructor(
    private toastr:ToastrService,
    private buyersService:BuyersService,
    private dialogRef: MatDialogRef<BuyergroupFormComponent>,public fb: FormBuilder,
    ) {}

  ngOnInit(): void {

    this.buyersService.getParametersListArray().subscribe(
      (res:any) =>{
        console.log(res);
        this.result=res;
        
        
      });

    this.buyergroupForm = this.fb.group({
      groupName: ['' || '', Validators.required],
      groupDesc:[''||''],
      valueOrPercent:[''||''],
      deviation: ['' || ''],
      paramOffer:[''||''],
      paramMoq:[''||''],
    });
  }
  save(){
    if (!this.buyergroupForm.valid) {
      console.log("Not valid Form")
      this.toastr.error("Please check if you have entered all the required  * fields","Error")
    } 
    else{
    console.log(this.buyergroupForm.value);
    
    this.dialogRef.close(this.buyergroupForm.value); 
    } 
  }
  
  close() {
    this.dialogRef.close();  
}
}
