
<mat-sidenav-container class="sidenav-container ">

 
  <mat-sidenav #drawer class="sidenav blue-container" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
    
     <!--  <mat-toolbar > 
        <img src="../assets/icon/sm-logo_blue.png"  alt="" style="height:35px">
       
      </mat-toolbar>
      <mat-divider></mat-divider> -->
   
   <!--  <div class="app-sidebar__user">
      <img class="app-sidebar__user-avatar" src="../../assets/icon/warehouse_logo.jpg" width="80" alt="User Image"> 
             <div>
              <p class="app-sidebar__user-name">Welcome</p> -->
              <!--  <h5 class="app-sidebar__user-designation">{{businessName}}</h5>
             </div>
    </div>  -->
    <div class="app-sidebar__user">
    <ngx-avatar class="app-sidebar__user-avatar" name={{businessName}} value="28%" bgColor="#000000" fgColor="#58D3F7" size="60"
  textSizeRatio="2.5" [round]="true"></ngx-avatar>
  <h5 class="app-sidebar__user-designation">{{businessName}}</h5>
  
</div>
    <mat-divider></mat-divider>


    <mat-nav-list>
      <span *ngFor="let item of menuArray">
      <span *ngIf="item.displayName === 'Catalog Manager'">
        <mat-list-item (click)="openOldAdmin()">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <img src={{item.iconName}} height="15" width="15" alt="">
            <span class="title" style="color: white;">{{item.displayName}}</span>
          </div>
        </mat-list-item>
      </span>
      <span *ngIf="item.displayName === 'Order Manager'">
        <mat-list-item (click)="openOfs()">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <img src={{item.iconName}} height="15" width="15" alt="">
            <span class="title" style="color: white;">{{item.displayName}}</span>
          </div>
        </mat-list-item>
      </span>
      <span *ngIf="item.displayName !== 'Catalog Manager' && item.displayName !== 'Order Manager' ">
        <mat-list-item [routerLink]="item.route" [routerLinkActive]="['is-active']">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <img src={{item.iconName}} height="15" width="15" alt="">
            <span class="title" style="color: white;">{{item.displayName}}</span>
          </div>
        </mat-list-item>
      </span>
    </span>
    </mat-nav-list>
    
    
      <!--   <mat-nav-list *ngIf="enableInventory===true">
          <span *ngFor="let item of menuArray">
            <span *ngIf="item.children && item.children.length > 0">
              <mat-accordion class="app-nav-accordion">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div fxLayout="row" fxLayoutAlign="space-between center" > 
                         <img src={{item.iconName}} height="15" width="15" alt=""> 
                        <span class="title" style="color: white;">{{item.displayName}}</span>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <span *ngFor="let child of item.children">
                    <mat-list-item [routerLink]="child.route" [routerLinkActive]="['is-active']" >
                      
                      <div fxLayout="row" fxLayoutAlign="space-between center" > 
                        <img src={{child.iconName}} height="15" width="15" alt="">
                        <span class="title" style="color: white;">{{child.displayName}}</span>
                      </div>
                    </mat-list-item>
                  </span>
                </mat-expansion-panel>
              </mat-accordion>
            </span>
            <span *ngIf="!item.children || item.children.length === 0">
              <mat-list-item [routerLink]="item.route" [routerLinkActive]="['is-active']">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <img src={{item.iconName}} height="15" width="15" alt="">
                  <span class="title" style="color: white;">{{item.displayName}}</span>
                </div>
              </mat-list-item>
            </span>
          </span>
        </mat-nav-list> -->

        
    
        <!-- <mat-nav-list *ngIf="!enableInventory || enableInventory !== true">
          <span *ngFor="let item of menuArrayWithoutStock">
            <span *ngIf="item.displayName === 'Catalog Manager'">
              <mat-list-item (click)="openOldAdmin()">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <img src={{item.iconName}} height="15" width="15" alt="">
                  <span class="title" style="color: white;">{{item.displayName}}</span>
                </div>
              </mat-list-item>
            </span>
            <span *ngIf="item.displayName === 'Order Manager'">
              <mat-list-item (click)="openOfs()">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <img src={{item.iconName}} height="15" width="15" alt="">
                  <span class="title" style="color: white;">{{item.displayName}}</span>
                </div>
              </mat-list-item>
            </span>
            <span *ngIf="item.displayName !== 'Catalog Manager' && item.displayName !== 'Order Manager' ">
              <mat-list-item [routerLink]="item.route" [routerLinkActive]="['is-active']">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <img src={{item.iconName}} height="15" width="15" alt="">
                  <span class="title" style="color: white;">{{item.displayName}}</span>
                </div>
              </mat-list-item>
            </span>
          </span>
        </mat-nav-list> -->
        
        <div class="footer footerLogo p-4">
          <img src="../assets/icon/White_logo.png"  alt="">
        </div>
    
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar class="white">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
        <mat-icon aria-label="Side nav toggle icon" style="color:#858B95">menu</mat-icon>
    </button>
    <h4>{{headerName}}</h4>
     
      <span class="example-spacer"></span>
      <!-- <button  mat-icon-button (click)="openOldAdmin()" class="example-icon">
        <img src="../assets/icon/catalog.png"  alt="">
        <img src="../assets/icon/catalogManager.png"  alt="" height="40px">
      </button>  
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      
      <button mat-icon-button (click)="openOfs()" class="example-icon">
        <img src="../assets/icon/cart.png"  alt="">
        <img src="../assets/icon/orderManager.png"  alt="" height="40px">
      </button>  
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -->
    
     <!--   
     <button mat-icon-button class="example-icon" style="color:#858B95">
      <mat-icon matTooltip="Refresh Dashboard" (click)="reload('/dashboard')">refresh</mat-icon>
    </button>
 -->
    
    <button mat-icon-button [matMenuTriggerFor]="menu" style="color:#858B95" class="example-icon" (click)="toggleBadgeVisibility()">
      <mat-icon matBadge={{notAcceeptedCount}} matBadgeColor="accent" [matBadgeHidden]="hideMatBadge">warning</mat-icon>
    </button>
    
      <mat-menu #menu="matMenu">
       <h5 class="app-notification__title"> New Alerts </h5>
        <button class="app-notification__content" (click)="openDialog(alertAccountId,notAccepted)" mat-menu-item> {{notAcceeptedCount}} Orders not accepted after 15 minutes of submission</button>
        <button class="app-notification__content" (click)="openDialog(alertAccountId,notDelivered)" mat-menu-item> {{notDeliveredCount}} Orders not delivered after 2 hour of preferred delivery time</button>
      </mat-menu>
    
    <button mat-icon-button [matMenuTriggerFor]="menuNot" style="color:#858B95" class="example-icon"  (click)="toggleBadgeVisibilityNoti()">
      <mat-icon matBadge={{countNots}} matBadgeColor="accent" [matBadgeHidden]="hideMatBadgen">notifications</mat-icon>
    </button> 
    <mat-menu #menuNot="matMenu">
      <h5 class="app-notification__title">Notifications</h5>
      <button mat-menu-item *ngFor="let x of notsData">
       <p>{{x.data2}}</p>&nbsp; 
       <p>{{x.data3 | dateAgo }}</p>
      </button>
      
     </mat-menu>

    <button mat-icon-button (click)="confirmLogout()" class="example-icon" style="color:#858B95">
      <mat-icon>logout</mat-icon>
    </button>
    </mat-toolbar>
     
    <router-outlet></router-outlet>
  </mat-sidenav-content>
 

</mat-sidenav-container>



