import { Component, OnInit,Inject,ViewChild } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { AppUserSettingsComponent } from '../app-user-settings/app-user-settings.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {BuyersService} from '../buyers.service'
import {UpdateAppUserModel} from '../models/buyer.model'
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-app-users',
  templateUrl: './app-users.component.html',
  styleUrls: ['./app-users.component.css']
})
export class AppUsersComponent implements OnInit {

  displayedColumns: string[] = ['id', 'name', 'phone','email','pin','actions'];
  @ViewChild(MatPaginator) paginatorappuser: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;
  dataSourceAppUsers : any;
  dataLengthAppUsers:number=0;
  isAppUser =true;
  updateAppUserModel:UpdateAppUserModel;
  errorMessage:string ="";
  shopid:string;
  id:string;

  constructor(private buyersService:BuyersService,
    private toastr:ToastrService,
    public dialog: MatDialog,private dialogRef: MatDialogRef<AppUsersComponent>,
   
    @Inject(MAT_DIALOG_DATA) data) { 
       
      
      this.dataSourceAppUsers = new MatTableDataSource(data.users);
      this.shopid = data.shopid;
      this.id = data.retailerid;
     
      this.dataLengthAppUsers = data.users.length;
      console.log(this.dataLengthAppUsers)
      if(this.dataLengthAppUsers === 0){
        this.dialogRef.updateSize('250px','200px');
        this.isAppUser = false;
        }
    }

  ngOnInit(): void {
   
  }
  ngAfterViewInit() {
    this.dataSourceAppUsers.paginator = this.paginatorappuser;
    this.dataSourceAppUsers.sort = this.sort; 
  }


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSourceAppUsers.filter = filterValue;
  }

  openAppUserSettings(settings){
    const dialogConfig = new MatDialogConfig();

            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;

            dialogConfig.width = "30%";
          
            dialogConfig.data = settings;
            const dialogRef = this.dialog.open(AppUserSettingsComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(data => {

              this.updateAppUserModel = data;
              if (this.updateAppUserModel) {
              this.updateAppUserModel['shopId'] = this.shopid;
              this.updateAppUserModel['retailerShopId'] =this.id;
              
                console.log(this.updateAppUserModel);
        
                this.buyersService.updateAppUser(this.updateAppUserModel).subscribe(
                  (res: any) => {
                    if (res.acknowledgement.status === "SUCCESS") {
        
                      this.toastr.success("App user details updated", "SUCCESS")
                      this.refreshTable();
                    }
                    else {
                      this.errorMessage = res.acknowledgement.message
                      this.toastr.error(this.errorMessage, "ERROR")
                    }
        
                  },
                  (err: HttpErrorResponse) => {
                    if (err) {
                      this.errorMessage = err.error.message
                      this.toastr.error(this.errorMessage, "ERROR")
                    }
                    else {
                      this.toastr.error("Sorry,Could not update the App user details", "ERROR")
                    }
        
                  }
                );
              }
            });
            

  }
save ()
{
 
 this.dialogRef.close(); 
}

close() {
  
  this.dialogRef.close();  
}

refreshTable(){
  this.buyersService.getUserListOfBuyer(this.shopid, this.id).subscribe(
    (res: any) => {
      if (res) {
        this.dataSourceAppUsers = new MatTableDataSource(res.Users)
        this.dataSourceAppUsers.paginator = this.paginatorappuser;
      }
    });
}
}