<div class="example-container mat-elevation-z8">

    <h2 class="text-center" mat-dialog-title>Excel</h2>
    
    <mat-dialog-content>
        <button type="button" class="btn btn-info" (click)="processExcel()"><i class="mdi mdi-rocket mr-1"></i><span>Generate Excel</span></button>
        <div class="mt-4">
          
          

        </div>
        <div class="progress mb-2" *ngIf="isGeneratedClick">
          
          <div class="progress-bar progress-bar-striped bg-success" role="progressbar" id="myBar" ></div>
      </div>
      <div *ngIf="isGenerated">
        Please press 'Download' to download the Excel file <br>
      <a href="{{excelDataUrl}}" class="btn btn-info" ><i class="mdi mdi-arrow-down-bold-hexagon-outline mr-1"></i><span>Download Excel</span></a>
    </div>
        
    </mat-dialog-content>
    <mat-dialog-actions class="align-items-right">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeExcel()">Close</button>
    </mat-dialog-actions>



</div>