import { Component, ViewChild,Input } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from '../../../src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { BnNgIdleService } from 'bn-ng-idle';
import { authService } from './../Service/authService';
import { ActivatedRoute, Router ,NavigationEnd,Event as NavigationEvent } from '@angular/router';
import { ReportService } from './../Service/report.service';
import { notificationResponse } from './../Models/Response/notificationResponse';
import { ServiceService } from './../Service/service.service';
import { alertSummeryResponse } from '../Models/Response/alertSummeryResponse';
import { alertDetailsResponse } from '../Models/Response/alertDetailsResponse';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertDialogComponent } from '../my-navbar/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from '../my-navbar/confirm-dialog/confirm-dialog/confirm-dialog.component';
import { NavItem } from './models/navItem'
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {ControlPanelService} from '../control-panel/control-panel.service'


@Component({
  selector: 'app-my-navbar',
  templateUrl: './my-navbar.component.html',
  styleUrls: ['./my-navbar.component.scss']
})



export class MyNavbarComponent {

  isExpanded = true;
  headerName:string="";
  showSubmenu: boolean = false;
  isShowing = false;
  showModel: boolean = false
  showSubSubMenu: boolean = false;
  catalogURL = environment.catalogURL
  ofsURL = environment.ofsURL
  businessName: string;
  hideMatBadge: boolean;
  hideMatBadgen: boolean;
  notAcceeptedCount: string;
  notDeliveredCount: string;
  notsData: any;
  countNots: number;
  notAccepted: string;
  notDelivered: string;
  alertAccountId: number;
  displayedColumns: string[] = ['orderId', 'customerBusinuss', 'phone', 'customerUser', 'submittedTime', 'expectedDeliveryTime', 'lastUpdateTime', 'totalCost', 'paymentMethod', 'status'];
  alertDetailsData
  alertData
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  alertCount: string;
  alertMessage: string;
  buyerRoute: string = '/buyers'
  productUpload: string = '/productupload'
  groupRoute: string = '/buyers/1'
  enableInventory:boolean = false;
  ssoUrl = environment.SsoURL;
  public currentUrl = new BehaviorSubject<string>(undefined);
  baseUrl=environment.serviceURL+"storimartadmin";
  menuArray: NavItem[] = [
    {
      displayName: 'Dashboard',
      iconName: "../assets/icon/dash.png",
     
      route: './dashboard',
    },
    {
      displayName: 'Catalog Manager',
      iconName: "../assets/icon/catalog_white.png",
    },
    
    /* {
      displayName: 'Subscription',
      iconName: '../assets/icon/subscribeWhite.png',
      
      route: '/subscription',
    }, */
    {
      displayName: 'Buyers',
      iconName: '../assets/icon/buyer.png',
      route: this.buyerRoute
    },
    {
      displayName: 'Generate Excel',
      iconName: '../assets/icon/excel.png',
      route: this.productUpload
    },
    {
      displayName: 'Order Manager',
      iconName: "../assets/icon/order_white.png",
    },
   /*  {
      displayName: 'Sales Force',
      iconName: '../assets/icon/salesMen.png',
      route: '/salesexecutives'
    }, */
    {
      displayName: 'Stock',
      iconName: '../assets/icon/stock_white.png',
      route: '/stock'
    },
    {
      displayName: 'Reports',
      iconName: "../assets/icon/white_c.png",
     
      route: '/report'
    },
    {
      displayName: 'Settings',
      iconName: '../assets/icon/white_a.png',
      //iconName:'&#x1F601;',
      route: '/settings',
    },
  ]

  menuArrayWithoutStock: NavItem[] = [
    {
      displayName: 'Dashboard',
      iconName: "../assets/icon/dash.png",
     
      route: '/dashboard',
    },
    {
      displayName: 'Catalog Manager',
      iconName: "../assets/icon/catalog_white.png",
    },
    /* {
      displayName: 'Subscription',
      iconName: '../assets/icon/subscribeWhite.png',
      
      route: '/subscription',
    }, */
    {
      displayName: 'Buyers',
      iconName: '../assets/icon/buyer.png',
      route: this.buyerRoute
    },
    {
      displayName: 'Order Manager',
      iconName: "../assets/icon/order_white.png",
    },
   /*  {
      displayName: 'Sales Force',
      iconName: '../assets/icon/salesMen.png',
      route: '/salesexecutives'
    }, */
    {
      displayName: 'Reports',
      iconName: "../assets/icon/white_c.png",
     
      route: '/report'
    },
    {
      displayName: 'Settings',
      iconName: '../assets/icon/white_a.png',
      //iconName:'&#x1F601;',
      route: '/settings',
    },
  ]



  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
    event$
  generalSettingsDetails: any;

  constructor(private breakpointObserver: BreakpointObserver,
    private bnIdle: BnNgIdleService,
    private authService: authService,
    private toastr: ToastrService,
    private router: Router,
    private service: ServiceService,
    private reportService: ReportService,
    public dialog: MatDialog,
    private controlPanelService:ControlPanelService
   
  ) {

    //this.headerName = (router.routerState.snapshot.url).substring(1);
    this.event$ = this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationEnd) {
        console.log('event url - ',event.url)
        if(event.url.includes("?"))
        event.url = event.url.substring( 0, event.url.indexOf("?"))
        this.headerName = (event.url).substring(1).toUpperCase();
        if(this.headerName=='PRODUCTUPLOAD')
          this.headerName = 'PRODUCT UPLOAD';    
      }
  });

    this.businessName = localStorage.getItem("CompanyName");
    /* this.bnIdle.startWatching(900).subscribe((res) => {
      if (res) {
        alert("session expired. Please login");
        this.authService.logoutHandler()
        this.bnIdle.stopTimer();
      }  })*/
   
  }

  ngOnDestroy(){
    this.event$.unsubscribe();
  }
   


  onmouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  onmouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

  toggleBadgeVisibility(){
    this.hideMatBadge=true;
    localStorage.setItem('hideMatBadge',"true");
  }

  toggleBadgeVisibilityNoti(){
    this.hideMatBadgen=true;
    localStorage.setItem('hideMatBadgen',"true");
  }



  ngOnInit() {
    this.controlPanelService.inventorySubject$.subscribe(res => {
      this.enableInventory=res;
    })
  
    this.controlPanelService.getgeneralSettings().subscribe(
      res => {

        this.generalSettingsDetails = res.generalSettings;
        if(this.generalSettingsDetails)
        {
        if(this.generalSettingsDetails.enableInventory === true) 
        this.enableInventory=true;
        }
      })

      console.log(this.enableInventory);
      console.log(this.menuArray)

   if(localStorage.getItem('hideMatBadge') === "true")
   this.hideMatBadge = true;
   if(localStorage.getItem('hideMatBadgen') === "true")
   this.hideMatBadgen = true;

    this.service.getNotification()
      .subscribe((nots: notificationResponse) => {
        if (nots.acknowledgement && nots.acknowledgement.status === "SUCCESS") {
          this.notsData = nots.data
          console.log(this.notsData);
          if (this.notsData != null) {
            this.countNots = this.notsData.length;
          }
          else {
            this.hideMatBadgen = true;
          }
        }

      });

    this.reportService.getAlertSummery()
      .subscribe((alerts: alertSummeryResponse) => {
        this.notAcceeptedCount = alerts.notAccepted
        this.notDeliveredCount = alerts.notDelivered
        this.alertAccountId = alerts.accountId
        this.notAccepted = "notAccepted"
        this.notDelivered = "notDelivered"
        if (this.notAcceeptedCount == '0') {
          this.hideMatBadge = true;
        }
      });
  }

  /*  reLoad(): void{
     this.router.navigateByUrl('/dashboard');
     this.toastr.success("Dashboard Refreshed","SUCCESS")
   }
  */
  async reload(url: string): Promise<boolean> {
    await this.router.navigateByUrl('.', { skipLocationChange: true });
    return this.router.navigateByUrl(url);

  }

  logout() {
    this.authService.logoutHandler()
  }


  openOldAdmin() {
  
    window.open(this.baseUrl +'/SSOLogin/'+localStorage.getItem("AuthorizationToken"),"_blank")
    //window.open('http://localhost:4200/#/catalogManager',"_blank")
    /*  var url = '';
    this.authService.newSsoCall()
      .subscribe(loginData => {
        //window.location.href = loginData.url;
        //window.open(loginData.url, "_blank");
        console.log(loginData);
      }, (err: HttpErrorResponse) => {
        //window.location.href = err.url;
       // window.open(err.url, "_blank");
      });  */
  }


  openOfs() {

    window.open(this.ssoUrl+'#/sso/'+localStorage.getItem("AuthorizationToken"),"_blank")
   /*  var url = '';
    this.authService.openOfs()
      .subscribe(loginData => {
       //window.location.href = loginData.url;
       window.open(loginData.url, "_blank");
        console.log(loginData);
      }, (err: HttpErrorResponse) => {
      // window.location.href = err.url;
      window.open(err.url, "_blank");
        console.log(err);
      }); */
  }

  openDialog(accountId, alertType) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;



    dialogConfig.data = {
      accountId: accountId,
      alertType: alertType
    }


    const dialogRef = this.dialog.open(AlertDialogComponent, dialogConfig);
  }

  confirmLogout(){
    
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
          title: "Do you wish to Log Off?"
         }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.authService.logoutHandler(); // Call logout only if the user confirms
      }
    });
   
  }

 

}


