import { Component, OnInit,Inject,ViewChild } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppUserSettingsComponent } from '../app-user-settings/app-user-settings.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {BuyersService} from '../buyers.service'
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-buyergroups',
  templateUrl: './buyergroups.component.html',
  styleUrls: ['./buyergroups.component.css']
})
export class BuyergroupsComponent implements OnInit {

  displayedColumns: string[] = ['id', 'parameter', 'value'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource : any;
  dataLength:any;
  errorMessage:string ="";
  

  constructor(private buyersService:BuyersService,
    private toastr:ToastrService,
    public dialog: MatDialog,private dialogRef: MatDialogRef<BuyergroupsComponent>,
   
    @Inject(MAT_DIALOG_DATA) data) { 
       
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort; 
      this.dataLength = this.dataSource.length;
    }

  ngOnInit(): void {
   
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  save ()
{
 
 this.dialogRef.close(); 
}

close() {
  
  this.dialogRef.close();  
}

}
